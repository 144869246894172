import React from 'react';

export default function Overview({
  size, // or any default size of your choice
  color, // or any color of your choice
  style,
  onClick,
  className,
}) {
  return (
    <svg
      style={style}
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 25 25`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.2383 3.22217H3.23828V10.2222H10.2383V3.22217Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.2383 3.22217H14.2383V10.2222H21.2383V3.22217Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.2383 14.2222H14.2383V21.2222H21.2383V14.2222Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.2383 14.2222H3.23828V21.2222H10.2383V14.2222Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
