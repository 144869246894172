import React, { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import NavBar from '../NavBar/NavBar';
import TopBar from '../TopBar';
import SideBar from '../SideBar/SideBar';
import Hamburger from 'hamburger-react';
// import logo from '../../Assets/Ixian/images/Grand/logo_black.svg';

import actions from '../../../Redux/reducers/user/user.actions';
import { useDispatch, useSelector } from 'react-redux';
import './headerAtlantica.css';

export const Header = () => {
  const dispatch = useDispatch();

  const isSidebarVisible = useSelector((state) => state.user.isSidebarVisible);

  const toggle = () => {
    dispatch(actions.setSidebarVisibility(!isSidebarVisible));
    window.scrollTo(0, 0);
  };

  const Tablet = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  const Mobile = useMediaQuery({
    query: '(min-width: 280px) and (max-width: 1023px)',
  });
  const programLabel = localStorage.getItem('programLabel');
  console.log();

  // const [isAnimated, setIsAnimated] = useState(false);

  // const animateMenu = () => setIsAnimated(!isAnimated);

  return (
    <div id='homeContainer' className='homeContainer'>
      <div
        style={{
          // backgroundImage: `url(${logo})`,
          backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        className='nav'>
        <div onClick={toggle} className='nav-menu'>
          <Hamburger color='#585955' hideOutline={true} direction='right' />
          {/* <div
            id='nav-icon1'
            className={isAnimated ? 'open' : ''}
            onClick={animateMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div> */}
        </div>
        {Tablet && <TopBar />}
        {/* <img id='logo' className='nav-logo' src={logo} alt='logo' /> */}
      </div>
      {Mobile && <SideBar isOpen={isSidebarVisible} toggle={toggle} />}
      <div className='navbarContainer'>
        <NavBar />
      </div>
    </div>
  );
};

export default Header;
