import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import actions from '../../Redux/reducers/user/user.actions';
import CookieService from '../../API/Services/CookieService';
import { ProgramLabelDataContext } from '../../ContextProviders/programLabel';

const useLogin = (Validate) => {
  const [values, setValues] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});

  const [authErrors, setAuthErrors] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const expiresAt = 60 * 24;

  const urlParams = new URLSearchParams(window.location.search);
  const programLabelFromURL = urlParams.get('programLabel');

  const programLabel =
    programLabelFromURL ?? localStorage.getItem('programLabel');

  const handleChange = (e) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const { name } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  function isEmpty(object) {
    for (const property in object) {
      return false;
    }
    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(Validate(values));
    console.log(errors);
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      signIn();
    }
  }, [errors, isSubmitting]);

  const signIn = () => {
    dispatch(actions.userLoginStart());

    axios({
      method: 'post',
      baseURL: '/',
      url: 'api/account/login',
      data: {
        username: values.email,
        password: values.password,
        programLabel: programLabel,
      },
    })
      .then((resp) => {
        dispatch(actions.userLoginSuccess(resp.data));
        console.log(resp.data);
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.data.errorMessage);
        }
        handleLoginSuccess(resp.data, values.rememberMe);
        console.log(resp.data, rememberMe);
        navigate('/home');
        sessionStorage.setItem('acid', resp.data.acid);
      })
      .catch((error) => {
        dispatch(actions.userLoginError(error.toString()));
        console.log(error);
        setAuthErrors(error.toString());
      });
  };

  const handleLoginSuccess = (response, rememberMe) => {
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('userInfo', JSON.stringify(response));
    if (!rememberMe) {
      let options = { path: '/' };
      CookieService.set('acidToken', response.acidToken, options);
      return;
    }

    let date = new Date();
    date.setTime(date.getTime() + expiresAt * 60 * 1000);
    let options = {
      path: '/',
      expires: date,
      secure: true,
      sameSite: true,
    };
    CookieService.set('acidToken', response.acidToken, options);
    return;
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    authErrors,
    setAuthErrors,
  };
};

export default useLogin;
