import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Header from '../Components/Header';
import Message from '../Components/Message/Message';
import '../Components/ChangePassword/ChangePasswordAtlantica.css';
import { ThemeDataContext } from '../ContextProviders/theme';
import { useForm } from 'react-hook-form';
import { BsEyeFill } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import toast, { Toaster, useToaster, useToasterStore } from 'react-hot-toast';
import Exclamation from '../Icons/Exclamation';
import Tick from '../Icons/Tick';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export default function ChangePassword() {
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmNewPasswordShown, setConfirmNewPasswordShown] = useState(false);
  // const [message, setMessage] = useState('');
  // const [hasError, setHasError] = useState(false);

  const navigate = useNavigate();
  const toggleCurrentPassword = () => {
    setCurrentPasswordShown(!currentPasswordShown);
  };
  const toggleNewPassword = () => {
    setNewPasswordShown(!newPasswordShown);
  };
  const toggleConfirmNewPassword = () => {
    setConfirmNewPasswordShown(!confirmNewPasswordShown);
  };

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const newPassword = watch('newPassword');
  const confirmNewPassword = watch('confirmNewPassword');
  const onError = (errors, e) => console.log(errors, e);

  const userInfo = JSON.parse(localStorage['userInfo']) || {};

  const memberEmail = userInfo.memberInfo.memberEmail || {};

  const onSubmit = (data) => {
    console.log(data, 'changePassword');
    window.scrollTo(0, 0);
    axios({
      method: 'post',
      baseURL: '/',
      url: 'api/password/change',
      data: {
        programLabel: localStorage.getItem('programLabel'),
        userEmail: memberEmail,
        oldPassword: data.currentPassword,
        newPassword: data.newPassword,
      },
    })
      .then((resp) => {
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.data.errorMessage);
        }
        notifySuccess();
        setTimeout(() => navigate('/home/myprofile'), 3000);
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.toString());

        // showMessage(err.toString(), true);
      });
  };

  // const showMessage = (msg, isError) => {
  //   const messageClearTimeout = 5000;

  //   setMessage(msg);
  //   setHasError(isError);
  //   window.scrollTo(0, 0);
  //   setInterval(() => {
  //     setMessage('');
  //     setHasError(false);
  //   }, messageClearTimeout);
  // };

  const { toasts, handlers } = useToaster();

  const notifySuccess = () =>
    toast.success('Your password has been changed successfully.');
  const notifyError = (error) => toast.error(error);

  const TOAST_LIMIT = 1;

  useEffect(() => {
    console.log(toasts, 'toasts');
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  const handleDismiss = () => {
    toasts.forEach((t) => toast.dismiss(t.id));
  };

  const notifyPassComposition = () =>
    toast(
      <div
        style={{
          display: 'flex',
          width: '100%',
          borderRadius: '7px',
          fontFamily: 'Lato',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
          background: 'transparent',
          color: `black`,
        }}>
        <div
          style={{
            display: 'flex',
            order: '1',
            alignItems: 'center',
            width: '20%',
            transform: 'translateX(-10%)',
          }}>
          <Exclamation
            width='50'
            height='50'
            viewBox='-4 1 25 15'
            color='red'
          />
          {/* <BsExclamationCircle
            color='red'
            size='40'
          /> */}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            order: '2',
            width: '80%',
            transform: 'translateX(0%)',
          }}>
          Password must contain at least: 8 characters, 1 uppercase letter, 1
          lowercase letter, 1 number, 1 symbol.
        </div>
      </div>,
      {
        style: {
          borderLeft: '6.5px solid red',
          maxWidth: '375px',
        },

        position: 'top-center',
      }
    );

  const isSidebarVisible = useSelector((state) => state.user.isSidebarVisible);
  return (
    <>
      <Header />
      <div style={{ visibility: `${isSidebarVisible ? 'hidden' : 'visible'}` }}>
        <div className='changePassword-heading'>
          <h1>Change Password</h1>
          {/* <Message message={message} isError={hasError} /> */}
          <div onClick={handleDismiss}>
            <Toaster
              duration='1000000'
              position='top-center'
              containerClassName='changePasswordToaster'
              containerStyle={{
                top: 10,
                left: 10,
                bottom: 10,
                right: 10,
              }}
              toastOptions={{
                error: {
                  duration: 1000000,
                  icon: (
                    <Exclamation
                      width='50'
                      height='50'
                      viewBox='-4 1 25 15'
                      color='red'
                    />
                  ),
                  style: {
                    // border: '1px solid #713200',
                    color: `black`,
                    fontWeight: 'normal',
                    background: `white` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                    filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                    fontFamily: 'Lato',
                    borderRadius: '7px',
                    height: 'auto',
                    maxHeight: '65px',
                    fontWeight: '600',
                    transform: 'translateY(100%)',
                    borderLeft: '6.5px solid red',
                  },
                },
                success: {
                  duration: 1000000,
                  icon: (
                    <Tick
                      color='#26A65B'
                      width='50'
                      height='50'
                      viewBox='0 0 50 50'
                    />
                    // <Exclamation width='60' height='60' viewBox='0 0 16 16' />
                  ),
                  style: {
                    // border: '1px solid #713200',
                    // padding: '16px',
                    color: `black`,
                    fontWeight: 'normal',
                    background: `#fff` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                    filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                    fontFamily: 'Lato',
                    borderRadius: '7px',
                    height: 'auto',
                    fontWeight: '600',
                    transform: 'translateY(100%)',
                    borderLeft: '6.5px solid #26A65B',
                  },
                },
              }}
            />
          </div>
        </div>
        <form
          className='changePassword-form'
          onSubmit={handleSubmit(onSubmit, onError)}>
          <div className='changePassword-CurrentPasswordWrap'>
            <label className='changePassword-Label' htmlFor='currentPassword'>
              Current Password:
            </label>
            <input
              className='changePassword-Input'
              type={currentPasswordShown ? 'text' : 'password'}
              id='currentPassword'
              name='currentPassword'
              {...register('currentPassword', {
                required: 'Password is required',
                // pattern: {
                //   value:
                //     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
                //   message:
                //     'Password must contain minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and any 1 symbol.',
                // },
              })}
            />
            <BsEyeFill
              fill='grey'
              className='changePassword-showIcon'
              onClick={toggleCurrentPassword}
            />
          </div>
          {errors.currentPassword && (
            <p role='alert' className='changePassword-CurrentPasswordError'>
              {errors?.currentPassword?.message}
            </p>
          )}
          <div className='changePassword-NewPasswordWrap'>
            <label className='changePassword-Label' htmlFor='newPassword'>
              New Password:
            </label>
            <input
              className='changePassword-Input'
              type={newPasswordShown ? 'text' : 'password'}
              id='newPassword'
              name='newPassword'
              {...register(
                'newPassword',
                {
                  required: 'Password is required',
                  validate: {
                    isValid: () =>
                      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/.test(
                        newPassword
                      ) || notifyPassComposition(),
                  },
                }
                // pattern: {
                //   value:
                //     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
                //   message:
                //     'Password must contain minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and any 1 symbol.',
                // },
              )}
            />
            <BsEyeFill
              fill='grey'
              className='changePassword-showIcon'
              onClick={toggleNewPassword}
            />
          </div>
          {errors.newPassword &&
            errors.newPassword.message === 'Password is required' && (
              <p role='alert' className='changePassword-NewPasswordError'>
                {errors?.newPassword?.message}
              </p>
            )}
          <div className='changePassword-ConfirmPasswordWrap'>
            <label className='changePassword-Label' htmlFor='confirmPassword'>
              Confirm New Password:
            </label>
            <input
              className='changePassword-Input'
              type={confirmNewPasswordShown ? 'text' : 'password'}
              id='confirmNewPassword'
              name='confirmNewPassword'
              {...register('confirmNewPassword', {
                required: 'Password is required',
                validate: {
                  passwordsMatch: () =>
                    newPassword === confirmNewPassword ||
                    'Passwords must match',
                },
              })}
            />
            <BsEyeFill
              fill='grey'
              className='changePassword-showIcon'
              onClick={toggleConfirmNewPassword}
            />
          </div>
          {errors.confirmNewPassword && (
            <p role='alert' className='changePassword-ConfirmNewPasswordError'>
              {errors?.confirmNewPassword?.message}
            </p>
          )}
          <div className='changePassword-buttonContainer'>
            <button
              style={buttonStyle}
              // onClick={notifySuccess}
              type='submit'
              className='changePassword-Button'>
              CHANGE PASSWORD
            </button>
          </div>
          {/* <ul className='changePassword-Requirements'>
          <li>Must be between 8 and 16 characters</li>
          <li>Contain one uppercase letter </li>
          <li>Contain one lowercase letter</li>
          <li>Contain a number</li>
          <li>Contain any one symbol</li>
        </ul> */}
          <br />
          <br />
        </form>
      </div>
    </>
  );
}
