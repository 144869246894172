import React, { useCallback, useRef, useEffect } from 'react';
import Draggable from 'react-draggable';
import LargeX from '../../../Icons/LargeX';

function CalendarModal({ setTrigger, trigger, children }) {
  const modalRef = useRef(null);
  const closeButtonRef = useRef(null);
  const nodeRef = useRef(null);

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setTrigger(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && trigger) {
        setTrigger(false);
      }
    },
    [setTrigger, trigger]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  useEffect(() => {
    function handler(event) {
      if (closeButtonRef.current?.contains(event.target)) {
        setTrigger(false);
      }
    }
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, []);

  return (
    <div>
      <div
        ref={modalRef}
        className='flex justify-center items-center z-[998] fixed top-0 left-0 w-full h-full bg-transparent landscape:overflow-y-scroll'
        onClick={closeModal}>
        <Draggable nodeRef={nodeRef}>
          <div
            ref={nodeRef}
            className='relative h-[auto]  w-[330px] max-w-[570.68px] bg-white z-[998] translateY-[5%] rounded-[20px] border-[2px] border-solid border-skin-secondary box-border
          shadow-[0px_4px_20px_rgba(0,0,0,0.4)]'>
            <div
              ref={closeButtonRef}
              className='flex justify-center items-center absolute w-[30px] h-[30px] top-[4%] right-[4%] text-skin-primary hover:text-skin-a11yrounded-[40px]'>
              <span onClick={closeModal}>
                <LargeX color='#909090' width='15' height='15' />
              </span>
            </div>
            {children}
          </div>
        </Draggable>
      </div>
    </div>
  );
}

export default CalendarModal;
