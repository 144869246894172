import React, { useState, useEffect, useContext, useRef } from 'react';
import { ThemeDataContext } from '../../../ContextProviders/theme';
import ChevronDown from '../../../Icons/ChevronDown';
import Language from '../../../Icons/Language';
import BookNow from '../BookNow/BookNow2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import Select, { components } from 'react-select';
import Danger from '../../../Icons/Danger';
import { format } from 'date-fns';
import FormErrorMessage from 'rsuite/esm/FormErrorMessage';
import { useMediaQuery } from 'react-responsive';
import Translation from '../Translation/Translation';
import '../Translation/translation.css';
import Hamburger from 'hamburger-react';
import actions from '../../../Redux/reducers/vlite/vlite.actions';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from '../../../Icons/Calendar';
import ArrivalDate from './ArrivalDate';

function TopBar2({ bookListings }) {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [hotels, setHotels] = useState([]);
  const programLabel = localStorage.getItem('programLabel');
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const handlePopper = () => {
    setIsPopperOpen(!isPopperOpen);
    // buttonRef?.current?.focus();
  };

  const dispatch = useDispatch();

  const isSidebarVisible = useSelector((state) => state.vlite.isSidebarVisible);

  const toggle = () => {
    dispatch(actions.setSidebarVisibility(!isSidebarVisible));
    window.scrollTo(0, 0);
  };

  const Lg = useMediaQuery({
    query: '(max-width: 1200px) ',
  });
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor, buttonColor } = portalInfo || {};
  
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const acidToken = userInfo.acidToken;
  const emailToken = userInfo.eMailToken;

  const initialValues = {
    nights: null,
    // hotel: { value: '', label: '' },
  };
  const {
    reset,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: initialValues,
  });

  const getHotels = async () => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/init/getLoyaltyInfo',
      params: {
        programLabel: localStorage['programLabel'],
      },
    })
      .then((resp) => {
        setHotels(resp.data.locations);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getHotels();
  }, []);

  const hotel = _.map(hotels, 'description');

  const newArray = hotel.map((h, i) => ({ value: h, label: h }));

  const style = {
    container: (base) => ({
      ...base,
      // marginTop: '0.25rem',
      width: '310px',
      height: '40px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#173352',
      fontWeight: '300',
    }),
    valueContainer: (base) => ({
      ...base,
      width: '310px',
      paddingBottom: '0',
      height: '41px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#173352',
      fontWeight: '300',
      // marginLeft: '-0.28rem',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#14D5FF',
      '&:hover': { color: '#14D5FF' },
    }),
    group: (base) => ({
      ...base,
      width: '310px',
    }),
    menu: (base) => ({
      ...base,
      width: '310px',
      // marginLeft: '2rem',
    }),
    control: (base) => ({
      ...base,
      '&:hover': { borderColor: '#14D5FF' },
      border: '1px solid #14D5FF',
      boxShadow: 'none',
      borderRadius: '5px',
      width: '310px',
      // marginLeft: '2rem',
      fontSize: '1rem',
      height: '41px',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: '#173352',
      marginLeft: '0.15rem',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#173352',
      fontWeight: '300',
    }),
    input: (base) => ({
      ...base,
      width: '310px',
      marginLeft: '-0.28rem',
      fontFamily: 'Roboto, sans-serif',
      color: '#173352',
      fontSize: '1rem',
      fontWeight: '300',
    }),

  };
  const styleErrors = {
    container: (base) => ({
      ...base,
      // marginTop: '0.25rem',
      width: '310px',
      height: '40px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
    }),
    valueContainer: (base) => ({
      ...base,
      width: '310px',
      paddingBottom: '0',
      height: '41px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
      // marginLeft: '-0.28rem',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'transparent',
      '&:hover': { color: '#D80027' },
    }),
    group: (base) => ({
      ...base,
      width: '310px',
    }),
    menu: (base) => ({
      ...base,
      width: '310px',
      // marginLeft: '2rem',
    }),
    control: (base) => ({
      ...base,
      '&:hover': { borderColor: '#D80027' },
      border: '1px solid #D80027',
      boxShadow: 'none',
      borderRadius: '5px',
      width: '310px',
      // marginLeft: '2rem',
      fontSize: '1rem',
      height: '41px',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      marginLeft: '0.15rem',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
    }),
    input: (base) => ({
      ...base,
      width: '310px',
      marginLeft: '-0.28rem',
      fontFamily: 'Roboto, sans-serif',
      color: '#D80027',
      fontSize: '1rem',
      fontWeight: '300',
    }),

  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {errors.hotel && <Danger color='#D80027' width='25' height='24' />}
      </components.DropdownIndicator>
    );
  };

  const clearHotelErrors = () => {
    if (errors.hotel) {
      clearErrors('hotel');
    }
  };

  const Hotel = (props) => (
    <Select
      {...props}
      // components={{
      //   IndicatorSeparator: () => null,
      // }}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      menuPlacement='auto'
      // className={`w-full h-[41px] bg-transparent rounded-[5px] border-skin-secondary ${
      //   errors.hotel && 'border-[#D80027]'
      // } indent-[0.5rem] border-solid  border-[1px]  text-skin-a11y font-light text-base leading-[19px]`}
      placeholder='Select Hotel...'
      styles={!errors.hotel ? style : styleErrors}
      options={newArray}
      onChange={props.onChange}
      onBlur={props.onBlur}
      selected={props.value}
      onClick={props.onClick}
    />
  );

    const bookNow = (data) => {
      console.log(data, 'data');
      let hotel = data.hotel?(hotels.filter((h) => h.description === data.hotel.value)):hotels[0].description;
      let url = data.hotel?hotel[0].bookNowUri:hotels[0].bookNowUri;
      let arrival;
      if (
        typeof data.arrivalDate === 'string' ||
        date.arrivalDate instanceof String
      ) {
        const [day, month, year] = data.arrivalDate.split('/');
        arrival = new Date(+year, +month - 1, +day);
        arrival = format(arrival, 'yyyy-MM-dd');
      } else if (
        typeof data.arrivalDate === 'Date' ||
        date.arrivalDate instanceof Date
      ) {
        arrival = `${format(arrival, 'yyyy-MM-dd')}`;
      }
      window.open(
        url +
          '?checkin=' +
          arrival +
          '&nights=' +
          data.nights +
          '&logintoken=' +
          emailToken
      );
    };

  const onError = (errors, e) => console.log(errors, e, 'error');
  const arrivalDate = watch('arrivalDate');

  function isInTheFuture(date) {
    let date2 = date;
    if (typeof date2 === 'string' || date2 instanceof String) {
      const [day, month, year] = date2.split('/');
      date2 = new Date(+year, +month - 1, +day);
    }

    const today = new Date();

    // 👇️ OPTIONAL!
    // This line sets the time of the current date to the
    // last millisecond, so the comparison returns `true` only if
    // date is at least tomorrow
    today.setHours(23, 59, 59, 998);

    return date2 > today;
  }

  const selectStyle = {
    dropdownIndicator: (base) => ({
      ...base,
      color: '#C2CFE0',
      '&:hover': { color: '#C2CFE0' },
    }),

    control: (base) => ({
      ...base,
      border: '0',
    }),
  };
  const selectStyleLg = {
    dropdownIndicator: (base) => ({
      ...base,
      color: '#C2CFE0',
      '&:hover': { color: '#C2CFE0' },
      transform: 'translate(-85%,0)',
    }),
    indicatorContainer: (base) => ({
      ...base,
      color: '#FFFFFF',
      background: '#FFFFFF',
    }),
    control: (base) => ({
      ...base,
      display: 'flex',
      border: '0',
      flexWrap: 'nowrap',
      alignContent: 'start',
    }),
    container: (base) => ({
      ...base,
      width: '50px',
    }),
    ValueContainer: (base) => ({
      ...base,
      width: '25px',
    }),
    IndicatorsContainer: (base) => ({
      ...base,
      marginRight: '3rem',
    }),
    indicatorContainer: (base) => ({
      ...base,
      transform: 'translateX: -10%',
    }),
  };

  const hotelOptions = newArray; // Replace newArray with the actual array of hotel options

// Check if there's only one hotel option
const showHotelDropdown = hotelOptions.length > 1;
  const pathname = window.location.pathname;

  if (Lg)
    return (
      <div className={`flex w-full h-auto mt-8  `}>
        <ul className='flex w-full h-auto list-none justify-center mb-0 p-0'>
          <div
            className={`${
              pathname.includes('myprofile') || pathname.includes('activity')
                ? 'invisible'
                : ''
            } ${
              bookListings?.length < 1 ? 'fixed bottom-10' : 'hidden'
            } w-full flex justify-center cursor-pointer`}>
            <button
              // style={{
              //   background: 'transparent',
              //   color: '#404040',
              // }}
              onClick={() => setButtonPopup(true)}
              className={`font-roboto cursor-pointer z-[999] text-skin-primary font-semibold w-[150px] h-[43px] text-base leading-[19px] tracking-[0.01em] mt-0 py-[1%] px-[0] rounded-[20px] bg-skin-secondary duration-300  hover:-translate-y-[2px] select-none`}
              style={{boxShadow: `0px 4px 10px ${buttonBackgroundColor}`}}>
              Book now
            </button>
            <BookNow
              trigger={buttonPopup}
              setTrigger={setButtonPopup}
              reset={reset}>
              <h1 className='font-roboto font-semibold text-[32px] leading-[38px] font-darkBlue text-center mt-[60px]'>
                Book your stay
              </h1>
              <form
                className='flex flex-col w-full h-full items-center mt-12'
                onSubmit={handleSubmit(bookNow, onError)}>
                                  {showHotelDropdown ? (

                <div
                  onClick={clearHotelErrors}
                  className='h-[105px]  w-[310px]'>
                  <label className='indent-2 h-[19px] font-roboto font-medium text-base leading-[18.75px] mb-[11.5px] text-skin-primary'>
                    Hotel
                  </label>

                  <Controller
  {...register('hotel', {
    required: 'Hotel is required',
  })}
  className={`rounded-[5px] h-[41px] indent-2 w-[310px] text-base text-skin-primary font-light font-roboto cursor-pointer border-[1px] border-solid ${
    !errors.arrivalDate ? 'border-skin-secondary' : 'border-[#D80027]'
  }`}
  control={control}
  options={hotelOptions}
  name='hotel'
  defaultValue={
    hotelOptions.length <=1 ? hotelOptions[0].value : null
  }
  rules={{
    required: 'Hotel is required',
  }}
  render={({ field: { onChange, onBlur, value } }) => (
    <Hotel
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  )}
/>

{errors.hotels && (
  <Danger
    color='#D80027'
    width='25'
    height='24'
    style={{ transform: 'translate(1090%, -145%)' }}
  />
)}
{errors.hotel && (
  <p
    role='alert'
    className='text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
    {errors?.hotel?.message}
  </p>
)}
                </div>
):null}
                <div className='h-[105px]  w-[310px] relative'>
                  <label className='indent-2 h-[19px] font-roboto font-medium text-base leading-[18.75px] mb-[11.5px] text-skin-primary'>
                    Arrival Date
                  </label>
                  <br />
                  <ArrivalDate
                    isPopperOpen={isPopperOpen}
                    handlePopper={handlePopper}
                    setValue={setValue}
                    value={arrivalDate}
                  />
                  <input
                    className={` rounded-[5px] h-[41px] indent-2 w-[310px] text-base text-skin-primary font-light font-roboto cursor-pointer border-[1px] border-solid ${
                      !errors.arrivalDate
                        ? 'border-skin-secondary'
                        : 'border-[#D80027]'
                    }`}
                    {...register('arrivalDate', {
                      required: 'Arrival date is required',
                      // valueAsDate: true,
                      validate: {
                        isAFutureDate: () =>
                          isInTheFuture(arrivalDate) ||
                          'Arrival date must be a future date',
                      },
                    })}
                  />
                  {/* <Controller
                    {...register('arrivalDate', {
                      required: 'Arrival date is required',
                      valueAsDate: true,
                      validate: {
                        isAFutureDate: () =>
                          isInTheFuture(arrivalDate) ||
                          'Arrival date must be a future date',
                      },
                    })}
                    control={control}
                    defaultValue={null}
                    name='arrivalDate'
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        className={`${
                          !errors.arrivalDate
                            ? 'vlite-datepicker'
                            : 'vlite-datepicker-danger'
                        } rounded-[5px] h-[41px] indent-2 w-[310px] text-base text-skin-primary font-light font-roboto cursor-pointer border-[1px] border-solid ${
                          !errors.arrivalDate
                            ? 'border-skin-secondary'
                            : 'border-[#D80027]'
                        }`}
                        style={{ marginLeft: '2rem' }}
                        dateFormat='dd/MM/yyyy'
                        onChange={onChange}
                        selected={value}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        placeholderText={'DD / MM / YYYY'}
                      />
                    )}
                  /> */}
                  <button
                    type='button'
                    className={`w-[51px] h-[51px] bg-transparent absolute right-[2%] top-[22%]`}
                    aria-label='Pick a date'
                    onClick={handlePopper}>
                    <span
                      className='w-[51px] h-[51px] '
                      role='img'
                      aria-label='calendar icon'>
                      <Calendar />
                    </span>
                  </button>
                  {errors.arrivalDate && (
                    <p
                      role='alert'
                      className={`text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm w-[170px]  ${
                        errors.arrivalDate.message ===
                          'Arrival date must be a future date' && 'w-auto'
                      } h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1`}>
                      {errors?.arrivalDate?.message}
                    </p>
                  )}
                </div>
                <div className='h-[105px] w-[310px]'>
                  <label
                    className='indent-2 h-[19px] font-roboto font-medium text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                    htmlFor='BookNow-Nights'>
                    Nights
                  </label>
                  <br />
                  <input
                    {...register('nights', {
                      required: 'Nights is required',
                      min: {
                        value: 1,
                        message: 'Value must be greater than or equal to 1',
                      },
                    })}
                    className={`w-[310px] h-[41px] bg-transparent rounded-[5px] ${
                      errors.nights
                        ? 'border-[#D80027]'
                        : ' border-skin-secondary '
                    }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                    id='BookNow-Nights'
                    name='nights'
                    type='number'
                  />
                  {errors.nights && (
                    <Danger
                      color='#D80027'
                      width='25'
                      height='24'
                      style={{ transform: 'translate(1090%, -145%)' }}
                    />
                  )}
                  {errors.nights && (
                    <p
                      role='alert'
                      className='text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                      {errors?.nights?.message}
                    </p>
                  )}
                </div>

                <div className='flex w-full justify-center'>
                  <button
                    className={`font-roboto cursor-pointer z-[999] text-skin-primary font-semibold w-[150px] h-[43px] text-base leading-[19px] tracking-[0.01em] mt-0 py-[1%] px-[0] rounded-[20px] bg-skin-secondary duration-300  hover:-translate-y-[2px] select-none`}
                    style={style.shadow}>
                    Book now
                  </button>
                </div>
              </form>
            </BookNow>
          </div>
          <div
            style={{
              //backgroundImage: `url(${logo})`,
              backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
            }}
            className={` w-[65px] bg-no-repeat h-[65px] bg-[length:65px_65px] bg-center  -translate-y-3
            } `}
          />
          <li className='flex absolute top-8 right-7  max-w-[50px] items-center h-[43px] cursor-pointer'>
            <Translation
              className={
                ' max-w-[100px] tracking-[1%] border-0 text-base font-roboto font-semibold leading-[1.35rem] '
              }
              style={selectStyleLg}
            />

            {/* <Language
              width='39'
              height='39'
              className='mr-1 translate-y-[0.12rem]'
            />
            <span
              className={`font-roboto text-skin-primary font-semibold text-base h-[43px]leading-[19px] tracking-[0.01em] mr-2`}>
              English
            </span>
            <ChevronDown width='13' height='8' color='#173352' /> */}
          </li>
        </ul>
      </div>
    );

  return (
    <div className={`flex w-full h-12 mt-8 pr-[6%] `}>
      <ul className='flex w-full h-full list-none justify-end mb-0   '>
        <li className='mr-4 cursor-pointer'>
          <button
            onClick={() => setButtonPopup(true)}
            className={`font-roboto cursor-pointer z-[999] bg-skin-secondary text-skin-primary font-semibold w-[123px] h-[43px] text-base leading-[19px] tracking-[0.01em] mt-0 py-[1%] px-[0] rounded-[20px]  duration-300  hover:-translate-y-[2px] select-none`}
            //style={{boxShadow:style.shadow}}
            >
            Book now
          </button>
          <BookNow
            trigger={buttonPopup}
            setTrigger={setButtonPopup}
            reset={reset}>
            <h1 className='font-roboto font-semibold text-[32px] leading-[38px] font-darkBlue text-center mt-[60px]'>
              Book your stay
            </h1>
            {/* <hr /> */}
            <form
              className='flex flex-col w-full h-full items-center mt-12'
              onSubmit={handleSubmit(bookNow, onError)}>
             {showHotelDropdown ? ( <div onClick={clearHotelErrors} className='h-[105px]  w-[310px]'>
             <label className='indent-2 h-[19px] font-roboto font-medium text-base leading-[18.75px] mb-[11.5px] text-skin-primary'>
                    Hotel
                </label>
                <Controller
                {...register('hotel')}
                defaultValue={
                  hotelOptions.length === 1 ? hotelOptions[0] : null // Set default value if only one option exists
                }
                control={control}
                options={hotelOptions}
                name='hotel'
                rules={{
                  required: 'Hotel is required',
                }}
                render={({ field: { onChange, onBlur, value } }) =>
                  showHotelDropdown ? (
                    <Hotel
                      className={`rounded-[5px] h-[41px] indent-2 w-[310px] text-base text-skin-primary font-light font-roboto cursor-pointer border-[2px] border-solid border-skin-secondary`}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  ) : null
                }
              />
{errors.hotels && (
  <Danger
    color='#D80027'
    width='25'
    height='24'
    style={{ transform: 'translate(1090%, -145%)' }}
  />
)}
{errors.hotel && (
  <p
    role='alert'
    className='text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
    {errors?.hotel?.message}
  </p>
)}
              </div>
             ):null}
              <div className='h-[105px]  w-[310px] relative'>
                <label className='indent-2 h-[19px] font-roboto font-medium text-base leading-[18.75px] mb-[11.5px] text-skin-primary'>
                  Arrival Date
                </label>
                <br />
                <ArrivalDate
                  isPopperOpen={isPopperOpen}
                  handlePopper={handlePopper}
                  setValue={setValue}
                  value={arrivalDate}
                />
                <input
                  className={` rounded-[5px] h-[41px] indent-2 w-[310px] text-base text-skin-primary font-light font-roboto cursor-pointer border-[1px] border-solid ${
                    !errors.arrivalDate
                      ? 'border-skin-secondary'
                      : 'border-[#D80027]'
                  }`}
                  {...register('arrivalDate', {
                    required: 'Arrival date is required',
                    validate: {
                      isAFutureDate: () =>
                        isInTheFuture(arrivalDate) ||
                        'Arrival date must be a future date',
                    },
                  })}
                />
                <button
                  type='button'
                  className={`w-[51px] h-[51px] bg-transparent absolute right-[2%] top-[22%]`}
                  aria-label='Pick a date'
                  onClick={handlePopper}>
                  <span
                    className='w-[51px] h-[51px] '
                    role='img'
                    aria-label='calendar icon'>
                    <Calendar />
                  </span>
                </button>
                {errors.arrivalDate && (
                  <p
                    role='alert'
                    className={`text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm w-[170px]  ${
                      errors.arrivalDate.message ===
                        'Arrival date must be a future date' && 'w-auto'
                    } h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1`}>
                    {errors?.arrivalDate?.message}
                  </p>
                )}
              </div>
              <div className='h-[105px] w-[310px]'>
                <label
                  className='indent-2 h-[19px] font-roboto font-medium text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                  htmlFor='BookNow-Nights'>
                  Nights
                </label>
                <br />
                <input
                  {...register('nights', {
                    required: 'Nights is required',
                    min: {
                      value: 1,
                      message: 'Value must be greater than or equal to 1',
                    },
                  })}
                  className={`w-[310px] h-[41px] bg-transparent rounded-[5px] ${
                    errors.nights
                      ? 'border-[#D80027]'
                      : ' border-skin-secondary '
                  }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                  id='BookNow-Nights'
                  name='nights'
                  type='number'
                />
                {errors.nights && (
                  <Danger
                    color='#D80027'
                    width='25'
                    height='24'
                    style={{ transform: 'translate(1090%, -145%)' }}
                  />
                )}
                {errors.nights && (
                  <p
                    role='alert'
                    className='text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                    {errors?.nights?.message}
                  </p>
                )}
              </div>

              <div className='flex w-full justify-center'>
                <button className='font roboto w-[330px] h-[43px] text-xl  tracking-[0.01em] mt-[30px] py-[1%] px-[0] rounded-[20px] bg-skin-secondary duration-300 hover:-translate-y-[2px] select-none  transition text-roboto text-center font-bold text-skin-primary leading-[23px] rounded-5 p-1'

>                  Book now
                </button>
              </div>
            </form>
          </BookNow>
        </li>
        <li className='flex items-center h-[43px] cursor-pointer'>
          <Translation
            className={
              'container ml-[1rem] min-w-[150px] h-auto tracking-[1%] border-0 text-base font-roboto font-semibold leading-[1.35rem]'
            }
            style={selectStyle}
          />
          {/* <Language
            width='39'
            height='39'
            className='mr-1 translate-y-[0.12rem]'
          />
          <span
            className={`font-roboto text-skin-primary font-semibold text-base h-[43px]leading-[19px] tracking-[0.01em] mr-2`}>
            English
          </span>
          <ChevronDown width='13' height='8' color='#173352' /> */}
        </li>
      </ul>
    </div>
  );
}

export default TopBar2;
