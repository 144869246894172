import React from 'react';
import './Pagination.css';
import { RxDoubleArrowLeft } from 'react-icons/rx';
import { RxDoubleArrowRight } from 'react-icons/rx';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { SlArrowLeft } from 'react-icons/sl';
import { SlArrowRight } from 'react-icons/sl';

const Pagination = ({
  listingsPerPage,
  totalListings,
  paginate,
  indexOfFirstListing,
  indexOfLastListing,
  previous,
  next,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalListings / listingsPerPage); i++) {
    pageNumbers.push(i);
  }

  console.log(pageNumbers, 'pageNumbers in pagination');
  console.log(indexOfFirstListing, 'indexOfFirstListing');
  console.log(indexOfLastListing, 'indexOfLastListing');

  return (
    <nav className='w-full flex justify-center h-9'>
      <ul className='paginationCustom h-9'>
        <RxDoubleArrowLeft
          color='#14d5ff'
          size='22'
          className='mt-[12px] mr-4 lg:mt-[12px] lg:mr-2 cursor-pointer'
          onClick={() => paginate(indexOfFirstListing + 1)}
        />
        <SlArrowLeft
          className='mr-4 mt-3 lg:mr-4 lg:mt-3 cursor-pointer'
          size='14.5'
          color='#14d5ff'
          onClick={() => previous()}
        />
        {pageNumbers.map((number) => (
          <li key={number} className='page-itemCustom w-9'>
            <button
              onClick={() => paginate(number)}
              className='page-linkCustom w-9 h-9 flex items-center text-sm'>
              {number}
            </button>
          </li>
        ))}
        <SlArrowRight
          className='ml-4 mt-3 lg:ml-4 lg:mt-3 cursor-pointer'
          size='14.5'
          color='#14d5ff'
          onClick={() => next()}
        />
        <RxDoubleArrowRight
          color='#14d5ff'
          size='22'
          className='mt-[12px] ml-4 lg:mt-[12px] lg:ml-2 cursor-pointer'
          onClick={() => paginate(indexOfLastListing - 1)}
        />
      </ul>
    </nav>
  );
};

export default Pagination;
