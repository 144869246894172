import React, { useContext } from 'react';
import { DayPicker, useInput } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
// import styles from 'react-day-picker/dist/style.module.css';
import DayPickerModal from './DayPickerModal';
import { format } from 'date-fns';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import * as MobileDatePicker from 'react-mobile-datepicker';
import { useMediaQuery } from 'react-responsive';
import { ThemeDataContext } from '../../../ContextProviders/theme';

export default function Birthday({
  selected,
  isPopperOpen,
  handlePopper,
  setValue,
  value,
}) {
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};
  const { globalBackgroundColor } = portalInfo || {};
  const { globalColor } = portalInfo || {};
  const Lg = useMediaQuery({
    query: '(max-width: 1200px) ',
  });

  const { dayPickerProps } = useInput({
    defaultSelected: new Date(),
    format: 'dd/MM/yyyy',
    required: true,
    mode: 'single',
    defaultMonth: selected,
  });

  const handleDayClickMobile = (date) => {
    console.log(date, 'selectedDate');
    let formattedDate = format(date, 'dd/MM/yyyy');
    setValue('birthday', formattedDate);
  };
  const handleDayClick = (date) => {
    console.log(date, 'selectedDate');
    let formattedDate = format(date, 'dd/MM/yyyy');
    setValue('birthday', formattedDate);
    handlePopper();
  };

  // const classNames = {
  //   ...styles,
  //   head: 'custom-head',
  //   caption: 'custom-caption',
  //   day_outside: 'custom-day_outside',
  //   day_selected: 'custom-day_selected',
  // };

  // const footer = (
  //   <div onClick={clearBirthdayErrors} className='w-full h-[100px] relative'>
  //     <label
  //       className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
  //       htmlFor='vlite-myprofile-birthday'>
  //       Birthdate
  //     </label>
  //     <input
  //       {...inputProps}
  //       className={`
  //       rounded-[5px] h-[41px] indent-2 w-full border-[1px] border-solid border-skin-secondary
  //       text-base text-skin-primary font-light font-roboto cursor-pointer`}
  //       {...register('birthday', {
  //         validate: {
  //           isDefined: () =>
  //             (birthdayWatch !== '' && birthdayWatch !== null) ||
  //             'Birthday is required',
  //         },
  //         onChange: () => {
  //           if (errors.birthday) {
  //             clearErrors('birthday');
  //           }
  //         },
  //       })}
  //     />
  //     <button

  //       type='button'
  //       className={`w-[21px] h-[21px] bg-transparent ba absolute right-[2%] top-[22%]`}
  //       aria-label='Pick a date'
  //       onClick={handlePopper}>
  //       <span className={`w-[21px] h-[21px]`} role='img' aria-label='calendar icon'>
  //         <Calendar />
  //       </span>
  //     </button>
  //     {errors.birthday && (
  //       <Danger
  //         color='#D80027'
  //         width='25'
  //         height='24'
  //         className={`absolute right-[2%] top-[43%]`}
  //       />
  //     )}
  //     {errors.birthday && (
  //       <p
  //         role='alert'
  //         className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm  w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
  //         {errors?.birthday?.message}
  //       </p>
  //     )}
  //   </div>
  // );

  const currentYear = new Date().getFullYear();
  const dateConfig = {
    date: {
      format: 'D',
      caption: 'Day',
      step: 1,
    },
    month: {
      format: 'M',
      caption: 'Mon',
      step: 1,
    },
    year: {
      format: 'YYYY',
      caption: 'Year',
      step: 1,
    },
  };
  const headerFormat = 'D/M/YYYY';

  let formattedValue = new Date();

  if (value) {
    const [day, month, year] = value.split('/');
    formattedValue = new Date(+year, +month - 1, +day);
  }
  // formattedValue = format(arrival, 'yyyy-MM-dd');

  if (isPopperOpen && Lg)
    return (
      // <DayPickerModal trigger={isPopperOpen} setTrigger={handlePopper}>

      <MobileDatePicker
        showHeader={false}
        headerformat='DD/MM/YYYY'
        theme='default'
        dateConfig={dateConfig}
        onSelect={handlePopper}
        // dateConfig={dateConfig}
        value={formattedValue}
        isOpen={true}
        cancelText=''
        confirmText='X'
        onCancel={handlePopper}
        onChange={handleDayClickMobile}

        // value={value}
        // onSelect={this.handleSelect}
        // onCancel={this.handleCancel}
      />
      // </DayPickerModal>
    );

  if (isPopperOpen)
    return (
      <ErrorBoundary>
        <DayPickerModal trigger={isPopperOpen} setTrigger={handlePopper}>
          <DayPicker
            styles={{
              caption: { color: buttonColor, fontFamily: 'roboto' },
              head: { color: buttonColor, fontFamily: 'roboto' },
              tbody: { color: buttonColor, fontFamily: 'roboto' },
              dropdown: { color: buttonColor, fontFamily: 'roboto' },
              dropdown_month: { color: buttonColor, fontFamily: 'roboto' },
              dropdown_year: {
                color: buttonColor,
                fontFamily: 'roboto',
                // maxHeight: '100px',
              },
              caption_dropdown: {
                color: buttonColor,
                fontFamily: 'roboto',
                // maxHeight: '50px',
              },
            }}
            {...dayPickerProps}
            initialFocus={true}
            captionLayout='dropdown'
            onDayClick={handleDayClick}
            fromYear={1900}
            toYear={currentYear}
          />
        </DayPickerModal>
      </ErrorBoundary>
    );

  // if (!isPopperOpen)
  //   return (
  //     <div onClick={clearBirthdayErrors} className='w-full h-[100px] relative'>
  //       <label
  //         className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
  //         htmlFor='vlite-myprofile-birthday'>
  //         Birthdate
  //       </label>
  //       <input
  //         {...inputProps}
  //         className={`
  //       rounded-[5px] h-[41px] indent-2 w-full border-[1px] border-solid border-skin-secondary
  //       text-base text-skin-primary font-light font-roboto cursor-pointer`}
  //         {...register('birthday', {
  //           validate: {
  //             isDefined: () =>
  //               (birthdayWatch !== '' && birthdayWatch !== null) ||
  //               'Birthday is required',
  //           },
  //           onChange: () => {
  //             if (errors.birthday) {
  //               clearErrors('birthday');
  //             }
  //           },
  //         })}
  //       />
  //       <button
  //         ref={buttonRef}
  //         type='button'
  //         className={`w-[20px] h-[20px] bg-transparent absolute right-[2%] top-[22%]`}
  //         aria-label='Pick a date'
  //         onClick={handlePopper}>
  //         <span role='img' aria-label='calendar icon'>
  //           <Calendar />
  //         </span>
  //       </button>
  //       {errors.birthday && (
  //         <Danger
  //           color='#D80027'
  //           width='25'
  //           height='24'
  //           className={`absolute right-[2%] top-[43%]`}
  //         />
  //       )}
  //       {errors.birthday && (
  //         <p
  //           role='alert'
  //           className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm  w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
  //           {errors?.birthday?.message}
  //         </p>
  //       )}
  //     </div>
  //   );
}
