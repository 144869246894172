import React, { useState, useEffect, useContext } from 'react';
import OverviewIcon from '../../../Icons/Overview';
import MyProfileIcon from '../../../Icons/MyProfile';
import ActivityIcon from '../../../Icons/Activity';
import { NavLink } from 'react-router-dom';
import LogoutIcon from '../../../Icons/Logout';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../Redux/reducers/vlite/vlite.actions';
import Toggle from '../../../Icons/Toggle.js';
import { useNavigate } from 'react-router-dom';
import CookieService from '../../../API/Services/CookieService';
import { useMediaQuery } from 'react-responsive';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import BookNow from '../BookNow/BookNow2';
import { useForm, Controller } from 'react-hook-form';
import { isInTheFuture } from '../../Services/IsInTheFuture';
import Calendar from '../../../Icons/Calendar';
import Select, { components } from 'react-select';
import ArrivalDate from '../TopBar/ArrivalDate';
import format from 'date-fns/format';
import { ThemeDataContext } from '../../../ContextProviders/theme';
import { GiSelect } from 'react-icons/gi';

function SideBar2() {
  const urlParams = new URLSearchParams(window.location.search);
  const programLabelFromURL = urlParams.get('programLabel');
  const programLabel =
    programLabelFromURL ?? localStorage.getItem('programLabel');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const pathname = window.location.pathname;

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};
  const { globalBackgroundColor } = portalInfo || {};
  const { globalColor } = portalInfo || {};

  console.log(buttonColor, 'buttonColor in Overview');
  console.log(buttonBackgroundColor, 'buttonBackgroundColor in Overview');

  const emailToken = userInfo.eMailToken;

  const [buttonPopup, setButtonPopup] = useState(false);
  const [hotels, setHotels] = useState([]);

  const initialValues = {
    nights: null,
  };
  const {
    reset,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: initialValues,
  });

  const style = {
    container: (base) => ({
      ...base,
      // marginTop: '0.25rem',
      width: '310px',
      height: '40px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: buttonColor,
      fontWeight: '300',
    }),
    valueContainer: (base) => ({
      ...base,
      width: '310px',
      paddingBottom: '0',
      height: '41px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: buttonColor,
      fontWeight: '300',
      // marginLeft: '-0.28rem',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: buttonBackgroundColor,
      '&:hover': { color: buttonBackgroundColor },
    }),
    group: (base) => ({
      ...base,
      width: '310px',
    }),
    menu: (base) => ({
      ...base,
      width: '310px',
      // marginLeft: '2rem',
    }),
    control: (base) => ({
      ...base,
      '&:hover': { borderColor: buttonBackgroundColor },
      border: `1px solid ${buttonBackgroundColor}`,
      boxShadow: 'none',
      borderRadius: '5px',
      width: '310px',
      // marginLeft: '2rem',
      fontSize: '1rem',
      height: '41px',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: buttonColor,
      marginLeft: '0.15rem',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: buttonColor,
      fontWeight: '300',
    }),
    input: (base) => ({
      ...base,
      width: '310px',
      marginLeft: '-0.28rem',
      fontFamily: 'Roboto, sans-serif',
      color: buttonColor,
      fontSize: '1rem',
      fontWeight: '300',
    }),
  };
  const styleErrors = {
    container: (base) => ({
      ...base,
      // marginTop: '0.25rem',
      width: '310px',
      height: '40px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
    }),
    valueContainer: (base) => ({
      ...base,
      width: '310px',
      paddingBottom: '0',
      height: '41px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
      // marginLeft: '-0.28rem',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'transparent',
      '&:hover': { color: '#D80027' },
    }),
    group: (base) => ({
      ...base,
      width: '310px',
    }),
    menu: (base) => ({
      ...base,
      width: '310px',
      // marginLeft: '2rem',
    }),
    control: (base) => ({
      ...base,
      '&:hover': { borderColor: '#D80027' },
      border: '1px solid #D80027',
      boxShadow: 'none',
      borderRadius: '5px',
      width: '310px',
      // marginLeft: '2rem',
      fontSize: '1rem',
      height: '41px',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      marginLeft: '0.15rem',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
    }),
    input: (base) => ({
      ...base,
      width: '310px',
      marginLeft: '-0.28rem',
      fontFamily: 'Roboto, sans-serif',
      color: '#D80027',
      fontSize: '1rem',
      fontWeight: '300',
    }),
  };

  const onError = (errors, e) => console.log(errors, e, 'error');
  const arrivalDate = watch('arrivalDate');
  const clearHotelErrors = () => {
    if (errors.hotel) {
      clearErrors('hotel');
    }
  };

  const getHotels = async () => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/init/getLoyaltyInfo',
      params: {
        programLabel: localStorage['programLabel'],
      },
    })
      .then((resp) => {
        setHotels(resp.data.locations);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getHotels();
  }, []);

  const hotel = _.map(hotels, 'description');

  const newArray = hotel?.map((h, i) => ({ value: h, label: h }));
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const handlePopper = () => {
    setIsPopperOpen(!isPopperOpen);
  };

  const bookNow = (data) => {
    console.log(data, 'data');
    console.log(hotels, 'hotels');
    let hotel = hotels?.filter((h) => h?.description === data?.hotel?.value);
    let url = hotel[0]?.bookNowUri;
    let arrival;
    if (
      typeof data?.arrivalDate === 'string' ||
      date?.arrivalDate instanceof String
    ) {
      const [day, month, year] = data?.arrivalDate.split('/');
      arrival = new Date(+year, +month - 1, +day);
      arrival = format(arrival, 'yyyy-MM-dd');
    } else if (
      typeof data?.arrivalDate === 'Date' ||
      date?.arrivalDate instanceof Date
    ) {
      arrival = `${format(arrival, 'yyyy-MM-dd')}`;
    }
    window.open(
      url +
        '?checkin=' +
        arrival +
        '&nights=' +
        data.nights +
        '&logintoken=' +
        emailToken
    );
  };

  const Hotel = (props) => (
    <Select
      {...props}
      // components={{
      //   IndicatorSeparator: () => null,
      // }}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      menuPlacement='auto'
      // className={`w-full h-[41px] bg-transparent rounded-[5px] border-skin-secondary ${
      //   errors.hotel && 'border-[#D80027]'
      // } indent-[0.5rem] border-solid  border-[1px]  text-skin-a11y font-light text-base leading-[19px]`}
      placeholder='Select Hotel...'
      styles={!errors.hotel ? style : styleErrors}
      options={newArray}
      onChange={props.onChange}
      onBlur={props.onBlur}
      selected={props.value}
      onClick={props.onClick}
    />
  );

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {errors.hotel && <Danger color='#D80027' width='25' height='24' />}
      </components.DropdownIndicator>
    );
  };

  AOS.init();
  const isSidebarVisible = useSelector((state) => state.vlite.isSidebarVisible);

  const Lg = useMediaQuery({
    query: '(max-width: 1000px) ',
  });

  useEffect(() => {
    if (!localStorage['programLabel']) {
      localStorage.setItem('programLabel', programLabel);
    }
  }, [programLabel]);

  useEffect(() => {
    if (
      localStorage.getItem('programLabel') !== programLabelFromURL &&
      programLabelFromURL
    ) {
      localStorage.setItem('programLabel', programLabelFromURL);
    }
  }, [programLabelFromURL]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = useSelector((state) => state.vlite.isSidebarOpen);

  const [isHoveringOverview, setIsHoveringOverview] = useState(false);
  const [isHoveringMyProfile, setIsHoveringMyProfile] = useState(false);
  const [isHoveringActivity, setIsHoveringActivity] = useState(false);
  const [isHoveringPreferences, setIsHoveringPreferences] = useState(false);
  const [isHoveringToggle, setIsHoveringToggle] = useState(false);

  const handleMouseOverOverview = () => {
    setIsHoveringOverview(true);
  };

  const handleMouseOutOverview = () => {
    setIsHoveringOverview(false);
  };
  const handleMouseOverMyProfile = () => {
    setIsHoveringMyProfile(true);
  };

  const handleMouseOutMyProfile = () => {
    setIsHoveringMyProfile(false);
  };
  const handleMouseOverActivity = () => {
    setIsHoveringActivity(true);
  };

  const handleMouseOutActivity = () => {
    setIsHoveringActivity(false);
  };
  const handleMouseOverPreferences = () => {
    setIsHoveringPreferences(true);
  };

  const handleMouseOutPreferences = () => {
    setIsHoveringPreferences(false);
  };
  const handleMouseOverToggle = () => {
    setIsHoveringToggle(true);
  };

  const handleMouseOutToggle = () => {
    setIsHoveringToggle(false);
  };

  const toggle = () => {
    dispatch(actions.setSidebarOpen(!open));
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(actions.setSidebarOpen(true));
    dispatch(actions.userLogout());
    localStorage.clear();
    sessionStorage.clear();
    let options = { path: '/' };
    CookieService.remove('acidToken', options);
    navigate(`/signIn?programLabel=${programLabel}`);
  };

  useEffect(() => {
    dispatch(actions.setSidebarOpen(true));
  }, [isSidebarVisible]);

  const handleClick = () => {
    dispatch(actions.setSidebarVisibility(false));
  };

  if (Lg && isSidebarVisible)
    return (
      <>
        <div
          data-aos='fade-right'
          className={`fixed z-[50] h-full w-4/5 bg-skin-primary landscape:overflow-scroll landscape:top-0 landscape:bottom-0 `}>
          <br />
          <br />

          <br />
          <br />
          <br />
          <ul
            className={`grid grid-cols-1 grid-row-4 h-[215px] w-full p-0 mt-1 ml-7`}>
            <li
              onMouseOver={handleMouseOverOverview}
              onMouseOut={handleMouseOutOverview}
              className='flex gap-x-4 h-[40px] items-center text-skin-a11y hover:text-skin-secondary mb-6'>
              <NavLink
                onClick={handleClick}
                to='/home/overview'
                className=' flex gap-x-3 items-center no-underline text-inherit'>
                <OverviewIcon
                  size={isHoveringOverview && !open ? `50` : `25`}
                  color={
                    !isHoveringOverview ? `#FFFFFF` : buttonBackgroundColor
                  }
                  className={`cursor-pointer duration-500 ${
                    isHoveringOverview && !open && `scale-1`
                  }`}
                />
                <span
                  className={` ${
                    !open && `hidden`
                  }  text-base font-roboto font-bold origin-left duration-200 leading-[19px] whitespace-nowrap`}>
                  Overview
                </span>
              </NavLink>
            </li>
            <li
              onMouseOver={handleMouseOverMyProfile}
              onMouseOut={handleMouseOutMyProfile}
              className='flex gap-x-4  h-[40px] items-center  text-skin-a11y hover:text-skin-secondary mb-6'>
              <NavLink
                onClick={handleClick}
                to='/home/myprofile'
                className='no-underline flex gap-x-3 items-center text-inherit'>
                <MyProfileIcon
                  size={isHoveringMyProfile && !open ? `50` : `25`}
                  color={
                    !isHoveringMyProfile ? `#FFFFFF` : buttonBackgroundColor
                  }
                  className={`cursor-pointer duration-500 ${
                    isHoveringMyProfile && !open && `scale-1`
                  }`}
                />

                <span
                  className={` ${
                    !open && `hidden`
                  } text-base font-roboto font-bold origin-left duration-200 leading-[19px] whitespace-nowrap`}>
                  My Profile
                </span>
              </NavLink>
            </li>

            <li
              onMouseOver={handleMouseOverActivity}
              onMouseOut={handleMouseOutActivity}
              className='flex h-[40px] gap-x-4 items-center  text-skin-a11y hover:text-skin-secondary'>
              <NavLink
                onClick={handleClick}
                to='/home/activity'
                className='no-underline flex gap-x-3 items-center text-inherit'>
                <ActivityIcon
                  size={isHoveringActivity && !open ? `50` : `25`}
                  color={
                    !isHoveringActivity ? `#FFFFFF` : buttonBackgroundColor
                  }
                  className={`cursor-pointer duration-500 
                `}
                />
                <span
                  className={`  ${
                    !open && `hidden`
                  } text-base font-roboto font-bold origin-left duration-200 leading-[19px] whitespace-nowrap`}>
                  My Activity
                </span>
              </NavLink>
            </li>
              <li
              onMouseOver={handleMouseOverPreferences}
              onMouseOut={handleMouseOutPreferences}
              className='flex h-[40px] gap-x-4 items-center translate-y-6  text-skin-a11y hover:text-skin-secondary'>
              <NavLink
                onClick={handleClick}
                to='/home/preferencesVL'
                className='no-underline flex gap-x-3 items-center text-inherit'>
                <GiSelect
                  size={isHoveringPreferences && !open ? `40` : `25`}
                  color={
                    !isHoveringPreferences ? `#FFFFFF` : buttonBackgroundColor
                  }
                  className={`cursor-pointer duration-500 
                `}
                />
                
                <span
                  className={`  ${
                    !open && `hidden`
                  } text-base font-roboto font-bold origin-left duration-200 leading-[19px] whitespace-nowrap`}>
                  Preferences
                </span>
                
              </NavLink>
            </li>
            <li className={`h-[50px] w-full`}>
              <div className={`w-full flex cursor-pointer mt-12`}>
                <button
                  // style={{
                  //   background: 'transparent',
                  //   color: '#404040',
                  // }}
                  onClick={() => setButtonPopup(true)}
                  className={`font-roboto cursor-pointer z-[999] text-skin-primary font-semibold w-[150px] h-[43px] text-base leading-[19px] tracking-[0.01em] mt-0 py-[1%] px-[0] rounded-[20px] bg-skin-secondary duration-300 shadow-[0px_4px_10px] hover:-translate-y-[2px] select-none`}>
                  Book now
                </button>
                <BookNow
                  trigger={buttonPopup}
                  setTrigger={setButtonPopup}
                  reset={reset}>
                  <h1 className='font-roboto font-semibold text-[32px] leading-[38px] text-skin-primary text-center mt-[60px]'>
                    Book your stay
                  </h1>
                  <form
                    className='flex flex-col w-full h-full items-center mt-12'
                    onSubmit={handleSubmit(bookNow, onError)}>
                    <div
                      onClick={clearHotelErrors}
                      className='h-[105px]  w-[310px]'>
                      <label className='indent-2 h-[19px] font-roboto font-medium text-base leading-[18.75px] mb-[11.5px] text-skin-primary'>
                        Hotel
                      </label>

                      <Controller
                        {...register('hotel')}
                        defaultValue={null}
                        control={control}
                        options={newArray}
                        name='hotel'
                        rules={{
                          required: 'Hotel is required',
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Hotel
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          />
                        )}
                      />
                      {errors.hotels && (
                        <Danger
                          color='#D80027'
                          width='25'
                          height='24'
                          style={{ transform: 'translate(1090%, -145%)' }}
                        />
                      )}
                      {errors.hotel && (
                        <p
                          role='alert'
                          className='text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                          {errors?.hotel?.message}
                        </p>
                      )}
                    </div>

                    <div className='h-[105px]  w-[310px] relative'>
                      <label className='indent-2 h-[19px] font-roboto font-medium text-base leading-[18.75px] mb-[11.5px] text-skin-primary'>
                        Arrival Date
                      </label>
                      <br />
                      <ArrivalDate
                        isPopperOpen={isPopperOpen}
                        handlePopper={handlePopper}
                        setValue={setValue}
                        value={arrivalDate}
                      />
                      <input
                        className={` rounded-[5px] h-[41px] indent-2 w-[310px] text-base text-skin-primary font-light font-roboto cursor-pointer border-[1px] border-solid ${
                          !errors.arrivalDate
                            ? 'border-skin-secondary'
                            : 'border-[#D80027]'
                        }`}
                        {...register('arrivalDate', {
                          required: 'Arrival date is required',
                          // valueAsDate: true,
                          validate: {
                            isAFutureDate: () =>
                              isInTheFuture(arrivalDate) ||
                              'Arrival date must be a future date',
                          },
                        })}
                      />
                      {/* <Controller
                    {...register('arrivalDate', {
                      required: 'Arrival date is required',
                      valueAsDate: true,
                      validate: {
                        isAFutureDate: () =>
                          isInTheFuture(arrivalDate) ||
                          'Arrival date must be a future date',
                      },
                    })}
                    control={control}
                    defaultValue={null}
                    name='arrivalDate'
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        className={`${
                          !errors.arrivalDate
                            ? 'vlite-datepicker'
                            : 'vlite-datepicker-danger'
                        } rounded-[5px] h-[41px] indent-2 w-[310px] text-base text-skin-primary font-light font-roboto cursor-pointer border-[1px] border-solid ${
                          !errors.arrivalDate
                            ? 'border-skin-secondary'
                            : 'border-[#D80027]'
                        }`}
                        style={{ marginLeft: '2rem' }}
                        dateFormat='dd/MM/yyyy'
                        onChange={onChange}
                        selected={value}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        placeholderText={'DD / MM / YYYY'}
                      />
                    )}
                  /> */}
                      <button
                        type='button'
                        className={`w-[51px] h-[51px] bg-transparent absolute right-[2%] top-[22%]`}
                        aria-label='Pick a date'
                        onClick={handlePopper}>
                        <span
                          className={`w-[51px] h-[51px]`}
                          role='img'
                          aria-label='calendar icon'>
                          <Calendar />
                        </span>
                      </button>
                      {errors.arrivalDate && (
                        <p
                          role='alert'
                          className={`text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm w-[170px]  ${
                            errors.arrivalDate.message ===
                              'Arrival date must be a future date' && 'w-auto'
                          } h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1`}>
                          {errors?.arrivalDate?.message}
                        </p>
                      )}
                    </div>
                    <div className='h-[105px] w-[310px]'>
                      <label
                        className='indent-2 h-[19px] font-roboto font-medium text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                        htmlFor='BookNow-Nights'>
                        Nights
                      </label>
                      <br />
                      <input
                        {...register('nights', {
                          required: 'Nights is required',
                          min: {
                            value: 1,
                            message: 'Value must be greater than or equal to 1',
                          },
                        })}
                        className={`w-[310px] h-[41px] bg-transparent rounded-[5px] ${
                          errors.nights
                            ? 'border-[#D80027]'
                            : ' border-skin-secondary '
                        }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                        id='BookNow-Nights'
                        name='nights'
                        type='number'
                      />
                      {errors.nights && (
                        <Danger
                          color='#D80027'
                          width='25'
                          height='24'
                          style={{ transform: 'translate(1090%, -145%)' }}
                        />
                      )}
                      {errors.nights && (
                        <p
                          role='alert'
                          className='text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                          {errors?.nights?.message}
                        </p>
                      )}
                    </div>

                    <div className='flex w-full justify-center'>
                      <button
                        className={`font-roboto cursor-pointer z-[999] text-skin-primary font-semibold w-[150px] h-[43px] text-base leading-[19px] tracking-[0.01em] mt-0 py-[1%] px-[0] rounded-[20px] bg-skin-secondary duration-300 shadow-[0px_4px_10px_rgba(20,213,255,0.4)] hover:-translate-y-[2px] select-none`}>
                        Book now
                      </button>
                    </div>
                  </form>
                </BookNow>
              </div>
            </li>
          </ul>
          {/* <div
            onMouseOver={handleMouseOverToggle}
            onMouseOut={handleMouseOutToggle}
            className={`h-[24px] w-full absolute bottom-[9rem] flex gap-x-3 items-center  text-skin-primary hover:text-skin-secondary
          } `}
            onClick={toggle}>
            <Toggle
              width='25'
              height='24'
              color={!isHoveringToggle ? buttonColor : buttonBackgroundColor}
              className={`cursor-pointer duration-500 ${
                !open && 'hover:w-12 hover:h-12 rotate-180'
              }`}
            />
            <span
              className={`${
                !open && `hidden`
              } font-roboto font-semibold text-base leading-[19px] origin-left duration-200 cursor-pointer `}>
              Toggle sidebar
            </span>
          </div> */}
          <div
            onClick={(e) => handleLogout(e)}
            className={` mt-[10rem] flex gap-x-3 items-center no-underline ml-7 cursor-pointer`}>
            <LogoutIcon
              width='25'
              height='24'
              color='#E84E1C'
              className={`cursor-pointer duration-500 hover:text-skin-secondary ${
                !open && 'hover:w-12 hover:h-12'
              }`}
            />
            <span
              className={`text-[#E84E1C]  ${
                !open && `hidden`
              } font-roboto font-medium text-base leading-[19px] origin-left duration-200 cursor-pointer `}>
              Logout
            </span>
          </div>
        </div>
      </>
    );

  if (!Lg)
    return (
      <>
        <div
          className={`${
            open
              ? 'min-w-[305px] w-[10.4%]   p-[2.25rem]'
              : 'w-12 p-[0.5rem] min-w-[48px]'
          } duration-300  ${
            pathname.includes('profile') ? 'h-[1900px]' : 'h-auto'
          }  pt-1 bg-white shadow-3xl relative z-20`}>
          <br />
          <br />
          <div
            style={{
              //backgroundImage: `url(${logo})`,
              backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
            }}
            className={` w-full origin-left duration-200 bg-no-repeat  ${
              !open
                ? 'h-[33px] bg-[length:33px_33px] bg-center -translate-x-[0.1rem] -translate-y-[2.5rem]'
                : 'h-[159px] bg-[length:159px_159px] bg-left -translate-x-[0.7rem]'
            } `}
          />
          <br />
          <br />
          <br />
          <ul
            className={`grid grid-cols-1 grid-row-4 h-[215px] w-full p-0 mt-1`}>
            <li
              onMouseOver={handleMouseOverOverview}
              onMouseOut={handleMouseOutOverview}
              className={`flex gap-x-4 h-[40px] items-center text-skin-primary hover:text-skin-secondary mb-6`}
                >
               <NavLink
                to='/home/overview'
                className=' flex gap-x-3 items-center no-underline text-inherit'
                >
                <OverviewIcon
                  size={isHoveringOverview && !open ? `50` : `25`}
                  color={
                    (!isHoveringOverview && pathname !== '/home/overview') ? buttonColor : buttonBackgroundColor
                  }
                  className={`cursor-pointer duration-500 ${
                    isHoveringOverview && !open && `scale-1`
                  }`}

                />
                <span
                  className={` ${
                    !open && `hidden`
                  }  text-base font-roboto font-bold origin-left duration-200 leading-[19px] whitespace-nowrap`}
                  
                  style={{
    color: pathname.includes('/home/overview')||isHoveringOverview ? buttonBackgroundColor : buttonColor
  }}>
                  Overview
                </span>
              </NavLink>
            </li>
            <li
              onMouseOver={handleMouseOverMyProfile}
              onMouseOut={handleMouseOutMyProfile}
              className='flex gap-x-4  h-[40px] items-center  text-skin-primary hover:text-skin-secondary mb-6'>
              <NavLink
                to='/home/myprofile'
                className='no-underline flex gap-x-3 items-center text-inherit'>
                <MyProfileIcon
                  size={isHoveringMyProfile && !open ? `50` : `25`}
                  color={
                    (!isHoveringMyProfile && pathname !== '/home/myprofile') ? buttonColor : buttonBackgroundColor
                  }
                  className={`cursor-pointer duration-500 ${
                    isHoveringMyProfile && !open && `scale-1`
                  }`}
                />

                <span
                  className={` ${
                    !open && `hidden`
                  } text-base font-roboto font-bold origin-left duration-200 leading-[19px] whitespace-nowrap`}
                  style={{
    color: pathname.includes('/home/myprofile')||isHoveringMyProfile ? buttonBackgroundColor : buttonColor
  }}>
                  My Profile
                </span>
              </NavLink>
            </li>

            <li
              onMouseOver={handleMouseOverActivity}
              onMouseOut={handleMouseOutActivity}
              className='flex h-[40px] gap-x-4 items-center  text-skin-primary hover:text-skin-secondary'>
              <NavLink
                to='/home/activity'
                className='no-underline flex gap-x-3 items-center text-inherit'>
                <ActivityIcon
                  size={isHoveringActivity && !open ? `50` : `25`}
                  color={
                    (!isHoveringActivity && pathname !== '/home/activity') ? buttonColor : buttonBackgroundColor
                  }
                  className={`cursor-pointer duration-500 
                `}
                />
                <span
                  className={`  ${
                    !open && `hidden`
                  } text-base font-roboto font-bold origin-left duration-200 leading-[19px] whitespace-nowrap`}
                  style={{
    color: pathname.includes('/home/activity')||isHoveringActivity ? buttonBackgroundColor : buttonColor
  }}>
                  My Activity
                </span>
              </NavLink>
            </li>
            <li
              onMouseOver={handleMouseOverPreferences}
              onMouseOut={handleMouseOutPreferences}
              className='flex h-[40px] gap-x-4 items-center translate-y-6  text-skin-primary hover:text-skin-secondary'>
              <NavLink
                to='/home/preferencesVL'
                className='no-underline flex gap-x-3 items-center text-inherit'>
                <GiSelect
                  size={isHoveringPreferences && !open ? `40` : `25`}
                  color={
                    (!isHoveringPreferences && pathname !== '/home/preferencesVL') ? buttonColor : buttonBackgroundColor
                  }
                  className={`cursor-pointer duration-500 
                `}
                />
                <span
                  className={`  ${
                    !open && `hidden`
                  } text-base font-roboto font-bold origin-left duration-200 leading-[19px] whitespace-nowrap`}
                  style={{
    color: pathname.includes('/home/preferencesVL')||isHoveringPreferences ? buttonBackgroundColor : buttonColor
  }}>
                  Preferences
                </span>
              </NavLink>
            </li>
          </ul>
          <div
            onMouseOver={handleMouseOverToggle}
            onMouseOut={handleMouseOutToggle}
            className={`flex h-[40px] gap-x-4  mt-8 items-center translate-y-6  text-skin-primary hover:text-skin-secondary `}
            onClick={toggle}>
            <Toggle
              width='25'
              height='24'
              color={!isHoveringToggle ? buttonColor : buttonBackgroundColor}
              className={`cursor-pointer duration-500 ${
                !open && 'hover:w-12 hover:h-12 rotate-180'
              }`}
            />
            <span
              className={`${
                !open && `hidden`
              } text-base font-roboto font-bold origin-left duration-200 leading-[19px] whitespace-nowrap `}>
              Toggle sidebar
            </span>
          </div>
          <div
            onClick={(e) => handleLogout(e)}
            className={` flex h-[40px] gap-x-4  mt-8 items-center translate-y-6  text-skin-primary hover:text-skin-secondary cursor-pointer`}>
            <LogoutIcon
              width='25'
              height='24'
              color='#E84E1C'
              className={`cursor-pointer duration-500 hover:text-skin-secondary ${
                !open && 'hover:w-12 hover:h-12'
              }`}
            />
            <span
              className={`text-[#E84E1C]  ${
                !open && `hidden`
              } text-base font-roboto font-bold origin-left duration-200 leading-[19px] whitespace-nowrap `}>
              Logout
            </span>
          </div>
        </div>
      </>
    );
}

export default SideBar2;
