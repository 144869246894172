import React from 'react';
import './Logout.css';
import LogoutModal from '../Modal/LogoutModal';
import ReactPortal from '../ReactPortal/ReactPortal';

const Logout = ({ trigger, setTrigger, children, handleLogout }) => {
  return (
    <>
      {trigger ? (
        <ReactPortal wrapperId='react-portal-modal-container'>
          <LogoutModal
            setTrigger={setTrigger}
            trigger={trigger}
            children={children}
            handleLogout={handleLogout}
          />
        </ReactPortal>
      ) : (
        ''
      )}
    </>
  );
};

export default Logout;
