import React, { useContext, useEffect, useState } from 'react';
import '../Components/Benefits/BenefitsAtlantica.css';
import 'react-modern-drawer/dist/index.css';
import Header from '../Components/Header';
import { ThemeDataContext } from '../ContextProviders/theme';
import Ixian from '../Components/Benefits/BenefitsIxian';
import Drawer from 'react-modern-drawer';
import { useMediaQuery } from 'react-responsive';
import { VscCircleFilled } from 'react-icons/vsc';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { ALT, IXI, MAST,MK } from '../Data/HotelCode';
import { ReactComponent as Alianthos} from '../Assets/Images/Alianthos.svg';
import { ReactComponent as AlianthosW} from '../Assets/Images/Alianthos_White.svg';
import { BsSuitDiamondFill } from "react-icons/bs";


export default function Benefits({ toggle }) {
  const [Drawer1IsOpen, setDrawer1IsOpen] = React.useState(false);
  const [Drawer2IsOpen, setDrawer2IsOpen] = React.useState(false);
  const [Drawer3IsOpen, setDrawer3IsOpen] = React.useState(false);
  const [Drawer4IsOpen, setDrawer4IsOpen] = React.useState(false);
  const toggleDrawer1 = () => {
    setDrawer1IsOpen((prevState) => !prevState);
  };
  const toggleDrawer2 = () => {
    setDrawer2IsOpen((prevState) => !prevState);
  };
  const toggleDrawer3 = () => {
    setDrawer3IsOpen((prevState) => !prevState);
  };
  const toggleDrawer4 = () => {
    setDrawer4IsOpen((prevState) => !prevState);
  };

  const [barTiers, setBarTiers] = useState([]);
  const programLabel = localStorage.getItem('programLabel');

  const theme = useContext(ThemeDataContext) || {};
  const { tiers } = theme || {};

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const memberInfo = userInfo.memberInfo;
  // useEffect(() => {
  //   if (
  //     programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ||
  //     'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B'
  //   ) {
  //     const { tiers } = theme || { tiers: null };
  //     const bTiers =
  //       tiers &&
  //       tiers
  //         .filter((tier) => tier.barPercentage > 0)
  //         .map((tier) => ({
  //           name: tier.description,
  //           color: tier.color,
  //         }));
  //     setBarTiers(bTiers);
  //   } else if (
  //     programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' ||
  //     'IXI-INTG-E98241F0-EE89-4B11-8F81-94C0CA0085FA'
  //   ) {
  //     const bTiers =
  //       Ixian &&
  //       Ixian.map((tier) => ({
  //         name: tier.Name,
  //         apply: tier.Apply,
  //         color: tier.Color,
  //         benefits: tier.Benefits,
  //       }));
  //     setBarTiers(bTiers);
  //   }
  // }, [theme, Ixian]);

  // console.log(barTiers, 'barTiers');

  const Tablet = useMediaQuery({
    query: '(min-width: 768px) ',
  });

  const Mobile = useMediaQuery({
    query: '(min-width: 280px) and (max-width: 767px)',
  });

  const isSidebarVisible = useSelector((state) => state.user.isSidebarVisible);

  const [fullBenefitsList, setFullBenefitsList] = useState([]);
  const [benefitsListByTier, setBenefitsListByTier] = useState([]);

  useEffect(() => {
    const benefitsArray = Ixian.map((e) => e.Benefits);
    const benefits = [];
    benefitsArray.forEach((benefit) =>
      benefit.forEach((benefit) => benefits.push(benefit))
    );

    const uniq = [...new Set(benefits)];

    setFullBenefitsList(uniq);
  }, []);

  // function GenerateBenefitsListByTier(benefits) {
  //   let array = [];
  //   for (let i = 0; i < benefits.length; i++) {
  //     let array1 = Ixian[i].Benefits;
  //     array.push(array1);
  //   }

  //   console.log(array, 'array');
  //   return array;
  // }

  // useEffect(() => {
  //   setBenefitsListByTier(GenerateBenefitsListByTier(Ixian));
  // }, [Ixian]);

  // const getBulletPoint = () => {
  //   return <VscCircleFilled />;
  // };

  return (
    <>
      <Header />
      <div
        style={{
          visibility: `${isSidebarVisible ? 'hidden' : 'visible'}`,
        }}>
        <div className='Benefits-heading'>
          <h1>Benefits</h1>
          <hr className='Benefits-line'></hr>
          <br />
          <br />

          {/* {programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' &&
            Tablet && (
              <>
                <button
                  className='Benefits-button'
                  style={{
                    background: `${tiers[0].color}`,
                  }}
                  onClick={toggleDrawer1}>
                  {barTiers[0]?.name}
                </button>
                <Drawer
                  open={Drawer1IsOpen}
                  onClose={toggleDrawer1}
                  direction='top'
                  style={{ marginTop: '7rem' }}
                  className='Benefits-Drawer'>
                  <div
                    style={{
                      transform: 'translateY(100%)',
                      display: 'block',
                      width: '100%',
                      height: '100px',
                      justifyContent: 'left',
                    }}>
                    {barTiers[0]?.benefits.map((benefit, index) => (
                      <p
                        key={index}
                        style={{ display: 'block', fontSize: '1.25rem' }}>
                        {benefit}
                      </p>
                    ))}
                  </div>
                </Drawer>
                <button
                  className='Benefits-button'
                  style={{
                    background: `${tiers[1].color}`,
                  }}
                  onClick={toggleDrawer2}>
                  {barTiers[1]?.name}
                </button>
                <Drawer
                  open={Drawer2IsOpen}
                  onClose={toggleDrawer2}
                  direction='left'
                  style={{
                    paddingLeft: '1rem',
                    width: '30vw',
                    marginTop: '9rem',
                    overflow: 'scroll',
                  }}
                  className='Benefits-Drawer'>
                  <div
                    style={{
                      transform: 'translateY(30%)',
                      display: 'block',
                      textAlign: 'left',
                    }}>
                    {barTiers[1]?.benefits.map((benefit, index) => (
                      <p
                        style={{
                          lineHeight: '2rem',
                          fontSize: '1.25rem',
                          marginBottom: '1rem',
                        }}
                        key={index}>
                        {benefit}
                      </p>
                    ))}
                  </div>
                </Drawer>
                <button
                  className='Benefits-button'
                  style={{ background: `${tiers[2].color}` }}
                  onClick={toggleDrawer3}>
                  {barTiers[2]?.name}
                </button>
                <Drawer
                  open={Drawer3IsOpen}
                  onClose={toggleDrawer3}
                  direction='bottom'
                  style={{ height: '65.5vh', paddingTop: '2rem' }}
                  className='Benefits-Drawer'>
                  <div
                    style={{
                      transform: 'translateY(0%)',
                      fontSize: '1.25rem',
                      margin: '0',
                      lineheight: '0.5rem',
                      height: '10px',
                      padding: '0',
                    }}>
                    {barTiers[2]?.benefits.map((benefit, index) => (
                      <p>{benefit}</p>
                    ))}
                  </div>
                </Drawer>
                <button
                  className='Benefits-button'
                  style={{ background: `${tiers[3].color}` }}
                  onClick={toggleDrawer4}>
                  {barTiers[3]?.name}
                </button>
                <Drawer
                  open={Drawer4IsOpen}
                  onClose={toggleDrawer4}
                  direction='right'
                  style={{ width: '80vw', marginTop: '12.5rem' }}
                  className='Benefits-Drawer'>
                  <div
                    style={{
                      transform: 'translateY(0%)',
                      fontSize: '1.25rem',
                    }}>
                    {barTiers[3]?.benefits.map((benefit, index) => (
                      <p key={index}>{benefit}</p>
                    ))}
                  </div>
                </Drawer>
              </>
            )} */}
          {/* {programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' &&
            Mobile && (
              <>
                <button
                  className='Benefits-button'
                  style={{ background: `${tiers[0].color}` }}
                  onClick={toggleDrawer1}>
                  {barTiers[0]?.name}
                </button>
                <Drawer
                  open={Drawer1IsOpen}
                  onClose={toggleDrawer1}
                  direction='top'
                  style={{ marginTop: '1rem' }}
                  className='Benefits-Drawer'>
                  <div className='Benefits-Drawer1'>{barTiers[0]?.apply}</div>
                  <div
                    style={{
                      transform: 'translateY(100%)',
                      display: 'block',
                      width: '100%',
                      height: '100px',
                      justifyContent: 'left',
                    }}>
                    {barTiers[0]?.benefits.map((benefit, index) => (
                      <p
                        key={index}
                        style={{ display: 'block', fontSize: '0.83rem' }}>
                        {benefit}
                      </p>
                    ))}
                  </div>
                </Drawer>
                <button
                  className='Benefits-button'
                  style={{ background: `${tiers[1].color}` }}
                  onClick={toggleDrawer2}>
                  {barTiers[1]?.name}
                </button>
                <Drawer
                  open={Drawer2IsOpen}
                  onClose={toggleDrawer2}
                  direction='left'
                  style={{
                    paddingLeft: '1rem',
                    width: '75vw',
                    overflow: 'scroll',
                    paddingTop: '1.5rem',
                  }}
                  className='Benefits-Drawer'>
                  <div
                    style={{
                      transform: 'translateY(30%)',
                      display: 'block',
                      textAlign: 'left',
                      overflow: 'scroll',
                    }}>
                    {barTiers[1]?.benefits.map((benefit, index) => (
                      <p
                        style={{
                          margin: '0',
                          lineHeight: '2rem',
                          fontSize: '0.83rem',
                        }}
                        key={index}>
                        {benefit}
                      </p>
                    ))}
                  </div>
                </Drawer>
                <button
                  className='Benefits-button'
                  style={{ background: `${tiers[2].color}` }}
                  onClick={toggleDrawer3}>
                  {barTiers[2]?.name}
                </button>
                <Drawer
                  open={Drawer3IsOpen}
                  onClose={toggleDrawer3}
                  direction='bottom'
                  style={{
                    height: '60.5vh',
                    paddingTop: '2rem',
                    overflow: 'scroll',
                  }}
                  className='Benefits-Drawer'>
                  <div
                    style={{
                      transform: 'translateY(0%)',
                      fontSize: '0.83rem',
                      margin: '0',
                      lineheight: '0.5rem',
                      padding: '0',
                    }}>
                    {barTiers[2]?.benefits.map((benefit, index) => (
                      <p>{benefit}</p>
                    ))}
                  </div>
                </Drawer>
                <button
                  className='Benefits-button'
                  style={{ background: `${tiers[3].color}` }}
                  onClick={toggleDrawer4}>
                  {barTiers[3]?.name}
                </button>
                <Drawer
                  open={Drawer4IsOpen}
                  onClose={toggleDrawer4}
                  direction='right'
                  style={{
                    width: '80vw',
                    paddingLeft: '1rem',

                    marginTop: '7rem',
                    textAlign: 'left',
                    overflowY: 'scroll',
                  }}
                  className='Benefits-Drawer'>
                  <div style={{ display: 'block', overflowY: 'scroll' }}>
                    {barTiers[3]?.benefits.map((benefit, index) => (
                      <p
                        style={{
                          fontSize: '0.83rem',
                          marginBottom: '0rem',
                          lineHeight: '2rem',
                        }}
                        key={index}>
                        {benefit}
                      </p>
                    ))}
                  </div>
                </Drawer>
              </>
            )} */}
        </div>
        {/* {programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' && (
          <table className='Benefits-tableContainer'>
            <thead>
              <th>
                <td>{Ixian[0].title}</td>
              </th>
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  id='Benefits-benefits'
                  style={{
                    fontWeight: '600',
                    textAlign: 'center',
                    width: '40%',
                  }}>
                  Benefits at a glance
                </td>
                {Ixian.map((e, index) => (
                  <td
                    key={index}
                    className='Benefits-Tier'
                    style={{
                      textAlign: 'center',
                      fontWeight: '600',
                      width: '40%',
                    }}>
                    {e.Name}
                  </td>
                ))}
              </tr>
              {fullBenefitsList.map((benefit, index) => (
                <tr id='Benefits-benefits' key={index}>
                  <td>{benefit}</td>
                  <td style={{ textAlign: 'center' }}>{getBulletPoint()}</td>
                  <td style={{ textAlign: 'center' }}>{getBulletPoint()}</td>
                  <td style={{ textAlign: 'center' }}>{getBulletPoint()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <table className='Benefits-tableContainer'>
          <thead>
            <th
              style={{
                display: 'block',
                justifyContent: 'center',
                width: '100%',
              }}>
              <td className='Benefits-title'>Ixian Grand Club</td>
            </th>
          </thead>

          <tbody>
            <tr className='Benefits-headings'>
              <td
                style={{
                  fontWeight: '600',
                  textAlign: 'center',
                  width: '40%',
                }}>
                Benefits at a glance
              </td>
              <td
                style={{
                  background: `${tiers[0].color}`,
                  fontWeight: '600',
                  textAlign: 'center',
                  className: 'Benefits-Tier',
                }}>
                Level 1
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  fontWeight: '600',
                  textAlign: 'center',
                  className: 'Benefits-Tier',
                }}>
                Level 2
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,

                  textAlign: 'center',
                  className: 'Benefits-Tier',
                  color: 'white',
                }}>
                Level 3
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Spend 1€, get 1 loyalty point
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                {' '}
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                {' '}
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Discount for direct bookings
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                5%
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                7%
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                10%
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                A bottle of wine, water and a fruit basket in room upon arrival
                (applies only for online bookings via atlanticahotels.com)
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Late check-out until 14.00 (<i>subject to availability</i>)
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                One free a la Carte meal* during stay in the Hotel (
                <i>
                  Applies for Atlantica Hotels which operate A La Carte
                  Restaurants
                </i>
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Late check-out until 18.00 (subject to availability)
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Room Upgrade (subject to availability)
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                <VscCircleFilled />
              </td>
            </tr>
          </tbody>
        </table>
        ) */}
        {(programLabel === MAST.PROD || programLabel === MAST.INTG) && (
          <div>
          <table className='Benefits-tableContainer'>
            <thead>
             
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  id='Benefits-TierGlance'
                  style={{
                    textAlign: 'center',
                    width: '40%',
                  }}>
                  Benefits at a glance
                </td>

                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',

                  }}>
                  Friends
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                    color:'white',

                  }}>
                 Close Friends
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                Family 
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Discount for direct bookings</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}>
                  7.50%*
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  10%*
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Discount for spa treatments**</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}>
                  10%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  15%
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                  Free upgrade (upon availability)
                </td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Early check in/ late check out (upon availability)
                </td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                     <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                    // color: 'white',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                In room welcome offer
                </td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 
                   <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}>
                     <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Exclusive offers                
                </td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                   <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}>
                  <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Special farewell gift according to tier                
                </td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}>
                   <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
               <tr>
                <td id='Benefits-benefits'>
                Best room available in booked category               
                 </td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}>
                   <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Complimentary Spa treatment for two**
                 </td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}>
                   
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                In room breakfast
                </td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}>
                   
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                 Once per stay
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Priority check in
                </td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}>
                   
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
            </tbody>
          </table>
          <br></br>
          <span className='text-md italic note'>* valid for April to October months	</span>
          <br></br>
          <span className='text-md italic note'>** valid for stays at Parga Beach Resort</span>
          <br></br>
          <br></br>
          </div>
        )}
        
      
        {(programLabel === IXI.PROD || programLabel === IXI.INTG) && (
          <div>
          <table className='Benefits-tableContainer'>
          <thead>
         
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  id='Benefits-Tier'
                  style={{
                    
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Benefits at a glance
                </td>

                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[0].color}`,
                   
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  White
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[1].color}`,
                   
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Blue
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[2].color}`,
                   
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Silver
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[3].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Gold
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[0]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  {' '}
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[1]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[2]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[3]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  15%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  15%
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[4]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  {' '}
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[5]}</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[6]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[7]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  {' '}
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[8]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[9]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[10]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[11]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
             
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[13]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[14]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[15]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[16]}</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
            </tbody>
          </table>
          <br></br>
          <br></br>
          </div>
        )}
        { programLabel === MK.PROD && (
          <div>
          <table className='Benefits-tableContainer'>
          <thead>
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  id='Benefits-Tier'
                  style={{
                    fontWeight: '600',
                    textAlign: 'center',
                    width: '40%',
                    

                  }}>
                  Benefits at a glance
                </td>

                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[0].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
                  White
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[1].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
                  Silver
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[2].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
                  Gold
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Discount for direct bookings</td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  8%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  12%
                </td>
              </tr>
               <tr>
                <td id='Benefits-benefits'>
                Welcome In-room Amenity & Welcome Gift                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 
                   <BsSuitDiamondFill />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                     <BsSuitDiamondFill />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Farewell Gift                 </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 
                   <BsSuitDiamondFill />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                     <BsSuitDiamondFill />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Discount for Spa treatments                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>5%</td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
15%                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>
                Discount in Restaurants & Bars for Pnoe                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>5%</td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
10%                  </td>
                <td
                id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
15%                </td>
              </tr>
        
              <tr>
                <td id='Benefits-benefits'>Discount for a second visit in A la carte for Unique Blue</td>

                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  20%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  20%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  20%
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Turn down Service for Pnoe</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                                    <BsSuitDiamondFill />

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                </td>
              </tr> 
     
              <tr>
                <td id='Benefits-benefits'>Room Upgrade (subject to availability)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
<BsSuitDiamondFill />               </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
<BsSuitDiamondFill />                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>Early Check In (upon availability)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>Late Check out (upon availability)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                </td>
              </tr>
              
              <tr>
                <td id='Benefits-benefits'>Discount at Klosti Boutique for Pnoe</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
10%                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>One complimentary use of tennis court for Unique Blue</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                                    <BsSuitDiamondFill />

                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Complimentary Access to the Spa area (once per stay) for Pnoe</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>

                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Turn down Service for Unique Blue</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                </td>
              </tr>
             
              <tr>
                <td id='Benefits-benefits'>Complimentary Mini-Bar (upon arrival)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                </td>
              </tr>

            

              <tr>
                <td id='Benefits-benefits'>Complimentary A la Carte Dinner for Pnoe</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <BsSuitDiamondFill />
                  </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>Complimentary Transfer (upon arrival, from and to Heraklion airport/port - for direct bookings)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                 <BsSuitDiamondFill />
                </td>
              </tr>

             

            </tbody>
          </table>
         
          <br />
          <br />
          </div>
        )}
        
        {(programLabel === ALT.PROD || programLabel === ALT.INTG) && (
          <div>
        <table className='Benefits-tableContainer'>
          <thead>
          
            
          </thead>
          <tbody style={{ border: '1px solid black' }}>
           
            <tr
              className='Benefits-headings'
              style={{ borderBottom: '1px solid black' }}>
              <td
                style={{
                  fontWeight: '600',
                  textAlign: 'center',
                  width: '40%',

                }}>
                Benefits at a glance
              </td>

              <td 
               className='Benefits-Tier'
                style={{
                  // background: `${tiers[0].color}`,
                  fontWeight: '600',
                  textAlign: 'center',

                }}
                >
                Level 1
              </td>
              <td
                  className='Benefits-Tier'
                style={{
                  // background: `${tiers[1].color}`,
                  fontWeight: '600',
                  textAlign: 'center',
                }}>
                Level 2
              </td>
              <td
                  className='Benefits-Tier'
                style={{
                  // background: `${tiers[2].color}`,
                  fontWeight: '600',
                  textAlign: 'center',


                }}>
                Level 3
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
              Spend  1€, get 1 loyalty point!              
              </td>
              <td
                style={{
                  // background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  // background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  // background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              
            </tr>
            <tr>
              <td id='Benefits-benefits'>Discount for direct bookings</td>

              <td
                style={{
                  // background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                5%
              </td>
              <td
                style={{
                  // background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                7%
              </td>
              <td
                style={{
                  // background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                10%
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'> A bottle of wine, water and a fruit basket in room upon arrival (applies only for online bookings via atlanticahotels.com)</td>

              <td
                style={{
                  // background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  // background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  // background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
             
            </tr>
            <tr>
              <td id='Benefits-benefits'>Late check-out until 14:00 (subject to availability)</td>

              <td
                style={{
                  // background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
              </td>
              <td
                style={{
                  // background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  // background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
             
            </tr>
            <tr>
              <td id='Benefits-benefits'>One free a la Carte meal* during the stay in the Hotel (Applies for Atlantica Hotels which operate A La Carte Restaurants)</td>

              <td
                style={{
                  // background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
              </td>
              <td
                style={{
                  // background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
              </td>
              <td
                style={{
                  // background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
             
            </tr>
            <tr>
              <td id='Benefits-benefits'>
              Late check-out until 18:00 (subject to availability)             
              </td>

              <td
                style={{
                  // background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  // background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
              </td>
              <td
                style={{
                  // background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              
            </tr>
            <tr>
              <td id='Benefits-benefits'>
              Room Upgrade (subject to availability)
              </td>

              <td
                style={{
                  // background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  // background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
              </td>
              <td
                style={{
                  // background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
             
            </tr>             
          </tbody>
        </table>
        <br />
          <br />
          </div>
              )}
              {( programLabel==='AA116997-54BC-468D-94C7-28EFD2FF7C4C') && (
                <div>
          <table className='Benefits-tableContainer'>
          <thead>
         
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  id='Benefits-Tier'
                  style={{
                    
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Benefits at a glance
                  </td>

                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[0].color}`,
                   
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Silver
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[1].color}`,
                   
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Gold
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[2].color}`,
                   
                    fontWeight: '600',
                    textAlign: 'center', color:'white'
                  }}>
                  Platinum
                </td>
               
              </tr>
              <tr>
                <td id='Benefits-benefits' className=' font-bold'>Enrollment bonus points</td>

                <td
                  id='Benefits-benefits' className=' font-bold'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  30
                                  </td>
                <td
                  id='Benefits-benefits' className=' font-bold'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  {' '}
                  30
                                  </td>
                <td
                  id='Benefits-benefits' className=' font-bold'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  30
                </td>
               
              </tr>
              <tr>
                <td id='Benefits-benefits'>Discount on hotels's website rates (discount percentage according to tier)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  7%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  10%
                </td>
                
              </tr>
              <tr>
                <td id='Benefits-benefits'>Invitation to Manager's Cocktail</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <VscCircleFilled />
                </td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>Welcome offer in room</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <VscCircleFilled />
                </td>
               
              </tr>


              <tr>
                <td id='Benefits-benefits'>Special departure gift according to tier</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  {' '}
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <VscCircleFilled />
                </td>
                
              </tr>
              <tr>
                <td id='Benefits-benefits'>Priority Check In Upon Arrival </td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <VscCircleFilled />
                </td>
               
              </tr>
              <tr>
                <td id='Benefits-benefits'>Discount on Spa Treatments</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <VscCircleFilled />
                </td>
               
              </tr>
              <tr>
                <td id='Benefits-benefits'>Hotel Outlets discount</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <VscCircleFilled />
                </td>
               
              </tr>
              <tr>
                <td id='Benefits-benefits'>3rd party discount </td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <VscCircleFilled />
                </td>
               
              </tr>
             
              <tr>
                <td id='Benefits-benefits'>3rd party experiences</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',                    

                  }}>                  <VscCircleFilled />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>                  <VscCircleFilled />
</td>
               
              </tr>
              <tr>
                <td id='Benefits-benefits'>Exclusive emails with hidden benefits per tier</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}> <VscCircleFilled /></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}> <VscCircleFilled /></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}> <VscCircleFilled /></td>
               
              </tr>
              <tr>
                <td id='Benefits-benefits'>Free nights</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}><VscCircleFilled /></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}><VscCircleFilled /></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}><VscCircleFilled /></td>
               
              </tr>
             
              <tr>
                <td id='Benefits-benefits'>Complimentary Early Check In</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}><VscCircleFilled /></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>Complimentary Late Check Out</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}><VscCircleFilled /></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>Free Spa Treatment</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}><VscCircleFilled /></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>Reserved sunbeds at the beach</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}><VscCircleFilled /></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>Free Room Upgrade</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>Complimentary a la carte Dinner</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>Free Transfer from/to Heraklion airport</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>Reserved Gazebo</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>Free laundry service</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>Room extras discount</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>Beauty Basket with branded local products</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>In room Breakfast </td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
              <tr>
                <td id='Benefits-benefits'>Luxurious Hotel Beach Bag</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                    color:'white'

                  }}><VscCircleFilled /></td>
              
              </tr>
                           
            </tbody>
          </table>
          <br></br>
          <div style={{marginLeft:'10%'}}>
      <h3>Conditions</h3>
      <ul>
        <li>Direct bookings are considered those made directly with the hotel via its website, or by email, or by phone.</li>
        <li>Priority Check In Upon Arrival, Complimentary Early Check In, Complimentary Late Check Out, Free Room Upgrade: Benefits applied upon availability</li>
        <li>Luxurious Hotel Beach Bag, Beauty Basket with branded local products, In-Room Breakfast, Free laundry service, Free Spa treatment: Benefits applied if minimum stay of 3 days is fulfilled and offered once per stay</li>
        <li>Reserved sunbeds at the beach, Reserved Gazebo: Benefits applied if available by the hotel</li>
        <li>Free Transfer from/to Heraklion airport: Benefits applied if available by the hotel and a minimum stay applies depending on the hotel</li>
        <li>Complimentary a la carte Dinner: Benefits applied once per week and minimum stay of one fully completed week is required</li>
      </ul>
    </div>
          <br></br>
          <br></br>
          </div>
              )}

              { programLabel === 'AVRACOLL-13A15C9C-186C-42C6-9240-FEB62E2A9EC1' && (
          <div>
          <table className='Benefits-tableContainer'>
          <thead>
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  id='Benefits-Tier'
                  style={{
                    fontWeight: '600',
                    textAlign: 'center',
                    width: '40%',
                    

                  }}>
                  Benefits at a glance
                </td>

                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[0].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
                  White
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[1].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
                  Silver
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[2].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
                  Gold
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Member Rates for Direct Discount *</td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  3%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  7%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
              </tr>
               <tr>
                <td id='Benefits-benefits'>
                  Room upgrade on availablity
                                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 
                   <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                     <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                VIP welcome amenity
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 
                   <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                     <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Daily unlimited (high speed) internet access for unlimited number of devices
                 </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}><VscCircleFilled /></td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                   <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>
                Early check-in bassed on availability
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                   <VscCircleFilled />
                  </td>
                <td
                id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
        
              <tr>
                <td id='Benefits-benefits'>Late check-out(4 p.m.) based on availability</td>

                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Personalized Welcome Note</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>Complimentary 30-minute extension on any 50-minute body or face treatment(exc. ...)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                                    <VscCircleFilled />
                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>Clothes pressing/dry cleaning</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                                    <VscCircleFilled />

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>Unpacking services</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                                    <VscCircleFilled />

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>


            </tbody>
          </table>
         
          <br />
          <br />
          </div>
        )}  

        {( programLabel==='ALD-D6723397-1407-4C60-93BD-386DCE063346') &&(
          <div>
          <table className='Benefits-tableContainer'>
          <thead>
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  id='Benefits-Tier'
                  style={{
                    fontWeight: '600',
                    textAlign: 'center',
                    width: '40%',
                    

                  }}>
                  Benefits
                </td>

                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[0].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
                  Admirer (from 0 - 99.999 points)
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[1].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
                  Enthusiast (from 100.000 – 199.999 points)

                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[2].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
                  Devotee (from 200.000 points)

                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Discount on direct future bookings</td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
              </tr>
               <tr>
                <td id='Benefits-benefits'>
                Priority check in
                                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 
                   <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                     <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Bonus points with registration (500 points)
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 
                   <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                     <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Discount in Spa/Wellness Center
                 </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>10%</td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                   10%
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                 Complimentary 25' Massage
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>
                Complimentary late check out until 17:00 (upon availability)
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                   <VscCircleFilled />
                  </td>
                <td
                id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
        
              <tr>
                <td id='Benefits-benefits'>Room Upgrade (upon availability)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Welcome Treat upon arrival</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  Enthusiast Welcome Treat
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  Devotee Welcome Treat
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>Free mini bar with soft drinks & refreshments with daily refill
                </td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>1 Complimentary a la carte meal</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>Special departure gift</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Afternoon maid service</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>


            </tbody>
          </table>
         
          <br />
          <br />
          </div>
        )}  
          {/* <div className="tier-benefits-container">
      <div className={`tier-column ${memberInfo.memberTier === 'Admirer' ? 'active-tier' : ''}`} 
      style={{ backgroundColor: `${tiers[0].color}`}}>
        <h3>Admirer (0 - 99,999 points)</h3>
        <ul>
          <li> 5% discount on direct future bookings</li>
          <li> Priority check-in</li>
          <li> Bonus points with registration (500 points)</li>
          <li> 10% discount in Spa/Wellness Center</li>
        </ul>
      </div>
      <div className={`tier-column ${memberInfo.memberTier === 'Enthusiast' ? 'active-tier' : ''}`}
        style={{ backgroundColor: `${tiers[1].color}`}}>
        <h3>Enthusiast (100,000 – 199,999 points)</h3>
        <ul>
          <li> 5% discount on direct future bookings</li>
          <li> Priority check-in</li>
          <li> Sparkling wine and fruit basket in the room upon arrival</li>
          <li> Complimentary check-out until 17:00 (upon availability)</li>
          <li> Room Upgrade (upon availability)</li>
          <li> 10% discount in Spa/Wellness Center</li>
        </ul>
      </div>
      <div className={`tier-column ${memberInfo.memberTier === 'Devotee' ? 'active-tier' : ''}`}
        style={{ backgroundColor: `${tiers[2].color}`, color:'white'}}>
        <h3>Devotee (200,000 points)</h3>
        <ul>
          <li> 5% discount on direct future bookings</li>
          <li> Priority check-in</li>
          <li> Free mini bar with soft drinks & refreshments with daily refill</li>
          <li> Bottle of champagne & flowers upon arrival</li>
          <li> 1 Complimentary a la carte meal</li>
          <li> Special departure gift</li>
          <li> Complimentary 25' massage</li>
          <li> Room Upgrade (upon availability)</li>
          <li> Afternoon maid service</li>
        </ul>
      </div>
    </div> */}
    {( programLabel==='0E2CBAB8-4DA6-402D-8185-36E907243324') &&(
          <div>
          <table className='Benefits-tableContainer'>
          <thead>
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  id='Benefits-Tier'
                  style={{
                    fontWeight: '600',
                    textAlign: 'center',
                    width: '40%',
                    

                  }}>
Zoe's Loyalty Club & Rewards
                </td>

                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[0].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
Loyal (0 - 39,999 points)
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[1].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
VIP (40,000 - 59,999 points)

                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[2].color}`,
                    color:'white',

                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
Ambassador (60,000+ points)

                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Member Rate
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
10%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
12%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    color:'white',

                    textAlign: 'center',
                  }}>
15%
                </td>
              </tr>
               <tr>
                <td id='Benefits-benefits'>
                Welcome Treatment in the room
                                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 
                   <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                     <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Earn points from hotel stays
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 
                   <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                     <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Welcome Drink
                 </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                                     <VscCircleFilled />

                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                                   <VscCircleFilled />

                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>
                Discount at Restaurant and Bar
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>10%
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
10%
                  </td>
                <td
                id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
10%
                </td>
              </tr>
        
              <tr>
                <td id='Benefits-benefits'>*****Discount at Vanity Spa according to tier
</td>

                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 5%


                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
10%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
15%
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Exclusive Offers
</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />

                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>Free Bottle of Water (daily)

                </td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />

                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>*Early Check In
</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>                  <VscCircleFilled />

                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>*Late Check Out
</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>   <VscCircleFilled />
                </td>               

                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    color:'white',
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>**Airport Transfer Discount
</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}> <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>No Room Service Charges

</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}> <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Complimentary Cocktail (once per stay)

</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}> <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>***Discount in Laundry Service

</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}> <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>*Free Access in Spa (hammam & sauna)

</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}> <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits' className=' font-bold'>Turn down Service (evening)

</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}> <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>****Free Room Upgrade

</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}> 
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Special Amenities

</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>In Room Breakfast


</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Free Mini Bar at Check in (once)


</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>*Free Boat Trip


</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>*Free Scuba Diving Lesson Experience


</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>*Car Rental Upgrade


</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Make a friend a VIP Member


</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[2].color}`,
color:'white',

                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

            </tbody>
          </table>
          <br></br>
          <div className='notes'>
          <span className='text-md italic note'>*Subject to Avaialability & pre-booking </span>
          <br></br>

          <span className='text-md italic note'>**When transfer booked with us in advace for Larnaca & Paphos airports</span>
          <br></br>

          <span className='text-md italic note'>***For the usage of our selected Laundry Service Partners</span>
          <br></br>

          <span className='text-md italic note'>****Subject to availbility and occupancy</span>
          <br></br>

          <span className='text-md italic note'>*****From selected options in spa brochure</span>
          <br></br>
          </div>
          <br />
          <br />
          </div>
        )} 

        {( programLabel==='53CE9A36-A9EC-4A7A-97A1-7E76FCFF27F5') &&(
          <div>
          <table className='Benefits-tableContainer'>
          <thead>
         
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  id='Benefits-Tier'
                  style={{
                    
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Benefits
                </td>

                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[0].color}`,
                   
                    fontWeight: '600',
                    textAlign: 'center', color:'white'
                  }}>
Member                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[1].color}`,
                   
                    fontWeight: '600',
                    textAlign: 'center', color:'white'
                  }}>
Local                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[2].color}`,
                   
                    fontWeight: '600',
                    textAlign: 'center', color:'white'
                  }}>
Friend                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                   background: `${tiers[3].color}`,
                    fontWeight: '600',
                    textAlign: 'center',                    color:'white'

                  }}>
Family                 </td>
              </tr>
            
              <tr>
                <td id='Benefits-benefits'>Discount on direct bookings</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center', color:'white'
                  }}>
10%                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center', color:'white'
                  }}>
10%                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center', color:'white'
                  }}>
12%                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
15%                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Early check-in (on request, upon availability)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Exclusive members-only offers</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
            
              <tr>
                <td id='Benefits-benefits'>Discounts at partner businesses</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                  <AlianthosW />
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  {' '}
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Personal Advisor Long Time Αppointment</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                  
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Complimentary cocktail</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>Farewell gift</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>One complimentary Lunch - one per person</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>                
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
              {/* <tr>
                <td id='Benefits-benefits'>Special Welcome Treat</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>                  
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr> */}
              <tr>
                <td id='Benefits-benefits'>Best room available (in booked category, upon availability)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>                
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Complimentary stay</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>                 
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <AlianthosW />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
             
              <tr>
                <td id='Benefits-benefits'>Discount in Hotel restaurant & bar</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Tailor your stay (on request, upon availability)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>                 
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Late check-out (on request, upon availability)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>                  
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Afternoon housekeeping (on request)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>                 
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Room upgrade (on request, upon availability)</td>

                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>                 
</td>
                <td
                  id='Benefits-benefits'
                  style={{
                   background: `${tiers[3].color}`,
                    textAlign: 'center',                    color:'white'

                  }}>
                  <AlianthosW />
                </td>
              </tr>
             
            </tbody>
          </table>
          <br></br>
          <br></br>
          </div>
        )}

        {( programLabel==='9CBD7717-07C3-4C3E-90B4-6FD3DBF8A8BD') &&(
          <div>
          <table className='Benefits-tableContainer'>
          <thead>
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  id='Benefits-Tier'
                  style={{
                    fontWeight: '600',
                    textAlign: 'center',
                    width: '40%',
                    

                  }}>
                  Benefits
                </td>

                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[0].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
Silver
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[1].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                    

                  }}>
Gold

                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    background: `${tiers[2].color}`,
                    fontWeight: '600',
                    textAlign: 'center',color:'white'
                    

                  }}>
Diamond

                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Discount on reservations</td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
7%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
10%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',color:'white'
                  }}>
15%
                </td>
              </tr>
               <tr>
                <td id='Benefits-benefits'>
                Welcome gift
                                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 
                   <VscCircleFilled />
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                     <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',color:'white'
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Discount in SPA
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>                 
5%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
7%
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',color:'white'
                  }}>
12%
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                Complimentary Roundtrip transfer to/from the airport/port
                 </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                  </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',color:'white'
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>
                Early check in - late check out upon availability
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                   <VscCircleFilled />
                  </td>
                <td
                id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',color:'white'
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
        
              <tr>
                <td id='Benefits-benefits'>Discount on new openings
</td>

                <td
                  id='Benefits-benefits'
                  style={{
                     background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
10%
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',color:'white'
                  }}>
15%
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Complimentary a la carte dinner
</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',color:'white'
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>Free upgrade (upon availability)

                </td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',color:'white'
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>1 Complimentary a la carte meal</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center',color:'white'
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>Free mini-bar refill</td>

                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>

                   </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                </td>
                <td
                  id='Benefits-benefits'
                  style={{
                    background: `${tiers[2].color}`,
                    textAlign: 'center', color:'white'
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
            </tbody>
          </table>
         
          <br />
          <br />
          </div>
        )} 
  {(programLabel === 'STR-E6EA5920-0DA2-4C53-8098-8F28EB81C228') && (
    <div>
      <table className='Benefits-tableContainer'>
        <thead>
          <tr className='Benefits-headings'>
            <th style={{ fontWeight: '600', textAlign: 'center', width: '40%' }}>Benefits</th>
            <th style={{ background: `${tiers[0].color}`, fontWeight: '600', textAlign: 'center' }}>Silver</th>
            <th style={{ background: `${tiers[1].color}`, fontWeight: '600', textAlign: 'center' }}>Gold</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id='Benefits-benefits'>Discount on rates (automatically applied upon registration)</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}>10%</td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>12.5%</td>
          </tr>
          <tr>
            <td id='Benefits-benefits'>Exclusive offers for members only</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
          </tr>
          <tr>
            <td id='Benefits-benefits'>Discount on Spa Treatments</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}>10%</td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>20%</td>
          </tr>
          <tr>
            <td id='Benefits-benefits'>Welcome Drink</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
          </tr>
          <tr>
            <td id='Benefits-benefits'>Early check-in & late check-out (upon availability)</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
          </tr>
          <tr>
            <td id='Benefits-benefits'>Discount in the a la carte restaurants / bars</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}>10%</td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>20%</td>
          </tr>
          <tr>
            <td id='Benefits-benefits'>Complimentary cocktail on Captain’s Terrace</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
          </tr>
          <tr>
            <td id='Benefits-benefits'>A bottle of local wine and fruit platter in the room upon arrival</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
    </div>
  )}
  {(programLabel === 'EF395EDA-A887-4FC8-8FCF-E42AB9B4603C') && (
    <div>
      <table className='Benefits-tableContainer'>
        <thead>
          <tr className='Benefits-headings'>
            <th style={{ fontWeight: '600', textAlign: 'center', width: '40%' }}>Benefits</th>
            <th style={{ background: `${tiers[0].color}`, fontWeight: '600', textAlign: 'center' }}>Silver</th>
            <th style={{ background: `${tiers[1].color}`, fontWeight: '600', textAlign: 'center' }}>Gold</th>
            <th style={{ background: `${tiers[2].color}`, fontWeight: '600', textAlign: 'center' }}>Diamond</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <td id='Benefits-benefits'>Discount on reservations.</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}>7%</td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>10%</td>
            <th style={{ background: `${tiers[2].color}`, fontWeight: '600', textAlign: 'center' }}>15%
</th>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Welcome gift
</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center' }}><VscCircleFilled /></td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Discount in SPA
</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}>5%
</td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>7%
</td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center' }}>12%
</td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Complimentary Roundtrip transfer to/from the airport/port
</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center' }}><VscCircleFilled /></td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Early check in - late check out upon availability
</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center' }}><VscCircleFilled /></td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Discount on new openings
</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>10%</td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center' }}>15%
</td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Complimentary a la carte dinner
</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center' }}><VscCircleFilled /></td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Free upgrade (upon availability)
</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center' }}><VscCircleFilled /></td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Free mini-bar refill

</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center' }}><VscCircleFilled /></td>

          </tr>
        </tbody>
      </table>
      <br />
      <br />
    </div>
  )}
  {(programLabel === 'D7238698-8B30-44CC-A818-3D3E28176180') && (
    <div>
      <table className='Benefits-tableContainer'>
        <thead>
          <tr className='Benefits-headings'>
            <th style={{ fontWeight: '600', textAlign: 'center', width: '40%' }}>Loyalty Benefits</th>
            <th style={{ background: `${tiers[0].color}`, fontWeight: '600', textAlign: 'center' }}>Cap Silver  0 – 100,000 </th>
            <th style={{ background: `${tiers[1].color}`, fontWeight: '600', textAlign: 'center' }}>Cap Gold 100,001 – 300,000</th>
            <th style={{ background: `${tiers[2].color}`, fontWeight: '600', textAlign: 'center',color:'white' }}>Cap Platinum  300,001</th>

          </tr>
        </thead>
        <tbody>
        <tr>
            <td id='Benefits-benefits'>Discount for direct bookings</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}>5%</td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>7%</td>
            <th style={{ background: `${tiers[2].color}`, fontWeight: '600', textAlign: 'center',color:'white' }}>10%</th>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Complimentary Welcome Amenities</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}>ELITE 3</td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>ELITE 2</td>
            <th style={{ background: `${tiers[2].color}`, fontWeight: '600', textAlign: 'center',color:'white' }}>ELITE 1</th>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Complimentary Late Check-Out</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}>14:00 </td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>14:00 </td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center' ,color:'white'}}>17.00</td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Farewell Gift /Receive a special gift upon arrival at participating hotels. Gifts vary by brand and region. (Olive Oil)
</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}><VscCircleFilled />
</td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}><VscCircleFilled />
</td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center' ,color:'white'}}><VscCircleFilled />
</td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Discount at Restaurants/Bars</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}>10%</td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>10%</td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center',color:'white' }}>10%</td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Discount on Spa Treatments/Retail</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}>10%</td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>10%</td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center',color:'white' }}>10%</td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Discount on Experiences</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}>10%</td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>10%</td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center',color:'white' }}>10%</td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Complimentary Early Check In </td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>11.00 am </td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center',color:'white' }}>11.00 am </td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Complimentary Dinner / Lunch Experience per person in room (max 100€ total)
</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}><VscCircleFilled /></td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center',color:'white' }}><VscCircleFilled /></td>

          </tr>
          {/* <tr>
            <td id='Benefits-benefits'>Free upgrade (upon availability)
</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center',color:'white' }}><VscCircleFilled /></td>

          </tr> */}
          <tr>
            <td id='Benefits-benefits'>Discount /Free Airport Transfer

</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}>50% discount	</td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center',color:'white' }}>Free transfer</td>

          </tr>
          <tr>
            <td id='Benefits-benefits'>Complimentary Spa Treatment per person in room up to 100EUR per person

</td>
            <td style={{ background: `${tiers[0].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[1].color}`, textAlign: 'center' }}></td>
            <td style={{ background: `${tiers[2].color}`, textAlign: 'center',color:'white' }}><VscCircleFilled /></td>

          </tr>
         
        </tbody>
      </table>
      <br />
      <br />
    </div>
  )}

      </div>


    </>
  );
}
