import React, { createContext, useState, useEffect } from 'react';
import useFetch from '../Components/Fetch/useFetch';

export const InfoDataContext = createContext();

const InfoContext = (props) => {
  const { data: user } = useFetch('/api/member/getInfo');
  const [info, setInfo] = useState(user);

  useEffect(() => {
    if (JSON.stringify(info) !== JSON.stringify(user)) {
      setInfo(user);
    }
  }, [user]);
  console.log(info, 'info');

  return (
    <InfoDataContext.Provider value={info}>
      {info && props.children}
    </InfoDataContext.Provider>
  );
};
export default InfoContext;
