import React, { useState, useContext, useRef, useEffect } from 'react';
import Message from '../Message/Message';
import { NavLink } from 'react-router-dom';
import Validate from './Validate';
import UseLogin from './UseLogin';
import { ThemeDataContext } from '../../../ContextProviders/theme';
import './SignInFormVerity2.css';
import { BsEyeFill } from 'react-icons/bs';
import Show from '../../../Icons/Show';
import DontShow from '../../../Icons/DontShow';
import { RiEyeLine } from 'react-icons/ri';
import { MdOutlinePanoramaFishEye } from 'react-icons/md';
import styled from 'styled-components';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import Circle from '../../../Icons/Circle';
import Vector from '../../../Assets/Verity2/Vector.svg';
import CheckboxVerity2 from '../../../Components/Checkbox/CheckboxVerity2';

export function SignInForm() {
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    authErrors,
    setAuthErrors,
    isDisabled,
    // handleSubmitClicked,
  } = UseLogin(Validate);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const programLabel = localStorage.getItem('programLabel');

  const { toasts } = useToasterStore();

  const TOAST_LIMIT = 1;

  useEffect(() => {
    console.log(toasts, 'toasts');
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  // const enableComponents = () => {
  //   setIsDisabled(false);
  //   console.log('setIsDisabled to false');
  // };

  // const handleSubmitClicked = () => {
  //   setIsDisabled(true);
  //   console.log('setIsDisabled to true');
  //   setTimeout(() => enableComponents(), 5000);
  // };

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };
  const linkStyle = {
    color: `${buttonBackgroundColor}`,
  };

  // const getHeadingColor = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return 'white';
  //   }
  //   return 'var(--color-accent)';
  // };

  // const getBackgroundColor = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //      return 'transparent';
  //   }
  //   return 'white';
  // };

  // const getBorder = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return '2px solid white';
  //   }
  //   return 'none';
  // };

  // const getBorderRadius = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return '50px';
  //   }
  //   return 'none';
  // };
  // const getHeight = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return '50px';
  //   }
  //   return 'auto';
  // };
  // const getColor = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return 'white';
  //   }
  //   return 'var(--color-accent)';
  // };

  // const getEmailPlaceholder = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return `${''} ${''} ${''} ${''} ${''}  Email`;
  //   }
  //   return '';
  // };
  // const getPasswordPlaceholder = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return `${''} ${''} ${''} ${''} ${''}  Password`;
  //   }
  //   return '';
  // };

  // const getFillColor = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return 'white';
  //   }
  //   return 'grey';
  // };
  // const getEyeTransform = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return 'translate(-150%, -18%)';
  //   }
  //   return 'translateX(-90%)';
  // };

  const buttonRef = useRef(null);

  const notify = () => toast(authErrors);

  useEffect(() => {
    if (authErrors.length != 0) {
      notify();
      setAuthErrors([]);
      buttonRef.current.disabled = true;
      setTimeout(() => (buttonRef.current.disabled = false), 3000);
    }
  }, [authErrors, notify, buttonRef]);

  return (
    <>
      <div className='signIn2Heading'>
        <br />
        <br />
        {/* {programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ? (
          <div className='signInKeys'></div>
        ) : (
          <div className='empty'></div>
        )} */}
        <h1
          // style={{ color: `${getHeadingColor()}` }}
          id='signIn2Welcome'>
          Welcome to our{' '}
          <span
            style={{
              color: '#FDAF17',
            }}>
            Loyalty Club
          </span>
        </h1>

        {/* <Message message={authErrors} isError={true} /> */}

        <hr className='line' />
        <p>Enter your details below to continue</p>
        <Toaster
          position='top-center'
          containerStyle={{
            top: 10,
            left: 10,
            bottom: 10,
            right: 10,
          }}
          toastOptions={{
            duration: 4000,
            style: {
              // border: '1px solid #713200',
              padding: '16px',
              color: 'white',
              background: '#FDAF17',
              filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
              fontFamily: 'Open Sans',
            },
          }}
        />
      </div>
      <form className='signIn2Form' onSubmit={handleSubmit}>
        <div className='signIn2-inputs'>
          <div className='signIn2-emailWrap'>
            <br />
            <label htmlFor='email' id='signIn2-email-Label'>
              {/*<img src={EmailIcon} className='emailIcon' alt='email' />*/}
              {/* {programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B'
              ? 'Email'
              : ''} */}
              Username
            </label>
            <input
              // style={{
              //   background: `${getBackgroundColor()}`,
              //   border: `${getBorder()}`,
              //   borderRadius: `${getBorderRadius()}`,
              //   height: `${getHeight()}`,
              //   color: `${getColor()}`,
              //   marginLeft: '3rem',
              // }}
              // style={{ borderBottom: '1px solid var(--color-accent)' }}
              id='email'
              type='email'
              spellCheck='false'
              name='email'
              className='signIn2-form-emailInput'
              value={values.email}
              placeholder='Enter your username'
              // placeholder={getEmailPlaceholder()}
              onChange={handleChange}
            />

            {errors.email && (
              <p className='signIn2-emailError'>{errors.email}</p>
            )}
          </div>
          <br />
          <div className='signIn2-passWrap'>
            <br />
            <label htmlFor='password' className='signIn2passLabel'>
              {/*<img src={PassIcon} className='passIcon' alt='password' />*/}
              {/* {programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B'
              ? 'Password'
              : ''} */}
              Password
            </label>
            <input
              type={passwordShown ? 'text' : 'password'}
              // placeholder={getPasswordPlaceholder()}
              // style={{
              //   background: `${getBackgroundColor()}`,
              //   border: `${getBorder()}`,
              //   borderRadius: `${getBorderRadius()}`,
              //   height: `${getHeight()}`,
              //   color: `${getColor()}`,
              //   marginLeft: '5rem',
              // }}
              placeholder='Enter your password'
              name='password'
              spellCheck='false'
              id='password'
              className='signIn2-form-passInput'
              value={values.password}
              onChange={handleChange}
            />
            {passwordShown ? (
              <DontShow
                color='#FDAF17'
                // style={{ transform: `${getEyeTransform()}` }}
                style={{
                  // display: 'block',
                  gridRow: '3',
                  transform: 'translate(1310%, -157%)',
                }}
                onClick={togglePassword}
              />
            ) : (
              <RiEyeLine
                fill='#FDAF17'
                style={{
                  color: '#FDAF17',
                  height: '21',
                  width: '21',
                  gridRow: '3',
                  transform: 'translate(1310%, -157%)',
                }}
                onClick={togglePassword}
              />
              // <Show
              //   style={{
              //     gridRow: '3',
              //     transform: 'translate(1300%, -150%)',
              //   }}
              //   onClick={togglePassword}
              // />
            )}
            {errors.password && (
              <p className='signIn2-passError'>{errors.password}</p>
            )}
          </div>
        </div>

        <br />
        <br />
        <br />
        <div className='signIn2-formButtonContainer'>
          <button
            disabled={isDisabled}
            style={buttonStyle}
            className='signIn2-formButton'
            type='submit'
            ref={buttonRef}>
            Login
          </button>
        </div>
        <br />
        <div className='signIn2-rememberContainer'>
          <div className='signIn2-formRemember'>
            <br />
            <div className='signIn2Container'>
              <label
                style={{ color: '#fdaf17' }}
                htmlFor='signIn2-rememberMe'
                className='signIn2-checkBoxLabel'>
                Remember Me
              </label>
              <CheckboxVerity2
                id='signIn2-rememberMe'
                type='checkbox'
                name='rememberMe'
                className='checkboxVerity2'
                checked={values.rememberMe}
                onChange={handleChange}
                required={false}
              />

              {/* <Circle
                color='#FDAF17'
                onClick={handleChangeCircle(values)}
                checked={values.rememberMe}
              /> */}
            </div>
          </div>
          <div className='signIn2-forgotPassLinkWrap'>
            <NavLink
              style={linkStyle}
              to='/signIn/forgotpassword'
              className='signIn2-forgotPassLink'>
              Forgot password
              <img src={Vector} width='20.06' height='10.52' alt='vector' />
            </NavLink>
          </div>
        </div>
      </form>
      <footer className='signIn2-footer'>
        <p>
          Don't have an account?{' '}
          <NavLink className='' to='/signUp'>
            Sign up here
          </NavLink>
        </p>
      </footer>
    </>
  );
}

export default SignInForm;

const SideBarContainer = styled.div`
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background: white;
  top: 0%;
  left: -100%;

  transition: 0.3s ease-in-out;

  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};

  @media (orientation: landscape) {
    top: 30%;
    height: 100%;
  }
`;
