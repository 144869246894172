/**
 * Contains methods for color operations.
 */
export class ColorLogic {
    /**
     * Converts a color hex into RGB values.
     * @param {string} hex A hex string of 6 digits. Leading hash symbols are ignored.
     * @returns An object of properties R, G, B.
     */
    static hexToRGB = (hex) => {
        const trimmed = hex.replace('#', '');

        if(trimmed.length != 6){
            throw "Only 6 hex characters are allowed.";
        }

        var rgbHex = trimmed.match(/.{1,2}/g);

        return {
            R: parseInt(rgbHex[0], 16),
            G: parseInt(rgbHex[1], 16),
            B: parseInt(rgbHex[2], 16),
        };
    }

    /**
     * Calculates how bright a colour is. Larger values indicate higher brighness.
     * @param {string} hex A hex string of 6 digits. Leading hash symbols are ignored.
     * @returns A number that represents a colour's brightness.
     */
    static calculateBrightness = (hex) => {
        const rgb = this.hexToRGB(hex);

        return Math.sqrt(
            rgb.R * rgb.R * 0.241 +
            rgb.G * rgb.G * 0.691 +
            rgb.B * rgb.B * 0.068
        );
    }

    /**
     * Returns true if the given colour is dark.
     * @param {string} hex A hex string of 6 digits. Leading hash symbols are ignored.
     */
    static isDark = (hex) => this.calculateBrightness(hex) < 130;
}