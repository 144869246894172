import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ThemeDataContext } from '../../ContextProviders/theme';
import { ProgramLabelDataContext } from '../../ContextProviders/programLabel';

const useForm = (submitForm, Validate) => {
  const theme = useContext(ThemeDataContext);
  const urlParams = new URLSearchParams(window.location.search);
  const programLabelFromURL = urlParams.get('programLabel');
  const programLabel =
    programLabelFromURL ?? localStorage.getItem('programLabel');

  const { portalInfo } = theme || {};

  const [values, setValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstname: '',
    lastname: '',
    promotionalMailAccepted: false,
    termsAccepted: false,
  });

  const [birthdate, setBirthdate] = useState(false);

  const [errors, setErrors] = useState({});
  const [authErrors, setAuthErrors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseReceived, setResponseReceived] = useState(false);

  const handleChange = (e) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const { name } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setBirthdate(date);
  };

  const enableComponents = () => {
    setIsDisabled(false);
    console.log('enabling components');
  };

  const disableComponents = () => {
    setIsDisabled(true);
    console.log('disabling componenents');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const hasNumbers = /\d/;

  // Check if first name contains numbers
  if (hasNumbers.test(values.firstname)) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      firstname: 'First name cannot contain numbers.'
    }));
  }

  // Check if last name contains numbers
  if (hasNumbers.test(values.lastname)) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      lastname: 'Last name cannot contain numbers.'
    }));
  }

  // If no errors are found, proceed with form submission
  if (!hasNumbers.test(values.firstname) && !hasNumbers.test(values.lastname)) {
    setErrors({});
    setErrors(Validate(values, birthdate)); // Assuming Validate is your validation function
    setIsSubmitting(true);
  }

  
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      disableComponents();
      register();
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [errors, isSubmitting]);

  useEffect(() => {
    if (
      Object.keys(errors).length === 0 &&
      authErrors.length === 0 &&
      isSubmitting &&
      responseReceived === '200'
    ) {
      submitForm();
    }
  }, [errors, authErrors, isSubmitting, responseReceived, submitForm]);

  const register = () => {
    const bday = new Date(birthdate);
    
    // Set the time to 00:00:00
    bday.setHours(0, 0, 0, 0);
  
    // Assuming the local time is at UTC+0, you can adjust the date to match the local time zone
    // by subtracting the time zone offset
    const timeZoneOffsetMinutes = bday.getTimezoneOffset();
    bday.setMinutes(bday.getMinutes() - timeZoneOffsetMinutes);
  
    axios({
      method: 'post',
      baseURL: '/',
      url: 'api/account/register',
      data: {
        programLabel,
        Email: values.email,
        Password: values.password,
        ConfirmPassword: values.confirmPassword,
        Firstname: values.firstname.toUpperCase(),
        Lastname: values.lastname.toUpperCase(),
        Birthday:bday.toISOString(),
        PromotionalMailAccepted: values.promotionalMailAccepted,
        TermsAccepted: values.termsAccepted,
        Template: 1,
        Customization: {
          logoUrl: `https://verity.blob.core.windows.net/portal/${programLabel}/logo.svg`,
          globalColor: portalInfo?.globalColor,
          backgroundColor: portalInfo?.globalBackgroundColor,
          buttonColor: portalInfo?.buttonColor,
          buttonBackgroundColor: portalInfo?.buttonBackgroundColor,
        },
        Newsletter: true,
      },
    })
      .then((resp) => {
        console.log(resp.data, 'response');
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.data.errorMessage);
        }
        setResponseReceived('200');
      })
      .catch((error) => {
        console.log(error, 'error');
        setAuthErrors(error.toString());
        setValues({
          ...values,
          email: '',
          password: '',
          confirmPassword: '',
        });
        setIsSubmitting(false);
        enableComponents();
      });
    setTimeout(() => setAuthErrors([]), 10000);
    console.log(authErrors);
  };

  return {
    handleChange,
    handleDateChange,
    handleSubmit,
    isSubmitting,
    setIsSubmitting,
    values,
    errors,
    setErrors,
    authErrors,
    setAuthErrors,
    isDisabled,
    birthdate,
  };
};

export default useForm;
