import React, { useEffect, useState, useContext } from 'react';
import '../Components/Activity/ActivityAtlantica.css';
import Header from '../Components/Header';
import Listings from '../Components/Activity/Listings';
import Navigate from '../Components/Activity/Navigate';
import axios from 'axios';
import { ThemeDataContext } from '../ContextProviders/theme';
import { useSelector } from 'react-redux';

export default function Activities({ toggle }) {
  const [showEvents, setShowEvents] = useState(true);
  const [showRevenue, setShowRevenue] = useState(false);
  const theme = useContext(ThemeDataContext);
  // const { programLabel } = theme || {};

  const programLabel = localStorage.getItem('programLabel');

  const [listings, setListings] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listingsPerPage] = useState(5);

  useEffect(() => getActivityListings(), []);

  const getActivityListings = () => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/event/getActivity',
      params: {
        acid: sessionStorage.getItem('acid'),
        programLabel: localStorage.getItem('programLabel'),
      },
    })
      .then((resp) => {
        console.log(resp, 'activity');
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.ErrorMessage);
        }
        const { data } = resp;
        console.log(data);
        const { transactions } = data;
        setListings(transactions);
        console.log(listings);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = listings.slice(
    indexOfFirstListing,
    indexOfLastListing
  );
  const totalListings = listings.length;

  const paginate = (pageNumber) => setcurrentPage(pageNumber);
  const next = () => {
    if (currentPage !== Math.ceil(totalListings / listingsPerPage)) {
      setcurrentPage(currentPage + 1);
    }
    return;
  };
  const previous = () => {
    if (currentPage > 1) {
      setcurrentPage(currentPage - 1);
    }
    return;
  };

  const getShowRooms = () => {
    if (
      programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ||
      'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA'
    ) {
      return false;
    }
    return true;
  };

  const isSidebarVisible = useSelector((state) => state.user.isSidebarVisible);

  if (listings.length === 0)
    return (
      <>
        <Header />
        <div
          style={{ visibility: `${isSidebarVisible ? 'hidden' : 'visible'}` }}
          className='Activity-heading'>
          <h1>Activity</h1>
          <hr className='Activity-line'></hr>
          <span id='Activity-none'>You have no activity!</span>
        </div>
      </>
    );

  return (
    <>
      <Header />
      <div style={{ visibility: `${isSidebarVisible ? 'hidden' : 'visible'}` }}>
        <div className='Activity-heading'>
          <h1>Activity</h1>
          <hr className='Activity-line'></hr>
        </div>

        <Listings
          showEvents={showEvents}
          showRevenue={showRevenue}
          currentListings={currentListings}
          showRooms={getShowRooms()}
          showNights={true}
          showPoints={true}
        />

        <Navigate next={next} previous={previous} />
      </div>
    </>
  );
}
