import React from 'react';
import './CheckboxVerityLite.css';

const CheckboxVerityLite = ({
  id,
  name,
  className,
  onChange,
  checked,
  required,
  disabled,
}) => {
  return (
    <input
      type={'checkbox'||'radio'}
      id={id}
      name={name}
      checked={checked}
      onChange={onChange}
      className={className}
      required={required}
      disabled={disabled}
    />
  );
};

export default CheckboxVerityLite;
