import React from 'react';

export default function Calendar({
  size, // or any default size of your choice
  color, // or any color of your choice
  style,
  onClick,
  className,
}) {
  return (
    <svg
 
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.716 2.4262H15.4573V1.66518C15.4736 1.421 15.3882 1.18097 15.2209 1.00205C15.0539 0.823412 14.8199 0.721924 14.5752 0.721924C14.3304 0.721924 14.0968 0.823412 13.9295 1.00205C13.7624 1.18097 13.6767 1.421 13.6933 1.66518V2.4262H7.19141V1.66518C7.208 1.421 7.12228 1.18097 6.95525 1.00205C6.78795 0.823412 6.55427 0.721924 6.30954 0.721924C6.06481 0.721924 5.83086 0.823412 5.66383 1.00205C5.49653 1.18097 5.41108 1.421 5.4274 1.66518V2.4262H2.16874C1.64304 2.4262 1.13863 2.63526 0.766967 3.00692C0.395305 3.37859 0.186523 3.8827 0.186523 4.40841V18.7397C0.186523 19.2654 0.395305 19.7695 0.766967 20.1415C1.13863 20.5131 1.64303 20.7219 2.16874 20.7219H18.7158C19.2415 20.7219 19.746 20.5131 20.1176 20.1415C20.4893 19.7695 20.6981 19.2654 20.6981 18.7397V4.40841C20.6981 3.88272 20.4893 3.37859 20.1176 3.00692C19.746 2.63526 19.2416 2.4262 18.7158 2.4262H18.716ZM2.16886 4.17861H5.42753V4.97142C5.45878 5.43572 5.84426 5.7963 6.30968 5.7963C6.77481 5.7963 7.16061 5.43569 7.19154 4.97142V4.17861H13.6934V4.97142C13.6768 5.2156 13.7626 5.45563 13.9296 5.63455C14.0969 5.81319 14.3306 5.91468 14.5753 5.91468C14.82 5.91468 15.054 5.81319 15.221 5.63455C15.3883 5.45563 15.4738 5.2156 15.4574 4.97142V4.17861H18.7161C18.8367 4.17861 18.9343 4.27623 18.9343 4.39652V7.17153L1.95039 7.1718V4.4084C1.94707 4.34867 1.96864 4.29005 2.00984 4.24663C2.05133 4.20321 2.10857 4.1786 2.16858 4.1786L2.16886 4.17861ZM18.716 18.958H2.16886C2.0483 18.958 1.95068 18.8604 1.95068 18.7398V8.94778H18.9346V18.7398C18.9346 18.8604 18.837 18.958 18.7164 18.958H18.716Z'
        fill='#14D5FF'
      />
      <path
        d='M10.9937 11.3461H9.8915C9.64732 11.3298 9.40729 11.4155 9.22864 11.5825C9.04973 11.7498 8.94824 11.9835 8.94824 12.2282C8.94824 12.473 9.04973 12.7069 9.22864 12.8739C9.40728 13.0412 9.64732 13.1267 9.8915 13.1104H10.9937C11.2379 13.1267 11.478 13.0412 11.6566 12.8739C11.8355 12.7069 11.937 12.473 11.937 12.2282C11.937 11.9835 11.8355 11.7498 11.6566 11.5825C11.478 11.4155 11.2379 11.3298 10.9937 11.3461Z'
        fill='#14D5FF'
      />
      <path
        d='M15.7945 11.3461H14.6923C14.4481 11.3298 14.2081 11.4155 14.0294 11.5825C13.8505 11.7498 13.749 11.9835 13.749 12.2282C13.749 12.473 13.8505 12.7069 14.0294 12.8739C14.2081 13.0412 14.4481 13.1267 14.6923 13.1104H15.7945C16.0387 13.1267 16.2787 13.0412 16.4574 12.8739C16.6363 12.7069 16.7375 12.473 16.7375 12.2282C16.7375 11.9835 16.6363 11.7498 16.4574 11.5825C16.2787 11.4155 16.0387 11.3298 15.7945 11.3461Z'
        fill='#14D5FF'
      />
      <path
        d='M6.19269 11.3461H5.09044C4.84626 11.3298 4.60623 11.4155 4.42759 11.5825C4.24867 11.7498 4.14746 11.9835 4.14746 12.2282C4.14746 12.473 4.24867 12.7069 4.42759 12.8739C4.60623 13.0412 4.84626 13.1267 5.09044 13.1104H6.19269C6.43686 13.1267 6.6769 13.0412 6.85554 12.8739C7.03446 12.7069 7.13594 12.473 7.13594 12.2282C7.13594 11.9835 7.03446 11.7498 6.85554 11.5825C6.6769 11.4155 6.43686 11.3298 6.19269 11.3461Z'
        fill='#14D5FF'
      />
      <path
        d='M10.9937 14.6719H9.8915C9.64732 14.6556 9.40729 14.7413 9.22864 14.9083C9.04973 15.0756 8.94824 15.3093 8.94824 15.554C8.94824 15.7988 9.04973 16.0327 9.22864 16.1997C9.40728 16.367 9.64732 16.4525 9.8915 16.4362H10.9937C11.2379 16.4525 11.478 16.367 11.6566 16.1997C11.8355 16.0327 11.937 15.7988 11.937 15.554C11.937 15.3093 11.8355 15.0756 11.6566 14.9083C11.478 14.7413 11.2379 14.6556 10.9937 14.6719Z'
        fill='#14D5FF'
      />
      <path
        d='M15.7945 14.6719H14.6923C14.4481 14.6556 14.2081 14.7413 14.0294 14.9083C13.8505 15.0756 13.749 15.3093 13.749 15.554C13.749 15.7988 13.8505 16.0327 14.0294 16.1997C14.2081 16.367 14.4481 16.4525 14.6923 16.4362H15.7945C16.0387 16.4525 16.2787 16.367 16.4574 16.1997C16.6363 16.0327 16.7375 15.7988 16.7375 15.554C16.7375 15.3093 16.6363 15.0756 16.4574 14.9083C16.2787 14.7413 16.0387 14.6556 15.7945 14.6719Z'
        fill='#14D5FF'
      />
      <path
        d='M6.19269 14.6719H5.09044C4.84626 14.6556 4.60623 14.7413 4.42759 14.9083C4.24867 15.0756 4.14746 15.3093 4.14746 15.554C4.14746 15.7988 4.24867 16.0327 4.42759 16.1997C4.60623 16.367 4.84626 16.4525 5.09044 16.4362H6.19269C6.43686 16.4525 6.6769 16.367 6.85554 16.1997C7.03446 16.0327 7.13594 15.7988 7.13594 15.554C7.13594 15.3093 7.03446 15.0756 6.85554 14.9083C6.6769 14.7413 6.43686 14.6556 6.19269 14.6719Z'
        fill='#14D5FF'
      />
    </svg>
  );
}
