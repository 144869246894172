import React, { createContext, useState, useEffect } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import ClientSwitch from '../Container/clientSwitch';

export const ProgramLabelDataContext = createContext();

const ProgramLabelContext = (props) => {
  const [programLabel, setProgramLabel] = useState('');

  useEffect(() => {
    if (localStorage['programLabel']) return;
    const client = ClientSwitch.getProgramLabel();

    setProgramLabel(client);

    localStorage.setItem('programLabel', client);
    // setProgramLabel(localStorage.getItem('programLabel'));
    // console.log(window.location.pathname, 'pathname');
    // localStorage.removeItem('programlLabel');
  }, [ClientSwitch]);

  return (
    <ProgramLabelDataContext.Provider value={programLabel}>
      {props.children}
    </ProgramLabelDataContext.Provider>
  );
};
export default ProgramLabelContext;
