import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ThemeDataContext } from '../../../ContextProviders/theme';
import { ProgramLabelDataContext } from '../../../ContextProviders/programLabel';

const useForm = (Validate) => {
  const theme = useContext(ThemeDataContext);
  const urlParams = new URLSearchParams(window.location.search);
  const programLabelFromURL = urlParams.get('programLabel');
  const programLabel =
    programLabelFromURL ?? localStorage.getItem('programLabel');

  const { portalInfo } = theme || {};

  const [values, setValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstname: '',
    lastname: '',
    promotionalMailAccepted: false,
    termsAccepted: false,
  });

  // const [birthdate, setBirthdate] = useState(false);

  const [errors, setErrors] = useState({});
  const [authErrors, setAuthErrors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseReceived, setResponseReceived] = useState('');

  const handleChange = (e) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const { name } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  // const handleDateChange = (date) => {
  //   setBirthdate(date);
  // };

  const enableComponents = () => {
    setIsDisabled(false);
    console.log('components enabled');
  };

  const disableComponents = () => {
    setIsDisabled(true);
    console.log('components disabled');
    // setTimeout(() => enableComponents(), 2000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    setErrors(Validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      disableComponents();
      register();
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [errors, isSubmitting]);

  const register = () => {
    axios({
      method: 'post',
      baseURL: '/',
      url: 'api/account/register',
      data: {
        programLabel,
        Email: values.email,
        Password: values.password,
        ConfirmPassword: values.confirmPassword,
        Firstname: values.firstname?.toUpperCase(),
        Lastname: values.lastname?.toUpperCase(),
        Birthday: null,
        PromotionalMailAccepted: values.promotionalMailAccepted,
        TermsAccepted: values.termsAccepted,
        Template: 1,
        Customization: {
          logoUrl: `https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg`,
          globalColor: portalInfo?.globalColor,
          backgroundColor: portalInfo?.globalBackgroundColor,
          buttonColor: portalInfo?.buttonColor,
          buttonBackgroundColor: portalInfo?.buttonBackgroundColor,
        },
        Newsletter: true,
      },
    })
      .then((resp) => {
        console.log(resp.data, 'response');
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.data.errorMessage);
        }
        setResponseReceived('200');
      })
      .catch((error) => {
        console.log(error, 'error');
        setAuthErrors(error.toString());
        setValues({
          ...values,
          email: '',
          password: '',
          confirmPassword: '',
          firstname: '',
          lastname: '',
          promotionalMailAccepted: false,
          termsAccepted: false,
        });
        setIsSubmitting(false);
        enableComponents();
      });
    setTimeout(() => setAuthErrors([]), 10000);
    console.log(authErrors);
  };

  return {
    handleChange,
    // handleDateChange,
    handleSubmit,
    isSubmitting,
    setIsSubmitting,
    values,
    errors,
    setErrors,
    authErrors,
    setAuthErrors,
    isDisabled,
    responseReceived,
    // birthdate,
    enableComponents,
  };
};

export default useForm;
