import React, { useEffect, useState, useContext, useRef } from 'react';
import VerityLogo from '../../Assets/VerityLite/VerityGuestLogo_white.png';
// import VerityLogo from '../../Icons/VerityLogo';
import CheckBoxVerityLite from '../Components/Checkbox/CheckboxVerityLite';
import Vector from '../../Icons/VectorLite';
import { NavLink, useNavigate } from 'react-router-dom';
import { RiEyeLine } from 'react-icons/ri';
import DontShow from '../../Icons/DontShow';
import Validate from '../Components/SignInForm/Validate';
import useLogin from '../Components/SignInForm/useLogin';
import toast, { Toaster, useToaster } from 'react-hot-toast';
import { useMediaQuery } from 'react-responsive';
import { ThemeDataContext } from '../../ContextProviders/theme';
import Exclamation from '../../Icons/Exclamation';
import Danger from '../../Icons/Danger';
import Arrow from '../../Icons/Arrow';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

const SignIn = () => {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const buttonRef = useRef();

  const Lg = useMediaQuery({
    query: '(max-width: 1200px) ',
  });

  const iphone5SE = useMediaQuery({
    query: '(max-height: 568px) ',
  });

  const iphonePlus = useMediaQuery({
    query: '(min-height: 700px)',
  });

  const navigate = useNavigate();
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    authErrors,
    setAuthErrors,
  } = useLogin(Validate);

  const [loadingScreen, setLoadingScreen] = useState(true);
  const [login, setLogin] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleClickLogin = () => {
    setLoadingScreen(!loadingScreen);
    setLogin(true);
    setCreateAccount(false);
  };

  const handleClickCreateAccount = () => {
    navigate('/signUp');
  };

  const handleClickArrow = () => {
    setLoadingScreen(!loadingScreen);
    setLogin(false);
    setCreateAccount(false);
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    if (!rememberMe) {
      setPasswordShown(!passwordShown);
    }
    return;
  };

  const urlParams = new URLSearchParams(window.location.search);
  const programLabelFromURL = urlParams.get('programLabel');
  const programLabel =
    programLabelFromURL ?? localStorage.getItem('programLabel');

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};
  const { globalBackgroundColor } = portalInfo || {};
  const { globalColor } = portalInfo || {};

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };
  const linkStyle = {
    color: `${buttonBackgroundColor}`,
  };

  console.log(portalInfo);

  useEffect(() => {
    if (!localStorage['programLabel']) {
      localStorage.setItem('programLabel', programLabel);
    }
  }, [programLabel]);

  useEffect(() => {
    if (
      localStorage.getItem('programLabel') !== programLabelFromURL &&
      programLabelFromURL
    ) {
      localStorage.setItem('programLabel', programLabelFromURL);
    }
  }, [programLabelFromURL]);

  const { toasts } = useToaster();

  const TOAST_LIMIT = 1;

  const handleDismiss = () => {
    toasts.forEach((t) => toast.dismiss(t.id));
  };

  useEffect(() => {
    toast.remove();
  }, []);

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  const notifyError = () =>
    toast(
      <div
        style={{
          display: 'flex',
          width: '100%',
          borderRadius: '7px',
          fontFamily: 'Lato',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
          background: 'transparent',
          color: `black`,
        }}>
        <div
          style={{
            display: 'flex',
            order: '1',
            alignItems: 'center',
            width: '20%',
            transform: 'translateX(-10%)',
          }}>
          <Exclamation
            width='50'
            height='50'
            viewBox='-4 1 25 15'
            color='red'
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            order: '2',
            width: '80%',
            transform: 'translateX(0%)',
          }}>
          {authErrors}
        </div>
      </div>,
      {
        style: {
          borderLeft: '6.5px solid red',
          maxWidth: '350px',
        },
        duration: 30000,
        position: 'top-center',
      }
    );

  useEffect(() => {
    if (authErrors.length != 0) {
      notifyError();
      setAuthErrors([]);
      buttonRef.current.disabled = true;
      setTimeout(() => (buttonRef.current.disabled = false), 3000);
    }
  }, [authErrors, notifyError, buttonRef]);

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('email') && localStorage.getItem('password')) {
      setRememberMe(true);
    }
  }, [localStorage]);

  if (Lg && loadingScreen) {
    return (
      <div className='h-screen portrait:overflow-hidden landscape:overflow-scroll w-screen'>
        <div
          style={{
            backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
          }}
          className='h-[50vh] w-screen bg-no-repeat bg-center landscape:bg-[length:150px_150px] bg-[length:290px_290px]'></div>
        <div className=' bg-skin-primary flex flex-col justify-center items-center h-[50vh] landscape:h-auto w-screen landscape:pt-8'>
          <h2 className='text-skin-a11y pw-full h-50px text-center font-roboto font-normal  text-[24px] md:text-[40px] leading-{50} tracking-wide my-0 drop-shadow-3xl mt-10'>
            Welcome to our
          </h2>
          <h2 className=' text-skin-secondary w-full h-50px text-center font-roboto font-medium text-[24px] md:text-[40px] leading-{50} tracking-wide my-0 drop-shadow-3xl'>
            Loyalty Club
          </h2>
          <br />
          <div className='flex flex-col w-full items-center mt-8'>
            <button
              onClick={handleClickLogin}
              className='text-skin-primary bg-skin-secondary block w-[275px] md:w-[330px] h-[39px] md:h-[43px] hover:-translate-y-[2px] transition duration-300 text-roboto text-center text-base md:text-xl font-bold  leading-11  rounded-5 py-1'>
              Login
            </button>
            <button
              onClick={handleClickCreateAccount}
              className='block w-[275px] text-skin-a11y md:w-[330px] h-[39px] md:h-[43px] border hover:-translate-y-[2px] transition duration-300 text-roboto text-center text-base md:text-xl font-bold leading-11 bg-transparent rounded-5 mt-4 py-1'>
              Create Account
            </button>
          </div>
          <br />
          <br />
        </div>
      </div>
    );
  } else if (Lg && login) {
    return (
      <div className='bg-skin-primary flex flex-col flex-grow justify-between items-center h-screen portrait:overflow-hidden landscape:overflow-scroll w-screen'>
        <div onClick={handleDismiss}>
          <Toaster
            position='top-center'
            containerClassName='SignInToaster ez '
            containerStyle={{
              top: 10,
              left: 10,
              bottom: 10,
              right: 10,
            }}
          />
        </div>
        <div onClick={handleClickArrow}>
          <Arrow
            className={`absolute top-6 left-5`}
            width='25'
            height='24'
            color='#FFF'
          />
        </div>
        <div className='block w-[275px] md:w-[310px] h-20 mx-auto'>
          <p
            className={`text-skin-a11y w-full h-5 font-medium text-lg ${
              iphonePlus ? 'mt-24' : 'mt-16'
            } sm:mt-16 md:text-xl leading-[21px] font-roboto `}>
            Login
          </p>
        </div>
        <form
          className='text-skin-a11y block w-[275px] landscape:mt-[10%] md:w-[310px] mt-[15%] md:mt-[15%] h-auto mx-auto font-roboto '
          onSubmit={handleSubmit}>
          <div className='block w-full h-24 sm:py-2 sm:mt-6'>
            <label
              className='block font-roboto font-normal text-base leading-[18.75px] indent-[0.5rem]'
              htmlFor='vlite-email'>
              Username
            </label>
            <input
              disabled={rememberMe}
              id='vlite-email'
              name='email'
              className={`text-skin-a11y block w-full h-[41px] bg-transparent rounded-[5px] indent-[0.5rem] border-solid border-[1px] ${
                errors.password ? 'border-[#D80027]' : 'border-skin-secondary'
              } font-light text-base leading-[19px] mt-[0.7rem] placeholder-skin-a11y placeholder-opacity-50`}
              type='email'
              spellCheck='false'
              placeholder='Enter your username'
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && (
              <Danger
                color='#D80027'
                width='25'
                height='24'
                className={`translate-x-[980%] md:translate-x-[1090%] translate-y-[-145%]`}
              />
            )}
            {errors.email && (
              <p className='font-roboto text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                {errors.email}
              </p>
            )}
          </div>
          <br />
          <div className='block w-full h-24 relative'>
            <label
              htmlFor='vlite-password'
              className='block font-normal text-base leading-[19px] indent-[0.5rem]'>
              Password
            </label>
            <input
              disabled={rememberMe}
              id='vlite-password'
              name='password'
              className={`block w-full h-[41px] bg-transparent rounded-[5px] indent-[0.5rem] border-solid  ${
                errors.password ? 'border-[#D80027]' : ' border-skin-secondary'
              } border-[1px] text-skin-a11y font-light text-base leading-[19px]  placeholder-skin-a11y placeholder-opacity-50 mt-[0.7rem]`}
              type={passwordShown ? 'text' : 'password'}
              placeholder='Enter your password'
              value={values.password}
              onChange={handleChange}
              autoComplete='true'
            />
            {passwordShown && !errors.password && (
              <DontShow
                color={buttonBackgroundColor}
                className={`absolute right-3 top-10`}
                onClick={togglePassword}
              />
            )}
            {!passwordShown && !errors.password && (
              <RiEyeLine
                fill={buttonBackgroundColor}
                size='21'
                className={` absolute right-3 top-10`}
                onClick={togglePassword}
              />
            )}
            {errors.password && (
              <Danger
                color='#D80027'
                width='25'
                height='24'
                className={`translate-x-[980%] md:translate-x-[1090%] translate-y-[-145%]`}
              />
            )}
            {errors.password && (
              <p className='text-skin-a11y  font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                {errors.password}
              </p>
            )}
          </div>
          <br />
          <div
            className={`flex my-[0.5rem] ${
              iphone5SE ? 'my-0' : 'my-[0.5rem]'
            }`}>
            <label
              htmlFor='vlite-rememberMe'
              className='text-skin-secondary text-[1rem] font-normal leading-[19px] order-2  ml-2'>
              Remember me
            </label>
            <CheckBoxVerityLite
              id='vlite-rememberMe'
              name='rememberMe'
              checked={values.rememberMe}
              className='checkboxVerityLite order-1 mt-0.5'
              required={false}
              disabled={false}
              onChange={handleChange}
            />
          </div>
          <div
            className={`flex w-full justify-center ${
              iphone5SE ? 'mt-0' : 'mt-10'
            } h-12 sm:mt-10 md:mt-10`}>
            <button
              disabled={isDisabled}
              ref={buttonRef}
              className='w-[275px] md:w-[330px] hover:-translate-y-[2px] transition duration-300 text-roboto text-center text-base md:text-xl font-bold text-skin-primary leading-11 bg-turquoise rounded-5 py-1'
              type='submit'>
              Login
            </button>
          </div>
          <div
            className={`flex w-full justify-center h-auto mt-3 ${
              iphone5SE && 'mt-[2px]'
            }sm:mt-5 md:mt-6`}>
            <NavLink
              to='/signIn/forgotpassword'
              className='font-roboto font-normal no-underline text-base md:text-lg text-skin-a11y  leading-[21px]'>
              Forgot password
              {/* <img src={Vector} width='20.06' height='10.52' alt='vector' /> */}
              <Vector
                className={`text-skin-a11y`}
                style={{
                  marginLeft: '0.25rem',
                  color: `var(--color-a11y-lite-hex)`,
                }}
                // color={globalColor}
              />
            </NavLink>
          </div>
        </form>
        <br />

        <div
          className={`landscape:relative ${
            isKeyboardOpen ? '' : ''
          } w-full h-auto`}>
          <p className='text-skin-a11y  font-roboto font-normal leading-[14px] md:leading-[21px] text-xs md:text-lg w-full text-center translate-y-[1.8rem] md:translate-y-4'>
            Powered by
          </p>
          <div
            style={{
              backgroundImage: `url(${VerityLogo})`,
            }}
            className='w-full h-20 bg-[length:60px_33px] md:bg-auto bg-no-repeat bg-center mx-auto z-10'></div>
        </div>
      </div>
    );
  }
  return (
    <div className='flex h-auto min-h-screen overflow-scroll w-screen vlite'>
      <div
        style={{
          backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
        }}
        className='order-1 h-auto w-screen bg-no-repeat bg-center bg-[length:290px_290px]'></div>
      <div className={`order-2 h-auto w-screen pt-[8.5rem] bg-skin-primary`}>
        <h2
          className={`w-full text-skin-a11y h-50px text-center font-roboto font-normal text-[40px] leading-{50} tracking-wide my-0 drop-shadow-3xl text-skin-a11y`}>
          Welcome to our
        </h2>
        <h2
          className={`w-full h-50px text-center text-skin-secondary font-roboto font-medium text-[40px] leading-{50} tracking-wide my-0 drop-shadow-3xl`}>
          Loyalty Club
        </h2>
        <br />
        <br />
        <br />
        <div onClick={handleDismiss}>
          <Toaster
            position='top-center'
            containerClassName='SignInToaster ez' 
            containerStyle={{
              top: 10,
              left: 10,
              bottom: 10,
              right: 10,
            }}
          />
        </div>
        <form
          className='block text-skin-a11y w-[310px] h-[435px] my-0 mx-auto font-roboto'
          onSubmit={handleSubmit}>
          <div className='block w-full h-24 py-2'>
            <label
              className='block font-normal text-base leading-[19px] indent-[0.5rem]'
              htmlFor='vlite-email'>
              Username
            </label>
            <input
              disabled={false}
              id='vlite-email'
              name='email'
              className={`block w-full h-[41px] bg-transparent rounded-[5px] indent-[0.5rem] border-solid text-skin-a11y  ${
                errors.email ? 'border-[#D80027]' : 'border-skin-secondary'
              } border-[1px]  font-light text-base leading-[19px] mt-[0.7rem] placeholder-opacity-50 placeholder-skin-a11y`}
              type='email'
              spellCheck='false'
              placeholder='Enter your username'
              value={values.email}
              onChange={handleChange}
              autoComplete='true'
            />
            {errors.email && (
              <Danger
                color='#D80027'
                width='25'
                height='24'
                style={{ transform: 'translate(1090%, -145%)' }}
              />
            )}
            {errors.email && (
              <p className='font-roboto text-skin-a11y  font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                {errors.email}
              </p>
            )}
          </div>
          <br />
          <div className='block w-full h-24'>
            <label
              htmlFor='vlite-password'
              className='block font-normal text-base leading-[19px] indent-[0.5rem]'>
              Password
            </label>
            <input
              disabled={false}
              id='vlite-password'
              name='password'
              s
              className={`block w-full h-[41px] bg-transparent rounded-[5px] indent-[0.5rem] border-solid  ${
                errors.password ? 'border-[#D80027]' : 'border-skin-secondary'
              } border-[1px] text-skin-a11y font-light text-base leading-[19px] placeholder-skin-a11y placeholder-opacity-50 mt-[0.7rem]`}
              type={passwordShown ? 'text' : 'password'}
              placeholder='Enter your password'
              value={values.password}
              onChange={handleChange}
              autoComplete='true'
            />
            {passwordShown && !errors.password && (
              <DontShow
                color={buttonBackgroundColor}
                style={{
                  transform: 'translate(1310%, -157%)',
                }}
                onClick={togglePassword}
              />
            )}
            {!passwordShown && !errors.password && (
              <RiEyeLine
                fill={buttonBackgroundColor}
                size='21'
                style={{
                  position: 'absolute',
                  // color: '#14D5FF',
                  transform: 'translate(1310%, -145%)',
                }}
                onClick={togglePassword}
              />
            )}
            {errors.password && (
              <Danger
                color='#D80027'
                width='25'
                height='24'
                style={{ transform: 'translate(1090%, -145%)' }}
              />
            )}
            {errors.password && (
              <p className='text-skin-a11y  font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                {errors.password}
              </p>
            )}
          </div>
          <br />
          <div className='flex my-[0.5rem]'>
            <label
              // style={{ color: buttonBackgroundColor }}
              htmlFor='vlite-rememberMe'
              className=' text-skin-secondary text-[1rem] font-normal leading-[19px] order-2  ml-2'>
              Remember me
            </label>
            <CheckBoxVerityLite
              id='vlite-rememberMe'
              name='rememberMe'
              checked={values.rememberMe}
              className='checkboxVerityLite order-1 mt-0.5'
              required={false}
              disabled={false}
              onChange={handleChange}
            />
          </div>
          <div className='flex w-full justify-center h-12 mt-4 mb-3'>
            <button
              // style={{ color: buttonColor, background: buttonBackgroundColor }}
              disabled={isDisabled}
              ref={buttonRef}
              className='text-skin-primary bg-skin-secondary w-[330px] hover:-translate-y-[2px] transition duration-300 text-roboto text-center text-xl font-bold leading-11 rounded-5 py-1'
              type='submit'>
              Login
            </button>
          </div>

          <div className='flex w-full justify-center '>
            <NavLink
              to='/signIn/forgotpassword'
              className='font-roboto text-skin-a11y font-normal no-underline text-lg leading-[21px]'>
              Forgot password
             
            </NavLink>
          </div>
        </form>
        <div className='flex w-full h-12 justify-center -translate-y-4'>
          <p
            // style={{ color: globalColor }}
            className='text-skin-a11y font-roboto font-bold text-lg  leading-[21px]' style={{marginTop:"30px"}}>
            Not a member yet? {''}
            <NavLink
              // style={{ color: buttonBackgroundColor }}
              className='text-skin-secondary font-roboto font-bold text-lg leading-[21px] no-underline'
              to='/signUp'>
              Register here
            </NavLink>
          </p>
        </div>
        <br />
        <div className='w-full h-[150px] mt-2'>
          <p
            // style={{ color: globalColor }}
            className=' text-skin-a11y font-roboto font-normal leading-[21px] text-lg w-full text-center'>
            Powered by
          </p>
          <div
            style={{
              backgroundImage: `url(${VerityLogo})`,
            }}
            className='w-full h-28 bg-auto bg-no-repeat bg-center mx-auto z-10 -translate-y-[2.1rem]'>
            {/* <VerityLogo color={globalColor} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
