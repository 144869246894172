import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import configureStore from './Redux/Store/configureStore';
import ThemeContext from './ContextProviders/theme';
import 'include-media/dist/_include-media.scss';
// import '@coreui/coreui/dist/css/coreui.min.css';
// import 'bootstrap/dist/css/bootstrap.css';
import './Globals/AtlanticaGlobalStyle.css';
import ProgramLabelContext from './ContextProviders/programLabel';
import InfoContext from './ContextProviders/info';
import './EasyApp/translations/i18n';
import { BrowserRouter } from 'react-router-dom';
import LoadApps from './Hooks/LoadApps';
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = configureStore();

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ProgramLabelContext>
        <ThemeContext>
          <InfoContext>
            <BrowserRouter>
              <LoadApps />
            </BrowserRouter>
          </InfoContext>
        </ThemeContext>
      </ProgramLabelContext>
    </Provider>
  // </React.StrictMode>
);
