import React, { useCallback, useRef, useEffect } from 'react';

// import Draggable from 'react-draggable';
import LargeX from '../../../Icons/LargeX';

function Modal({ setTrigger, trigger, children, reset }) {
  // const nodeRef = useRef(null);
  const modalRef = useRef(null);

  // const closeButtonRef = useRef(null);

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      reset();
      setTrigger(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && trigger) {
        reset();
        setTrigger(false);
      }
    },
    [setTrigger, trigger]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  // useEffect(() => {
  //   function handler(event) {
  //     if (closeButtonRef.current?.contains(event.target)) {
  //       reset();
  //       setTrigger(false);
  //     }
  //   }
  //   window.addEventListener('click', handler);
  //   return () => window.removeEventListener('click', handler);
  // }, []);

  return (
    <div>
      <div
        ref={modalRef}
        className='flex justify-center items-center z-[998] fixed top-0 left-0 w-full h-full bg-[#404040B2] landscape:overflow-y-scroll'
        onClick={closeModal}>
        {/* <Draggable nodeRef={nodeRef}> */}
        <div
          // ref={nodeRef}
          className='relative h-[634.24px] w-[570.68px] max-w-[570.68px]  bg-white z-[998] translateY-[5%] rounded-[20px] border-[2px] border-solid border-skin-secondary box-border
          shadow-[0px_4px_20px_rgba(0,0,0,0.4)]'>
          <div
            // ref={closeButtonRef}
            onClick={() => {
              reset();
              setTrigger(false);
            }}
            className='z-50 flex justify-center items-center absolute w-[30px] h-[30px] top-[4%] right-[4%] text-skin-primary hover:text-skin-a11yrounded-[40px]'>
            {/* <span onClick={() => closeModal}> */}
            <LargeX
              // onClick={() => {
              //   reset();
              //   setTrigger(false);
              // }}
              color='#909090'
              width='33'
              height='33'
            />
            {/* </span> */}
          </div>
          {children}
        </div>
        {/* </Draggable> */}
      </div>
    </div>
  );
}

export default Modal;
