import { combineReducers } from 'redux';

import userReducer from './user/user.reducer';
import easyReducer from './easy/easy.reducer';
import vliteReducer from './vlite/vlite.reducer';

const initialState = {
  checkInInfo: null,
  // Other reducer states...
};

const checkInReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, checkInInfo: action.checkInInfo };
    // Other cases...
    default:
      return state;
  }
};

const rootReducer = () =>
  combineReducers({
    user: userReducer,
    easy: easyReducer,
    vlite: vliteReducer,
    checkIn:checkInReducer
  });

export default rootReducer;
