import React from 'react';

export default function LargeX({
  size, // or any default size of your choice
  color, // or any color of your choice
  style,
  width,
  height,
  onClick,
  className,
}) {
  return (
    <svg
      className={className}
      onClick={onClick}
      style={style}
      width={width}
      height={height}
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.46387 31.6371L16.4638 16.5945L1.46387 1.552'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.4639 1.55191L16.464 16.5944L31.4639 31.637'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
