import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import logout from '../../../Assets/Images/logout.svg';
import { useNavigate } from 'react-router-dom';
import Redeem from '../Redeem/Redeem';
import BookNow from '../BookNow/BookNow';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import CookieService from '../../../API/Services/CookieService';
import actions from '../../../Redux/reducers/user/user.actions';
import { ThemeDataContext } from '../../../ContextProviders/theme';
import './TopBarVerity2.css';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import * as _ from 'lodash';
import Language from '../../../Icons/Language';

export function TopBar() {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [buttonPopup2, setButtonPopup2] = useState(false);
  const [buttonPopup3, setButtonPopup3] = useState(false);
  const [buttonPopup4, setButtonPopup4] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [hotels, setHotels] = useState([]);

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { locations } = portalInfo || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};
  const { termsFileUri } = portalInfo || {};

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const programLabel = localStorage.getItem('programLabel');

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const getHotels = async () => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/init/getLoyaltyInfo',
      params: {
        programLabel: localStorage['programLabel'],
      },
    })
      .then((resp) => {
        setHotels(resp.data.locations);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getHotels();
  }, []);

  const hotel = _.map(hotels, 'description');

  const newArray = hotel.map((h, i) => ({ value: h, label: h }));

  const FreeNightsStyle = {
    color: 'black',
    backgroundColor: 'white',
    border: `2px solid ${buttonBackgroundColor}`,
  };

  const HotelServicesStyle = {
    color: 'black',
    backgroundColor: 'white',
    border: `2px solid ${buttonBackgroundColor}`,
  };

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      '&:hover': { borderColor: '#535353' },
      borderBottom: '2px solid #535353',
      boxShadow: 'none',
    }),
  };

  const Hotel = (props) => (
    <Select
      {...props}
      components={{
        IndicatorSeparator: () => null,
      }}
      menuPlacement='auto'
      placeholder='Select Hotel...'
      styles={style}
      options={newArray}
      onChange={props.onChange}
      onBlur={props.onBlur}
      selected={props.value}
    />
  );

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(actions.userLogout());
    // localStorage.clear();
    // sessionStorage.clear();
    let options = { path: '/' };
    CookieService.remove('acidToken', options);
    navigate(`/signIn?programLabel=${programLabel}`);
  };

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const acidToken = userInfo.acidToken;
  const emailToken = userInfo.eMailToken;

  const redeem = (data) => {
    let hotel = hotels.filter((h) => h.description === data.hotel.value);

    let url = hotel[0].redeemNowUri;

    window.open(
      url +
        '?checkin=' +
        data.arrivalDate +
        '&nights=' +
        data.nights +
        '&bkcode=XLP&acidtkn=' +
        acidToken
    );
  };
  const bookNow = (data) => {
    let hotel = hotels.filter((h) => h.description === data.hotel.value);

    let url = hotel[0].bookNowUri;

    window.open(
      url +
        '?checkin=' +
        data.arrivalDate +
        '&nights=' +
        data.nights +
        '&logintkn=' +
        emailToken
    );
  };
  const onError = (errors, e) => console.log(errors, e, 'error');
  const arrivalDate = watch('arrivalDate');
  function isInTheFuture(date) {
    const today = new Date();

    // 👇️ OPTIONAL!
    // This line sets the time of the current date to the
    // last millisecond, so the comparison returns `true` only if
    // date is at least tomorrow
    today.setHours(23, 59, 59, 998);

    return date > today;
  }

  return (
    <div className='top2-container'>
      <h3 className='top2-heading'>Loyalty Club</h3>
      <ul className='top2-list'>
        <li className='top2-items'>
          <button
            // style={buttonStyle}
            style={{
              background: 'transparent',
              color: '#404040',
            }}
            onClick={() => setButtonPopup(true)}
            className='top2-Redeem'>
            Redeem
          </button>
          <Redeem trigger={buttonPopup} setTrigger={setButtonPopup}>
            <h1>Redeem your points</h1>
            <hr />
            <div className='Redeem-ButtonContainer'>
              <button
                style={FreeNightsStyle}
                onClick={() => {
                  setButtonPopup(false);
                  setButtonPopup2(true);
                }}
                className='Redeem-FreeNights'>
                With Free Nights
              </button>
              <button
                style={HotelServicesStyle}
                onClick={() => {
                  setButtonPopup(false);
                  setButtonPopup3(true);
                }}
                className='Redeem-HotelServices'>
                With Hotel Services
              </button>
            </div>
          </Redeem>
          <Redeem trigger={buttonPopup2} setTrigger={setButtonPopup2}>
            <h1>Redeem your points</h1>
            <hr />
            <div className='Redeem-ButtonContainer'>
              <button
                style={buttonStyle}
                onClick={() => setButtonPopup(true)}
                className='Redeem-FreeNights'>
                With Free Nights
              </button>
              <button
                onClick={() => {
                  setButtonPopup2(false);
                  setButtonPopup3(true);
                }}
                className='Redeem-HotelServices'>
                With Hotel Services
              </button>
            </div>
            <form
              className='Redeem-FreeNights-form'
              onSubmit={handleSubmit(redeem, onError)}>
              <label className='Redeem-label'>Hotel(*):</label>
              <Controller
                {...register('hotel')}
                control={control}
                options={newArray}
                name='hotel'
                rules={{ required: 'Hotel is required' }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Hotel onChange={onChange} onBlur={onBlur} value={value} />
                )}
              />
              {errors.hotel && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0.4rem',
                  }}>
                  {errors?.hotel?.message}
                </p>
              )}

              <div className='Redeem-FreeNights-ArrivalDateWrap'>
                <label className='Redeem-label' htmlFor='Redeem-ArrivalDate'>
                  Arrival Date*:
                </label>
                <br />
                <input
                  {...register('arrivalDate', {
                    required: 'Arrival date is required',
                    valueAsDate: true,
                    validate: {
                      isAFutureDate: () =>
                        isInTheFuture(arrivalDate) ||
                        'Arrival date must be in the future',
                    },
                  })}
                  className='Redeem-input'
                  id='Redeem-ArrivalDate'
                  name='arrivalDate'
                  type='date'
                />
              </div>
              {errors.arrivalDate && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0.4rem',
                  }}>
                  {errors?.arrivalDate?.message}
                </p>
              )}
              <div>
                <div className='Redeem-FreeNights-NightsWrap'>
                  <label className='Redeem-label' htmlFor='Redeem-Nights'>
                    Nights*:
                  </label>
                  <br />
                  <input
                    {...register('nights', {
                      required: 'Nights is required',
                      min: {
                        value: 1,
                        message: 'Value must be greater than or equal to 1',
                      },
                    })}
                    className='Redeem-input'
                    id='Redeem-Nights'
                    name='nights'
                    type='number'
                  />
                </div>
                {errors.nights && (
                  <p role='alert' style={{ color: 'rgb(255, 0, 0)' }}>
                    {errors?.nights?.message}
                  </p>
                )}
              </div>
              <div>
                <button style={buttonStyle} className='Redeem-Search'></button>
              </div>
            </form>
          </Redeem>
          <Redeem trigger={buttonPopup3} setTrigger={setButtonPopup3}>
            <h1>Redeem your points</h1>
            <hr />
            <div className='Redeem-ButtonContainer'>
              <button
                onClick={() => {
                  setButtonPopup3(false);
                  setButtonPopup2(true);
                }}
                className='Redeem-FreeNights'>
                With Free Nights
              </button>
              <button
                style={buttonStyle}
                onClick={() => {
                  setButtonPopup(false);
                  setButtonPopup3(true);
                }}
                className='Redeem-HotelServices'>
                With Hotel Services
              </button>
            </div>
            <div className='Redeem-HotelServices-Info'>
              <p>Redemption of Points</p>
              {programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
              'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B' ? (
                <p>
                  Level 2 & 3 members are entitled to redeem up to 8000 points
                  per stay, towards discounts on their extras during their stay
                  in the hotel.
                </p>
              ) : (
                <p>
                  You may redeem your award points for Hotel Services during
                  your stay in any of our hotels.
                </p>
              )}

              {programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
              'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B' ? (
                <p>
                  Points for hotel services can be only redeemed during the
                  checkout process at reception. {''}
                  <a
                    href={termsFileUri}
                    style={{
                      color: `${buttonBackgroundColor}`,
                      textDecoration: 'underline',
                    }}
                    target='_blank'
                    rel='noreferrer noopener'>
                    Click here
                  </a>
                  , for terms and conditions.
                </p>
              ) : (
                <p>
                  Please visit the Guest Relations to complete and sign a
                  Redemption Voucher Application Form. You will receive your
                  Redemption Voucher within 24hours from your application.
                </p>
              )}
            </div>
          </Redeem>
        </li>
        <li className='top-items'>
          <button
            // style={buttonStyle}
            style={{
              background: 'transparent',
              color: '#404040',
            }}
            onClick={() => setButtonPopup4(true)}
            className='top2-BookNow'>
            Book Now
          </button>
          <BookNow trigger={buttonPopup4} setTrigger={setButtonPopup4}>
            <h1>Book Your Stay</h1>
            <hr />
            <form
              className='BookNow-form'
              onSubmit={handleSubmit(bookNow, onError)}>
              <label className='BookNow-label'>Hotel(*):</label>

              <Controller
                {...register('hotel')}
                control={control}
                options={newArray}
                name='hotel'
                rules={{ required: 'Hotel is required' }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Hotel onChange={onChange} onBlur={onBlur} value={value} />
                )}
              />
              {errors.hotel && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0.4rem',
                  }}>
                  {errors?.hotel?.message}
                </p>
              )}
              <div className='BookNow-ArrivalDateWrap'>
                <label className='BookNow-label' htmlFor='BookNow-ArrivalDate'>
                  Arrival Date*:
                </label>
                <br />
                <input
                  {...register('arrivalDate', {
                    required: 'Arrival date is required',
                  })}
                  className='BookNow-input'
                  id='BookNow-ArrivalDate'
                  name='arrivalDate'
                  type='date'
                />
              </div>
              {errors.arrivalDate && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0.4rem',
                  }}>
                  {errors?.arrivalDate?.message}
                </p>
              )}

              <div>
                <div className='BookNow-NightsWrap'>
                  <label className='BookNow-label' htmlFor='BookNow-Nights'>
                    Nights*:
                  </label>
                  <br />
                  <input
                    {...register('nights', {
                      required: 'Nights is required',
                      min: {
                        value: 1,
                        message: 'Value must be greater than or equal to 1',
                      },
                    })}
                    className='BookNow-input'
                    id='BookNow-Nights'
                    name='nights'
                    type='number'
                  />
                </div>
                {errors.nights && (
                  <p role='alert' style={{ color: 'rgb(255, 0, 0)' }}>
                    {errors?.nights?.message}
                  </p>
                )}
              </div>
              <div>
                <button style={buttonStyle} className='BookNow-Search'>
                  Search
                </button>
              </div>
            </form>
          </BookNow>
        </li>
        <Language width='54' height='54' />
      </ul>
      <div className='top2-logoutWrap'>
        <NavLink
          className='top2-logoutLink'
          to='/'
          onClick={(e) => handleLogout(e)}>
          Logout
          {/* <img src={logout} alt='logout' /> */}
        </NavLink>
      </div>
    </div>
  );
}

export default TopBar;
