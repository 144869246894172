export default {
  //user: {},
  //theme: {
  //  global_Background_Color: "yellow",
  //  globalColor: "black",
  //  button_Background_Color: "red",
  //  button_Color: "#ffffff",
  //},
  isLoggedIn: false,
  programLabel: '',
  isSidebarVisible: false,
  showSidebarToggler: true,
  hasBooking: false,
};
