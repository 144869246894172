import React, { createContext, useState, useEffect } from 'react';
import useFetch from '../Components/Fetch/useFetch';

export const ThemeDataContext = createContext();

const ThemeContext = (props) => {
  const { data: customization } = useFetch('/api/init/getLoyaltyInfo');
  const [theme, setTheme] = useState(customization);

  useEffect(() => {
    if (JSON.stringify(theme) !== JSON.stringify(customization)) {
      setTheme(customization);
    }
  }, [customization, setTheme]);

  console.log('theme: ', theme);

  return (
    <ThemeDataContext.Provider value={theme}>
      {customization && props.children}
    </ThemeDataContext.Provider>
  );
};
export default ThemeContext;
