import React, { useCallback, useRef, useEffect } from 'react';
import { GrClose } from 'react-icons/gr';
// import toast, { Toaster, useToaster, useToasterStore } from 'react-hot-toast';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import '../Logout/Logout.css';
import { useMediaQuery } from 'react-responsive';
import { getPosition } from 'rsuite/esm/DOMHelper';

function LogoutModal({ setTrigger, trigger, children, handleLogout }) {
  const modalRef = useRef();
  // const { toasts, handlers } = useToaster();
  const Tablet = useMediaQuery({
    query: '(min-width: 768px) ',
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      // toasts.forEach((t) => toast.dismiss(t.id));
      setTrigger(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && trigger) setTrigger(false);
      // toasts.forEach((t) => toast.dismiss(t.id));
    },

    [setTrigger, trigger]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  // useEffect(() => {
  //   toasts.length === 0 && setTrigger(false);
  // }, [toasts]);

  useEffect(() => {
    trigger && notify();
  }, [trigger]);

  const handleDismiss = () => {
    setTrigger(false);
    toast.dismiss();
  };

  const notify = () =>
    toast(
      <div style={{ width: '100%' }}>
        <div
          style={{
            fontSize: '1.2rem',
            fontFamily: `'Lato', Arial, sans-serif`,
            userSelect: 'none',
            color: 'var(--color-accent)',
            paddingRight: '0.7rem',
          }}>
          Are you sure you want to <b>logout?</b>
        </div>

        <br />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '0.5rem',
          }}>
          <button className='LogoutButton' onClick={(e) => handleLogout(e)}>
            YES
          </button>
          <button className='LogoutButton' onClick={() => handleDismiss()}>
            NO
          </button>
        </div>
      </div>,
      { containerId: 'logout' },
      {
        // position: toast.POSITION.TOP_CENTER,
        className: 'Logout-Toaster',
      }
    );

  console.log(toast, 'toastify');
  return (
    <div>
      <div ref={modalRef} className='Logout-popup' onClick={closeModal}>
        {/* <DismissableToast /> */}
        {/* <Toaster
          containerStyle={{
            top: 400,
          }}
        /> */}
        {Tablet ? (
          <ToastContainer
            enableMultiContainer
            containerId={'logout'}
            limit={1}
            hideProgressBar={true}
            transition={Zoom}
            closeButton={false}
            className='Login-ToastContainer'
            autoClose={false}
            style={{
              position: 'absolute',
              top: '38%',
              left: '0%',
              width: '367px',
              borderRadius: '0',
              margin: '0 auto',
              textAlign: 'center',
            }}
          />
        ) : (
          <ToastContainer
            enableMultiContainer
            containerId={'logout'}
            limit={1}
            hideProgressBar={true}
            transition={Zoom}
            closeButton={false}
            className='Login-ToastContainer'
            autoClose={false}
            style={{
              position: 'absolute',
              top: '35%',
              left: '2%',
              right: '2%',
              width: '96vw',
              maxWidth: '767px',
              borderRadius: '0',
              margin: '0 auto',

              textAlign: 'center',
              // transform: 'translateY(170%)',
            }}
          />
        )}
      </div>
    </div>
  );
}

export default LogoutModal;
