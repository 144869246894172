import React from 'react';

export default function Overview({
  size, // or any default size of your choice
  color, // or any color of your choice
  style,
  onClick,
  className,
}) {
  return (
    <svg
      style={style}
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 25 25`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.358 13.0021C12.288 12.9921 12.198 12.9921 12.118 13.0021C10.358 12.9421 8.95801 11.5021 8.95801 9.73215C8.95801 7.92215 10.418 6.45215 12.238 6.45215C14.048 6.45215 15.518 7.92215 15.518 9.73215C15.508 11.5021 14.118 12.9421 12.358 13.0021Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.978 19.6022C17.198 21.2322 14.838 22.2222 12.238 22.2222C9.63805 22.2222 7.27805 21.2322 5.49805 19.6022C5.59805 18.6622 6.19805 17.7422 7.26805 17.0222C10.008 15.2022 14.488 15.2022 17.208 17.0222C18.278 17.7422 18.878 18.6622 18.978 19.6022Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.2383 22.2222C17.7611 22.2222 22.2383 17.745 22.2383 12.2222C22.2383 6.69932 17.7611 2.22217 12.2383 2.22217C6.71543 2.22217 2.23828 6.69932 2.23828 12.2222C2.23828 17.745 6.71543 22.2222 12.2383 22.2222Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
