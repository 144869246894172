import React, { useContext, useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ThemeDataContext } from '../../../ContextProviders/theme';
import './ThankYouAtlantica.css';
import background from '../../../Assets/VerityLite/hexperienceBackground.jpg';

export default function ThankYou() {
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const programLabel = localStorage.getItem('programLabel');

  const buttonStyle = {
    color: `${buttonColor}`,
    backgroundColor: `${buttonBackgroundColor}`,
  };

  const navigate = useNavigate();
  setTimeout(() => navigate('/signIn'), 5000);

  return (
    <div>
      <header
        className='bg-skin-primary'
        style={{
          height: '100vh',
          // backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/backroundimage_front.jpg)`,
          // backgroundImage: `url(${background})`,

          // backgroundPosition: 'center',
          // backgroundSize: 'cover',
          // backgroundAttachment: 'fixed',
          // backgroundRepeat: 'no-repeat',
          width: '100%',
          position: 'relative',
          zIndex: '0',
          overflow: 'hidden',
        }}
        id='thankYou-background'>
        {/* <div
          style={{
            backgroundColor: '#003F5E',
            mixBlendMode: 'multiply',
            opacity: '0.8',
            position: 'absolute',
            width: '100%',
            height: '1440px',
            zIndex: '-1',
          }}></div> */}
        <div
          style={
            {
              // backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
            }
          }
          className='thankYou-logo'></div>
        <br />
        <div
          className={`thankYou-formContainer rounded-[5px] border-0 shadow-white bg-transparent`}>
          <div className='thankYou-Heading'>
            <br />
            <br />
            <h1 className='font-roboto text-skin-a11y font-semibold'>
              Thank you for verifying your email !
            </h1>
            <hr className='thankYou-line' />
            <p className='font-roboto text-skin-a11y font-medium'>
              Please click the SIGN IN option to login to your account.
            </p>
          </div>
          <div className='thankYou-buttonContainer'>
            <NavLink
              style={buttonStyle}
              className='w-[330px] hover:-translate-y-[2px] no-underline transition duration-300 text-roboto text-center text-xl font-bold text-skin-primary leading-11 bg-skin-secondary rounded-5 py-[1%]'
              to='/signIn'>
              Sign In
            </NavLink>
          </div>
        </div>
      </header>
    </div>
  );
}
