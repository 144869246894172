import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Components/ResetPassword/ResetPasswordAtlantica.css';
import axios from 'axios';
import Message from '../Components/Message/Message';
import { BsEyeFill } from 'react-icons/bs';
import { ThemeDataContext } from '../ContextProviders/theme';
import { useForm } from 'react-hook-form';
import toast, { Toaster, useToaster, useToasterStore } from 'react-hot-toast';
import Exclamation from '../Icons/Exclamation';
import Tick from '../Icons/Tick';

export default function ResetPassword() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [authError, SetAuthError] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isHoveringSignIn, setIsHoveringSignIn] = useState(false);

  const [isHoveringSignUp, setIsHoveringSignUp] = useState(false);

  const handleMouseOverSignIn = () => {
    setIsHoveringSignIn(true);
  };

  const handleMouseOutSignIn = () => {
    setIsHoveringSignIn(false);
  };

  const handleMouseOverSignUp = () => {
    setIsHoveringSignUp(true);
  };

  const handleMouseOutSignUp = () => {
    setIsHoveringSignUp(false);
  };

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const buttonStyle = {
     backgroundColor: 'white',
    color: `${buttonBackgroundColor}`
  };

  const buttonStyle2 = {
    backgroundColor:`${buttonBackgroundColor}` ,
   color:'white' 
 };


  const buttonStyleFill = {
     backgroundColor: `${buttonBackgroundColor}`,
    color: 'white'
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const PasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!-/:-@{-~"^_`\[\]]).{8,}$/;
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get('email');
  const token = queryParams.get('token');
  const programLabel = queryParams.get('programLabel');

  const onSubmit = (data) => {
    setIsSubmitting(true);

    axios({
      method: 'post',
      baseURL: '/',
      url: 'api/password/reset',
      data: {
        email: email,
        newPassword: data.newPassword,
        token: token,
        programLabel: programLabel,
      },
    })
      .then((resp) => {
        console.log(resp);
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.data.errorMessage);
        }
        navigate('/resetpassword/success');
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.toString());
        // SetAuthError(err.toString());
      });
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const newPassword = watch('newPassword');
  const confirmPassword = watch('confirmPassword');
  const onError = (errors, e) => console.log(errors, e);

  // useEffect(() => {
  //   if (isSubmitting && Object.keys(authError) === 0) {
  //     navigate('/resetpassword/success');
  //   }
  // }, [authError, isSubmitting]);

  const { toasts, handlers } = useToaster();

  const notifySuccess = () =>
    toast.success('Your password has been successfully resetted.');
  const notifyError = (error) => toast.error(error);

  const TOAST_LIMIT = 1;

  useEffect(() => {
    console.log(toasts, 'toasts');
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  const handleDismiss = () => {
    toasts.forEach((t) => toast.dismiss(t.id));
  };

  const notifyPassComposition = () =>
    toast(
      <div
        style={{
          display: 'flex',
          width: '100%',
          borderRadius: '7px',
          fontFamily: 'Lato',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
          background: 'transparent',
          color: `black`,
        }}>
        <div
          style={{
            display: 'flex',
            order: '1',
            alignItems: 'center',
            width: '20%',
            transform: 'translateX(-10%)',
          }}>
          <Exclamation
            width='50'
            height='50'
            viewBox='-4 1 25 15'
            color='red'
          />
          {/* <BsExclamationCircle
            color='red'
            size='40'
          /> */}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            order: '2',
            width: '80%',
            transform: 'translateX(0%)',
          }}>
          Password must contain at least: 8 characters, 1 uppercase letter, 1
          lowercase letter, 1 number, 1 symbol.
        </div>
      </div>,
      {
        style: {
          borderLeft: '6.5px solid red',
          maxWidth: '375px',
        },

        position: 'top-center',
      }
    );

  useEffect(() => {
    document.body.style.backgroundImage = `url(https://verity.blob.core.windows.net/portal/${programLabel}/backroundimage_front.jpg)`;
    document.body.style.backgroundColor = 'transparent';
    document.body.style.background = `url(https://verity.blob.core.windows.net/portal/${programLabel}/backroundimage_front.jpg)`;
    document.body.style.backgroundSize = 'cover';
    document.body.style.WebkitBackgroundSize = 'cover';
    document.body.style.MozBackgroundSize = 'cover';
    document.body.style.backgroundAttachment = 'fixed';
    document.body.style.height = '100vh';
    document.body.style.width = '100vw';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.backgroundPosition = 'center';
  }, []);
  return (
    <header className={`h-full`} id='resetPassword-background'>
      <div
        style={{
          display: 'block',
          margin: '0 auto',
          // backgroundImage: `url(${img})`,
          backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        className='resetPassword-logo'></div>
      <br />

      <div className='resetPassword-buttonContainer'>
        <button
          style={isHoveringSignIn ? buttonStyleFill : buttonStyle}
          // style={buttonStyleTransparent}
          name='signIn'
          className='resetPassword-button'
          onMouseOver={handleMouseOverSignIn}
          onMouseOut={handleMouseOutSignIn}
          onClick={() => navigate('/signIn')}>
          Sign In
        </button>

        <button
          style={isHoveringSignUp ? buttonStyleFill : buttonStyle}
          // style={buttonStyleTransparent}
          name='signUp'
          className='resetPassword-button'
          onMouseOver={handleMouseOverSignUp}
          onMouseOut={handleMouseOutSignUp}
          onClick={() => navigate('/signUp')}>
          Sign Up
        </button>
      </div>
      <div className='resetPassword-formContainer'>
        <div className='resetPassword-Heading'>
          <br />
          <br />
          <h1>Reset Password</h1>
          <hr className='line' />
          <p>Please enter your new password:</p>
        </div>
        <br />
        <br />
        <div onClick={handleDismiss}>
          <Toaster
            duration='1000000'
            position='top-center'
            containerClassName='resetPasswordToaster'
            containerStyle={{
              top: 10,
              left: 10,
              bottom: 10,
              right: 10,
            }}
            toastOptions={{
              error: {
                duration: 1000000,
                icon: (
                  <Exclamation
                    width='50'
                    height='50'
                    viewBox='-4 1 25 15'
                    color='red'
                  />
                ),
                style: {
                  // border: '1px solid #713200',
                  color: `black`,
                  fontWeight: 'normal',
                  background: `white` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                  filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                  fontFamily: 'Lato',
                  borderRadius: '7px',
                  height: 'auto',
                  maxHeight: '65px',
                  fontWeight: '600',
                  transform: 'translateY(100%)',
                  borderLeft: '6.5px solid red',
                },
              },
              success: {
                duration: 1000000,
                icon: (
                  <Tick
                    color='#26A65B'
                    width='50'
                    height='50'
                    viewBox='0 0 50 50'
                  />
                  // <Exclamation width='60' height='60' viewBox='0 0 16 16' />
                ),
                style: {
                  // border: '1px solid #713200',
                  // padding: '16px',
                  color: `black`,
                  fontWeight: 'normal',
                  background: `#fff` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                  filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                  fontFamily: 'Lato',
                  borderRadius: '7px',
                  height: 'auto',
                  fontWeight: '600',
                  transform: 'translateY(100%)',
                  borderLeft: '6.5px solid #26A65B',
                },
              },
            }}
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className='resetPassword-form'>
          <div className='resetPassword-NewPasswordWrap'>
            <label
              className='resetPassword-Passwordlabel'
              htmlFor='newPassword'>
              New Password:
            </label>
            <input
              className='resetPassword-Input'
              type={passwordShown ? 'text' : 'password'}
              id='newPassword'
              name='newPassword'
              {...register('newPassword', {
                required: 'Password is required',
                validate: {
                  isValid: () => {
                    let passwordRegex =
                      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
                    return (
                      passwordRegex.test(newPassword) || notifyPassComposition()
                    );
                  },
                },
                // pattern: {
                //   value:
                //     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
                //   message:
                //     'Password must contain minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and any 1 symbol.',
                // },
              })}
            />
            <BsEyeFill
              fill='grey'
              style={{ transform: 'translateX(-90%)' }}
              onClick={togglePassword}
            />
            {errors.newPassword &&
              errors.newPassword.message === 'Password is required' && (
                <p role='alert' className='resetPasswordErrors'>
                  {errors?.newPassword?.message}
                </p>
              )}
          </div>
          <div className='resetPassword-ConfirmPasswordWrap'>
            <label
              className='resetPassword-ConfirmPasswordlabel'
              htmlFor='confirmPassword'>
              Confirm New Password:
            </label>
            <input
              className='resetPassword-Input'
              type={confirmPasswordShown ? 'text' : 'password'}
              id='confirmPassword'
              name='confirmPassword'
              {...register('confirmPassword', {
                required: 'Password is required',
                validate: {
                  passwordsMatch: () =>
                    newPassword === confirmPassword || 'Passwords must match',
                },
              })}
            />
            <BsEyeFill
              fill='grey'
              style={{ transform: 'translateX(-90%)' }}
              onClick={toggleConfirmPassword}
            />
            {errors.confirmPassword && (
              <p role='alert' className='resetPasswordErrors'>
                {errors?.confirmPassword?.message}
              </p>
            )}
          </div>
          {/* <ul className='resetPassword-Requirements'>
            <li>Must contain minimum 8 characters</li>
            <li>Contain one uppercase letter </li>
            <li>Contain one lowercase letter</li>
            <li>Contain a number</li>
            <li>Contain any one symbol</li>
          </ul> */}
          <div className='resetPassword-saveButtonContainer'>
            <button
              style={buttonStyle2}
              type='submit'
              className='resetPassword-saveButton'>
              Save
            </button>
          </div>
        </form>
      </div>
      <br />
    </header>
  );
}
