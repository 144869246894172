import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import VerityLite from '../VerityLite/App/VerityLite.js';
import Verity from '../App/App';
import EasyCampaign from '../EasyApp/App';
import Verity2 from '../Verity2/App/Verity2.js';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ALT, IXI, MAST, MK } from '../Data/HotelCode';

export default function LoadApps() {
  const [pageTitle, setPageTitle] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const [programLabel, setProgramLabel]=useState();
  useEffect(()=>{
    setProgramLabel(urlParams.get('programLabel')?
    urlParams.get('programLabel'):
    localStorage.getItem('programLabel'))


  },[])
  const [verity, setVerity] = useState(false);

  const [verityLite, setVerityLite] = useState(false);

  useEffect(() => {
    if (programLabel === ALT.INTG || programLabel === ALT.PROD)
      setPageTitle(ALT.TITLE);
    else if (programLabel === IXI.INTG || programLabel === IXI.PROD)
      setPageTitle(IXI.TITLE);
    else if (programLabel === MAST.INTG || programLabel === MAST.PROD) {
      setPageTitle(MAST.TITLE);
    }
    else if (programLabel === MK.PROD) {
      setPageTitle(MK.TITLE);
    }
  }, [programLabel]);

  //   useEffect(() => {
  //     let link = document.querySelector("link[rel~='icon']");
  //     if (!link) {
  //       link = document.createElement('link');
  //       link.rel = 'icon';
  //       document.getElementsByTagName('head')[0].appendChild(link);
  //     }
  //     if (
  //       programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
  //       'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B'
  //     ) {
  //       link.href =
  //         'https://www.verityguest.com/wp-content/uploads/2021/12/cropped-verity-icon-16x16-1-32x32.png';
  //     } else if (
  //       programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' ??
  //       'IXI-INTG-E98241F0-EE89-4B11-8F81-94C0CA0085FA'
  //     ) {
  //       link.href =
  //         'https://www.verityguest.com/wp-content/uploads/2021/12/cropped-verity-icon-16x16-1-32x32.png';
  //     }
  //     let link2 = document.querySelector("link[rel~='apple-touch-icon']");
  //     if (!link2) {
  //       link2 = document.createElement('link');
  //       link2.rel = 'apple-touch-icon';
  //       document.getElementsByTagName('head')[0].appendChild(link2);
  //     }
  //     if (
  //       programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
  //       'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B'
  //     ) {
  //       link2.href =
  //         'https://www.verityguest.com/wp-content/uploads/2021/12/cropped-verity-icon-16x16-1-32x32.png';
  //     } else if (
  //       programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' ??
  // C
  //     ) {
  //       link2.href =
  //         'https://www.verityguest.com/wp-content/uploads/2021/12/cropped-verity-icon-16x16-1-32x32.png';
  //     }
  //   }, [programLabel]);


useEffect(()=>{
  if (programLabel==="CND-76AA1EB8-A465-48FD-ADBE-D7B316FBA824"||programLabel==="MUSKITA-E1EF60CC-27DA-4D63-B7E8-C61D840E6BD7"
    ||programLabel==="MUSKITA-INTG-E1EF60CC-27DA-4D63-B7E8-C61D840E6BD7"||programLabel==="C5AF4165-2D16-434C-AFD4-7B9D5E37FA45"
    ||programLabel==="HVJT-INTG-E67BE408-9AF0-466A-A882-54470B1E02C1"||programLabel==="HEXPER-E67BE408-9AF0-466A-A882-54470B1E02C1"
    ||programLabel==="AVRACOLL-13A15C9C-186C-42C6-9240-FEB62E2A9EC1"|| programLabel==="LEPTOS-A3A66E19-00F4-47AF-9303-850AB8413EDC"){
      setVerity(false);
      setVerityLite(true);
    }
  else 
  if(programLabel==="ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B"||programLabel==="IXI-INTG-E98241F0-EE89-4B11-8F81-94C0CA0085FA"
    ||programLabel==="MAST-687F4563-95DA-4EA7-B919-6D376CE738B8"||programLabel==="MAST-INTG-687F4563-95DA-4EA7-B919-6D376CE738B8"
    ||programLabel==="ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B"||programLabel==="9DF45F46-9911-4A64-A340-126466DAF541"
    ||programLabel==="IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA"|| programLabel==="AA116997-54BC-468D-94C7-28EFD2FF7C4C"
    ||programLabel==="ALD-D6723397-1407-4C60-93BD-386DCE063346" ||programLabel==='0E2CBAB8-4DA6-402D-8185-36E907243324'
    ||programLabel==='53CE9A36-A9EC-4A7A-97A1-7E76FCFF27F5'||programLabel==='STR-E6EA5920-0DA2-4C53-8098-8F28EB81C228'
    ||programLabel==='EF395EDA-A887-4FC8-8FCF-E42AB9B4603C'||programLabel==='D7238698-8B30-44CC-A818-3D3E28176180'
   )
  {
      setVerity(true);
      setVerityLite(false);    
    }else if(programLabel===null||programLabel===undefined||programLabel===""){
      setVerity(false);
      setVerityLite(false);
    }

},[programLabel])
  
return (
  <>
    {programLabel === null || programLabel === undefined || programLabel === '' ? (
      // Render your empty page here
      <div >
</div>

    ) : (
      <>
        {verityLite ? (
          <div>
            <VerityLite />
            <HelmetProvider>
              <Helmet>
                <title>Welcome to Verity Lite</title>
              </Helmet>
            </HelmetProvider>
          </div>
        ) : verity ? (
          <div>
            <Verity />
            <HelmetProvider>
              <Helmet>
                <title>{pageTitle}</title>
              </Helmet>
            </HelmetProvider>
          </div>
        ) : (
          <div>
            <Verity />
          </div>
        )}
      </>
    )}
  </>
);
}
 
  
  // switch (programLabel) {
  //   case 'CND-76AA1EB8-A465-48FD-ADBE-D7B316FBA824':
  //     return (
  //       <>
  //         <VerityLite />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>"Welcome to Verity Jumpstart"</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
  //   case 'MUSKITA-E1EF60CC-27DA-4D63-B7E8-C61D840E6BD7':
  //     return (
  //       <>
  //         <VerityLite />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>"Welcome to Verity Jumpstart"</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
  //   case 'MUSKITA-INTG-E1EF60CC-27DA-4D63-B7E8-C61D840E6BD7':
  //     return (
  //       <>
  //         <VerityLite />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>"Welcome to Verity Jumpstart"</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
  //     case 'C5AF4165-2D16-434C-AFD4-7B9D5E37FA45':
  //     return (
  //       <>
  //         <VerityLite />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>"Welcome to Verity Jumpstart"</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
  //   case 'HVJT-INTG-E67BE408-9AF0-466A-A882-54470B1E02C1':
  //     return (
  //       <>
  //         <VerityLite />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>"Welcome to Verity Jumpstart"</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
  //   case 'HEXPER-E67BE408-9AF0-466A-A882-54470B1E02C1':
  //     return (
  //       <>
  //         <VerityLite />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>"Welcome to Verity Jumpstart"</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
  //   case 'Verity2':
  //     return (
  //       <>
  //         <Verity2 />
  //       </>
  //     );

  //   case 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B':
  //     return (
  //       <>
  //         <Verity />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>{pageTitle}</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
  //   case 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA':
  //     return (
  //       <>
  //         <Verity />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>{pageTitle}</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
  //   case 'IXI-INTG-E98241F0-EE89-4B11-8F81-94C0CA0085FA':
  //     return (
  //       <>
  //         <Verity />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>{pageTitle}</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
  //   case 'MAST-687F4563-95DA-4EA7-B919-6D376CE738B8':
  //     return (
  //       <>
  //         <Verity />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>{pageTitle}</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
  //   case 'MAST-INTG-687F4563-95DA-4EA7-B919-6D376CE738B8':
  //     return (
  //       <>
  //         <Verity />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>{pageTitle}</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
  //   case 'DEV2-CB82ED7D-4F63-4A8A-B8E5-D14EF8957B40':
  //     return (
  //       <>
  //         <EasyCampaign />
  //       </>
  //     );

  //   case 'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B':
  //     return (
  //       <>
  //         <Verity />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>'Atlantica Loyalty Club'</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
  //     case '9DF45F46-9911-4A64-A340-126466DAF541':
  //     return (
  //       <>
  //         <Verity />
  //         <HelmetProvider>
  //           <Helmet>
  //             <title>'MK Collection'</title>
  //           </Helmet>
  //         </HelmetProvider>
  //       </>
  //     );
      
  //   default:
  //     return (
  //       <>
  //         <Verity />
  //       </>
  //     );
  // }

