export default {
  USER_LOGIN_START: 'USER_LOGIN_START',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',
  USER_LOGOUT: 'USER_LOGOUT',
  SET_SIDEBAR_VISIBILITY: 'SET_SIDEBAR_VISIBILITY',
  SET_SIDEBAR_OPEN: 'SET_SIDEBAR_OPEN',
  SET_CHANGEPASSWORD_OPEN: 'SET_CHANGEPASSWORD_OPEN',
  SET_CHANGEEMAIL_OPEN: 'SET_CHANGEEMAIL_OPEN',
  SET_PREV_PATH: 'SET_PREV_PATH',
};
