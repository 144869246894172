import React, { useContext, useEffect } from 'react';
import './formSuccessAtlantica.css';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { ThemeDataContext } from '../../ContextProviders/theme';
import fx from 'fireworks';
import { useMediaQuery } from 'react-responsive';

export default function FormSuccess() {
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const programLabel = localStorage.getItem('programLabel');

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };

  const navigate = useNavigate();

  const isMobile = useMediaQuery({
    query: '(min-width:300px)',
  });

  const isTablet = useMediaQuery({
    query: '(min-width: 768px)',
  });

  useEffect(() => {
    isTablet &&
      fx({
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
        colors: ['#cc3333', '#4CAF50', '#81C784'],
      });
    isMobile &&
      fx({
        x: window.innerWidth / 2,
        y: window.innerHeight / 3,
        colors: ['#cc3333', '#4CAF50', '#81C784'],
      });
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [isTablet, isMobile]);

  return (
    <div className='formSuccess-Heading'>
      {isTablet && (
        <>
          <br />
          <br />
        </>
      )}

      <div className='empty'></div>

      <h1>Thank you for signing up!</h1>
      <hr className='line' />
      <p>
        You will receive a confirmation email shortly. Please follow the steps
        in the email to confirm your email address.
      </p>
      <br />
      <br />
      <p id='formSuccess-spam'>
        Please be sure to check your Spam and Junk folder.
      </p>
      <br />

      <div className='formSuccess-buttonContainer'>
        <NavLink
          style={buttonStyle}
          onClick={(e) => navigate('/signIn')}
          className='formSuccess-button'
          to='/signIn'>
          Sign In
        </NavLink>
      </div>
    </div>
  );
}
