import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { ThemeDataContext } from '../ContextProviders/theme';
import '../Components/ForgotPasswordSuccess/ForgotPasswordSuccessAtlantica.css';

export default function ForgotPasswordSuccess() {
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };
  const programLabel = localStorage.getItem('programLabel');

  return (
    <div>
      <header
        id='success-background'
        style={{
          height: '1700px',
          backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/backroundimage_front.jpg)`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          width: '100%',
        }}>
        <div
        className={`${localStorage['programLabel']==="9DF45F46-9911-4A64-A340-126466DAF541"?"success-logo MKLogo":"success-logo"}`}
          style={{
            display: 'block',
            margin: '0 auto',
            transform: 'translate(0%, 25%)',
            backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}></div>
        <br />

        <div className={`${localStorage['programLabel']==="9DF45F46-9911-4A64-A340-126466DAF541"?"success-formContainer MKContainer":"success-formContainer"}`} >         
         <div className='success-Heading'>
            <br />
            <br />

            <hr className='success-line' />
            <p>
              If an account matches your email, you should receive an email with
              instructions on how to reset your password shortly!
            </p>
            <br />
            <div className='success-spam'>
              Please be sure to check your Spam and Junk folder.
            </div>
          </div>
          <div className='success-buttonsContainer'>
            <NavLink
              style={buttonStyle}
              className='success-button'
              to='/signIn'>
              Sign In
            </NavLink>
          </div>
        </div>
      </header>
    </div>
  );
}
