import React, { useEffect, useState, useContext, useRef } from 'react';
import { DateLogic } from '../../Container/dateLogic';
import { NavLink } from 'react-router-dom';
import Message from '../Message/Message';
import useForm from './useForm';
import Validate from './validate';
import { ThemeDataContext } from '../../ContextProviders/theme';
import './SignUpFormAtlantica.css';
import { BsEyeFill } from 'react-icons/bs';
import CheckboxVerity from '../Checkbox/CheckboxVerity';
import toast, { Toaster, useToaster, useToasterStore } from 'react-hot-toast';
import Exclamation from '../../Icons/Exclamation';
import { useMediaQuery } from 'react-responsive';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

function SignUpForm({ submitForm }) {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  const Tablet = useMediaQuery({
    query: '(min-width: 768px) ',
  });

  const Mobile = useMediaQuery({
    query: '(min-width: 280px) and (max-width: 767px)',
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const {
    handleChange,
    handleDateChange,
    handleSubmit,
    isSubmitting,
    setIsSubmitting,
    values,
    errors,
    setErrors,
    authErrors,
    setAuthErrors,
    isDisabled,
    birthdate,
  } = useForm(submitForm, Validate);

  const programLabel = localStorage.getItem('programLabel');
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const { termsFileUri } = portalInfo || {};
  console.log(termsFileUri, 'terms');

  const style = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };

  useEffect(() => {
    const dateRange = DateLogic.getBirthdateRange();
    setMinDate(dateRange.min);
    setMaxDate(dateRange.max);
  }, []);

  // const getClassName = () =>
  //   errors.length === 0 ? 'registerContainer' : 'registerContainerWithErrors';

  // function showPopup(termsFileUri) {
  //   newwindow = window.open(
  //     termsFileUri,
  //     'name',
  //     'height=190,width=520,top=200,left=300,resizable'
  //   );
  //   if (window.focus) {
  //     newwindow.focus();
  //   }
  // }

  const { toasts, handlers } = useToaster();

  const notifyError = (err) =>
    toast(
      <div
        style={{
          display: 'flex',
          width: '100%',

          borderRadius: '7px',
          fontFamily: 'Lato',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
          background: 'transparent',
          color: `black`,
        }}>
        <div
          style={{
            display: 'flex',
            order: '1',
            alignItems: 'center',
            width: '20%',
            transform: 'translateX(-10%)',
          }}>
          <Exclamation
            width='50'
            height='50'
            viewBox='-4 1 25 15'
            color='red'
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            order: '2',
            width: '80%',
            transform: 'translateX(0%)',
          }}>
          {err}
        </div>
      </div>,
      {
        style: {
          borderLeft: '6.5px solid red',
          maxWidth: '400px',
        },
        duration: '60000',

        position: 'top-center',
      }
    );
  const TOAST_LIMIT = 1;

  const notifyPassComposition = () =>
    toast.error(
      'Password must contain at least: 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, 1 symbol.'
    );

  const buttonRef = useRef();

  useEffect(() => {
    console.log(toasts, 'toasts');
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  useEffect(() => {
    if (errors.passwordComposition) {
      notifyPassComposition();
      setErrors({
        ...errors,
        passwordComposition: '',
      });
      setIsSubmitting(false);
      console.log(isSubmitting, 'isSubmitting');
      buttonRef.current.disabled = true;
      setTimeout(() => (buttonRef.current.disabled = false), 3000);
    }
  }, [errors, notifyPassComposition, setErrors, isSubmitting, setIsSubmitting]);

  useEffect(() => {
    if (authErrors.length != 0) {
      notifyError(authErrors);
      setIsSubmitting(false);
      console.log(isSubmitting, 'isSubmitting');
      setAuthErrors([]);
      buttonRef.current.disabled = true;
      setTimeout(() => (buttonRef.current.disabled = false), 3000);
    }
  }, [
    authErrors,
    notifyError,
    setAuthErrors,
    buttonRef,
    isSubmitting,
    setIsSubmitting,
  ]);

  const handleDismiss = () => {
    toasts.forEach((t) => toast.dismiss(t.id));
  };

  return (
    <>
      <div className='signUpHeading'>
        <br />
        <br />
        {/* {programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
        'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B' ? (
          <div className='signUpKeys'></div>
        ) : (
          <div className='empty'></div>
        )} */}
        <h1>Become a Member !</h1>
        <hr className='signUpLine' />
      </div>
      <Message message={authErrors} isError={true} />
      <div onClick={handleDismiss}>
        <Toaster
          style={{ width: '500px' }}
          position='top-center'
          containerClassName='signUpToaster'
          containerStyle={{
            top: 10,
            left: 10,
            bottom: 10,
            right: 10,
          }}
          // toastOptions={{
          //   error: {
          //     duration: 2000000,
          //     icon: (
          //       <Exclamation
          //         width='50'
          //         height='50'
          //         viewBox='-4 1 25 15'
          //         color='red'
          //       />
          //     ),
          //     style: {
          //       // border: '1px solid #713200',

          //       color: `black`,
          //       fontWeight: 'normal',
          //       background: `white` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
          //       filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
          //       fontFamily: 'Lato',
          //       borderRadius: '7px',
          //       height: 'auto',

          //       fontWeight: '600',
          //       transform: 'translateY(100%)',
          //       borderLeft: '6.5px solid red',
          //     },
          //   },
          // }}
          // toastOptions={{
          //   duration: 15000,
          //   icon: (
          //     <Exclamation
          //       width='50'
          //       height='50'
          //       viewBox='-4 1 25 15'
          //       color='red'
          //     />
          //   ),
          //   style: {
          //     // border: '1px solid #713200',
          //     padding: '16px',
          //     color: `red`,
          //     fontWeight: 'normal',
          //     background: `rgba(255, 228, 228, 1)` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
          //     filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
          //     fontFamily: 'Lato',
          //     borderRadius: '7px',
          //     height: 'auto',
          //     maxHeight: '100px',
          //     fontWeight: '600',
          //   },
          // }}
        />
      </div>
      <form className='signUpForm' onSubmit={handleSubmit}>
        <div className='form-inputs'>
          <br />

          <label htmlFor='email' className='SignUp-email-Label'>
            Email*
          </label>
          <input
            disabled={isDisabled}
            id='email'
            type='email'
            name='email'
            className='SignUp-form-emailInput'
            placeholder=''
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && <p id='SignUp-email-error'>{errors.email}</p>}
        </div>
        <br />
        <div className='formGroup'>
          <div className='leftForm'>
            <br />
            <label htmlFor='firstname' className='SignUp-firstName-formLabel '>
              First Name*
            </label>
            <input
              disabled={isDisabled}
              id='firstname'
              type='text'
              name='firstname'
              className='SignUp-form-input'
              placeholder=''
              value={values.firstname}
              onChange={handleChange}
            />
            {errors.firstname && (
              <p id='SignUp-firstname-error'>{errors.firstname}</p>
            )}
          </div>
          <br />
          <div className='rightForm'>
            <br />
            <label
              htmlFor='Veritylastname'
              className='SignUp-lastName-formLabel '>
              Last Name*
            </label>
            <input
              disabled={isDisabled}
              id='Veritylastname'
              type='text'
              name='lastname'
              className='SignUp-form-input'
              placeholder=''
              value={values.lastname}
              onChange={handleChange}
            />

            {errors.lastname && (
              <p id='SignUp-lastname-error'>{errors.lastname}</p>
            )}
          </div>
        </div>
        <br />
        <div className='form-inputs'>
          <br />
          <label htmlFor='dob' className='SignUp-dob-formLabel'>
            Birthdate*
          </label>
          {/* <div className='SignUp-ReactDatePickerWrap'> */}
          <DatePicker
                   portalId="root-portal"

            className='SignUp-ReactDatePicker'
            style={{ marginLeft: '2rem' }}
            dateFormat='dd/MM/yyyy'
            onChange={(date) => handleDateChange(date)}
            selected={birthdate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            placeholderText='DD/MM/YYYY'
          />
          {/* </div> */}
          {/* <input
            disabled={isDisabled}
            id='dob'
            type='date'
            name='birthday'
            className='SignUp-form-input --date'
            placeholder=''
            value={values.birthday}
            onChange={handleChange}
            min={minDate}
            max={maxDate}
          /> */}
          {errors.birthday && <p id='SignUp-dob-error'>{errors.birthday}</p>}
        </div>
        <br />
        <div className='formGroup'>
          <div className='leftForm'>
            <br />
            <label htmlFor='password' className='SignUp-password-formLabel '>
              Password*
            </label>
            <input
              disabled={isDisabled}
              id='password'
              type={passwordShown ? 'text' : 'password'}
              name='password'
              className='SignUp-form-input'
              placeholder=''
              value={values.password}
              onChange={handleChange}
            />
            <BsEyeFill
              className='signUp-eye-password'
              fill='grey'
              onClick={togglePassword}
            />

            {errors.password && <p id='SignUp-pass-error'>{errors.password}</p>}
            {/* {errors.passwordComposition && (
              <p id='SignUp-passComposition-error'>
                {errors.passwordComposition}
              </p>
            )} */}
          </div>

          <br />
          <div className='rightForm'>
            <br />
            <label htmlFor='confirmPassword' className='formLabel'>
              Confirm Password*
            </label>
            <input
              disabled={isDisabled}
              id='confirmPassword'
              type={confirmPasswordShown ? 'text' : 'password'}
              name='confirmPassword'
              className='SignUp-form-input'
              placeholder=''
              value={values.confirmPassword}
              onChange={handleChange}
            />
            <BsEyeFill
              className='signUp-eye-confirmPass'
              fill='grey'
              onClick={toggleConfirmPassword}
            />
            {errors.confirmPassword && (
              <p id='SignUp-confirmPass-error'>{errors.confirmPassword}</p>
            )}
          </div>
        </div>
        <br />
        <br />

        <div className='checkbox-inputs'>
          <br />
          <div className='signUpContainer'>
            <label htmlFor='promo' className='checkBoxLabel'>
              I would like to receive exclusive member offers, account updates,
              program and hotel news via email.
            </label>
            <CheckboxVerity
              disabled={isDisabled}
              id='promo'
              type='checkbox'
              name='promotionalMailAccepted'
              className='checkboxVerity'
              checked={values.promotionalMailAccepted}
              onChange={handleChange}
              required={false}
            />
          </div>
        </div>

        <div className='checkbox-inputs'>
          {Mobile && <br />}
          <div className='signUpContainer'>
            <label htmlFor='terms' className='checkBoxLabel'>
              I have read and accept the{' '}
              <a
                href={termsFileUri}
                target='_blank'
                rel='noreferrer noopener'
                className='signUpConditions'
                style={{
                  color: `${buttonBackgroundColor}`,
                }}>
                Terms & Conditions*
              </a>
            </label>
            <CheckboxVerity
              disabled={isDisabled}
              id='terms'
              type='checkbox'
              name='termsAccepted'
              className='checkboxVerity'
              checked={values.termsAccepted}
              onChange={handleChange}
              required={true}
            />
          </div>
          <span className='signUpContainer checkBoxLabel'>
                <i>Points are credited only to Loyalty members who have a valid reservation under their name.</i>      
            </span>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className='registerContainer'>
          <button
            ref={buttonRef}
            style={style}
            disabled={isDisabled}
            className='SignUpFormButton'
            type='submit'>
            Register
          </button>
        </div>
        <br />
      </form>
    </>
  );
}

export default SignUpForm;
