import React from 'react';

export default function Logout({
  size, // or any default size of your choice
  color, // or any color of your choice
  style,
  width,
  height,
  onClick,
  className,
}) {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      className={className}
      style={style}
      viewBox='0 0 25 24'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.2156 2.39989C18.9038 2.16595 18.4909 2.11627 18.1326 2.26931C17.7741 2.42235 17.5246 2.75512 17.4781 3.1421C17.4313 3.52909 17.5948 3.91151 17.9065 4.14528C19.6992 5.4898 20.978 7.40723 21.5306 9.57891C22.0833 11.7506 21.8762 14.046 20.944 16.0837C20.0119 18.1214 18.4107 19.7792 16.4064 20.7814C14.4021 21.7837 12.1154 22.07 9.92597 21.593C7.73657 21.116 5.77589 19.9044 4.36984 18.1592C2.96398 16.4142 2.19747 14.2407 2.19747 12C2.19199 10.4748 2.54428 8.96962 3.22641 7.60537C3.90853 6.24112 4.90129 5.05607 6.12472 4.14546C6.35611 3.97179 6.50915 3.71336 6.55006 3.42701C6.59097 3.14066 6.51646 2.8497 6.34296 2.61814C6.16928 2.38675 5.91086 2.2337 5.62451 2.19279C5.33796 2.15188 5.04701 2.2264 4.81563 2.40008C2.62446 4.04335 1.06151 6.3867 0.386163 9.04087C-0.289184 11.6952 -0.0362597 14.5007 1.10317 16.9912C2.2424 19.4819 4.19929 21.508 6.64901 22.733C9.09877 23.9578 11.8938 24.3078 14.5699 23.7248C17.2459 23.1419 19.6421 21.6609 21.3603 19.528C23.0785 17.3953 24.0155 14.7386 24.0155 11.9998C24.0225 10.1357 23.5917 8.29591 22.758 6.62837C21.9243 4.96116 20.7111 3.5127 19.2155 2.3998L19.2156 2.39989Z'
        fill='#E84E1C'
      />
      <path
        d='M12.0156 13.0905C12.3049 13.0905 12.5825 12.9757 12.7871 12.7711C12.9916 12.5666 13.1065 12.289 13.1065 11.9997V1.09083C13.1065 0.701098 12.8987 0.340955 12.5612 0.146089C12.2237 -0.0487776 11.8076 -0.0487776 11.4701 0.146089C11.1326 0.340955 10.9248 0.701093 10.9248 1.09083V11.9997C10.9248 12.289 11.0397 12.5666 11.2442 12.7711C11.4488 12.9757 11.7263 13.0905 12.0156 13.0905Z'
        fill='#E84E1C'
      />
    </svg>
  );
}

//alternative

//  <svg
//    style={style}
//    className={className}
//    width={width}
//    height={height}
//    viewBox='0 0 25 24'
//    fill='none'
//    xmlns='http://www.w3.org/2000/svg'>
//    <path
//      d='M9.01562 20.9999H5.01562C4.48519 20.9999 3.97648 20.7892 3.60141 20.4142C3.22634 20.0391 3.01563 19.5304 3.01562 18.9999V4.99994C3.01562 4.46951 3.22634 3.9608 3.60141 3.58573C3.97648 3.21065 4.48519 2.99994 5.01562 2.99994H9.01562'
//      stroke={color}
//      strokeWidth='2'
//      strokeLinecap='round'
//      strokeLinejoin='round'
//    />
//    <path
//      d='M16.0156 16.9999L21.0156 11.9999L16.0156 6.99994'
//      stroke={color}
//      strokeWidth='2'
//      strokeLinecap='round'
//      strokeLinejoin='round'
//    />
//    <path
//      d='M21.0156 11.9999H9.01562'
//      stroke={color}
//      strokeWidth='2'
//      strokeLinecap='round'
//      strokeLinejoin='round'
//    />
//  </svg>;
