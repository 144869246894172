import React, { useState, useContext, useEffect } from 'react';
import Buttons from '../Buttons/Buttons';
import Form from '../Form/Form';
import { useNavigate } from 'react-router-dom';
import { ThemeDataContext } from '../../ContextProviders/theme';
import './HomeAtlantica.css';
import '../../Globals/AtlanticaGlobalStyle.css';
import { useMediaQuery } from 'react-responsive';
import actions from '../../Redux/reducers/user/user.actions';
import { useDispatch } from 'react-redux';

function Home(props) {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isDowntime, setIsDowntime] = useState(true);
    const urlParams = new URLSearchParams(window.location.search);
    const programLabelFromURL = urlParams.get('programLabel');
    const programLabel =
        programLabelFromURL ?? localStorage.getItem('programLabel');

    useEffect(() => {
        if (!localStorage['programLabel']) {
            localStorage.setItem('programLabel', programLabel);
        }
    }, [programLabel]);

    useEffect(() => {
        if (
            localStorage.getItem('programLabel') !== programLabelFromURL &&
            programLabelFromURL
        ) {
            localStorage.setItem('programLabel', programLabelFromURL);
        }
    }, [programLabelFromURL]);

    function submitForm() {
        setIsSubmitted(true);
    }

    const { portalInfo } = useContext(ThemeDataContext) || {};

    const Tablet = useMediaQuery({
        query: '(min-width: 768px) ',
    });

    const { menu } = props;

    const [activeButton, setActiveButton] = useState(menu);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const toggle = (e) => {
        const { name } = e.target;
        setActiveButton(name);

        let url = '../' + name;
        navigate(url);
        if (!localStorage['programLabel']) {
            localStorage.setItem('programLabel', programLabel);
        }
    };

    useEffect(() => {
        dispatch(actions.setPrevPath(window.location.pathname));
    }, []);

    useEffect(() => {
        let background = document.getElementById('HomeBackground');
        background.style.setProperty('backgroundSize', 'cover', 'priority');
    }, []);

    const getClassName = () => {
        if (activeButton === 'signIn') return 'HomeFormContainer';
        else if (activeButton === 'signUp' && !isSubmitted)
            return 'HomeFormContainer extended';
        else if (activeButton === 'signUp' && isSubmitted)
            return 'HomeFormContainer success';
    };

    useEffect(() => {
        document.body.style.backgroundImage = `url(https://verity.blob.core.windows.net/portal/${programLabel}/backroundimage_front.jpg)`;
        document.body.style.backgroundColor = 'transparent';
        document.body.style.background = `url(https://verity.blob.core.windows.net/portal/${programLabel}/backroundimage_front.jpg)`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.WebkitBackgroundSize = 'cover';
        document.body.style.MozBackgroundSize = 'cover';
        document.body.style.backgroundAttachment = 'fixed';
        document.body.style.height = '100vh';
        document.body.style.width = '100vw';
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.backgroundPosition = 'center';
    }, []);

  const getTemplateHeight = () => {
    if (Tablet) {
      return '150%';
    } else if (!Tablet && activeButton === 'signUp') {
      return '1770px';
    } else if (!Tablet && activeButton === 'signIn') {
      return '820px';
    }
  };

  const closeModal = () => setIsDowntime(false);

  return (
    <>
      {/* {isDowntime && (
        <div className="modal-overlay_urg">
          <div className="modal-content_urg">
            <h5>{activeButton === 'signIn' ? 'Access temporarily unavailable due to a planned system update. Please try again later' : 'The registration functionality is temporarily unavailable due to a planned system update. Please try again later.'}</h5>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )} */}
      <div className={``} id='HomeBackground'>
        <div
          className={programLabel==="9DF45F46-9911-4A64-A340-126466DAF541"?"HomeLogoMK":"HomeLogo"}
          style={{
            display: 'block',
            margin: '0 auto',
            backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}></div>
        <br />
        <div style={{ height: '600px' }} className={programLabel==="9DF45F46-9911-4A64-A340-126466DAF541"?"leftContainerMK":""}>
          <div className='HomeButtonsContainer'>
            <Buttons toggle={toggle} activeButton={activeButton} />
          </div>
          <div className={getClassName()}>
            <Form
              type={activeButton}
              submitForm={submitForm}
              isSubmitted={isSubmitted}
            />
          </div>
        </div>
        <p
          className='HomeTemplate'
          style={{
            position: 'absolute',
            top: `${getTemplateHeight()}`,
            left: `${Tablet ? '92%' : '64%'}`,
          }}>
          TMPL #1, v.0.2.0
        </p>
      </div>
    </>
  );
}

export default Home;
