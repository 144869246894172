import React, { useContext, useEffect, useState } from 'react';
import '../Components/Benefits/BenefitsAtlantica.css';
import 'react-modern-drawer/dist/index.css';
import Header from '../Components/Header';
import { ThemeDataContext } from '../../ContextProviders/theme';
import Ixian from '../Components/Benefits/BenefitsIxian';
import Drawer from 'react-modern-drawer';
import { useMediaQuery } from 'react-responsive';
import { VscCircleFilled } from 'react-icons/vsc';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { ALT, IXI, MAST } from '../../Data/HotelCode';

export default function Benefits({ toggle }) {
  const [Drawer1IsOpen, setDrawer1IsOpen] = React.useState(false);
  const [Drawer2IsOpen, setDrawer2IsOpen] = React.useState(false);
  const [Drawer3IsOpen, setDrawer3IsOpen] = React.useState(false);
  const [Drawer4IsOpen, setDrawer4IsOpen] = React.useState(false);
  const toggleDrawer1 = () => {
    setDrawer1IsOpen((prevState) => !prevState);
  };
  const toggleDrawer2 = () => {
    setDrawer2IsOpen((prevState) => !prevState);
  };
  const toggleDrawer3 = () => {
    setDrawer3IsOpen((prevState) => !prevState);
  };
  const toggleDrawer4 = () => {
    setDrawer4IsOpen((prevState) => !prevState);
  };

  const [barTiers, setBarTiers] = useState([]);
  const programLabel = localStorage.getItem('programLabel');

  const theme = useContext(ThemeDataContext) || {};
  const { tiers } = theme || {};

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};

  // useEffect(() => {
  //   if (
  //     programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ||
  //     'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B'
  //   ) {
  //     const { tiers } = theme || { tiers: null };
  //     const bTiers =
  //       tiers &&
  //       tiers
  //         .filter((tier) => tier.barPercentage > 0)
  //         .map((tier) => ({
  //           name: tier.description,
  //           color: tier.color,
  //         }));
  //     setBarTiers(bTiers);
  //   } else if (
  //     programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' ||
  //     'IXI-INTG-E98241F0-EE89-4B11-8F81-94C0CA0085FA'
  //   ) {
  //     const bTiers =
  //       Ixian &&
  //       Ixian.map((tier) => ({
  //         name: tier.Name,
  //         apply: tier.Apply,
  //         color: tier.Color,
  //         benefits: tier.Benefits,
  //       }));
  //     setBarTiers(bTiers);
  //   }
  // }, [theme, Ixian]);

  // console.log(barTiers, 'barTiers');

  const Tablet = useMediaQuery({
    query: '(min-width: 768px) ',
  });

  const Mobile = useMediaQuery({
    query: '(min-width: 280px) and (max-width: 767px)',
  });

  const isSidebarVisible = useSelector((state) => state.user.isSidebarVisible);

  const [fullBenefitsList, setFullBenefitsList] = useState([]);
  const [benefitsListByTier, setBenefitsListByTier] = useState([]);

  useEffect(() => {
    const benefitsArray = Ixian.map((e) => e.Benefits);
    const benefits = [];
    benefitsArray.forEach((benefit) =>
      benefit.forEach((benefit) => benefits.push(benefit))
    );

    const uniq = [...new Set(benefits)];

    setFullBenefitsList(uniq);
  }, []);

  // function GenerateBenefitsListByTier(benefits) {
  //   let array = [];
  //   for (let i = 0; i < benefits.length; i++) {
  //     let array1 = Ixian[i].Benefits;
  //     array.push(array1);
  //   }

  //   console.log(array, 'array');
  //   return array;
  // }

  // useEffect(() => {
  //   setBenefitsListByTier(GenerateBenefitsListByTier(Ixian));
  // }, [Ixian]);

  // const getBulletPoint = () => {
  //   return <VscCircleFilled />;
  // };

  return (
    <>
      <Header />
      <div
        style={{
          visibility: `${isSidebarVisible ? 'hidden' : 'visible'}`,
        }}>
        <div className='Benefits-heading'>
          <h1>Benefits</h1>
          <hr className='Benefits-line'></hr>
          <br />
          <br />

          {/* {programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' &&
            Tablet && (
              <>
                <button
                  className='Benefits-button'
                  style={{
                    background: `${tiers[0].color}`,
                  }}
                  onClick={toggleDrawer1}>
                  {barTiers[0]?.name}
                </button>
                <Drawer
                  open={Drawer1IsOpen}
                  onClose={toggleDrawer1}
                  direction='top'
                  style={{ marginTop: '7rem' }}
                  className='Benefits-Drawer'>
                  <div
                    style={{
                      transform: 'translateY(100%)',
                      display: 'block',
                      width: '100%',
                      height: '100px',
                      justifyContent: 'left',
                    }}>
                    {barTiers[0]?.benefits.map((benefit, index) => (
                      <p
                        key={index}
                        style={{ display: 'block', fontSize: '1.25rem' }}>
                        {benefit}
                      </p>
                    ))}
                  </div>
                </Drawer>
                <button
                  className='Benefits-button'
                  style={{
                    background: `${tiers[1].color}`,
                  }}
                  onClick={toggleDrawer2}>
                  {barTiers[1]?.name}
                </button>
                <Drawer
                  open={Drawer2IsOpen}
                  onClose={toggleDrawer2}
                  direction='left'
                  style={{
                    paddingLeft: '1rem',
                    width: '30vw',
                    marginTop: '9rem',
                    overflow: 'scroll',
                  }}
                  className='Benefits-Drawer'>
                  <div
                    style={{
                      transform: 'translateY(30%)',
                      display: 'block',
                      textAlign: 'left',
                    }}>
                    {barTiers[1]?.benefits.map((benefit, index) => (
                      <p
                        style={{
                          lineHeight: '2rem',
                          fontSize: '1.25rem',
                          marginBottom: '1rem',
                        }}
                        key={index}>
                        {benefit}
                      </p>
                    ))}
                  </div>
                </Drawer>
                <button
                  className='Benefits-button'
                  style={{ background: `${tiers[2].color}` }}
                  onClick={toggleDrawer3}>
                  {barTiers[2]?.name}
                </button>
                <Drawer
                  open={Drawer3IsOpen}
                  onClose={toggleDrawer3}
                  direction='bottom'
                  style={{ height: '65.5vh', paddingTop: '2rem' }}
                  className='Benefits-Drawer'>
                  <div
                    style={{
                      transform: 'translateY(0%)',
                      fontSize: '1.25rem',
                      margin: '0',
                      lineheight: '0.5rem',
                      height: '10px',
                      padding: '0',
                    }}>
                    {barTiers[2]?.benefits.map((benefit, index) => (
                      <p>{benefit}</p>
                    ))}
                  </div>
                </Drawer>
                <button
                  className='Benefits-button'
                  style={{ background: `${tiers[3].color}` }}
                  onClick={toggleDrawer4}>
                  {barTiers[3]?.name}
                </button>
                <Drawer
                  open={Drawer4IsOpen}
                  onClose={toggleDrawer4}
                  direction='right'
                  style={{ width: '80vw', marginTop: '12.5rem' }}
                  className='Benefits-Drawer'>
                  <div
                    style={{
                      transform: 'translateY(0%)',
                      fontSize: '1.25rem',
                    }}>
                    {barTiers[3]?.benefits.map((benefit, index) => (
                      <p key={index}>{benefit}</p>
                    ))}
                  </div>
                </Drawer>
              </>
            )} */}
          {/* {programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' &&
            Mobile && (
              <>
                <button
                  className='Benefits-button'
                  style={{ background: `${tiers[0].color}` }}
                  onClick={toggleDrawer1}>
                  {barTiers[0]?.name}
                </button>
                <Drawer
                  open={Drawer1IsOpen}
                  onClose={toggleDrawer1}
                  direction='top'
                  style={{ marginTop: '1rem' }}
                  className='Benefits-Drawer'>
                  <div className='Benefits-Drawer1'>{barTiers[0]?.apply}</div>
                  <div
                    style={{
                      transform: 'translateY(100%)',
                      display: 'block',
                      width: '100%',
                      height: '100px',
                      justifyContent: 'left',
                    }}>
                    {barTiers[0]?.benefits.map((benefit, index) => (
                      <p
                        key={index}
                        style={{ display: 'block', fontSize: '0.83rem' }}>
                        {benefit}
                      </p>
                    ))}
                  </div>
                </Drawer>
                <button
                  className='Benefits-button'
                  style={{ background: `${tiers[1].color}` }}
                  onClick={toggleDrawer2}>
                  {barTiers[1]?.name}
                </button>
                <Drawer
                  open={Drawer2IsOpen}
                  onClose={toggleDrawer2}
                  direction='left'
                  style={{
                    paddingLeft: '1rem',
                    width: '75vw',
                    overflow: 'scroll',
                    paddingTop: '1.5rem',
                  }}
                  className='Benefits-Drawer'>
                  <div
                    style={{
                      transform: 'translateY(30%)',
                      display: 'block',
                      textAlign: 'left',
                      overflow: 'scroll',
                    }}>
                    {barTiers[1]?.benefits.map((benefit, index) => (
                      <p
                        style={{
                          margin: '0',
                          lineHeight: '2rem',
                          fontSize: '0.83rem',
                        }}
                        key={index}>
                        {benefit}
                      </p>
                    ))}
                  </div>
                </Drawer>
                <button
                  className='Benefits-button'
                  style={{ background: `${tiers[2].color}` }}
                  onClick={toggleDrawer3}>
                  {barTiers[2]?.name}
                </button>
                <Drawer
                  open={Drawer3IsOpen}
                  onClose={toggleDrawer3}
                  direction='bottom'
                  style={{
                    height: '60.5vh',
                    paddingTop: '2rem',
                    overflow: 'scroll',
                  }}
                  className='Benefits-Drawer'>
                  <div
                    style={{
                      transform: 'translateY(0%)',
                      fontSize: '0.83rem',
                      margin: '0',
                      lineheight: '0.5rem',
                      padding: '0',
                    }}>
                    {barTiers[2]?.benefits.map((benefit, index) => (
                      <p>{benefit}</p>
                    ))}
                  </div>
                </Drawer>
                <button
                  className='Benefits-button'
                  style={{ background: `${tiers[3].color}` }}
                  onClick={toggleDrawer4}>
                  {barTiers[3]?.name}
                </button>
                <Drawer
                  open={Drawer4IsOpen}
                  onClose={toggleDrawer4}
                  direction='right'
                  style={{
                    width: '80vw',
                    paddingLeft: '1rem',

                    marginTop: '7rem',
                    textAlign: 'left',
                    overflowY: 'scroll',
                  }}
                  className='Benefits-Drawer'>
                  <div style={{ display: 'block', overflowY: 'scroll' }}>
                    {barTiers[3]?.benefits.map((benefit, index) => (
                      <p
                        style={{
                          fontSize: '0.83rem',
                          marginBottom: '0rem',
                          lineHeight: '2rem',
                        }}
                        key={index}>
                        {benefit}
                      </p>
                    ))}
                  </div>
                </Drawer>
              </>
            )} */}
        </div>
        {/* {programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' && (
          <table className='Benefits-tableContainer'>
            <thead>
              <th>
                <td>{Ixian[0].title}</td>
              </th>
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  style={{
                    fontWeight: '600',
                    textAlign: 'center',
                    width: '40%',
                  }}>
                  Benefits at a glance
                </td>
                {Ixian.map((e, index) => (
                  <td
                    key={index}
                    className='Benefits-Tier'
                    style={{
                      textAlign: 'center',
                      fontWeight: '600',
                      width: '40%',
                    }}>
                    {e.Name}
                  </td>
                ))}
              </tr>
              {fullBenefitsList.map((benefit, index) => (
                <tr id='Benefits-benefits' key={index}>
                  <td>{benefit}</td>
                  <td style={{ textAlign: 'center' }}>{getBulletPoint()}</td>
                  <td style={{ textAlign: 'center' }}>{getBulletPoint()}</td>
                  <td style={{ textAlign: 'center' }}>{getBulletPoint()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <table className='Benefits-tableContainer'>
          <thead>
            <th
              style={{
                display: 'block',
                justifyContent: 'center',
                width: '100%',
              }}>
              <td className='Benefits-title'>Ixian Grand Club</td>
            </th>
          </thead>

          <tbody>
            <tr className='Benefits-headings'>
              <td
                style={{
                  fontWeight: '600',
                  textAlign: 'center',
                  width: '40%',
                }}>
                Benefits at a glance
              </td>
              <td
                style={{
                  background: `${tiers[0].color}`,
                  fontWeight: '600',
                  textAlign: 'center',
                  className: 'Benefits-Tier',
                }}>
                Level 1
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  fontWeight: '600',
                  textAlign: 'center',
                  className: 'Benefits-Tier',
                }}>
                Level 2
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,

                  textAlign: 'center',
                  className: 'Benefits-Tier',
                  color: 'white',
                }}>
                Level 3
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Spend 1€, get 1 loyalty point
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                {' '}
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                {' '}
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Discount for direct bookings
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                5%
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                7%
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                10%
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                A bottle of wine, water and a fruit basket in room upon arrival
                (applies only for online bookings via atlanticahotels.com)
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Late check-out until 14.00 (<i>subject to availability</i>)
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                One free a la Carte meal* during stay in the Hotel (
                <i>
                  Applies for Atlantica Hotels which operate A La Carte
                  Restaurants
                </i>
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Late check-out until 18.00 (subject to availability)
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Room Upgrade (subject to availability)
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                  color: 'white',
                }}>
                <VscCircleFilled />
              </td>
            </tr>
          </tbody>
        </table>
        ) */}
        {(programLabel === ALT.PROD || programLabel === ALT.INTG) && (
          <table className='Benefits-tableContainer'>
            <thead>
              {/* <th
                style={{
                  display: 'block',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                <td className='Benefits-title'>Atlantica Loyalty Club</td>
              </th> */}
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  id='Benefits-Tier'
                  style={{
                    fontWeight: '600',
                    textAlign: 'center',
                    width: '40%',
                  }}>
                  Benefits at a glance
                </td>

                <td
                  id='Benefits-Tier'
                  style={{
                    // background: `${tiers[0].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Level 1
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    // background: `${tiers[1].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Level 2
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    // background: `${tiers[2].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Level 3
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Spend 1€, get 1 loyalty point</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>Discount for direct bookings</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  7%
                </td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                  A bottle of wine, water and a fruit basket in room upon
                  arrival (applies only for online bookings via
                  atlanticahotels.com)
                </td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                  Late check-out until 14.00 (<i>subject to availability</i>)
                </td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                    // color: 'white',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                  One free a la Carte meal* during stay in the Hotel (
                  <i>
                    Applies for Atlantica Hotels which operate A La Carte
                    Restaurants
                  </i>
                </td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                  Late check-out until 18.00 (subject to availability)
                </td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>
                  Room Upgrade (subject to availability)
                </td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {(programLabel === IXI.PROD || programLabel === IXI.INTG) && (
          <table className='Benefits-tableContainer'>
            <thead style={{ width: '100%', borderCollapse: 'collapse' }}>
              {/* <th
                style={{
                  display: 'block',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                <td className='Benefits-title'>Ixian Grand Loyalty Club</td>
              </th> */}
            </thead>
            <tbody>
              <tr className='Benefits-headings'>
                <td
                  id='Benefits-Tier'
                  style={{
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Benefits at a glance
                </td>

                <td
                  id='Benefits-Tier'
                  style={{
                    // background: `${tiers[0].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  White
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    // background: `${tiers[1].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Blue
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    // background: `${tiers[2].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Silver
                </td>
                <td
                  id='Benefits-Tier'
                  style={{
                    // background: `${tiers[3].color}`,
                    fontWeight: '600',
                    textAlign: 'center',
                  }}>
                  Gold
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[0]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  {' '}
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[1]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[2]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[3]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  15%
                </td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  15%
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[4]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  {' '}
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[5]}</td>
                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[6]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  5%
                </td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  10%
                </td>
              </tr>

              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[7]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}>
                  {' '}
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[8]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[9]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[10]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[11]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[12]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[13]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[14]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[15]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
              <tr>
                <td id='Benefits-benefits'>{fullBenefitsList[16]}</td>

                <td
                  style={{
                    // background: `${tiers[0].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[1].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[2].color}`,
                    textAlign: 'center',
                  }}></td>
                <td
                  style={{
                    // background: `${tiers[3].color}`,
                    textAlign: 'center',
                  }}>
                  <VscCircleFilled />
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {/* Ixian Table Values  below*/}
        {/* {programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' && Tablet && (
        <table className='Benefits-tableContainer'>
          <thead>
            <th>
              <td></td>
            </th>
            <th>
              <td></td>
            </th>
            <th>
              <td
                style={{
                  textAlign: 'center',
                  transform: 'translateX(-90%)',
                }}>
                Atlantica Loyalty Circle
              </td>
            </th>
            <th>
              <td></td>
            </th>
          </thead>
          <tbody style={{ border: '1px solid black' }}>
            <tr style={{ borderBottom: '1px solid black' }}>
              <td></td>

              <td></td>
              <td></td>
              <td
                style={{
                  fontWeight: '600',
                  textAlign: 'right',
                  transform: 'translateX(-35%)',
                }}>
                Tier
              </td>

              <td></td>
              <td></td>
            </tr>
            <tr
              className='Benefits-headings'
              style={{ borderBottom: '1px solid black' }}>
              <td
                style={{
                  fontWeight: '600',
                  textAlign: 'center',
                  width: '40%',
                }}>
                Benefits at a glance
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  fontWeight: '600',
                  textAlign: 'center',
                  className: 'Benefits-Tier',
                }}>
                White
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  fontWeight: '600',
                  textAlign: 'center',
                  className: 'Benefits-Tier',
                }}>
                Silver
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  fontWeight: '600',
                  textAlign: 'center',
                  className: 'Benefits-Tier',
                }}>
                Gold
              </td>
              <td
                style={{
                  fontWeight: '600',
                  textAlign: 'center',
                  className: 'Benefits-Tier',
                }}>
                Diamond
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Discount for direct bookings
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                5%
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                6%
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                8%
              </td>
              <td style={{ textAlign: 'center' }}>10%</td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>Discount for spa treatments</td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                5%
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                7%
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                10%
              </td>
              <td style={{ textAlign: 'center' }}>15%</td>
            </tr>
            <tr>
              <td id='Benefits-benefits'> In room welcome offer</td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td style={{ textAlign: 'center' }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>Exclusive Offers</td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td style={{ textAlign: 'center' }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>Member Exclusive Rates</td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td style={{ textAlign: 'center' }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Special farewell gift according to tier
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td style={{ textAlign: 'center' }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Best room available in the booked category
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td style={{ textAlign: 'center' }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Early Check-in (on request, upon availability)
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td style={{ textAlign: 'center' }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Free nights awards (no blackout dates)
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td style={{ textAlign: 'center' }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Free use of the wet spa area
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                once per stay
              </td>
              <td style={{ textAlign: 'center' }}>unlimited</td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>
                Late check out (on request, upon availability)
              </td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td style={{ textAlign: 'center' }}>
                <VscCircleFilled />
              </td>
            </tr>
            <tr>
              <td id='Benefits-benefits'>Turn Down Service</td>

              <td
                style={{
                  background: `${tiers[0].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[1].color}`,
                  textAlign: 'center',
                }}></td>
              <td
                style={{
                  background: `${tiers[2].color}`,
                  textAlign: 'center',
                }}>
                <VscCircleFilled />
              </td>
              <td style={{ textAlign: 'center' }}>
                <VscCircleFilled />
              </td>
            </tr>
          </tbody>
        </table>
              )} */}
      </div>
    </>
  );
}
