///////////////////////////////////////////////////////////////////////////////
// Determine the accessible color of text
///////////////////////////////////////////////////////////////////////////////
export const getAccessibleColor = (hex) => {
  let color = hex.replace(/#/g, '');
  // if shorthand notation is passed in
  if (color.length !== 6) {
    color = `${color}${color}`;
  }
  // rgb values
  var r = parseInt(color.substr(0, 2), 16);
  var g = parseInt(color.substr(2, 2), 16);
  var b = parseInt(color.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000' : '#FFFFFF';
};

///////////////////////////////////////////////////////////////////////////////
// Change hex color into RGB
///////////////////////////////////////////////////////////////////////////////
export const getRGBColor = (hex, type) => {
  let color = hex.replace(/#/g, '');
  // if shorthand notation is passed in
  if (color.length !== 6) {
    color = `${color}${color}`;
  }
  // rgb values
  var r = parseInt(color.substr(0, 2), 16);
  var g = parseInt(color.substr(2, 2), 16);
  var b = parseInt(color.substr(4, 2), 16);

  return `--color-${type}: ${r}, ${g}, ${b};`;
};

///////////////////////////////////////////////////////////////////////////////
// Hex To Rgb
///////////////////////////////////////////////////////////////////////////////
export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

///////////////////////////////////////////////////////////////////////////////
// Component to Hex
///////////////////////////////////////////////////////////////////////////////
export function componentToHex(c) {
  var hex = c?.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

///////////////////////////////////////////////////////////////////////////////
// Change RGB color into Hex
///////////////////////////////////////////////////////////////////////////////
export function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
