import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
  useLayoutEffect,
} from 'react';
import VerityLogo from '../../Assets/VerityLite/VerityGuestLogo_white.png';
import CheckBoxVerityLite from '../Components/Checkbox/CheckboxVerityLite';
import { NavLink, useNavigate } from 'react-router-dom';
import { RiEyeLine } from 'react-icons/ri';
import DontShow from '../../Icons/DontShow';
import Validate from '../Components/SignUpForm/validate';
import useForm from '../Components/SignUpForm/useForm';
import toast, { Toaster, useToaster } from 'react-hot-toast';
import Exclamation from '../../Icons/Exclamation';
import { useMediaQuery } from 'react-responsive';
import { ThemeDataContext } from '../../ContextProviders/theme';
import Danger from '../../Icons/Danger';
import { ToastContainer, toast as toastify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Zoom } from 'react-toastify';
import LargeX from '../../Icons/LargeX';
import Arrow from '../../Icons/Arrow';

const SignUp = () => {
  const Lg = useMediaQuery({
    query: '(max-width: 1200px) ',
  });
  const {
    handleChange,
    // handleDateChange,
    handleSubmit,
    isSubmitting,
    setIsSubmitting,
    values,
    errors,
    responseReceived,
    setErrors,
    authErrors,
    setAuthErrors,
    isDisabled,
    enableComponents,
  } = useForm(Validate);

  const programLabel = localStorage.getItem('programLabel');
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const { termsFileUri } = portalInfo || {};

  const style = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };

  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const { toasts } = useToaster();

  const handleClickArrow = () => {
    navigate(-1);
  };

  const notifyError = (err) =>
    toast(
      <div
        style={{
          display: 'flex',
          width: '100%',
          borderRadius: '7px',
          fontFamily: 'Lato',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
          background: 'transparent',
          color: `black`,
        }}>
        <div
          style={{
            display: 'flex',
            order: '1',
            alignItems: 'center',
            width: '20%',
            transform: 'translateX(-10%)',
          }}>
          <Exclamation
            width='50'
            height='50'
            viewBox='-4 1 25 15'
            color='red'
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            order: '2',
            width: '80%',
            transform: 'translateX(0%)',
          }}>
          {err}
        </div>
      </div>,
      {
        style: {
          borderLeft: '6.5px solid red',
          maxWidth: '400px',
        },
        duration: '60000',

        position: 'top-center',
      }
    );

  const TOAST_LIMIT = 1;

  const buttonRef = useRef();

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  useEffect(() => {
    if (errors.passwordComposition) {
      notifyError(errors.passwordComposition);
      setErrors({
        ...errors,
        passwordComposition: '',
      });
      setIsSubmitting(false);

      buttonRef.current.disabled = true;
      setTimeout(() => (buttonRef.current.disabled = false), 3000);
    }
  }, [errors, notifyError, setErrors, isSubmitting, setIsSubmitting]);

  useEffect(() => {
    if (authErrors.length != 0) {
      notifyError(authErrors);
      setIsSubmitting(false);
      console.log(isSubmitting, 'isSubmitting');
      setAuthErrors([]);
      buttonRef.current.disabled = true;
      setTimeout(() => (buttonRef.current.disabled = false), 3000);
    }
  }, [
    authErrors,
    notifyError,
    setAuthErrors,
    buttonRef,
    isSubmitting,
    setIsSubmitting,
  ]);

  const handleDismiss = () => {
    toasts.forEach((t) => toast.dismiss(t.id));
  };

  useEffect(() => {
    toast.remove();
  }, []);

  const notifyRegistrationSuccess = () =>
    toastify(
      <div className='w-full p-[5%] bg-no-repeat bg-center bg-opacity-50 bg-[length:50px_50px] rounded-[5px] '>
        <h1 className='font-roboto text-skin-primary font-bold text-xl'>
          Thank you for signing up!
        </h1>
        <br />
        <p className='font-roboto'>
          You will receive a confirmation email shortly. Please follow the steps
          in the email to confirm your email address.
        </p>
        <p className='font-roboto'>
          Please be sure to check your Spam and Junk folder.
        </p>
        <br />
        <div className='flex w-full justify-center h-12'>
          <button
            className='w-[330px] text-roboto text-center text-xl transition ease-in hover:-translate-y-[2px] duration-300 font-bold text-skin-primary leading-11 bg-skin-secondary rounded-5 py-1'
            type='submit'
            onClick={() => navigate('/signIn')}>
            Login
          </button>
        </div>
        <LargeX
          className={'absolute top-4 right-4'}
          color='#909090'
          width='33'
          height='33'
        />
      </div>,
      { containerId: 'registrationSuccess' },
      {
        className: '',
      }
    );

  useEffect(() => {
    if (responseReceived === '200') {
      notifyRegistrationSuccess();
      const enable = setTimeout(() => enableComponents(), 10000);
      const redirect = setTimeout(() => navigate('/signIn'), 5000);
      return clearTimeout(enable) && clearTimeout(redirect);
    }
  }, [responseReceived]);

  if (Lg)
    return (
      <div className='flex flex-col justify-center items-center w-screen h-auto bg-skin-primary pt-[8.5rem] overflow-x-hidden'>
        <br />
        <div onClick={handleDismiss}>
          <Toaster
            position='top-center'
            containerClassName='signUpToaster'
            containerStyle={{
              top: 10,
              left: 10,
              bottom: 10,
              right: 10,
            }}
          />
        </div>

        <div className='block w-[75%] min-w-[275px] absolute top-12 h-10 my-0 mx-auto'>
          <p className='w-full h-5 absolute top-[90%] font-medium text-lg md:text-xl leading-[21px] font-roboto text-skin-a11y'>
            Create Account
          </p>
        </div>
        <form
          className='block w-[75%] h-auto my-0 mx-auto'
          onSubmit={handleSubmit}>
          <div onClick={handleClickArrow}>
            <Arrow
              className={`absolute top-6 left-5`}
              width='25'
              height='24'
              color='#FFF'
            />
          </div>
          <div className='block w-full min-w-[275px] h-auto font-roboto'>
            <div className='relative block w-full h-[105px]'>
              <label
                htmlFor='vlite-email'
                className=' font-roboto text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem]'>
                Email
              </label>
              <input
                id='vlite-email'
                name='email'
                disabled={isDisabled}
                style={{ position: 'relative' }}
                className={`block w-full min-w-[275px] h-[41px] bg-transparent rounded-[5px] ${
                  errors.email ? 'border-[#D80027]' : ' border-skin-secondary'
                } indent-[0.5rem] border-solid  border-[1px]  text-skin-a11y font-light text-base leading-[19px] mt-[0.2rem]`}
                value={values.email}
                onChange={handleChange}
              />
              {errors.email && (
                <Danger
                  color='#D80027'
                  width='25'
                  height='24'
                  className={`absolute right-[3%] top-[40%]`}
                />
              )}
              {errors.email && (
                <p
                  role='alert'
                  className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] py-[0.5%] mt-1'>
                  {errors.email}
                </p>
              )}
            </div>
            <div className='relative block w-full min-w-[275px] h-[105px] font-roboto'>
              <label
                htmlFor='vlite-firstname'
                className='font-roboto text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem]'>
                First Name
              </label>
              <input
                input='vlite-firstname'
                name='firstname'
                disabled={isDisabled}
                className={`w-full min-w-[275px] h-[41px] bg-transparent rounded-[5px] ${
                  errors.firstname
                    ? 'border-[#D80027]'
                    : ' border-skin-secondary'
                } indent-[0.5rem] border-solid  border-[1px] font-roboto text-skin-a11y font-light text-base leading-[19px] mt-[0.2rem]`}
                value={values.firstname}
                onChange={handleChange}
              />
              {errors.firstname && (
                <Danger
                  color='#D80027'
                  width='25'
                  height='24'
                  className={`absolute right-[3%] top-[40%]`}
                />
              )}
              {errors.firstname && (
                <p
                  role='alert'
                  className='font-roboto text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] py-[1%] mt-1'>
                  {errors.firstname}
                </p>
              )}
            </div>
            <div className='relative block w-full min-w-[275px] h-[105px] font-roboto'>
              <label
                htmlFor='vlite-lastname'
                className='font-roboto text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem]'>
                Last Name
              </label>
              <input
                id='vlite-lastname'
                name='lastname'
                disabled={isDisabled}
                className={`w-full min-w-[275px] h-[41px] bg-transparent rounded-[5px] ${
                  errors.lastname ? 'border-[#D80027]' : 'border-skin-secondary'
                } indent-[0.5rem] border-solid  border-[1px]  text-skin-a11y font-light text-base leading-[19px] mt-[0.2rem]`}
                value={values.lastname}
                onChange={handleChange}
              />
              {errors.lastname && (
                <Danger
                  color='#D80027'
                  width='25'
                  height='24'
                  className={`absolute right-[3%] top-[40%]`}
                />
              )}
              {errors.lastname && (
                <p
                  role='alert'
                  className=' font-roboto text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                  {errors.lastname}
                </p>
              )}
            </div>
            <div className='relative block w-full min-w-[275px] h-[105px] mt-2'>
              <label
                htmlFor='vlite-password'
                disabled={isDisabled}
                className='font-roboto text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem]'>
                Password
              </label>
              <input
                id='vlite-password'
                name='password'
                disabled={isDisabled}
                type={passwordShown ? 'text' : 'password'}
                className={`w-full min-w-[275px] h-[41px] bg-transparent rounded-[5px]  ${
                  errors.password
                    ? 'border-[#D80027]'
                    : ' border-skin-secondary'
                } indent-[0.5rem] border-solid  border-[1px] font-roboto text-skin-a11y font-light text-base leading-[19px] mt-[0.2rem]`}
                value={values.password}
                onChange={handleChange}
              />
              {passwordShown && !errors.password && (
                <DontShow
                  color={buttonBackgroundColor}
                  className={`absolute right-[3%] top-[42%]`}
                  onClick={togglePassword}
                />
              )}
              {!passwordShown && !errors.password && (
                <RiEyeLine
                  fill={buttonBackgroundColor}
                  size='21'
                  className={`absolute right-[3%] top-[42%]`}
                  onClick={togglePassword}
                />
              )}
              {errors.password && (
                <Danger
                  color='#D80027'
                  width='25'
                  height='24'
                  className={`absolute right-[3%] top-[40%]`}
                />
              )}
              {errors.password && (
                <p
                  role='alert'
                  className='font-roboto text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                  {errors.password}
                </p>
              )}
            </div>
            <div className='relative block w-full min-w-[275px] h-[105px] mt-2'>
              <label
                htmlFor='vlite-confirmPassword'
                className='font-roboto text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem]'>
                Confirm Password
              </label>
              <input
                id='vlite-confirmPassword'
                name='confirmPassword'
                disabled={isDisabled}
                type={confirmPasswordShown ? 'text' : 'password'}
                className={`w-full min-w-[275px] h-[41px] bg-transparent rounded-[5px] ${
                  errors.confirmPassword
                    ? 'border-[#D80027]'
                    : ' border-skin-secondary'
                } indent-[0.5rem] border-solid  border-[1px] font-roboto text-skin-a11y font-light text-base leading-[19px] mt-[0.2rem]`}
                value={values.confirmPassword}
                onChange={handleChange}
              />
              {confirmPasswordShown && !errors.confirmPassword && (
                <DontShow
                  color={buttonBackgroundColor}
                  className={`absolute right-[3%] top-[42%]`}
                  onClick={toggleConfirmPassword}
                />
              )}
              {!confirmPasswordShown && !errors.confirmPassword && (
               <RiEyeLine
                  fill={buttonBackgroundColor}
                  size='21'
                  className={`absolute right-[3%] top-[42%]`}
                  onClick={toggleConfirmPassword}
                />
              )}
              {errors.confirmPassword && (
                <Danger
                  color='#D80027'
                  width='25'
                  height='24'
                  className={`absolute right-[3%] top-[40%]`}
                />
              )}
              {errors.confirmPassword && (
                <p
                  role='alert'
                  className='font-roboto text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                  {errors.confirmPassword}
                </p>
              )}
            </div>
          </div>
          <div className={`flex mt-4`}>
            <label
              htmlFor='vlite-promo'
              className='font-roboto text-skin-secondary  h-[19px] text-[14px] 900:text-base font-light 900:font-normal leading-[19px] order-2  ml-2 whitespace-normal'>
              I would like to receive exclusive member offers, account updates,
              program and hotel news via email
            </label>
            <CheckBoxVerityLite
              id='vlite-promo'
              name='promotionalMailAccepted'
              type='checkbox'
              checked={values.promotionalMailAccepted}
              className='checkboxVerityLite order-1 mt-0.5 flex-shrink-0'
              required={false}
              disabled={isDisabled}
              onChange={handleChange}
            />
          </div>
          {/* <br />
          <br /> */}
          {/* <div className='w-full h-7 text-center'>
            <label
              htmlFor='terms'
              className='text-skin-secondary 900:text-base leading-[22px] font-normal font-roboto '>
              By clicking Register, you agree to our{' '}
              <a
                // href={termsFileUri}
                target='_blank'
                rel='noreferrer noopener'
                className='text-skin-secondary text-base leading-[22px] font-bold font-roboto no-underline'>
                Terms of Use
              </a>
            </label>
          </div> */}
          <br />
          <div className='flex w-full justify-center h-12 mt-5 sm:mt-3'>
            <button
              disabled={isDisabled}
              ref={buttonRef}
              className='w-[330px] font-roboto text-center text-base 900:text-xl transition ease-in hover:-translate-y-[2px] duration-300 font-bold text-skin-primary leading-11 bg-skin-secondary rounded-5 py-1'
              type='submit'>
              Join now
            </button>
          </div>
        </form>
        <br />
        <div className='flex w-full h-7 justify-center'>
          <p className='font-roboto font-bold text-base 900:text-lg leading-[18.5px] 900:leading-[21px] text-skin-a11y'>
            Already a member? {''}
            <NavLink
              className='font-roboto font-bold text-base 900:text-lg leading-[18.5px] 900:leading-[21px] text-skin-secondary no-underline'
              to='/signIn'>
              Sign in here
            </NavLink>
          </p>
        </div>
        <br />
        <div className='w-full h-auto mt-1 mb-4'>
          <p className='text-skin-a11y font-roboto font-normal leading[14px] 900:leading-[21px] text-[12px] 900:text-lg w-full text-center translate-y-[2rem]'>
            Powered by
          </p>
          <div
            style={{
              backgroundImage: `url(${VerityLogo})`,
            }}
            className=' w-full h-20  bg-no-repeat bg-center mx-auto z-10 bg-[length:60px_33px]'></div>
        </div>
        <ToastContainer
          enableMultiContainer
          containerId={'registrationSuccess'}
          limit={1}
          hideProgressBar={true}
          transition={Zoom}
          closeButton={false}
          className=''
          autoClose={false}
          style={{
            position: 'absolute',
            top: '38%',
            left: '0%',
            width: '500px',
            height: '500px',
            borderRadius: '0',
            margin: '0 auto',
            textAlign: 'center',
          }}
        />
      </div>
    );

  return (
    <div className='w-screen h-auto min-h-screen bg-skin-primary pt-[8.5rem] overflow-scroll'>
      <form
        className='block w-[800px] h-auto my-0 mx-auto'
        onSubmit={handleSubmit}>
        <h2 className='w-full h-50px text-center font-roboto font-normal text-skin-a11y text-[40px] leading-{50} tracking-wide my-0 drop-shadow-3xl'>
          Become our
        </h2>
        <h2 className='w-full text-skin-secondary h-50px text-center font-roboto font-medium text-[40px] leading-{50} tracking-wide my-0 drop-shadow-3xl'>
          Loyalty Club Member
        </h2>
        <br />
        <div onClick={handleDismiss}>
          <Toaster
            position='top-center'
            containerClassName='signUpToaster'
            containerStyle={{
              top: 10,
              left: 10,
              bottom: 10,
              right: 10,
            }}
          />
        </div>

        <div className='block w-full h-[300px] px-[4.67rem] font-roboto'>
          <div className='block w-full h-[105px]'>
            <label
              htmlFor='vlite-email'
              className=' font-roboto text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem]'>
              Email
            </label>
            <input
              id='vlite-email'
              name='email'
              disabled={isDisabled}
              className={`w-full h-[41px] font-roboto bg-transparent rounded-[5px] ${
                errors.email ? 'border-[#D80027]' : ' border-skin-secondary'
              } indent-[0.5rem] border-solid  border-[1px]  text-skin-a11y font-light text-base leading-[19px] mt-[0.2rem]`}
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && (
              <Danger
                color='#D80027'
                width='25'
                height='24'
                style={{ transform: 'translate(2470%, -145%)' }}
              />
            )}
            {errors.email && (
              <p
                role='alert'
                className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] py-[0.5%] mt-1'>
                {errors.email}
              </p>
            )}
          </div>
          <div className='grid w-full h-48 grid-cols-2 gap-x-10 grid-rows-2'>
            <div className='block w-full h-[105px] font-roboto'>
              <label
                htmlFor='vlite-firstname'
                className='font-roboto text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem]'>
                First Name
              </label>
              <input
                input='vlite-firstname'
                name='firstname'
                disabled={isDisabled}
                className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                  errors.firstname
                    ? 'border-[#D80027]'
                    : ' border-skin-secondary'
                } indent-[0.5rem] border-solid  border-[1px] font-roboto text-skin-a11y font-light text-base leading-[19px] mt-[0.2rem]`}
                value={values.firstname}
                onChange={handleChange}
              />
              {errors.firstname && (
                <Danger
                  color='#D80027'
                  width='25'
                  height='24'
                  style={{ transform: 'translate(1090%, -145%)' }}
                />
              )}
              {errors.firstname && (
                <p
                  role='alert'
                  className='font-roboto text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] py-[1%] mt-1'>
                  {errors.firstname}
                </p>
              )}
            </div>
            <div className='block w-full h-[105px] font-roboto'>
              <label
                htmlFor='vlite-lastname'
                className='font-roboto text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem]'>
                Last Name
              </label>
              <input
                id='vlite-lastname'
                name='lastname'
                disabled={isDisabled}
                className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                  errors.lastname ? 'border-[#D80027]' : 'border-skin-secondary'
                } indent-[0.5rem] border-solid  border-[1px]  text-skin-a11y font-light text-base leading-[19px] mt-[0.2rem]`}
                value={values.lastname}
                onChange={handleChange}
              />
              {errors.lastname && (
                <Danger
                  color='#D80027'
                  width='25'
                  height='24'
                  style={{ transform: 'translate(1090%, -145%)' }}
                />
              )}
              {errors.lastname && (
                <p
                  role='alert'
                  className=' font-roboto text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                  {errors.lastname}
                </p>
              )}
            </div>
            <div className='block w-full h-[105px] mt-2 font-roboto'>
              <label
                htmlFor='vlite-password'
                disabled={isDisabled}
                className='font-roboto text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem]'>
                Password
              </label>
              <input
                id='vlite-password'
                name='password'
                disabled={isDisabled}
                type={passwordShown ? 'text' : 'password'}
                className={`w-full h-[41px] bg-transparent rounded-[5px]  ${
                  errors.password
                    ? 'border-[#D80027]'
                    : ' border-skin-secondary'
                } indent-[0.5rem] border-solid  border-[1px] font-roboto text-skin-a11y font-light text-base leading-[19px] mt-[0.2rem]`}
                value={values.password}
                onChange={handleChange}
              />
              {passwordShown && !errors.password && (
                <DontShow
                  color={buttonBackgroundColor}
                  style={{
                    transform: 'translate(1280%, -157%)',
                  }}
                  onClick={togglePassword}
                />
              )}
              {!passwordShown && !errors.password && (
                <RiEyeLine
                  fill={buttonBackgroundColor}
                  size='21'
                  style={{
                    transform: 'translate(1280%, -157%)',
                  }}
                  onClick={togglePassword}
                />
              )}
              {errors.password && (
                <Danger
                  color='#D80027'
                  width='25'
                  height='24'
                  style={{ transform: 'translate(1090%, -145%)' }}
                />
              )}
              {errors.password && (
                <p
                  role='alert'
                  className='font-roboto text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                  {errors.password}
                </p>
              )}
            </div>
            <div className='block w-full h-[105px] mt-2 font-roboto'>
              <label
                htmlFor='vlite-confirmPassword'
                className='font-roboto text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem]'>
                Confirm Password
              </label>
              <input
                id='vlite-confirmPassword'
                name='confirmPassword'
                disabled={isDisabled}
                type={confirmPasswordShown ? 'text' : 'password'}
                className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                  errors.confirmPassword
                    ? 'border-[#D80027]'
                    : ' border-skin-secondary'
                } indent-[0.5rem] border-solid  border-[1px] font-roboto text-skin-a11y font-light text-base leading-[19px] mt-[0.2rem]`}
                value={values.confirmPassword}
                onChange={handleChange}
              />
              {confirmPasswordShown && !errors.confirmPassword && (
                <DontShow
                  color={buttonBackgroundColor}
                  style={{
                    transform: 'translate(1280%, -157%)',
                  }}
                  onClick={toggleConfirmPassword}
                />
              )}
              {!confirmPasswordShown && !errors.confirmPassword && (
                <RiEyeLine
                  fill={buttonBackgroundColor}
                  size='21'
                  style={{
                    transform: 'translate(1280%, -157%)',
                  }}
                  onClick={toggleConfirmPassword}
                />
              )}
              {errors.confirmPassword && (
                <Danger
                  color='#D80027'
                  width='25'
                  height='24'
                  style={{ transform: 'translate(1090%, -145%)' }}
                />
              )}
              {errors.confirmPassword && (
                <p
                  role='alert'
                  className='font-roboto text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                  {errors.confirmPassword}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className='flex w-full h-7 justify-center mt-8 font-roboto'>
          <div className='flex'>
            <label
              htmlFor='vlite-promo'
              className='font-roboto text-skin-secondary text-[1rem] h-[19px] font-normal leading-[19px] order-2  ml-2 whitespace-nowrap'>
              I would like to receive exclusive member offers, account updates,
              program and hotel news via email
            </label>
            <CheckBoxVerityLite
              id='vlite-promo'
              name='promotionalMailAccepted'
              type='checkbox'
              checked={values.promotionalMailAccepted}
              className='checkboxVerityLite order-1 mt-0.5 flex-shrink-0'
              required={false}
              disabled={isDisabled}
              onChange={handleChange}
            />
          </div>
        </div>
        <br />
        {/* <div className='w-full h-7 text-center font-roboto'>
          <label
            htmlFor='terms'
            className='text-skin-secondary text-base leading-[22px] font-normal font-openSans '>
            By clicking Register, you agree to our{' '}
            <a
              // href={termsFileUri}
              target='_blank'
              rel='noreferrer noopener'
              className='text-skin-secondary text-base leading-[22px] font-bold font-openSans no-underline'>
              Terms of Use
            </a>
          </label>
        </div> */}
        {/* <br /> */}
        <div className='flex w-full justify-center h-12 font-roboto mt-2'>
          <button
            disabled={isDisabled}
            ref={buttonRef}
            className='w-[330px] outline-none font-roboto text-center text-xl transition ease-in hover:-translate-y-[2px] duration-300 font-bold text-skin-primary leading-11 bg-skin-secondary rounded-5 py-1'
            type='submit'>
            Join now
          </button>
        </div>
        <br />
        <div className='flex w-full h-7 justify-center font-roboto'>
          <p className='font-roboto font-bold text-lg leading-[21px] text-skin-a11y'>
            Already a member? {''}
            <NavLink
              className='font-roboto font-bold text-lg leading-[21px] text-skin-secondary no-underline'
              to='/signIn'>
              Sign in here
            </NavLink>
          </p>
        </div>
        <br />
        <div className='w-full h-auto mt-1 font-roboto'>
          <p className='text-skin-a11y font-roboto font-normal leading-[21px] text-lg w-full text-center translate-y-4'>
            Powered by
          </p>
          <div
            style={{
              backgroundImage: `url(${VerityLogo})`,
            }}
            className=' w-full h-20 bg-auto bg-no-repeat bg-center mx-auto z-10'></div>
        </div>
      </form>
      <br />

      <ToastContainer
        enableMultiContainer
        containerId={'registrationSuccess'}
        limit={1}
        hideProgressBar={true}
        transition={Zoom}
        closeButton={false}
        className=''
        autoClose={false}
        style={{
          position: 'absolute',
          top: '38%',
          left: '0%',
          width: '500px',
          height: '500px',
          borderRadius: '0',
          margin: '0 auto',
          textAlign: 'center',
        }}
      />
    </div>
  );
};

export default SignUp;
