import React, { useState } from 'react';
import SignInForm from '../SignInForm/signInForm';
import SignUpForm from '../SignUpForm/signUpForm';
import FormSuccess from '../SignUpForm/formSuccess';

export default function Form({ type, submitForm, isSubmitted }) {
  if (type === 'signIn') {
    return <SignInForm />;
  }
  return (
    <>
      {!isSubmitted ? <SignUpForm submitForm={submitForm} /> : <FormSuccess />}
    </>
  );
}
