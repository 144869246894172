import * as data from '../Assets/clientData.json';

/**
 * Contains methods to get the current client's program label.
 */
export default class ClientSwitch {
  /**
   * Gets the current program label of the client.
   * First, it tries to read it from the domain.
   * If it fails, it tries to read it from the url query.
   * If it fails again, it throws an error.
   * @returns A string that represents the current program label.
   * Throws an error, if the program label is not found.
   */
  static getProgramLabel = () => {
    let programLabel = this.readFromSubdomain();

    if (!programLabel) {
      programLabel = this.readFromQuery();
    }
    if (!programLabel) {
      return(
        <>
          <div>
            <h4>EMPTY</h4>
          </div>
        </>
      )
    }

    return programLabel;
  };

  /**
   * Tries to read the current program label from the subdomain.
   * @returns A string that represents the current program label, if found.
   */
  static readFromSubdomain = () => {
    // Find the subdomain in the url.
    const domain = window.location.host;
    const subdomains = domain.split('.');
    const subdomain = subdomains[1];

    // Try to map it with the clientData.json and return the corresponding
    // program label.
    const jsonStr = JSON.stringify(data);
    const clientData = JSON.parse(jsonStr);

    return clientData[subdomain];
  };

  /**
   * Tries to read the current program label from the url query.
   * @returns A string that represents the current program label, if found.
   */
  static readFromQuery = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('programLabel');
  };
}
