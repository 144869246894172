export function isInTheFuture(date) {
  let date2 = date;
  if (typeof date2 === 'string' || date2 instanceof String) {
    const [day, month, year] = date2.split('/');
    date2 = new Date(+year, +month - 1, +day);
  }

  const today = new Date();

  // 👇️ OPTIONAL!
  // This line sets the time of the current date to the
  // last millisecond, so the comparison returns `true` only if
  // date is at least tomorrow
  today.setHours(23, 59, 59, 998);

  return date2 > today;
}
