export default function Validate(values, birthdate) {
  let errors = {};
  const passwordsMatch = (password, confirmPassword) =>
    password === confirmPassword;

  let emailRegex = /\S+@\S+\.\S+/;
  if (!values.email.trim()) {
    errors.email = 'Email is required';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

 // Regular expression to match names containing characters from various languages
let nameRegex = /^[a-zA-Z\s]+$/;

if (!values.firstname.trim()) {
  errors.firstname = 'First Name is required';
} else if (!nameRegex.test(values.firstname)) {
  errors.firstname = 'First Name is invalid.';
}

if (!values.lastname.trim()) {
  errors.lastname = 'Last Name is required';
} else if (!nameRegex.test(values.lastname)) {
  errors.lastname = 'Last Name is invalid.';
}

  let passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;

  if (!values.password.trim()) {
    errors.password = 'Password is required';
  } else if (!passwordRegex.test(values.password)) {
    errors.passwordComposition =
      'Password must contain at least: 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, 1 symbol.';
  }

  if (!values.confirmPassword.trim()) {
    errors.confirmPassword = 'Password is required';
  } else if (!passwordsMatch(values.password, values.confirmPassword)) {
    errors.confirmPassword = 'Passwords do not match';
  }
  console.log(errors, 'errors');
  return errors;
}

// const isFormValid = () => {
//   const nameRegex = /^(?=.{1,40}$)[a-zA-Z]+(?:['\s][a-zA-Z]+)*$/;
//   const passRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

//   const error = (message) => {
//     setError(message);
//     return false;
//   };

//   if (!validNames()) {
//     return error(
//       'Names can only contain latin characters, spaces or apostrophes.'
//     );
//   }
//   if (!passwordsMatch()) {
//     return error('Passwords do not match.');
//   }
//   if (!password.match(passRegex)) {
//     return error(
//       'Invalid password. Please, refer to the instructions bellow ' +
//         'for valid password input.'
//     );
//   }
//   return true;
// };
