import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectToOverview() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/home/overview');
  });

  return null;
}

export default RedirectToOverview;
