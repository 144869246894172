import React, { useContext, useEffect, useState } from 'react';
import { ThemeDataContext } from '../../ContextProviders/theme';
import './Meter.css';
import { ColorLogic } from '../../Container/colourLogic.js';
import { useMediaQuery } from 'react-responsive';
import {
  AiOutlineCaretDown,
  AiOutlineDown,
  AiOutlineCaretUp,
} from 'react-icons/ai';
import { GiDiamondTrophy } from 'react-icons/gi';
import { Loader } from 'rsuite';
import Spinner from '../../Components/Spinner/Spinner';

const Meter = (props) => {
  const theme = useContext(ThemeDataContext);
  const { tierCount, tiers } = theme || {};
  console.log(tiers, 'tiers');

  const [barTiers, setBarTiers] = useState([]);
  const [pointsCurrent, setPointsCurrent] = useState(0);
  const [pointsRemaining, setPointsRemaining] = useState(0);
  const [pointsPerc, setPointsPerc] = useState(0);
  const [tierPointDescr, setTierPointDescr] = useState('');
  const [isLoading, SetIsLoading] = useState(true);

  const programLabel = localStorage.getItem('programLabel');

  const Tablet = useMediaQuery({
    query: '(min-width: 768px) ',
  });

  const Mobile = useMediaQuery({
    query: '(min-width: 280px) and (max-width: 767px)',
  });

  useEffect(() => {
    if (tiers) {
      SetIsLoading(false);
    }
  }, [tiers, SetIsLoading]);

  useEffect(() => {
    // const { tiers } = theme || { tiers: null };
    // const { tiers } = theme || {};

    const bTiers =
      tiers &&
      tiers
        .filter((tier) => tier?.barPercentage > 0)
        .map((tier) => ({
          width: tier?.barPercentage,
          color: tier?.color,
          description: tier?.description,
          value: tier?.thresholds[0].value,
          nextTier: tier?.nextTier,
          tierPointDescr: tier?.tierPointDescr,
        }));

    setBarTiers(bTiers);

    if (theme) {
      const max = theme.maxPoints;

      const info = JSON.parse(localStorage['userInfo']);

      let points = info?.memberInfo?.memberTierPoints;
      console.log(points, 'points');
      console.log(info?.memberInfo?.nextThresh, 'nextThresh');
      points = points > max ? max : points;

      const perc = (100 * points) / max;
      setPointsCurrent(points);
      setPointsPerc(perc);
      setPointsRemaining(info?.memberInfo?.nextThresh - points);
    }

    if (tiers) {
      setTierPointDescr(tiers[0]?.tierPointDescr);
    }
  }, [
    theme,
    setTierPointDescr,
    setBarTiers,
    setPointsCurrent,
    setPointsPerc,
    setPointsRemaining,
    tiers,
  ]);

  const {
    percent = 0, // number: 0 - 1, inclusive. Fill %
    height = 54, // the height of our meter
    rounded = false, // if true, use rounded corners
    animate = true, // if true, animate
    label = null, // a label for accessibility
  } = props;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const getTierLabel = (value, nextTier) => {
    if (
      (programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
        'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B') &&
      value === 0 &&
      nextTier
    ) {
      return `0 - ${nextTier - 1} nights`;
    } else if (value === 0 && nextTier) {
      return `up to ${nextTier - 1} nights`;
    }

    return nextTier
      ? `${value} - ${nextTier - 1} nights`
      : `${value} nights or more`;
  };

  const getProgressClassName = () =>
    pointsRemaining > 0 ? '' : 'noPointsRemaining';

  const getPoints = () =>
    tierPointDescr === 'points'
      ? `${numberWithCommas(pointsCurrent)} Tier ${tierPointDescr}`
      : `${pointsCurrent} Tier ${tierPointDescr}`;

  const getPointsRemaining = () =>
    tierPointDescr === 'points'
      ? `${numberWithCommas(pointsRemaining)} ${tierPointDescr}`
      : `${pointsRemaining} ${tierPointDescr}`;

  console.log(pointsRemaining, 'pointsRemaining');

  console.log(theme, 'theme');
  console.log(tiers, 'tiers');

  return isLoading ? (
    <>
      <Spinner />
    </>
  ) : (
    <>
      <div className='meterContainer'>
        <div
          id='progress'
          className={getProgressClassName()}
          style={{ left: `${pointsPerc}%` }}>
          <div id='progress-text'>{getPoints()}</div>
          <div id='progress-text'>
            {pointsRemaining <= 0
              ? ''
              : `${getPointsRemaining()} left for next Level`}
          </div>
          <div id='progress-pointer'>
            <AiOutlineCaretDown fill='#585955' />
          </div>
        </div>
        {barTiers !== undefined &&
          barTiers !== null &&
          barTiers.map((tier, index) => (
            <svg
               style={{ border: '1px solid gray' }}
              className='progress-svg'
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              key={index}
              width={`${tier.width}%`}
              height={height}
              aria-label={label}
              fill={ColorLogic.isDark(tier.color) ? 'white' : 'black'}>
              <g>
                <rect width={`100%`} height={height} fill={tier.color} />
                {(programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
                  'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B') &&
                  Tablet &&
                  tier.width > 5 && (
                    <image
                      href={
                        ColorLogic.isDark(tier.color)
                          ? require('../../Assets/Atlantica/Images/Keys/beigeKeyReSized.png')
                          : require('../../Assets/Atlantica/Images/Keys/blackKeyReSized.png')
                      }
                      x='2%'
                      y='37%'
                      height='16px'
                      width='40px'
                    />
                  )}
                {(programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
                  'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B') &&
                  Mobile &&
                  tier.width > 10 && (
                    <image
                      href={
                        ColorLogic.isDark(tier.color)
                          ? require('../../Assets/Atlantica/Images/Keys/beigeKeyReSized.png')
                          : require('../../Assets/Atlantica/Images/Keys/blackKeyReSized.png')
                      }
                      x='2%'
                      y='37%'
                      height='20px'
                      width='42px'
                    />
                  )}
                {(programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
                  'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B') &&
                Tablet &&
                tier.width > 5 ? (
                  <text text-anchor='middle' dominant-baseline='middle'>
                    <tspan x='55%' y='27%'>
                      {tier.description}
                    </tspan>
                    <tspan x='55%' y='75%' style={{ fontSize: '0.8rem' }}>
                      {getTierLabel(tier.value, tier.nextTier)}
                    </tspan>
                  </text>
                ) : (
                  Tablet &&
                  tier.width > 5 && (
                    <text text-anchor='middle' dominant-baseline='middle'>
                      <tspan x='50%' y='25%'>
                        {tier.description}
                      </tspan>
                      <tspan x='50%' y='75%'>
                        {tier.nextTier
                          ? `(${numberWithCommas(
                              tier.value
                            )} - ${numberWithCommas(tier.nextTier - 1)} points)`
                          : `(${numberWithCommas(tier.value)}+ points)`}
                      </tspan>
                    </text>
                  )
                )}

                {Mobile && tier.width > 10 && (
                  <text
                    x={
                      programLabel ===
                        'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
                      'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B'
                        ? '70%'
                        : '50%'
                    }
                    y='55%'
                    text-anchor='middle'
                    dominant-baseline='middle'>
                    {''}
                    {`${tier.description} `}
                  </text>

                  /*tier.value*/
                )}
              </g>
            </svg>
          ))}
      </div>

      {(programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
        'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B') &&
      Mobile ? (
        <div>
          <br />
          {/* <div id='progress-pointer'>
            <AiOutlineCaretUp />
          </div> */}

          {barTiers !== undefined &&
            barTiers !== null &&
            barTiers.map((tier, index) => (
              <table>
                <thead>
                  <tr key={index}>
                    <th>{`${tier.description}:`}</th>
                    <th style={{ paddingLeft: '0.35rem' }}>
                      {getTierLabel(tier.value, tier.nextTier)}
                    </th>
                  </tr>
                </thead>
              </table>
            ))}
        </div>
      ) : (
        Mobile && (
          <div>
            <br />
            {/* <div id='progress-pointer'>
            <AiOutlineCaretUp />
          </div> */}

            {barTiers !== undefined &&
              barTiers !== null &&
              barTiers.map((tier, index) => (
                <table>
                  <thead>
                    <tr key={index}>
                      <th>{`${tier.description}:`}</th>
                      <th style={{ paddingLeft: '0.35rem' }}>
                        {tier.nextTier
                          ? `${numberWithCommas(
                              tier.value
                            )} - ${numberWithCommas(tier.nextTier - 1)} points`
                          : `${numberWithCommas(tier.value)}+ points`}
                      </th>
                    </tr>
                  </thead>
                </table>
              ))}
          </div>
        )
      )}
    </>
  );
};
export default Meter;
