import React, { useEffect, useContext, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Header from '../Components/Header';
import { useNavigate, NavLink } from 'react-router-dom';
import Meter from '../Components/Meter/Meter';
import { ThemeDataContext } from '../ContextProviders/theme';
import '../Components/Overview/OverviewAtlantica.css';
import Listings from '../Components/Activity/Listings';
import axios from 'axios';
import { ProgramLabelDataContext } from '../ContextProviders/programLabel';
import { InfoDataContext } from '../ContextProviders/info';
import { format } from 'date-fns';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import SmartGuestNotification from '../Components/SmartGuest/SmartGuestNotification';
import actions from '../Redux/reducers/user/user.actions';
import { useDispatch } from 'react-redux';

const Overview = ({ isOpen, toggle }) => {
  useEffect(() => {
    document.body.style.backgroundColor = 'transparent';
    document.body.style.background = `transparent`;
  }, []);

  const [bookListings, setBookListings] = useState([]);
  const [myCardHtml, setMyCardHtml] = useState('');
  const [expiryDate, setExpiryDate] = useState([]);
  const [position, setPosition] = useState({});
  const [clicked, setClicked] = useState(false);

  const programLabel = localStorage.getItem('programLabel');
  const cardRef = useRef(null);
  const navigate = useNavigate();
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor, buttonColor } = portalInfo || {};
  const [trigger, setTrigger] = useState(true);

  const getBookingListings = () => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/event/getBookings',
      params: {
        acid: sessionStorage.getItem('acid'),
        programLabel,
      },
    })
      .then((resp) => {
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.data.ErrorMessage);
        }
        setBookListings(resp.data.transactions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMyCardHtml = async () => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/member/getCardPage',
      params: {
        memberCode: memberInfo.memberCode,
        firstname: memberInfo.memberFirstName,
        lastname: memberInfo.memberLastName,
        tier: memberInfo.memberTier,
        enrollmentDate:memberInfo.memberEnrollmentDate,
        programLabel,
      },
    })
      .then((resp) => {
        setMyCardHtml(resp.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBookingListings();
    getMyCardHtml();
  }, []);

  const viewMyCard = () => {
    if (myCardHtml) {
      var win = window.open('', '_blank');
      win.document.write(myCardHtml);
    } else {
      win.document.write('An error occured while making your card.');
    }
  };

  const count = 10;
  const cardColor = 'White';
  const basePoints = 0;
  const pointsForNextTier = 1000;

  // GET USER INFO FROM LOCALSTORAGE

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const memberInfo = userInfo.memberInfo;

  const user = useSelector((state) => state.user);

  const { deactivationDate } = useContext(InfoDataContext) || {};

  useEffect(() => {
    if (
      programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ||
      'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B' ||
      'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' ||
      'IXI-INTG-E98241F0-EE89-4B11-8F81-94C0CA0085FA'
    ) {
      setExpiryDate('No expiry');
    } else setExpiryDate(moment(deactivationDate).format('DD/MM/YYYY'), 3000);
  }, [deactivationDate, programLabel]);

  const Tablet = useMediaQuery({
    query: '(min-width: 1023px) ',
  });

  const Mobile = useMediaQuery({
    query: '(min-width: 280px) and (max-width: 1023px)',
  });

  console.log(expiryDate, 'expiryDate');

  const isSidebarVisible = useSelector((state) => state.user.isSidebarVisible);
  const prevPath = useSelector((state) => state.user.prevPath);
  console.log(prevPath);

  // useEffect(() => {
  //   console.log(cardRef.current.getBoundingClientRect(), 'cardRef');
  //   setPosition(cardRef.current.getBoundingClientRect() || {});
  // }, [cardRef]);

  // console.log(position.right, 'right');
  // console.log(position.top, 'top');
  console.log(memberInfo.memberTier, 'memberTier');

  // const notifySmartGuest = () =>
  //   toast(
  //     <div style={{ width: '100%' }}>
  //       <div
  //         style={{
  //           fontSize: '1.2rem',
  //           fontFamily: `'Lato', Arial, sans-serif`,
  //           userSelect: 'none',
  //           color: 'var(--color-accent)',
  //           paddingRight: '0.7rem',
  //         }}>
  //         Please update your Profile infromation
  //       </div>

  //       <br />

  //       <div
  //         style={{
  //           display: 'flex',
  //           justifyContent: 'center',
  //           marginLeft: '0.5rem',
  //         }}>
  //         <button
  //           className='LogoutButton'
  //           onClick={() => navigate('/home/myprofile')}>
  //           UPDATE
  //         </button>
  //         <button className='LogoutButton' onClick={toast.dismiss()}>
  //           NOT NOW
  //         </button>
  //       </div>
  //     </div>,
  //     {
  //       // position: toast.POSITION.TOP_CENTER,
  //       className: 'Logout-Toaster',
  //     }
  //   );

  // useEffect(() => {
  //   if (
  //     memberInfo &&
  //     (memberInfo.memberFirstName === null ||
  //       memberInfo.memberFirstName === 'Verity Test' ||
  //       memberInfo.memberLastName === null ||
  //       memberInfo.memberLastName === 'Verity Test')
  //   ) {
  //     notifySmartGuest();
  //   }
  // }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setPrevPath(window.location.pathname));
  }, []);

  return (
    <>
      <Header toggle={toggle} isOpen={isOpen} />
      <div
        className='overview-Visibility'
        style={{ visibility: `${isSidebarVisible ? 'hidden' : 'visible'}` }}>
        <div className='overview-heading'>
          <h1>Welcome {memberInfo && memberInfo.memberFirstName?.toUpperCase()}!</h1>
          <hr className='overview-line'></hr>
          <SmartGuestNotification
            memberInfo={memberInfo}
            prevPath={prevPath}
            clicked={clicked}
            setClicked={setClicked}
          />
        </div>
        {Mobile && (
          <div className='overview-TopContainer'>
            <div className='overview-Card' >
              <img
                src={
                  `https://verity.blob.core.windows.net/portal/${localStorage['programLabel']}/` +
                  `Cards/${memberInfo.memberTier}_front.png`
                }
                alt='card'
                style={programLabel==='ALD-D6723397-1407-4C60-93BD-386DCE063346'?{borderRadius:'20px'}:{  }}
              />
              <div className='overview-buttonContainer'>
                <button className='overview-button' onClick={viewMyCard}>
                  VIEW MY CARD
                </button>
              </div>
            </div>
            {/* <div className='overview-logo'></div> */}
            <div className='overview-info'>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th className='overview-info-label'>FIRSTNAME:</th>
                      <th className='overview-info-input'>
                        {memberInfo?.memberFirstName?.toUpperCase()}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='overview-info-label'>LASTNAME:</td>
                      <td className='overview-info-input'>
                        {memberInfo?.memberLastName?.toUpperCase()}
                      </td>
                    </tr>
                    <tr>
                      <td className='overview-info-label'>
                        DEACTIVATION DATE:
                      </td>
                      <td className='overview-info-input'>{expiryDate}</td>
                    </tr>
                    <tr>
                      <td className='overview-info-label'>STATUS:</td>
                      <td className='overview-info-input'>
                        {memberInfo && memberInfo.memberTier}
                      </td>
                    </tr>
                    <tr>
                      <td className='overview-info-label'>CARD NUMBER:</td>
                      <td className='overview-info-input'>
                        {memberInfo && memberInfo.memberCode}
                      </td>
                    </tr>
                    <tr>
                      <td className='overview-info-label'>MEMBER SINCE:</td>
                      <td className='overview-info-input'>
                        {memberInfo && memberInfo.memberEnrollmentDate}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {Tablet && (
          <div className={programLabel === '53CE9A36-A9EC-4A7A-97A1-7E76FCFF27F5' ?'overview-TopContainer_Alianth' :'overview-TopContainer'}>
            <div className='overview-Card' ref={cardRef}>
            <div
        className={programLabel === '53CE9A36-A9EC-4A7A-97A1-7E76FCFF27F5' ? 'scaled-down-image' : 'overview-Card'}
      >
        <img
          src={`https://verity.blob.core.windows.net/portal/${localStorage['programLabel']}/Cards/${memberInfo.memberTier}_front.png`}
          alt='card'
          width={
            programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ||
            programLabel === 'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B'
              ? '375'
              : '352'
          }
          style={
            programLabel === 'ALD-D6723397-1407-4C60-93BD-386DCE063346'
              ? { borderRadius: '20px' }
              : {}
          }
        />
      </div>
              {Tablet && (
                <div
                  style={{
                    marginLeft: `${
                      programLabel ===
                      'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B'
                        ? '0.6rem'
                        : '0'
                    }`,
                  }}
                  className={programLabel === '53CE9A36-A9EC-4A7A-97A1-7E76FCFF27F5' ? 'overview-buttonContainer_Alianth':'overview-buttonContainer'}>
                  <button className='overview-button' onClick={viewMyCard}>
                    VIEW MY CARD
                  </button>
                </div>
              )}
            </div>

            {/* <div className='overview-logo'></div> */}
            <div className={programLabel==='53CE9A36-A9EC-4A7A-97A1-7E76FCFF27F5'?'overview-info_Ali':'overview-info'}>
              <div>
                <table id='overview-table'>
                  <thead>
                    <tr>
                      <th className='overview-th'>FIRST NAME</th>
                      <th className='overview-table1--rightMargin'>
                        LAST NAME
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{memberInfo && memberInfo.memberFirstName?.toUpperCase()}</td>
                      <td className='overview-table1--rightMargin --col'>
                        {memberInfo && memberInfo.memberLastName?.toUpperCase()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div>
                <table id='overview-table'>
                  <thead>
                    <tr>
                      <th className='overview-th'>DEACTIVATION DATE</th>
                      <th className='overview-table2--rightMargin'>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{expiryDate}</td>
                      <td className='overview-table2--rightMargin --col'>
                        {memberInfo && memberInfo.memberTier}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div>
                <table id='overview-table'>
                  <thead>
                    <tr>
                      <th className='overview-th'>CARD NUMBER</th>
                      <th className='overview-table3--rightMargin'>
                        MEMBER SINCE
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{memberInfo && memberInfo.memberCode}</td>
                      <td className='overview-table3--rightMargin --col'>
                        {memberInfo && memberInfo.memberEnrollmentDate}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <section className='overview-MiddleContainer'>
          {/* <div className='overview-progressDataContainer'>
            <p>{memberInfo && memberInfo.memberTier} Tier</p>
            <p>{memberInfo && memberInfo.memberPoints} Base points.</p>
            <p>{memberInfo && memberInfo.nextThresh} Points for next tier.</p>
          </div> */}
          <div className='overview-meterBarContainer '>
            <Meter c position={position} />
          </div>
          <br />
          {Mobile && (
            <div className='overview-awards-tableContainer'>
              <div className='overview-points'>
                <h6>Award Points</h6>
                <p>{memberInfo && memberInfo.memberPoints}</p>
              </div>
              <div className='overview-nights'>
                <h6>Nights Stayed</h6>
                <p>{memberInfo && memberInfo.memberNights}</p>
              </div>
              <div className='overview-visits'>
                <h6>Visits</h6>
                <p>{memberInfo && memberInfo.memberVisits}</p>
              </div>
            </div>
          )}

          {Tablet && (
            <div className='overview-awards-tableContainer'>
              <br />
              <br />
              <table className='overview-tableAwards'>
                <tr>
                  <th className='overview-points'>Award Points</th>
                  <th className='overview-nights'>Nights Stayed</th>
                  <th className='overview-visits'>Visits</th>
                </tr>
                <tr>
                  <td className='overview-points-data'>
                    {memberInfo && memberInfo.memberPoints}
                  </td>
                  <td className='overview-nights-data'>
                    {memberInfo && memberInfo.memberNights}
                  </td>
                  <td className='overview-visits-data'>
                    {memberInfo && memberInfo.memberVisits}
                  </td>
                </tr>
              </table>
            </div>
          )}
        </section>
        <footer className='overview-footerContainer'>
          {bookListings.length > 0 ? (
            <div className='col'>
            <Listings
              currentListings={bookListings}
              showEvents={false}
              showRevenue={false}
              showRooms={false}
              showNights={false}
              showPoints={false}
              showCheckIn={true}
              title='Reservations'
            />
           </div>
          ) : (
            <div className='overview-footer-staysContainer'>
              <p>YOU HAVE NO RESERVATIONS</p>
            </div>
          )}

          <br />
          <br />

          <div className='overview-footer-missingFormLinkContainer'>
            <p>
              Do you have a reservation that you do not see or have yet to
              receive points for your stay?
            </p>
            {Mobile && <br />}
            <p>
              Fill out the form at {''}
              <NavLink
                className='overview-footer-missingFormLink'
                to='/home/missingpoints'>
                Missing Points {''}
              </NavLink>
              {''}
              {''}
              to update us.
            </p>
            <p>
              <em>
                Please note that reservations can take up to 24 hours to appear!
              </em>
            </p>
            <p>Points are credited only to Loyalty members who have a valid reservation under their name.</p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Overview;
