import React, { useEffect, useContext, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeDataContext } from '../../ContextProviders/theme';
import '../Components/Overview/OverviewAtlantica.css';
import Listings from '../Components/Activity/Listings';
import axios from 'axios';
import { InfoDataContext } from '../../ContextProviders/info';
import moment from 'moment';
import Danger from '../../Icons/Danger';
import { useMediaQuery } from 'react-responsive';
import actions from '../../Redux/reducers/vlite/vlite.actions';
import SideBar from '../Components/SideBar/SideBar2';
import TopBar from '../Components/TopBar/TopBar2';
import tinycolor from 'tinycolor2';
import toast, { Toaster, useToaster } from 'react-hot-toast';
import Toggler from '../Components/Toggler/Toggler';
import Translation from '../Components/Translation/Translation';
import BookNow from '../Components/BookNow/BookNow2';
import { useForm, Controller } from 'react-hook-form';
import { isInTheFuture } from '../Services/IsInTheFuture';
import Calendar from '../../Icons/Calendar';
import Select, { components } from 'react-select';
import ArrivalDate from '../Components/TopBar/ArrivalDate';
import format from 'date-fns/format';

const Overview = ({ isOpen, toggle }) => {
  const [bookListings, setBookListings] = useState([]);
  const [myCardHtml, setMyCardHtml] = useState('');
  const [expiryDate, setExpiryDate] = useState([]);
  const [position, setPosition] = useState({});
  const [clicked, setClicked] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [hotels, setHotels] = useState([]);

  const initialValues = {
    nights: null,
  };
  const {
    reset,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: initialValues,
  });

  const onError = (errors, e) => console.log(errors, e, 'error');
  const arrivalDate = watch('arrivalDate');
  const clearHotelErrors = () => {
    if (errors.hotel) {
      clearErrors('hotel');
    }
  };

  const getHotels = async () => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/init/getLoyaltyInfo',
      params: {
        programLabel: localStorage['programLabel'],
      },
    })
      .then((resp) => {
        setHotels(resp.data.locations);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getHotels();
  }, []);

  const hotel = _.map(hotels, 'description');

  const newArray = hotel.map((h, i) => ({ value: h, label: h }));
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const handlePopper = () => {
    setIsPopperOpen(!isPopperOpen);
  };

  const bookNow = (data) => {
    console.log(data, 'data');
    let hotel = hotels?.filter((h) => h.description === data.hotel.value);
    let url = hotel[0]?.bookNowUri;
    let arrival;
    if (
      typeof data.arrivalDate === 'string' ||
      date.arrivalDate instanceof String
    ) {
      const [day, month, year] = data.arrivalDate.split('/');
      arrival = new Date(+year, +month - 1, +day);
      arrival = format(arrival, 'yyyy-MM-dd');
    } else if (
      typeof data.arrivalDate === 'Date' ||
      date.arrivalDate instanceof Date
    ) {
      arrival = `${format(arrival, 'yyyy-MM-dd')}`;
    }
    window.open(
      url +
        '?checkin=' +
        arrival +
        '&nights=' +
        data.nights +
        '&logintoken=' +
        emailToken
    );
  };

  const Hotel = (props) => (
    <Select
      {...props}
      // components={{
      //   IndicatorSeparator: () => null,
      // }}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      menuPlacement='auto'
      // className={`w-full h-[41px] bg-transparent rounded-[5px] border-skin-secondary ${
      //   errors.hotel && 'border-[#D80027]'
      // } indent-[0.5rem] border-solid  border-[1px]  text-skin-a11y font-light text-base leading-[19px]`}
      placeholder='Select Hotel...'
      styles={!errors.hotel ? style : styleErrors}
      options={newArray}
      onChange={props.onChange}
      onBlur={props.onBlur}
      selected={props.value}
      onClick={props.onClick}
    />
  );

  const dispatch = useDispatch();

  const isSidebarVisible = useSelector((state) => state.vlite.isSidebarVisible);
  const Lg = useMediaQuery({
    query: '(max-width: 1180px) ',
  });

  const urlParams = new URLSearchParams(window.location.search);
  const programLabelFromURL = urlParams.get('programLabel');
  const programLabel =
    programLabelFromURL ?? localStorage.getItem('programLabel');

  useEffect(() => {
    if (!localStorage['programLabel']) {
      localStorage.setItem('programLabel', programLabel);
    }
  }, [programLabel]);

  useEffect(() => {
    if (
      localStorage.getItem('programLabel') !== programLabelFromURL &&
      programLabelFromURL
    ) {
      localStorage.setItem('programLabel', programLabelFromURL);
    }
  }, [programLabelFromURL]);
  const cardRef = useRef(null);

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor, buttonColor } = portalInfo || {};
  const [trigger, setTrigger] = useState(true);

  const { tiers, tierCount } = useContext(ThemeDataContext) || {};
  console.log(tiers, 'tiers');

  const { toasts } = useToaster();

  const handleDismiss = () => {
    toasts.forEach((t) => toast.dismiss(t.id));
  };

  useEffect(() => {
    toast.remove();
  }, []);

  const getBookingListings = () => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/event/getBookings',
      params: {
        acid: localStorage.getItem('acid'),
        programLabel,
      },
    })
      .then((resp) => {
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.data.ErrorMessage);
        }
        setBookListings(resp.data.transactions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMyCardHtml = async () => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/member/getCardPage',
      params: {
        memberCode: memberInfo.memberCode,
        firstname: memberInfo.memberFirstName,
        lastname: memberInfo.memberLastName,
        tier: memberInfo.memberTier,
        programLabel,
      },
    })
      .then((resp) => {
        setMyCardHtml(resp.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBookingListings();
    getMyCardHtml();
  }, []);

  const viewMyCard = () => {
    if (myCardHtml) {
      var win = window.open('', '_blank');
      win.document.write(myCardHtml);
    } else {
      win.document.write('An error occured while making your card.');
    }
  };

  const count = 10;
  const cardColor = 'White';
  const basePoints = 0;
  const pointsForNextTier = 1000;

  // GET USER INFO FROM LOCALSTORAGE

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const memberInfo = userInfo.memberInfo;

  const emailToken = userInfo.eMailToken;

  const user = useSelector((state) => state.vlite);

  const { deactivationDate } = useContext(InfoDataContext) || {};

  useEffect(() => {
    if (
      programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ||
      'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B' ||
      'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' ||
      'IXI-INTG-E98241F0-EE89-4B11-8F81-94C0CA0085FA'
    ) {
      setExpiryDate('No expiry');
    } else setExpiryDate(moment(deactivationDate).format('DD/MM/YYYY'), 3000);
  }, [deactivationDate, programLabel]);

  const Tablet = useMediaQuery({
    query: '(min-width: 481px) ',
  });

  const Mobile = useMediaQuery({
    query: '(min-width: 280px) and (max-width: 480px)',
  });

  const prevPath = useSelector((state) => state.vlite.prevPath);
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {errors.hotel && <Danger color='#D80027' width='25' height='24' />}
      </components.DropdownIndicator>
    );
  };

  const style = {
    container: (base) => ({
      ...base,
      // marginTop: '0.25rem',
      width: '310px',
      height: '40px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#173352',
      fontWeight: '300',
    }),
    valueContainer: (base) => ({
      ...base,
      width: '310px',
      paddingBottom: '0',
      height: '41px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#173352',
      fontWeight: '300',
      // marginLeft: '-0.28rem',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#14D5FF',
      '&:hover': { color: '#14D5FF' },
    }),
    group: (base) => ({
      ...base,
      width: '310px',
    }),
    menu: (base) => ({
      ...base,
      width: '310px',
      // marginLeft: '2rem',
    }),
    control: (base) => ({
      ...base,
      '&:hover': { borderColor: '#14D5FF' },
      border: '1px solid #14D5FF',
      boxShadow: 'none',
      borderRadius: '5px',
      width: '310px',
      // marginLeft: '2rem',
      fontSize: '1rem',
      height: '41px',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: '#173352',
      marginLeft: '0.15rem',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#173352',
      fontWeight: '300',
    }),
    input: (base) => ({
      ...base,
      width: '310px',
      marginLeft: '-0.28rem',
      fontFamily: 'Roboto, sans-serif',
      color: '#173352',
      fontSize: '1rem',
      fontWeight: '300',
    }),
  };
  const styleErrors = {
    container: (base) => ({
      ...base,
      // marginTop: '0.25rem',
      width: '310px',
      height: '40px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
    }),
    valueContainer: (base) => ({
      ...base,
      width: '310px',
      paddingBottom: '0',
      height: '41px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
      // marginLeft: '-0.28rem',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'transparent',
      '&:hover': { color: '#D80027' },
    }),
    group: (base) => ({
      ...base,
      width: '310px',
    }),
    menu: (base) => ({
      ...base,
      width: '310px',
      // marginLeft: '2rem',
    }),
    control: (base) => ({
      ...base,
      '&:hover': { borderColor: '#D80027' },
      border: '1px solid #D80027',
      boxShadow: 'none',
      borderRadius: '5px',
      width: '310px',
      // marginLeft: '2rem',
      fontSize: '1rem',
      height: '41px',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      marginLeft: '0.15rem',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
    }),
    input: (base) => ({
      ...base,
      width: '310px',
      marginLeft: '-0.28rem',
      fontFamily: 'Roboto, sans-serif',
      color: '#D80027',
      fontSize: '1rem',
      fontWeight: '300',
    }),
  };

  // const notifySmartGuest = () =>
  //   toast(
  //     <div style={{ width: '100%' }}>
  //       <div
  //         style={{
  //           fontSize: '1.2rem',
  //           fontFamily: `'Lato', Arial, sans-serif`,
  //           userSelect: 'none',
  //           color: 'var(--color-accent)',
  //           paddingRight: '0.7rem',
  //         }}>
  //         Please update your Profile infromation
  //       </div>

  //       <br />

  //       <div
  //         style={{
  //           display: 'flex',
  //           justifyContent: 'center',
  //           marginLeft: '0.5rem',
  //         }}>
  //         <button
  //           className='LogoutButton'
  //           onClick={() => navigate('/home/myprofile')}>
  //           UPDATE
  //         </button>
  //         <button className='LogoutButton' onClick={toast.dismiss()}>
  //           NOT NOW
  //         </button>
  //       </div>
  //     </div>,
  //     {
  //       // position: toast.POSITION.TOP_CENTER,
  //       className: 'Logout-Toaster',
  //     }
  //   );

  // useEffect(() => {
  //   if (
  //     memberInfo &&
  //     (memberInfo.memberFirstName === null ||
  //       memberInfo.memberFirstName === 'Verity Test' ||
  //       memberInfo.memberLastName === null ||
  //       memberInfo.memberLastName === 'Verity Test')
  //   ) {
  //     notifySmartGuest();
  //   }
  // }, []);

  useEffect(() => {
    dispatch(actions.setPrevPath(window.location.pathname));
  }, []);

  const [tierInfo, setTierInfo] = useState([]);
  const [tierColor, setTierColor] = useState('');

  useEffect(() => {
    const tierInfo =
      tiers &&
      tiers.map((tier, index) => ({
        width: tier?.barPercentage,
        color: tier?.color,
        description: tier?.description,
        value: tier?.thresholds[0].value,
        cardinality: tier?.cardinality,
        nextTier: tier?.nextTier,
        tierPointDescr: tier?.tierPointDescr,
      }));

    const sortedArray = tierInfo.sort((a, b) => a.cardinality - b.cardinality);

    setTierInfo(sortedArray);
  }, [tiers, setTierInfo]);

  useEffect(() => {
    let currentTier =
      tiers &&
      tiers.filter((tier) => {
        let current = tier.description === memberInfo.memberTier;
        return current;
      });

    setTierColor(currentTier[0]?.color);
  }, [tiers, setTierColor, memberInfo]);

  // const getColorWithLinearGradient = (color) => {
  //   const color2 = TinyColor(color).desaturate(10).toString();
  //   return color2;
  // };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const getTierLabel = (tier) => {
    if (tier.tierPointDescr === 'points' && tier.nextTier) {
      return `${numberWithCommas(tier.value)}-${numberWithCommas(
        tier.nextTier - 1
      )} ${tier.tierPointDescr}`;
    } else if (tier.tierPointDescr === 'points' && !tier.nextTier) {
      return `${numberWithCommas(tier.value)}+ ${tier.tierPointDescr}`;
    } else if (tier.tierPointDescr === 'nights' && tier.nextTier) {
      return `${tier.value}-${tier.nextTier - 1} ${tier.tierPointDescr}`;
    }
    return `${tier.value} + ${tier.tierPointDescr}`;
  };
  const getTierBorder = (col) => {
    const color = tinycolor(col);
    if (isLightColor(color)) {
      return '1px solid #000000'; // Apply black border for light colors
    } else if (color.isDark()) {
      return 'none';
    }
  };
  
  const getTierValueColor = (col) => {
    const color = tinycolor(col);
    if (isLightColor(color)) {
      return '#000000';
    } else if (color.isDark()) {
      return '#FFFFFF';
    }
  };
  
  const getTierLabelColor = (col) => {
    const color = tinycolor(col);
    if (isLightColor(color)) {
      return '#000000';
    } else if (color.isDark()) {
      return '#FFFFFF';
    }
  };
  
  const isLightColor = (color) => {
    // You can adjust the threshold (128) as per your preference
    return color.getBrightness() > 87;
  };
  
  if (Lg) {
    return (
      <>
        <div className='flex w-full'>
          <Toggler />
          <SideBar />
          <div
            className={`min-h-screen w-full z-10 s `}
            style={{
              // visibility: `${isSidebarVisible ? 'hidden' : 'visible'}`,
              background: '#FFFFFF',
            }}>
            <TopBar bookListings={bookListings} />
            <div className='w-full h-[70px] mt-[6%] text-center px-[7.2%] max-px-[100px]'>
              <h1 className='w-full font-roboto font-normal text-[24px] leading-[47px] text-skin-primary'>
                Welcome{' '}
                <span className='font-semibold text-skin-primary'>
                  {memberInfo && memberInfo.memberFirstName?.toUpperCase()}
                </span>
              </h1>
              {/* <SmartGuestNotification
              memberInfo={memberInfo}
              prevPath={prevPath}
              clicked={clicked}
              setClicked={setClicked}
            /> */}
            </div>
            <div className={` flex flex-col w-full h-auto px-[6.2%]`}>
              <div className='grid grid-cols-3 gap-x-[10px] grid-rows-8 w-full h-[75px]'>
                <div
                  style={{
                    background: `${tierColor}`,
                    border: `${getTierBorder(tierColor)}`,
                  }}
                  className={`flex flex-col h-[75px]  rounded-[10px] justify-center items-center shadow-[50px_60px_120px_rgba(0,0,0,0.03)] `}>
                  <p
                    style={{ color: `${getTierValueColor(tierColor)}` }}
                    className='font-roboto h-[9px] pt-2 font-normal text-base leading-[19px]'>
                    {memberInfo?.memberTier}
                  </p>
                  <p
                    style={{ color: `${getTierLabelColor(tierColor)}` }}
                    className='font-roboto h-[12px] font-extrabold text-xs leading-[19px]'>
                    Tier
                  </p>
                </div>
                <div className='flex flex-col h-[75px] rounded-[10px] justify-center border-solid border-[1px] border-skin-primary items-center shadow-[50px_60px_120px_rgba(0,0,0,0.03)]  text-center'>
                  <p className='font-roboto h-[16px]  pt-2 font-normal text-base leading-[38px] text-[#404040]'>
                    {memberInfo?.memberTierPoints}
                  </p>
                  <p className='font-roboto font-extrabold text-xs leading-[26px] text-skin-primary'>
                    {`Total ${tierInfo?.[0]?.tierPointDescr}`}
                  </p>
                </div>
                <div className=' flex flex-col  h-[75px] border-solid border-[1px] border-skin-primary rounded-[10px] justify-center  items-center shadow-[50px_60px_120px_rgba(0,0,0,0.03) text-center'>
                  <p className='font-roboto h-[16px] pt-2 font-normal text-base leading-[38px] text-[#404040]'>
                    {memberInfo?.memberNights}
                  </p>
                  <p className='font-roboto font-extrabold text-xs leading-[26px] text-skin-primary'>
                    Nights Stayed
                  </p>
                </div>
              </div>

              <div
                style={{
                  height: `${tierCount && tierCount * 55}`,
                }}
                className={`flex w-2/3 gap-x-[3.5%] mt-6`}>
                <div className='min-w-[105px] w-1/2 h-full pt-4'>
                  <div className='w-full h-full flex flex-col justify-center'>
                    {tierInfo.map((tier, index) => (
                      <div key={index} className='flex w-full h-1/3'>
                        <div className='w-[25px] h-full'>
                          <div
                            className={`mr-2 w-[250%] h-[11px] rounded-[10px] shadow-[50px_60px_120px_rgba(0,0,0,0.03)] backdrop-blur-[70px]  `}
                            style={{
                              background: `${tier?.color}`,
                              border: `${getTierBorder(tier?.color)}`,
                            }}></div>
                        </div>
                        <div className={`h-[45px] w-[105px]  ml-[25%]`}>
                          <div className='whitespace-nowrap -translate-y-[0.5rem] w-[50px] h-[15px] font-roboto font-bold text-[12px] leading-[12px] text-[#404040]'>
                            {tier?.description}
                          </div>

                          <div
                            className={`whitespace-normal -translate-y-[0.5rem] w-[105px] h-full min-h-[15px] font-roboto  font-normal text-[10px] leading-[12px] text-[#909090] text-left`}>
                            <div>{getTierLabel(tier)}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    height: `${tierCount && tierCount * 45}`,
                    maxHeight: 170,
                  }}
                  className={`relative flex flex-col w-full justify-center flex-1 shadow-sm rounded-[20px] ml-[10%]`}>
                  <div className='flex text-center w-full h-[25px] text-[#909090]'>
                    <div className='block font-roboto w-full h-[25px] text-[#909090] font-normal whitespace-nowrap text-[13px] leading-[14px]'>
                      Card Number
                      <span className='block w-full font-roboto font-normal text-[13px] leading-[16px] text-[#404040] mt-[8px]'>
                        {memberInfo?.memberCode}
                      </span>
                    </div>
                  </div>
                  {/* <div className='font-roboto flex flex-col justify-center text-center w-1/3 h-full'>
                  <div className='font-roboto text-[#909090] font-normal text-base leading-[19px]'>
                    Account
                  </div>
                  <div className='font-roboto font-normal text-2xl leading-[28px] text-[#404040] mt-[10px] whitespace-nowrap'>
                    TEST
                  </div>
                </div> */}
                  <div className='p-5 font-roboto flex w-full h-[25px]  text-center  text-[#909090]'>
                    <div className='block font-roboto w-full h-[25px] text-[#909090] font-normal text-[13px]  whitespace-nowrap leading-[14px]'>
                      Member Since
                      <span className='block font-roboto font-normal text-[13px] leading-[16px] text-[#404040] mt-[8px]'>
                        {memberInfo?.memberEnrollmentDate}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            
        
          

              <div className='w-full border-b  border-skin-primary mt-[15%]'></div>
            </div>

            <footer className='flex flex-col w-full justify-center px-[6.2%]'>
              <div className='w-full'>
                {bookListings.length > 0 ? (
                  <h3 className='mt-2 font-roboto font-semibold text-[18px] leading-[21px] text-left'>
                    Upcoming Stays
                  </h3>
                ) : (
                  <div className='w-full text-center'>
                    <p className='mx-auto my-0 mt-5 w-full'>
                      You have no upcoming stays
                    </p>
                  </div>
                )}
                <br />
                {bookListings.length > 0 && (
                  <div className=' w-[100%] overflow-x-hidden'>
                    <Listings
                      currentListings={bookListings}
                      showEvents={false}
                      showRevenue={false}
                      showRooms={false}
                      showNights={true}
                      showPoints={false}
                      currentPath='overview'
                    />
                    <div
                      className={` ${
                        bookListings === 0 ? 'hidden' : ''
                      } mt-4 w-full flex justify-center cursor-pointer`}>
                      <button
                        // style={{
                        //   background: 'transparent',
                        //   color: '#404040',
                        // }}
                        onClick={() => setButtonPopup(true)}
                        className={`font-roboto cursor-pointer z-[999] text-skin-primary font-semibold w-[150px] h-[43px] text-base leading-[19px] tracking-[0.01em] mt-0 py-[1%] px-[0] rounded-[20px] bg-turquoise duration-300 shadow-[0px_4px_10px_rgba(20,213,255,0.4)] hover:-translate-y-[2px] select-none `}>
                        Book now
                      </button>
                      <BookNow
                        trigger={buttonPopup}
                        setTrigger={setButtonPopup}
                        reset={reset}>
                        <h1 className='font-roboto font-semibold text-[32px] leading-[38px] text-skin-primary text-center mt-[60px]'>
                          Book your stay
                        </h1>
                        <form
                          className='flex flex-col w-full h-full items-center mt-12'
                          onSubmit={handleSubmit(bookNow, onError)}>
                          <div
                            onClick={clearHotelErrors}
                            className='h-[105px]  w-[310px]'>
                            <label className='indent-2 h-[19px] font-roboto font-medium text-base leading-[18.75px] mb-[11.5px] text-skin-primary'>
                              Hotel
                            </label>

                            <Controller
                              {...register('hotel')}
                              defaultValue={null}
                              control={control}
                              options={newArray}
                              name='hotel'
                              rules={{
                                required: 'Hotel is required',
                              }}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Hotel
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value}
                                />
                              )}
                            />
                            {errors.hotels && (
                              <Danger
                                color='#D80027'
                                width='25'
                                height='24'
                                style={{ transform: 'translate(1090%, -145%)' }}
                              />
                            )}
                            {errors.hotel && (
                              <p
                                role='alert'
                                className='text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                                {errors?.hotel?.message}
                              </p>
                            )}
                          </div>

                          <div className='h-[105px]  w-[310px] relative'>
                            <label className='indent-2 h-[19px] font-roboto font-medium text-base leading-[18.75px] mb-[11.5px] text-skin-primary'>
                              Arrival Date
                            </label>
                            <br />
                            <ArrivalDate
                              isPopperOpen={isPopperOpen}
                              handlePopper={handlePopper}
                              setValue={setValue}
                              value={arrivalDate}
                            />
                            <input
                              className={` rounded-[5px] h-[41px] indent-2 w-[310px] text-base text-skin-primary font-light font-roboto cursor-pointer border-[1px] border-solid ${
                                !errors.arrivalDate
                                  ? 'border-skin-secondary'
                                  : 'border-[#D80027]'
                              }`}
                              {...register('arrivalDate', {
                                required: 'Arrival date is required',
                                // valueAsDate: true,
                                validate: {
                                  isAFutureDate: () =>
                                    isInTheFuture(arrivalDate) ||
                                    'Arrival date must be a future date',
                                },
                              })}
                            />
                            {/* <Controller
                    {...register('arrivalDate', {
                      required: 'Arrival date is required',
                      valueAsDate: true,
                      validate: {
                        isAFutureDate: () =>
                          isInTheFuture(arrivalDate) ||
                          'Arrival date must be a future date',
                      },
                    })}
                    control={control}
                    defaultValue={null}
                    name='arrivalDate'
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        className={`${
                          !errors.arrivalDate
                            ? 'vlite-datepicker'
                            : 'vlite-datepicker-danger'
                        } rounded-[5px] h-[41px] indent-2 w-[310px] text-base text-skin-primary font-light font-roboto cursor-pointer border-[1px] border-solid ${
                          !errors.arrivalDate
                            ? 'border-skin-secondary'
                            : 'border-[#D80027]'
                        }`}
                        style={{ marginLeft: '2rem' }}
                        dateFormat='dd/MM/yyyy'
                        onChange={onChange}
                        selected={value}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        placeholderText={'DD / MM / YYYY'}
                      />
                    )}
                  /> */}
                            <button
                              type='button'
                              className={`w-[51px] h-[51px] bg-transparent button-reset ba absolute right-[2%] top-[22%]`}
                              aria-label='Pick a date'
                              onClick={handlePopper}>
                              <span
                                className={`w-[51px] h-[51px]`}
                                role='img'
                                aria-label='calendar icon'>
                                <Calendar />
                              </span>
                            </button>
                            {errors.arrivalDate && (
                              <p
                                role='alert'
                                className={`text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm w-[170px]  ${
                                  errors.arrivalDate.message ===
                                    'Arrival date must be a future date' &&
                                  'w-auto'
                                } h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1`}>
                                {errors?.arrivalDate?.message}
                              </p>
                            )}
                          </div>
                          <div className='h-[105px] w-[310px]'>
                            <label
                              className='indent-2 h-[19px] font-roboto font-medium text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                              htmlFor='BookNow-Nights'>
                              Nights
                            </label>
                            <br />
                            <input
                              {...register('nights', {
                                required: 'Nights is required',
                                min: {
                                  value: 1,
                                  message:
                                    'Value must be greater than or equal to 1',
                                },
                              })}
                              className={`w-[310px] h-[41px] bg-transparent rounded-[5px] ${
                                errors.nights
                                  ? 'border-[#D80027]'
                                  : ' border-skin-secondary '
                              }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                              id='BookNow-Nights'
                              name='nights'
                              type='number'
                            />
                            {errors.nights && (
                              <Danger
                                color='#D80027'
                                width='25'
                                height='24'
                                style={{ transform: 'translate(1090%, -145%)' }}
                              />
                            )}
                            {errors.nights && (
                              <p
                                role='alert'
                                className='text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                                {errors?.nights?.message}
                              </p>
                            )}
                          </div>

                          <div className='flex w-full justify-center'>
                            <button
                              className={`font-roboto cursor-pointer z-[999] text-skin-primary font-semibold w-[150px] h-[43px] text-base leading-[19px] tracking-[0.01em] mt-0 py-[1%] px-[0] rounded-[20px] bg-turquoise duration-300 shadow-[0px_4px_10px_rgba(20,213,255,0.4)] hover:-translate-y-[2px] select-none mb-[10%]`}>
                              Book now
                            </button>
                          </div>
                        </form>
                      </BookNow>
                    </div>
                  </div>
                )}
              </div>
              <br />
              <br />
            </footer>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
    
      <div className='flex vliteOver'>
        <SideBar />
        <div
          className='flex-1 min-h-screen z-10 overflow-x-scroll'
          style={{
            // visibility: `${isSidebarVisible ? 'hidden' : 'visible'}`,
            background: '#FFFFFF',
          }}>
          <div>
            <TopBar />
          </div>
          <div className='w-full h-[115px] mt-[6%] text-center mx-auto'>
            <h1 className='min-w-[902px] font-roboto font-normal text-[40px] leading-[47px] text-skin-primary'>
              Welcome{' '}
              <span className='font-semibold text-skin-primary'>
                {memberInfo && memberInfo.memberFirstName?.toUpperCase()}
              </span>
            </h1>
            {/* <SmartGuestNotification
              memberInfo={memberInfo}
              prevPath={prevPath}
              clicked={clicked}
              setClicked={setClicked}
            /> */}
          </div>
          <div className={`flex flex-col max-w-[902px] h-auto mx-auto`}>
            <div className='grid grid-cols-3 gap-x-8 grid-rows-8 min-w-[902px] h-[133px]'>
              <div
                style={{
                  background: `${tierColor}`,
                  border: `${getTierBorder(tierColor)}`,
                }}
                className={`flex flex-col rounded-[20px]  justify-center items-center shadow-[50px_60px_120px_rgba(0,0,0,0.03)] `}>
                <p
                  style={{ color: `${getTierValueColor(tierColor)}` }}
                  className='font-roboto font-normal text-[2rem] leading-[38px]'>
                  {memberInfo?.memberTier}
                </p>
                <p
                  style={{ color: `${getTierLabelColor(tierColor)}` }}
                  className='font-roboto font-bold text-[22px] leading-[26px]'>
                  Tier
                </p>
              </div>
              <div className='flex flex-col rounded-[20px] justify-center border-solid border-[1px] border-skin-primary items-center shadow-[50px_60px_120px_rgba(0,0,0,0.03)]  text-center'>
                <p className='font-roboto font-normal text-[2rem] leading-[38px] text-[#404040]'>
                  {memberInfo?.memberTierPoints}
                </p>
                <p className='font-roboto font-bold text-[22px] leading-[26px] text-skin-primary'>
                  {`Total ${tierInfo?.[0]?.tierPointDescr}`}
                </p>
              </div>
              <div className=' flex flex-col border-solid border-[1px] border-skin-primary rounded-[20px] justify-center  items-center shadow-[50px_60px_120px_rgba(0,0,0,0.03) text-center'>
                <p className='font-roboto font-normal text-[2rem] leading-[38px] text-[#404040]'>
                  {memberInfo?.memberNights}
                </p>
                <p className='font-roboto font-bold text-[22px] leading-[26px] text-skin-primary'>
                  Nights Stayed
                </p>
              </div>
            </div>
            <div
              style={{ height: `${tierCount && tierCount * 45}` }}
              className={`flex min-w-[902px] gap-x-[3.5%] mt-3`}>
              <div className='min-w-[31%] h-full pt-4'>
                <div className='min-w-[280.5px] h-full flex flex-col justify-center text-center'>
                  {tierInfo.map((tier, index) => (
                    <div
                      key={index}
                      className='flex min-w-[280.5px] h-1/3 mb-4'>
                      <div className='min-w-[130px] h-full'>
                        <div
                          className={`min-w-[130px] h-[19px] rounded-[20px] shadow-[50px_60px_120px_rgba(0,0,0,0.03)] backdrop-blur-[70px]  `}
                          style={{
                            background: `${tier.color}`,
                            border: `${getTierBorder(tier.color)}`,
                          }}></div>
                      </div>
                      <div className=' ml-1 mr-1 w-[25%] h-full font-roboto font-bold text-xs leading-[14px] text-[#404040]'>
                        {tier.description}
                      </div>
                      <div className='w-1/3 h-full font-roboto font-normal text-xs leading-[14px] text-[#909090] text-left'>
                        {getTierLabel(tier)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{ height: `${tierCount && tierCount * 45}` }}
                className={`relative flex flex-row min-w-[589.5px] flex-1 shadow-sm rounded-[20px]`}>
                <div className='flex flex-col gap-x-8 justify-center text-center  w-1/2 h-full text-[#909090]'>
                  <div className='font-roboto min-w-full  text-[#909090] font-normal text-[22px] leading-[19px]'>
                    Card Number
                  </div>
                  <div className='w-full font-roboto  max-w-full font-normal text-lg leading-[28px] text-[#404040] mt-[10px]'>
                    {memberInfo?.memberCode}
                  </div>
                </div>
                <div className='font-roboto flex w-1/2 flex-col justify-center text-center h-full text-[#909090]'>
                  <div className='font-roboto text-[#909090] font-normal text-[22px] leading-[19px]'>
                    Member Since
                  </div>
                  <div className='font-roboto font-normal text-xl leading-[28px] text-[#404040] mt-[10px]'>
                    {memberInfo?.memberEnrollmentDate}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className='w-full border-b min-w-[905px] border-skin-primary'></div>
          </div>
          <br />
          <footer className='flex flex-col w-full justify-center mt-1 mx-auto'>
            <div className='min-w-[902px]'>
              {bookListings.length > 0 ? (
                <h3 className='font-roboto font-semibold text-[22px] leading-[26px] text-center'>
                  Upcoming Stays
                </h3>
              ) : (
                <div className='overview-footer-staysContainer '>
                  <p style={{fontSize:'22px'}}>You have no upcoming stays</p>
                </div>
              )}
              <br />
              {bookListings.length > 0 && (
                <Listings
                  currentListings={bookListings}
                  showEvents={false}
                  showRevenue={false}
                  showRooms={false}
                  showNights={true}
                  showPoints={false}
                  currentPath='overview'
                />
              )}
            </div>
            <br />
            <br />
          </footer>
        </div>
      </div>
      
    </>
  );
};

export default Overview;
