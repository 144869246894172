import React from 'react';

export default function Arrow({
  size = 18, // or any default size of your choice
  color, // or any color of your choice
  width,
  height,
  style,
  className,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 25 24'
      style={style}
      className={className}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.78436 5.92993L3.71436 11.9999L9.78436 18.0699'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.7143 12H3.88428'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
