import React, { useEffect, useState, useContext } from 'react';
import '../Components/Activity/ActivityAtlantica.css';
import Header from '../Components/Header';
import Listings from '../Components/Activity/Listings';
import Navigate from '../Components/Activity/Navigate';
import axios from 'axios';
import { ThemeDataContext } from '../../ContextProviders/theme';
import { useSelector } from 'react-redux';
import SideBar from '../Components/SideBar/SideBar2';
import TopBar from '../Components/TopBar/TopBar2';
import { useMediaQuery } from 'react-responsive';
import Toggler from '../Components/Toggler/Toggler';
import Pagination from '../Components/Pagination/pagination';
export default function Activities({ toggle }) {
  const [showEvents, setShowEvents] = useState(true);
  const [showRevenue, setShowRevenue] = useState(false);
  const [showDeparture, setShowDeparture] = useState(false);
  const [showRooms, setShowRooms] = useState(false);
  const theme = useContext(ThemeDataContext);
  // const { programLabel } = theme || {};

  const urlParams = new URLSearchParams(window.location.search);
  const programLabelFromURL = urlParams.get('programLabel');
  const programLabel =
    programLabelFromURL ?? localStorage.getItem('programLabel');

  const Lg = useMediaQuery({
    query: '(max-width: 1200px) ',
  });

  useEffect(() => {
    if (!localStorage['programLabel']) {
      localStorage.setItem('programLabel', programLabel);
    }
  }, [programLabel]);

  useEffect(() => {
    if (
      localStorage.getItem('programLabel') !== programLabelFromURL &&
      programLabelFromURL
    ) {
      localStorage.setItem('programLabel', programLabelFromURL);
    }
  }, [programLabelFromURL]);

  const [listings, setListings] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listingsPerPage] = useState(5);

  useEffect(() => getActivityListings(), []);

  const getActivityListings = () => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/event/getActivity',
      params: {
        acid: localStorage.getItem('acid'),
        programLabel: localStorage.getItem('programLabel'),
      },
    })
      .then((resp) => {
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.ErrorMessage);
        }
        const { data } = resp;
        const { transactions } = data;
        setListings(transactions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = listings.slice(
    indexOfFirstListing,
    indexOfLastListing
  );
  const totalListings = listings.length;
  console.log(totalListings, 'totalListings');
  console.log(currentListings, 'currentListings');

  const paginate = (pageNumber) => setcurrentPage(pageNumber);
  const next = () => {
    if (currentPage !== Math.ceil(totalListings / listingsPerPage)) {
      setcurrentPage(currentPage + 1);
    }
    return;
  };
  const previous = () => {
    if (currentPage > 1) {
      setcurrentPage(currentPage - 1);
    }
    return;
  };

  // const getShowRooms = () => {
  //   if (
  //     programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ||
  //     'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA'
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };

  const isSidebarVisible = useSelector((state) => state.user.isSidebarVisible);

  if (listings.length === 0)
    return (
      <>
        <div className='flex vliteOver min-h-screen'>
          {Lg && <Toggler />}
          <SideBar />
          <div
            className='flex-1 h-auto z-10 '
            style={{
              // visibility: `${isSidebarVisible ? 'hidden' : 'visible'}`,
              background: '#FFFFFF',
            }}>
            <div>
              <TopBar />
            </div>
            <div className='w-full h-[115px] mt-[6%] text-center'>
              <h1 className='font-roboto font-normal text-[40px] leading-[47px] font-darkBlue'>
                My {''}
                <span className='font-semibold'>Activity</span>
              </h1>
              <span id='Activity-none'>You have no activity!</span>
            </div>
          </div>
        </div>
      </>
    );

  if (Lg) {
    return (
      <>
        <div className='flex w-full h-auto'>
          <Toggler />
          <SideBar />
          <div
            className='w-full h-auto z-10 '
            style={{
              visibility: `${isSidebarVisible ? 'hidden' : 'visible'}`,
              background: '#F5F5F5 !important',
            }}>
            <div>
              <TopBar />
            </div>
            <div className='w-full h-[115px] mt-[6%] text-center px-[21.2%]'>
              <h1 className='w-full font-roboto font-normal text-[24px] leading-[28px] font-darkBlue'>
                My <span className='font-semibold'>Activity</span>
              </h1>
            </div>
            <div className='h-auto scrollbar-thin scrollbar-thumb-[#14D5FF] scrollbar-thumb-rounded-md scrollbar-track-[#E8E8E8] mx-[9.2%]'>
              <Listings
                showDeparture={showDeparture}
                showEvents={showEvents}
                showRevenue={showRevenue}
                currentListings={currentListings}
                showRooms={showRooms}
                showNights={true}
                showPoints={true}
                currentPath='activity'
              />

              <br />

              <br />
              <br />
            </div>
            <br />
            <br />
            <br />
            <br />
            {totalListings > 5 ? (
              <Pagination
                listingsPerPage={listingsPerPage}
                totalListings={totalListings}
                paginate={paginate}
                indexOfFirstListing={indexOfFirstListing}
                indexOfLastListing={indexOfLastListing}
                next={next}
                previous={previous}
              />
            ) : null}
            {/* 
          <Navigate next={next} previous={previous} /> */}
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className='flex  vliteOver min-h-screen'>
        <SideBar />
        <div
          className='flex-1 h-auto z-10 overflow-x-scroll'
          style={{
            visibility: `${isSidebarVisible ? 'hidden' : 'visible'}`,
            background: '#F5F5F5 !important',
          }}>
          <div>
            <TopBar />
          </div>
          <div className='w-full h-[115px] mt-[6%] text-center lg:px-[5%] xl:px-[5%] 2xl:px-[21.2%]'>
            <h1 className='font-roboto font-normal text-[40px] leading-[47px] font-darkBlue'>
              My <span className='font-semibold'>Activity</span>
            </h1>
          </div>
          <div className='w-full lg:px-[5%] xl:px-[5%] 2xl:px-[21.2%] my-0 mx-auto'>
            <Listings
              showDeparture={showDeparture}
              showEvents={showEvents}
              showRevenue={showRevenue}
              currentListings={currentListings}
              showRooms={showRooms}
              showNights={true}
              showPoints={true}
              currentPath='activity'
            />
          </div>
          <br />
          {totalListings > 5 ? (
            <Pagination
              listingsPerPage={listingsPerPage}
              totalListings={totalListings}
              paginate={paginate}
              indexOfFirstListing={indexOfFirstListing}
              indexOfLastListing={indexOfLastListing}
              next={next}
              previous={previous}
            />
          ) : null}
          {/* 
          <Navigate next={next} previous={previous} /> */}
        </div>
      </div>
    </>
  );
}
