export const ALT = {
  INTG: 'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B',
  PROD: 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B',
  TITLE: 'Atlantica Loyalty Club',
};
export const IXI = {
  INTG: 'IXI-INTG-E98241F0-EE89-4B11-8F81-94C0CA0085FA',
  PROD: 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA',
  TITLE: 'Ixian Grand Club',
};
export const MAST = {
  INTG: 'MAST-INTG-687F4563-95DA-4EA7-B919-6D376CE738B8',
  PROD: 'MAST-687F4563-95DA-4EA7-B919-6D376CE738B8',
  TITLE: 'Mast Rewards',
};
export const MK = {
  //INTG: 'INTG-9DF45F46-9911-4A64-A340-126466DAF541',
  PROD: '9DF45F46-9911-4A64-A340-126466DAF541',
  TITLE: 'MK Hotel Collection',
};
