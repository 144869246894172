import { Navigate, Outlet } from 'react-router-dom';
import CookieService from '../../API/Services/CookieService';

const ProtectedRoute = ({ redirectPath = '/signIn' }) => {
  if (!CookieService.get('acidToken')) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
