import React from 'react';

export default function Activity({
  size, // or any default size of your choice
  color, // or any color of your choice
  style,
  onClick,
  className,
}) {
  return (
    <svg
      style={style}
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 25 25`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.6084 9.10217H17.8584'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.61816 9.10217L7.36816 9.85217L9.61816 7.60217'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.6084 16.1022H17.8584'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.61816 16.1022L7.36816 16.8522L9.61816 14.6022'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.23828 22.2222H15.2383C20.2383 22.2222 22.2383 20.2222 22.2383 15.2222V9.22217C22.2383 4.22217 20.2383 2.22217 15.2383 2.22217H9.23828C4.23828 2.22217 2.23828 4.22217 2.23828 9.22217V15.2222C2.23828 20.2222 4.23828 22.2222 9.23828 22.2222Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
