import React from 'react';
// import './ActivityAtlantica.css';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { format as fnsFormat } from 'date-fns';

function Listings({
  currentListings,
  showDeparture,
  showEvents,
  showRevenue,
  showNights,
  showRooms,
  showPoints,
  currentPath,
  title = '',
}) {
  const open = useSelector((state) => state.vlite.isSidebarOpen);

  const Tablet = useMediaQuery({
    query: '(min-width: 1024px) ',
  });

  const Mobile = useMediaQuery({
    query: '(min-width: 280px) and (max-width: 1023px)',
  });

  const programLabel = localStorage.getItem('programLabel');
  const GetEventType = (event) => {
    if (
      programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' &&
      event &&
      event === 'Booking'
    ) {
      return 'Upcoming Stay';
    } else if (
      programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' &&
      event &&
      event === 'Booking'
    ) {
      return 'Reservation';
    }
    return (event && event) || '';
  };

  console.log(currentListings, 'currentListings');

  if (Tablet)
    return (
      <>
        <table
          id=''
          className={`${
            currentPath === 'overview' ? 'min-w-[902px]' : 'min-w-[1000px]'
          } mx-auto  border-collapse border-spacing-x-[200px] border-spacing-y-[90px]`}>
          <thead>
            <tr className={`h-[50px] mx-40 w-full p-0`}>
              <th
                className={`${
                  currentPath === 'overview'
                    ? '-indent-[0%]'
                    : 'w-auto -indent-[14%]'
                }  text-center font-roboto font-normal text-lg leading-[18.75px] text-[#909090]`}>
                Property
              </th>
              <th
                className={` ${
                  currentPath === 'overview'
                    ? '-indent-[120%]'
                    : '-indent-[40%]'
                }  w-auto text-center font-roboto font-normal text-lg leading-[18.75px] text-[#909090]`}>
                Arrival Date
              </th>
              {showDeparture && (
                <th className='w-auto text-center font-roboto font-normal text-lg leading-[18.75px] text-[#909090] '>
                  Departure
                </th>
              )}
              {showEvents && (
                <th
                  className={`text-center w-auto font-roboto font-normal text-lg leading-[18.75px] text-[#909090]  whitespace-nowrap`}>
                  Activity
                </th>
              )}
              {showNights && (
                <th
                  className={`${
                    currentPath === 'overview'
                      ? '-indent-[300%]'
                      : 'min-w-[75px]'
                  } w-auto text-center font-roboto font-normal text-lg leading-[18.75px] text-[#909090] `}>
                  Nights
                </th>
              )}
              {showRooms && (
                <th
                  className={` w-auto text-center font-roboto font-normal text-lg leading-[18.75px] text-[#909090] `}>
                  Room
                </th>
              )}
              {showRevenue && (
                <th
                  className={`w-auto text-center font-roboto font-normal text-lg leading-[18.75px] text-[#909090]`}>
                  Revenue
                </th>
              )}
              {showPoints && (
                <th
                  className={`w-auto text-center font-roboto font-normal text-lg leading-[18.75px] text-[#909090] `}>
                  Points
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {currentListings?.map((item, index) => (
              <tr
                className='h-[50px] w-full'
                style={{
                  borderBottom: '1px solid #14D5FF',
                  fontSize: '1.15rem',
                  // textAlign: 'center',
                }}
                key={index}>
                <td
                  className={`${
                    currentPath === 'overview'
                      ? '-indent-[0%]'
                      : '-indent-[14%]'
                  } w-auto text-center font-roboto font-normal text-[22px] leading-[28px] text-[#404040] whitespace-nowrap p-0`}>
                  {item?.partnerDescription || ''}
                </td>
                <td
                  className={` ${
                    currentPath === 'overview'
                      ? '-indent-[120%]'
                      : '-indent-[40%]'
                  } w-auto text-center font-roboto font-normal text-[22px] leading-[28px] text-[#404040] whitespace-nowrap`}>
                  {fnsFormat(new Date(item?.arrivalDate), 'dd/MM/yyyy') || ''}
                </td>
                {showDeparture && (
                  <td className='w-auto text-center font-roboto font-normal text-[22px] leading-[28px] text-[#404040]'>
                    {fnsFormat(new Date(item?.departureDate), 'dd/MM/yyyy') ||
                      ''}
                  </td>
                )}
                {showEvents && (
                  <td
                    className={`w-auto text-center font-roboto font-normal text-[22px] leading-[28px] text-[#404040] whitespace-nowrap`}>
                    {item?.eventTypeDescription || ''}
                  </td>
                )}
                {showNights && (
                  <td
                    className={`${
                      currentPath === 'overview' ? '-indent-[300%]' : ''
                    } w-auto text-center font-roboto font-normal text-[22px] leading-[28px] text-[#404040]`}>
                    {item?.nights || ''}
                  </td>
                )}
                {showRooms && (
                  <td
                    className={`w-auto text-center font-roboto font-normal text-[22px]leading-[28px] text-[#404040]`}>
                    {item?.roomType || ''}
                  </td>
                )}
                {showRevenue && (
                  <td className='w-auto text-center font-roboto font-normal text-[22px] leading-[28px] text-[#404040]'>
                    {Number(item?.eventRevenue).toFixed(2) || ''}
                  </td>
                )}
                {showPoints && (
                  <td
                    className={`w-auto text-center font-roboto font-normal text-[22px] leading-[28px] text-[#404040]`}>
                    {item?.pointsEarned || ''}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  else if (Mobile) {
    return (
      <>
        <table id='' className='mx-auto w-full border-collapse '>
          <thead>
            <tr className={`h-[50px] mx-40 w-full p-0`}>
              <th
                className={`min-w-[32] text-left font-roboto font-normal text-[15px] leading-[16px] text-[#909090]`}>
                Property
              </th>
              <th
                className={`min-w-[32] pl-4 text-left font-roboto font-normal text-[15px] leading-[16px] text-[#909090]`}>
                Arrival
              </th>
              {showDeparture && (
                <th className='w-auto text-center font-roboto font-normal text-[15px] leading-[16px] text-[#909090] '>
                  Departure
                </th>
              )}
              {showEvents && (
                <th
                  className={`text-center w-auto font-roboto font-normal text-[15px] leading-[116px] text-[#909090]  whitespace-nowrap`}>
                  Activity
                </th>
              )}
              {showNights && (
                <th
                  className={`min-w-[32vw] ml-2 text-center font-roboto font-normal text-[15px] leading-[16px] text-[#909090] `}>
                  Nights
                </th>
              )}
              {showRooms && (
                <th
                  className={` w-auto text-center font-roboto font-normal text-[15px] leading-[16px] text-[#909090] `}>
                  Room
                </th>
              )}
              {showRevenue && (
                <th
                  className={`w-auto text-center font-roboto font-normal text-[15px] leading-[16px] text-[#909090]`}>
                  Revenue
                </th>
              )}
              {showPoints && (
                <th
                  className={`w-auto text-center font-roboto font-normal text-[15px] leading-[16px] text-[#909090] `}>
                  Points
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {currentListings?.map((item, index) => (
              <tr
                className='h-[50px] w-full border border-solid border-skin-secondary'
                style={{
                  // borderBottom: '1px solid #14D5FF',
                  fontSize: '1.15rem',
                }}
                key={index}>
                <td
                  className={`w-auto min-w-[120px] h-[111px] text-left font-roboto font-normal text-[18px] leading-[21px] text-[#404040] whitespace-wrap p-0`}>
                  {item?.partnerDescription || ''}
                </td>
                <td
                  className={`w-auto min-w-[120px] text-left   pl-4 font-roboto font-normal text-[18px] leading-[21px] text-[#404040] whitespace-nowrap`}>
                  {fnsFormat(new Date(item?.arrivalDate), 'dd/MM/yyyy') || ''}
                </td>
                {showDeparture && (
                  <td className='w-auto min-w-[120px] text-center font-roboto font-normal text-[18px] leading-[21px] text-[#404040]'>
                    {fnsFormat(new Date(item?.departureDate), 'dd/MM/yyyy') ||
                      ''}
                  </td>
                )}
                {showEvents && (
                  <td
                    className={`w-auto min-w-[120px] text-center font-roboto font-normal text-[18px] leading-[21px] text-[#404040] whitespace-wrap`}>
                    {item?.eventTypeDescription || ''}
                  </td>
                )}
                {showNights && (
                  <td
                    className={`w-auto min-w-[120px] text-center font-roboto font-normal text-[18px] leading-[21px] text-[#404040] whitespace-nowrap`}>
                    {item?.nights || ''}
                  </td>
                )}
                {showRooms && (
                  <td
                    className={`w-auto min-w-[120px] text-center font-roboto font-normal text-[18px] leading-[21px] text-[#404040]`}>
                    {item?.roomType || ''}
                  </td>
                )}
                {showRevenue && (
                  <td className='w-auto min-w-[120px] text-center font-roboto font-normal text-[18px] leading-[21px] text-[#404040]'>
                    {Number(item?.eventRevenue).toFixed(2) || ''}
                  </td>
                )}
                {showPoints && (
                  <td
                    className={`w-auto min-w-[120px] text-center font-roboto font-normal text-[18px] leading-[21px] text-[#404040]`}>
                    {item?.pointsEarned || ''}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

export default Listings;
