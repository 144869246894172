import React, { useState, useContext, useEffect, useRef } from 'react';
import EmailIcon from '../../Assets/Images/EmailIcon.svg';
import PassIcon from '../../Assets/Images/PassIcon.svg';
import Message from '../Message/Message';
import { NavLink } from 'react-router-dom';
import Validate from './validate';
import useLogin from './useLogin';
import { ThemeDataContext } from '../../ContextProviders/theme';
import mark from '../../Assets/Images/exclamation-circle.svg';
import { useMediaQuery } from 'react-responsive';

import './SignInFormAtlantica.css';
import { BsEyeFill } from 'react-icons/bs';
import CheckboxVerity from '../Checkbox/CheckboxVerity';
import toast, { Toaster, useToaster, useToasterStore } from 'react-hot-toast';
import Exclamation from '../../Icons/Exclamation';
import { BsExclamationCircle } from 'react-icons/bs';

export function SignInForm() {
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    authErrors,
    setAuthErrors,
  } = useLogin(Validate);

  const Tablet = useMediaQuery({
    query: '(min-width: 768px) ',
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const programLabel = localStorage.getItem('programLabel');

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };
  const linkStyle = {
    color: `${buttonBackgroundColor}`,
  };  

  const notifyError = () =>
    toast(
      <div
        style={{
          display: 'flex',
          width: '100%',

          borderRadius: '7px',
          fontFamily: 'Lato',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
          background: 'transparent',
          color: `black`,

          // padding: '0.7rem',

          // flexShrink: '0',
        }}>
        <div
          style={{
            display: 'flex',
            order: '1',
            alignItems: 'center',
            width: '20%',
            transform: 'translateX(-10%)',
          }}>
          <Exclamation
            width='50'
            height='50'
            viewBox='-4 1 25 15'
            color='red'
          />
          {/* <BsExclamationCircle
            color='red'
            size='40'
         
          /> */}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            order: '2',
            width: '80%',
            transform: 'translateX(0%)',
            // paddintTop: '20%',
            // paddingBottom: '20%',
          }}>
          {authErrors}
        </div>
      </div>,

      {
        // icon: '????',
        style: {
          borderLeft: '6.5px solid red',
          maxWidth: '350px',
        },

        position: 'top-center',
      }
    );
  // const getHeadingColor = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return 'white';
  //   }
  //   return 'var(--color-accent)';
  // };

  // const getBackgroundColor = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return 'transparent';
  //   }
  //   return 'white';
  // };

  // const getBorder = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return '2px solid white';
  //   }
  //   return 'none';
  // };

  // const getBorderRadius = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return '50px';
  //   }
  //   return 'none';
  // };
  // const getHeight = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return '50px';
  //   }
  //   return 'auto';
  // };
  // const getColor = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return 'white';
  //   }
  //   return 'var(--color-accent)';
  // };

  // const getEmailPlaceholder = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return `${''} ${''} ${''} ${''} ${''}  Email`;
  //   }
  //   return '';
  // };
  // const getPasswordPlaceholder = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return `${''} ${''} ${''} ${''} ${''}  Password`;
  //   }
  //   return '';
  // };

  // const getFillColor = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return 'white';
  //   }
  //   return 'grey';
  // };
  // const getEyeTransform = () => {
  //   if (programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA') {
  //     return 'translate(-150%, -18%)';
  //   }
  //   return 'translateX(-90%)';
  // };

  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  const TOAST_LIMIT = 1;

  useEffect(() => {
    console.log(toasts, 'toasts');
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  // const notifyError = () => toast.error(authErrors);

  const handleDismiss = () => {
    toasts.forEach((t) => toast.dismiss(t.id));
  };

  const buttonRef = useRef();
  useEffect(() => {
    if (authErrors.length != 0) {
      notifyError();
      setAuthErrors([]);
      buttonRef.current.disabled = true;
      setTimeout(() => (buttonRef.current.disabled = false), 3000);
    }
  }, [authErrors, notifyError, buttonRef]);

  const [isDisabled, setIsDisabled] = useState(false);
  const enableComponents = () => {
    setIsDisabled(false);
    console.log(isDisabled, 'isEnabled');
  };

  const handleSubmitClicked = () => {
    setIsDisabled(true);
    console.log(isDisabled, 'isDisabled');
    setTimeout(() => enableComponents(), 2000);
  };

  return (
    <>
      <div className='signInHeading'>
        {!Tablet && (
          <>
            <br />
            <br />
          </>
        )}

        {/* {programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
        'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B' ? (
          <div className='signInKeys'></div>
        ) : (
          <div className='empty'></div>
        )}  */}
        <h1
          // style={{ color: `${getHeadingColor()}` }}
          id='signInWelcome'>
          Welcome Back !
        </h1>
        {!Tablet && (
          <>
            <br />
            <br />
            <br />
          </>
        )}
        {/* <Message message={authErrors} isError={true} /> */}
        {/* <hr className='line' /> */}
        <div onClick={handleDismiss}>
          <Toaster
            position='top-center'
            containerClassName='SignInToaster'
            containerStyle={{
              top: 10,
              left: 10,
              bottom: 10,
              right: 10,
            }}
            // toastOptions={{
            // error: {
            //   duration: 2000000,
            //   icon: {
            //     style: { width: '50px' },
            //   },

            //   icon: (
            //     <BsExclamationCircle
            // preserveAspectRatio='xMidYMin'
            // preserveAspectRatio='xMidYMid slice'
            // preserveAspectRatio='none'
            //     preserveAspectRario='xMinYMid slice'
            //     size='30'
            //     color='red'
            //   />
            // ),

            // icon: (
            //   <Exclamation
            //     width='75'
            //     height='75'
            //     viewBox='-6 1 25 15'
            //     color='red'
            //   />
            // ),
            // style: {
            // border: '1px solid #713200',

            // color: `black`,
            // fontWeight: 'normal',
            // background: `white` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
            // filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
            // fontFamily: 'Lato',
            // borderRadius: '7px',
            // height: 'auto',
            // textIndent: '0.5rem',
            // maxHeight: '60px',
            // flexShrink: '0',

            // fontWeight: '600',
            // borderLeft: '6.5px solid red',
            // background: `url('../../Assets/Atlantica/Images/exclamation-mark.png') no-repeat 100%`,
            // backgroundSize: `22px 22px`,
            // backgroundPositionY: '60%',
            //     },
            //   },
            // }}
            // toastOptions={{
            //   duration: 3000,
            //   icon: (
            //     <Exclamation
            //       width='50'
            //       height='50'
            //       viewBox='-4 1 25 15'
            //       color='red'
            //     />
            //   ),
            //   style: {
            //     // border: '1px solid #713200',
            //     padding: '16px',
            //     color: `red`,
            //     fontWeight: 'normal',
            //     background: `rgba(255, 228, 228, 1)` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
            //     filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
            //     fontFamily: 'Lato',
            //     borderRadius: '7px',
            //     height: 'auto',
            //     maxHeight: '50px',
            //     fontWeight: '600',
            //   },
          />
        </div>
      </div>
      <form className='signInForm' onSubmit={handleSubmit}>
        <div className='signIn-inputs'>
          <br />
          <label htmlFor='email' id='signIn-email-Label'>
            <img src={EmailIcon} className='emailIcon' alt='email' />{' '}
            {/* {programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B'
              ? 'Email'
              : ''} */}
            Email
          </label>
          <input
            // style={{
            //   background: `${getBackgroundColor()}`,
            //   border: `${getBorder()}`,
            //   borderRadius: `${getBorderRadius()}`,
            //   height: `${getHeight()}`,
            //   color: `${getColor()}`,
            //   marginLeft: '3rem',
            // }}
            // style={{ borderBottom: '1px solid var(--color-accent)' }}
            id='email'
            type='email'
            name='email'
            className='signIn-form-input'
            value={values.email}
            // placeholder={getEmailPlaceholder()}
            onChange={handleChange}
          />

          {errors.email && <p className='signInErrors'>{errors.email}</p>}
        </div>
        <br />
        <div className='signIn-inputs'>
          <br />
          <label htmlFor='password' className='passLabel'>
            <img src={PassIcon} className='passIcon' alt='password' />
            {/* {programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B'
              ? 'Password'
              : ''} */}
            Password
          </label>
          <input
            type={passwordShown ? 'text' : 'password'}
            // placeholder={getPasswordPlaceholder()}
            // style={{
            //   background: `${getBackgroundColor()}`,
            //   border: `${getBorder()}`,
            //   borderRadius: `${getBorderRadius()}`,
            //   height: `${getHeight()}`,
            //   color: `${getColor()}`,
            //   marginLeft: '5rem',
            // }}
            name='password'
            id='password'
            className='signIn-form-input'
            value={values.password}
            onChange={handleChange}
          />
          <BsEyeFill
            fill='grey'
            // fill={getFillColor()}
            // style={{ transform: `${getEyeTransform()}` }}
            style={{ transform: 'translateX(-90%' }}
            onClick={togglePassword}
          />
          {errors.password && <p className='signInErrors'>{errors.password}</p>}
        </div>
        <br />
        <br />
        <br />
        <div className='formButtonContainer'>
          <button
            button={isDisabled}
            ref={buttonRef}
            style={buttonStyle}
            className='formButton'
            type='submit'
            // onClick={notifyError2}
            onClick={handleSubmitClicked}>
            Sign In
          </button>
        </div>
        <br />
        <div className='rememberContainer'>
          <div className='formRemember'>
            <br />
            <div className='signInContainer'>
              <label
                // style={{ color: `${getColor()}` }}
                htmlFor='rememberMe'
                className='signIn-checkBoxLabel'>
                Remember Me
              </label>
              <CheckboxVerity
                id='rememberMe'
                type='checkbox'
                name='rememberMe'
                className='checkboxVerity'
                checked={values.rembemberMe}
                onChange={handleChange}
                required={false}
              />
            </div>
          </div>
          <div className='forgotPassLinkWrap'>
            <NavLink
              style={linkStyle}
              to='/signIn/forgotpassword'
              className='forgotPassLink'>
              Forgot Password?
            </NavLink>
          </div>
        </div>
      </form>
    </>
  );
}

export default SignInForm;
