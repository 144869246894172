import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import tick from '../../Assets/Images/Tick.svg';
import axios from 'axios';
import { ThemeDataContext } from '../../ContextProviders/theme';
import '../Components/ForgotPassword/ForgotPasswordAtlantica.css';
import { ProgramLabelDataContext } from '../../ContextProviders/programLabel';
import logo from '../../Assets/VerityLite/HH.svg';
import { ToastContainer, toast as toastify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import LargeX from '../../Icons/LargeX';

function ForgotPassword() {
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const [isHoveringSignIn, setIsHoveringSignIn] = useState(false);

  const [isHoveringSignUp, setIsHoveringSignUp] = useState(false);

  const handleMouseOverSignIn = () => {
    setIsHoveringSignIn(true);
  };

  const handleMouseOutSignIn = () => {
    setIsHoveringSignIn(false);
  };

  const handleMouseOverSignUp = () => {
    setIsHoveringSignUp(true);
  };

  const handleMouseOutSignUp = () => {
    setIsHoveringSignUp(false);
  };

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };

  const buttonStyleTransparent = {
    backgroundColor: 'transparent',
    color: `${buttonBackgroundColor}`,
    border: `3px solid ${buttonBackgroundColor}`,
  };

  const buttonStyleFill = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: 'white',
    border: 'none',
  };

  const [email, setEmail] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const navigate = useNavigate();

  useEffect(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), []);
  const programLabel = localStorage.getItem('programLabel');

  const handleSubmit = (event) => {
    event.preventDefault();

    const programLabel = localStorage.getItem('programLabel');

    axios({
      method: 'post',
      baseURL: '/',
      url: 'api/password/forgotrequest',
      data: {
        email: email,
        programLabel,
        template: 1,
        customization: {
          logoUrl: portalInfo.logoUrl,
          globalColor: portalInfo.globalColor,
          backgroundColor: portalInfo.globalBackgroundColor,
          buttonColor: portalInfo.buttonColor,
          buttonBackgroundColor: portalInfo.buttonBackgroundColor,
        },
      },
    })
      .then((resp) => {
        console.log(resp, 'forgotrequest resp');
        setShowMessage(true);
      })
      .catch((error) => {
        console.log(error);
      });
    notifyForgotPasswordSuccess();
  };

  const notifyForgotPasswordSuccess = () =>
    toastify(
      <div
        style={
          {
            // backgroundImage: `url(${logo})`,
            // backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
          }
        }
        className='w-full p-[7%] bg-no-repeat bg-center bg-opacity-50 bg-[length:50px_50px] rounded-[5px] '>
        {/* <h1 className='font-roboto text-skin-primary font-bold text-xl'>
          You are moments away from resetting your password!
        </h1> */}
        <br />
        <p className='font-roboto'>
          If an account matches your email, you should receive an email with
          instructions on how to reset your password shortly!
        </p>

        <p className='font-roboto'>
          Please be sure to check your Spam and Junk folder.
        </p>
        <br />
        <div className='flex w-full justify-center h-12'>
          <button
            // disabled={isDisabled}
            // ref={buttonRef}
            // style={{background: 'background: #14D5FF', color: '#ffffff'}}
            className='w-[330px] text-roboto text-center text-xl transition ease-in hover:-translate-y-[2px] duration-300 font-bold text-skin-primary leading-11 bg-turquoise rounded-5 py-1'
            type='submit'
            onClick={() => navigate('/signIn')}>
            Login
          </button>
        </div>
        <LargeX
          className={'absolute top-4 right-4'}
          color='#909090'
          width='33'
          height='33'
        />
      </div>,
      { containerId: 'forgotPasswordSuccess' },
      {
        // position: toast.POSITION.TOP_CENTER,
        className: '',
      }
    );
  return (
    <div>
      <header
        className='w-screen h-screen bg-skin-primary pt-[8.5rem] overflow-hidden'
        style={
          {
            // height: '1700px',
            // backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/backroundimage_front.jpg)`,
            // backgroundPosition: 'center',
            // backgroundSize: 'cover',
            // backgroundAttachment: 'fixed',
            // backgroundRepeat: 'no-repeat',
            // width: '100%',
          }
        }>
        {/* <div
          style={{
            // backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,

            backgroundImage: `url(${logo})`,
          }}
          className='forgotPassLogo'></div> */}
        <br />
        {/* <div className='forgotPass-buttonsContainer'>
          <button
            // style={buttonStyleTransparent}
            style={isHoveringSignIn ? buttonStyleFill : buttonStyleTransparent}
            name='signIn'
            className='forgotPass-button'
            onMouseOver={handleMouseOverSignIn}
            onMouseOut={handleMouseOutSignIn}
            onClick={() => navigate('/signIn')}>
            Sign In
          </button>
          <button
            // style={buttonStyleTransparent}
            style={isHoveringSignUp ? buttonStyleFill : buttonStyleTransparent}
            name='signUp'
            className='forgotPass-button'
            onMouseOver={handleMouseOverSignUp}
            onMouseOut={handleMouseOutSignUp}
            onClick={() => navigate('/signUp')}>
            Join The Club
          </button>
        </div> */}
        <div className='forgotPass-formContainer bg-transparent shadow-none'>
          <div className='forgotPass-Heading'>
            <br />
            <br />
            <h1 className='w-full h-50px text-center font-roboto font-normal text-skin-a11y text-[40px] leading-{50} tracking-wide my-0 drop-shadow-3xl'>
              Forgot your <span className='text-skin-secondary'>Password?</span>
            </h1>
            <p className='text-skin-a11y font-roboto font-normal leading-[21px] text-lg w-full text-center -translate-x-8'>
              No problem! Please enter the email you used to sign in and we will
              send you a reset link:
            </p>
          </div>
          {/* {showMessage && (
            <div className='forgotPass-InfoContainer'>
              <div className='forgotPass-tickWrap'>
                <img src={tick} alt='tick' />
              </div>
              <p className='text-skin-a11y font-roboto font-normal leading-[21px] text-lg w-full text-center'>
                If an account matches this email address, you should receive an
                email with instructions on how to reset your password shortly
              </p>
            </div>
          )} */}

          <form
            onSubmit={handleSubmit}
            className='flex flex-col w-full justify-center items-center h-[200px]'>
            <div className='block w-[310px] h-[105px]'>
              <label
                className='font-roboto block text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem] '
                htmlFor='forgotPass-email'>
                Email
              </label>
              <input
                className={`font-roboto block w-[310px] h-[41px] bg-transparent rounded-[5px] border-skin-secondary indent-[0.7rem] border-solid  border-[1px]  text-skin-a11y font-light text-base leading-[19px] mt-[0.7rem]`}
                id='forgotPass-email'
                name='forgotPass-email'
                type='email'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className='flex w-full justify-center h-12'>
              <button
                style={buttonStyle}
                className='inline-block w-[330px] text-roboto text-center text-xl transition ease-in hover:-translate-y-[2px] font-bold  rounded-5 py-[1%]'
                type='submit'>
                Reset password
              </button>
            </div>
          </form>
          <div className='flex w-full h-7 justify-center'>
            <p className='font-roboto font-bold text-lg leading-[21px] text-skin-a11y'>
              Return to {''}
              <NavLink
                // style={linkStyle}
                className='font-roboto font-bold text-lg leading-[21px] text-skin-secondary no-underline'
                to='/signIn'>
                Sign in
              </NavLink>
            </p>
          </div>
        </div>
        <ToastContainer
          enableMultiContainer
          containerId={'forgotPasswordSuccess'}
          limit={1}
          hideProgressBar={true}
          transition={Zoom}
          closeButton={false}
          className=''
          autoClose={false}
          style={{
            position: 'absolute',
            top: '30%',
            left: '0%',
            width: '500px',
            height: '500px',
            borderRadius: '0',
            margin: '0 auto',
            textAlign: 'center',
          }}
        />
      </header>
    </div>
  );
}

export default ForgotPassword;
