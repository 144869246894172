import React from 'react';
import ReactPortal from '../ReactPortal/ReactPortal';
import CalendarModal from './CalendarModal';

const DayPickerModal = ({ trigger, setTrigger, children }) => {
  return (
    <>
      {trigger ? (
        <ReactPortal wrapperId='react-portal-modal-container'>
          <CalendarModal
            setTrigger={setTrigger}
            trigger={trigger}
            children={children}
          />
        </ReactPortal>
      ) : (
        ''
      )}
    </>
  );
};

export default DayPickerModal;
