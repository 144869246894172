import React, { useState,useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { ThemeDataContext } from '../../ContextProviders/theme';
import './NavBarAtlantica.css';


export function NavBar() {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
    const pathname = window.location.pathname;

    const customization = useContext(ThemeDataContext);
    const { preCheckin } = customization;

    // Check if optionObject exists and has the PreCheckin property
    const CheckInOption = preCheckin;

   // console.log('Check In Option:', CheckInOption);
    
  return (
    <ul onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} id='nav-list'>
      <li id='nav-item'>
        <NavLink id='nav-link' to='/home/overview'>
          <span
            className={
              pathname.includes('overview') && !isHovering
                ? `nav-ActiveBar `
                : ''
            }
            style={
              pathname.includes('overview') && !isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',            marginLeft:'4%',
            marginRight:'4%'

}
            :
            
            pathname.includes('overview') && isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',            marginLeft:'4%',
            marginRight:'4%'

}
            :{}
            }
            >
            Overview
          </span>
        </NavLink>
      </li>
      <li id='nav-item'>
        <NavLink id='nav-link' to='/home/myprofile'>
          <span
            className={
              pathname.includes('myprofile') && !isHovering
                ? 'nav-ActiveBar'
                : ''
            } style={
              pathname.includes('myprofile') && !isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',            marginLeft:'4%',
            marginRight:'4%'

}
            :
            
            pathname.includes('myprofile') && isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',            marginLeft:'4%',
            marginRight:'4%'

}
            :{}
            }>
            My Profile
          </span>
        </NavLink>
      </li>
      <li id='nav-item'>
        <NavLink id='nav-link' to='/home/activity'>
          <span
            className={
              pathname.includes('activity') && !isHovering
                ? 'nav-ActiveBar'
                : ''
            } style={
              pathname.includes('activity') && !isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',            marginLeft:'4%',
            marginRight:'4%'

}
            :
            pathname.includes('activity') && isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',            marginLeft:'4%',
            marginRight:'4%'

}
            :{}
            }>
            Activity
          </span>
        </NavLink>
      </li>
      <li id='nav-item'>
        <NavLink id='nav-link' to='/home/preferences'>
          <span
            className={pathname.includes('preferences') ? 'nav-ActiveBar' : ''} style={
              pathname.includes('preferences') && !isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',            marginLeft:'4%',
            marginRight:'4%'

}
            :
            
            pathname.includes('preferences') && isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',            marginLeft:'4%',
            marginRight:'4%'

}
            :{}
            }>
            Preferences
          </span>
        </NavLink>
          </li>
          {CheckInOption ? ( 
              <li id='nav-item'>
                  <NavLink id='nav-link' to='/home/OnlinePreCheckin'>
                      <span
                          className={pathname.includes('OnlinePreCheckin') ? 'nav-ActiveBar' : ''} style={
              pathname.includes('OnlinePreCheckin') && !isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',
            marginLeft:'4%',
            marginRight:'4%'


            }
            :
            
            pathname.includes('OnlinePreCheckin') && isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',
            marginLeft:'4%',
            marginRight:'4%'

            }
            :{}
            }>
                          Online Check In
                      </span>
                  </NavLink>

              </li>) : null}
      <li id='nav-item'>
        <NavLink id='nav-link' to='/home/benefits'>
          <span
            className={
              pathname.includes('benefits') && !isHovering
                ? 'nav-ActiveBar'
                : ''
            } style={
              pathname.includes('benefits') && !isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',
            marginLeft:'4%',
            marginRight:'4%'

}
            :
            
            pathname.includes('benefits') && isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',
            marginLeft:'4%',
            marginRight:'4%'

}
            :{}
            }>
            Benefits
          </span>
        </NavLink>
      </li>
      <li id='nav-item'>
        <NavLink id='nav-link' to='/home/missingpoints'>
          <span
            className={
              pathname.includes('missingpoints') && !isHovering
                ? 'nav-ActiveBar'
                : ''
            } style={
              pathname.includes('missingpoints') && !isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',
            marginLeft:'4%',
            marginRight:'4%'

}
            :
            
            pathname.includes('missingpoints') && isHovering?{
            backgroundColor:customization.portalInfo.buttonBackgroundColor,
            display: 'inline-block',
            width: 'calc(100vw / 6)',
color:customization.portalInfo.buttonColor,
            fontSize: '1.56rem',
            lineHeight: '60px',
            borderRadius: '0px',
            marginLeft:'4%',
            marginRight:'4%'

}
            :{}
            }>
            Missing Points
          </span>
        </NavLink>
      </li>
    </ul>
  );
}

export default NavBar;
