import React from 'react';
import Hamburger from 'hamburger-react';
import actions from '../../../Redux/reducers/vlite/vlite.actions';
import { useDispatch, useSelector } from 'react-redux';

function Toggler() {
  const dispatch = useDispatch();

  const isSidebarVisible = useSelector((state) => state.vlite.isSidebarVisible);

  const toggle = () => {
    dispatch(actions.setSidebarVisibility(!isSidebarVisible));
    window.scrollTo(0, 0);
  };

  // useEffect(() => {}, []);

  return (
    <div
      // onClick={toggle}
      className={`absolute block top-7 left-4 w-[30px] h-[20px] ${
        isSidebarVisible ? 'first:text-skin-a11y' : 'first: text-skin-primary'
      }  cursor-pointer z-[999] `}>
      <Hamburger
        easing='ease-in'
        toggle={toggle}
        distance='sm'
        hideOutline={true}
        direction='right'
        size='20'
        toggled={isSidebarVisible}
      />
    </div>
  );
}

export default Toggler;
