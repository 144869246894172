import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
// import { ThemeDataContext } from '../../ContextProviders/theme';
import './NavBarAtlantica.css';

export function NavBar() {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const pathname = window.location.pathname;

  return (
    <ul onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} id='nav-list'>
      <li id='nav-item'>
        <NavLink id='nav-link' to='/home/overview'>
          <span
            className={
              pathname.includes('overview') && !isHovering
                ? 'nav-ActiveBar'
                : ''
            }>
            Overview
          </span>
        </NavLink>
      </li>
      <li id='nav-item'>
        <NavLink id='nav-link' to='/home/myprofile'>
          <span
            className={
              pathname.includes('myprofile') && !isHovering
                ? 'nav-ActiveBar'
                : ''
            }>
            My Profile
          </span>
        </NavLink>
      </li>
      <li id='nav-item'>
        <NavLink id='nav-link' to='/home/activity'>
          <span
            className={
              pathname.includes('activity') && !isHovering
                ? 'nav-ActiveBar'
                : ''
            }>
            Activity
          </span>
        </NavLink>
      </li>
      {/* <li id='nav-item'>
        <NavLink id='nav-link' to='/home/preferences'>
          <span
            className={pathname.includes('preferences') ? 'nav-ActiveBar' : ''}>
            Preferences
          </span>
        </NavLink>
      </li> */}
      <li id='nav-item'>
        <NavLink id='nav-link' to='/home/benefits'>
          <span
            className={
              pathname.includes('benefits') && !isHovering
                ? 'nav-ActiveBar'
                : ''
            }>
            Benefits
          </span>
        </NavLink>
      </li>
      <li id='nav-item'>
        <NavLink id='nav-link' to='/home/missingpoints'>
          <span
            className={
              pathname.includes('missingpoints') && !isHovering
                ? 'nav-ActiveBar'
                : ''
            }>
            Missing Points
          </span>
        </NavLink>
      </li>
    </ul>
  );
}

export default NavBar;
