export default {
  isLoggedIn: false,
  member: null,
  errorMessage: null,
  isSidebarVisible: false,
  isSidebarOpen: true,
  isChangePasswordOpen: false,
  isChangeEmailOpen: false,
  prevPath: '',
};
