import actionTypes from './user.actionTypes';
import initialStates from './user.initialStates';

const userReducer = (state = initialStates, { type, payload }) => {
  switch (type) {
    case actionTypes.USER_LOGIN_START:
      return {
        ...state,
        isLoggedIn: false,
        member: null,
        errorMessage: null,
      };
    case actionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        member: payload,
      };

    case actionTypes.USER_LOGIN_ERROR:
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: payload,
      };

    case actionTypes.USER_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        member: null,
      };

    case actionTypes.SET_SIDEBAR_VISIBILITY:
      return {
        ...state,
        isSidebarVisible: payload,
      };

    case actionTypes.SET_PREV_PATH:
      return {
        ...state,
        prevPath: payload,
      };

    default:
      return state;
  }
};

export default userReducer;
