import React from 'react';
import './spinner.css';

export default function Spinner({ fill }) {
  return (
    <div className='spinner-container'>
      <div className='loading-spinner'></div>
    </div>
  );
}
