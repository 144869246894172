import actionTypes from './easy.actionTypes';
import initialStates from './easy.initialStates';

const easyReducer = (state = initialStates, { type, payload }) => {
  switch (type) {
    //case LOGIN: return {
    //  ...state,
    //  user: action.payload
    //}
    //case SET_THEME: return {
    //  ...state,
    //  theme: action.payload
    //}
    case actionTypes.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: payload,
      };
    case actionTypes.SET_PROGRAM_LABEL:
      return {
        ...state,
        programLabel: payload,
      };
    case actionTypes.SET_SIDEBAR_VISIBILITY:
      return {
        ...state,
        isSidebarVisible: payload,
      };
    case actionTypes.SET_SIDEBAR_TOGGLER_VISIBILITY:
      return {
        ...state,
        showSidebarToggler: payload,
      };
    default:
      return state;
  }
};

export default easyReducer;
