const Ixian = [
  {
    Name: 'WHITE TIER',
    Apply: 'Upon Registration',
    Benefits: [
      'Loyalty points & offers only for members',
      'eCheck in prior to arrival (via hotel app)',
      'Complimentary Roundtrip transfer to/from the airport/port',
      'Discount on next visit (direct bookings via website)',
    ],
    Color: '#FFFFFF',
  },
  {
    Name: 'BLUE TIER',
    Apply: '1000 points reached/timeframe',
    Benefits: [
      'Dinner in Alazonia (once, excluding drinks, upon availability)',
      'Discount at The Ixian Spa',
      'Loyalty points & offers only for members',
      'eCheck in prior to arrival (via hotel app)',
      'Complimentary Roundtrip transfer to/from the airport/port',
      'Discount on all drinks',
      'Complimentary Early check in (upon availability)',
    ],
    Color: '#0000FF',
  },
  {
    Name: 'SILVER TIER',
    Apply: '50.000 points reached/timeframe',
    Benefits: [
      'Discount on next visit (direct bookings via website)',
      'Dinner in Alazonia (once, excluding drinks, upon availability)',
      'Discount at The Ixian Spa',
      'Loyalty points & offers only for members',
      'eCheck in prior to arrival (via hotel app)',
      'Complimentary Roundtrip transfer to/from the airport/port',
      'Private check-in',
      'Complimentary Early check in (upon availability)',
      'Complimentary Late check out (upon availability)',
      'Complimentary room upgrade (upon availability, next category)',
      'Complimentary room nights (points redemption)',
    ],
    Color: '#c0c0c0',
  },
  {
    Name: 'GOLD TIER',
    Apply: '100.000 points reached/timeframe',
    Benefits: [
      'Dinner in Alazonia (once, excluding drinks, upon availability)',
      '15 mins complimentary massage per person (upon availability)',
      'Loyalty points & offers only for members',
      'eCheck in prior to arrival (via hotel app)',
      'Complimentary Roundtrip transfer to/from the airport/port',
      'Private check-in',
      'Complimentary Early check in (upon availability)',
      'Complimentary Late check out (upon availability)',
      'Complimentary room upgrade (upon availability, next category)',
      'Complimentary room nights (points redemption)',
      'Complimentary special dinner (once) set on the beachfront (excluding drinks, upon availability)',
      'Personalized beach towels (applies for reservations booked 2 months in advance)',
      'Personalized farewell gift (applies for reservations booked 2 months in advance)',
      'Daily restaurant specific table reservation (with 24hrs notice)',
    ],
    Color: '#ffd700',
  },
];

export default Ixian;
