import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { ProgramLabelDataContext } from '../../ContextProviders/programLabel';

function useFetch(url) {
  const programLabel = localStorage.getItem('programLabel');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const programLabelFromURL = urlParams.get('programLabel');

    setLoading(true);
    axios
      .get(url, {
        params: {
          programLabel:
            programLabelFromURL ?? localStorage.getItem('programLabel'),
          acid: sessionStorage['acid'],
        },
      })
      .then((response) => {
        console.log(response, 'useFetch');
        setData(response.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  return { data, loading, error };
}
export default useFetch;
