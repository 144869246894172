import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ThankYou from '../Components/ConfirmPage/ThankYou';

export default function ConfirmPage() {
  const navigate = useNavigate();
  const redirectTimeout = 5000;
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get('email');
  const token = queryParams.get('token');
  const programLabel = queryParams.get('programLabel');

  let success = false;

  const confirmHandler = () => {
    axios({
      method: 'post',
      baseURL: '/',
      url: 'api/account/confirm',
      data: { email: email, code: token, programLabel: programLabel },
    })
      .then((resp) => {
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.data.errorMessage);
        }
        success = true;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const redirect = () => navigate('/home');

  confirmHandler();
  //   setTimeout(redirect, redirectTimeout);

  return success ? <ThankYou /> : <ThankYou />;
}
