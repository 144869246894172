
import { debounce } from 'lodash';
import React, { useState, useEffect, useContext,useRef } from 'react';
import '../Components/Preferences/PreferencesAtlantica.css';

import Header from '../Components/Header';
import axios from 'axios';
import Message from '../Components/Message/Message';
import { ThemeDataContext } from '../ContextProviders/theme';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import DatePicker from 'react-datepicker';
import { CiEdit } from "react-icons/ci";
import toast, { Toaster, useToaster, useToasterStore } from 'react-hot-toast';
import Exclamation from '../Icons/Exclamation';
import Tick from '../Icons/Tick';
import { CFormTextarea } from '@coreui/react'



const hideOptions=(tagDescr)=>{
  let hiddenOptions = [''];
  return hiddenOptions.includes(tagDescr);
}

const hasRadioOptions = (group) => {
  return group.tagInfo.some((tag) => tag.type === "83");
};

export default function Preferences({ toggle }) {
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [textareaValues, setTextareaValues] = useState([]);
  const [textAreaVisibility, setTextAreaVisibility] = useState({});

  const textareaRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        closeTextArea();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
useEffect(() => {
  const handleClickOutside = (event) => {
    if (!event.target.closest('.textarea-wrapper')) {
      closeTextArea();
    }
  };

  document.addEventListener('click', handleClickOutside);
  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, []);

  const style = {
    container: (base) => ({
      ...base,
      marginTop: '0.95rem',
      height: '28px',
    }),
    valueContainer: (base) => ({
      ...base,

      marginLeft: '-0.20rem',
    }),
    group: (base) => ({
      ...base,
    }),
    menu: (base) => ({
      ...base,
    }),
    control: (base) => ({
      ...base,
      border: 0,
      '&:hover': { borderColor: '#535353' },
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: '#535353',
      boxShadow: 'none',
      borderRadius: 'none',

      fontSize: '1.25rem',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: '#111',
      marginLeft: '-0.20rem',
    }),
    input: (base) => ({
      ...base,

      marginLeft: '-0.20rem',
    }),
  };

  const mobileStyle = {
    container: (base) => ({
      ...base,
      marginTop: '0.95rem',
      height: '28px',
    }),
    valueContainer: (base) => ({
      ...base,

      marginLeft: '-0.20rem',
    }),
    group: (base) => ({
      ...base,
    }),
    menu: (base) => ({
      ...base,
    }),
    control: (base) => ({
      ...base,
      border: 0,
      '&:hover': { borderColor: '#535353' },
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: '#535353',
      borderRadius: 'none',

      boxShadow: 'none',
      fontSize: '1.25rem',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: '#111',
      marginLeft: '-0.20rem',
    }),
    input: (base) => ({
      ...base,
      marginLeft: '-0.20rem',
    }),
  };
  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };
 

  const chckboxstyle = {
    width:'15px',
    height:'15px',
    border:'1px solid ',
    borderColor: `${buttonBackgroundColor}`,   
    backgroundColor:`${buttonBackgroundColor}`

  };
  const radioStyle = {
    width:'15px',
    height:'15px',
    border:'1px solid ',
    borderColor: `${buttonBackgroundColor}`,
    backgroundColor:`${buttonBackgroundColor}`
  };
  const editstyle = {
    color: `${buttonBackgroundColor}`,
    width:'20px',
    height:'20px'
  };
 

const isSidebarVisible = useSelector((state) => state.user.isSidebarVisible);


  const Lg = useMediaQuery({
    query: '(max-width: 480px) ',
  });
  const urlParams = new URLSearchParams(window.location.search);
  const programLabelFromURL = urlParams.get('programLabel');
  const programLabel =
    programLabelFromURL ?? localStorage.getItem('programLabel');


  useEffect(() => {
    if (!localStorage['programLabel']) {
      localStorage.setItem('programLabel', programLabel);
    }
  }, [programLabel]);

  useEffect(() => {
    if (
      localStorage.getItem('programLabel') !== programLabelFromURL &&
      programLabelFromURL
    ) {
      localStorage.setItem('programLabel', programLabelFromURL);
    }
  }, [programLabelFromURL]);



  useEffect(() => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/member/getPreferencesNew',
      params: {
        acid: sessionStorage.getItem('acid'),
        programLabel,
      },
    })
    .then((response) => {
      const responseData = response.data.prefNewList;
  
      // Update the received data to include the value property with the received values
      const updatedData = responseData.map((item) => ({
        ...item,
        preferencesElement: item.preferencesElement.map((category) => ({
          ...category,
          tagInfo: category.tagInfo.sort((a, b) => a.tagGroupTagGroup.localeCompare(b.tagGroupTagGroup)).map((group) => ({
            ...group,
            tagInfo: group.tagInfo
              .filter(option => option.isInternal !== 0 && option.isInternal !== null) // Filter options with isInternal 0 or null
              .map((option) => ({
                ...option,
                value: option.value, // Set the value received from the API
                type: option.type,
              }))
              .sort((a, b) => {
                const aIsOther = a.tagDescr.toLowerCase().includes('other');
                const bIsOther = b.tagDescr.toLowerCase().includes('other');
  
                // Move entries with the word "other" to the end of their group
                if (aIsOther && !bIsOther) {
                  return 1;
                } else if (!aIsOther && bIsOther) {
                  return -1;
                }
  
                // For non-"other" tags, sort them alphabetically based on their descriptions
                return a.tagDescr.localeCompare(b.tagDescr);
              }).map((option, index, array) => {
                // Compare current option with the next one to check for duplicates
                if (index < array.length - 1 && option.tagDescr.toLowerCase() === array[index + 1].tagDescr.toLowerCase()) {
                  return null; // If duplicate, return null
                }
                return option;
              }).filter(Boolean), // Filter out null values (duplicates)
          })),
        })),
      }));
  
      setData(updatedData);
  
      // Update the selectedDate state if the tag code matches the calendar code
      const defaultDate = '2023-07-01';
  
      // Update the selectedDate state if the tag code matches the calendar code
      updatedData.forEach((item) => {
        item.preferencesElement.forEach((category) => {
          category.tagInfo.forEach((group) => {
            group.tagInfo.forEach((option) => {
              if (option.type === '86') {
                // Check if the option.value is not null before setting selectedDate
                if (option.value !== null) {
                  setSelectedDate(option.value);
                } else {
                  // Handle the case when the value is null by setting a default value
                  setSelectedDate(defaultDate);
                }
              }
            });
          });
        });
      });
    })
    .catch((error) => {
      console.error(error);
    });
  }, []);
  
  
  

    const handleCheckboxChange = (categoryIndex, groupIndex, optionIndex) => {
        debouncedHandleCheckboxChange(categoryIndex, groupIndex, optionIndex);
  const updatedData = data.map((item) => {
    const updatedCategory = {
      ...item,
      preferencesElement: item.preferencesElement.map((category, cIndex) => {
        if (cIndex === categoryIndex) {
          const updatedGroup = {
            ...category,
            tagInfo: category.tagInfo.map((group, gIndex) => {
              if (gIndex === groupIndex) {
                const updatedOption = {
                  ...group,
                  tagInfo: group.tagInfo.map((option, oIndex) => {
                    if (oIndex === optionIndex) {
                      return {
                        ...option,
                          value: option.value === "TRUE" ? "FALSE" : "TRUE",
                          clicked: !option.clicked, 
                      };
                    }
                    return option;
                  }),
                };
                return updatedOption;
              }
              return group;
            }),
          };
          return updatedGroup;
        }
        return category;
      }),
    };
    return updatedCategory;
  });

  setData(updatedData);
};
    const debouncedHandleCheckboxChange = debounce(handleCheckboxChange, 10000000);

    const handleRadioChange = (categoryIndex, groupIndex, selectedOptionIndex) => {
      debouncedHandleRadioChange(categoryIndex, groupIndex, selectedOptionIndex);
      const updatedData = data.map((item) => {
          const updatedCategory = {
              ...item,
              preferencesElement: item.preferencesElement.map((category, cIndex) => {
                  if (cIndex === categoryIndex) {
                      const updatedGroup = {
                          ...category,
                          tagInfo: category.tagInfo.map((group, gIndex) => {
                              if (gIndex === groupIndex) {
                                  const updatedOption = {
                                      ...group,
                                      tagInfo: group.tagInfo.map((option, oIndex) => {
                                          if (oIndex === selectedOptionIndex) {
                                              const newValue = option.value === "TRUE" ? "FALSE" : "TRUE";
                                              return {
                                                  ...option,
                                                  value: newValue,
                                                  clicked: !option.clicked,
                                                  previousValue: option.value // Save previous value
                                              };
                                          } else {
                                              return {
                                                  ...option,
                                                  value: "FALSE", // Set all other options to false
                                                  clicked: false
                                              };
                                          }
                                      }),
                                  };
                                  return updatedOption;
                              }
                              return group;
                          }),
                      };
                      return updatedGroup;
                  }
                  return category;
              }),
          };
          return updatedCategory;
      });
      setData(updatedData);
    };
    
  const debouncedHandleRadioChange = debounce(handleRadioChange, 10000000);


  const handleSelectorChange = (categoryIndex, groupIndex, value, optionIndex) => {
    debouncedHandleSelectorChange(categoryIndex, groupIndex, value, optionIndex);
    const updatedData = data.map((item) => {
      const updatedCategory = {
        ...item,
        preferencesElement: item.preferencesElement.map((category, cIndex) => {
          if (cIndex === categoryIndex) {
            const updatedGroup = {
              ...category,
              tagInfo: category.tagInfo.map((group, gIndex) => {
                if (gIndex === groupIndex) {
                  const updatedOptions = group.tagInfo.map((option, oIndex) => {
                    if (option.type === '82' && oIndex !== optionIndex) {
                      // Clear other selector options under the same taggrouptaggroup
                      return {
                        ...option,
                        value: '',
                        clicked: false,
                      };
                    }
                    if (oIndex === optionIndex) {
                      return {
                        ...option,
                        value: value,
                        clicked: true,
                      };
                    }
                    return option;
                  });
                  return {
                    ...group,
                    tagInfo: updatedOptions,
                  };
                }
                return group;
              }),
            };
            return updatedGroup;
          }
          return category;
        }),
      };
      return updatedCategory;
    });
  
    setData(updatedData);
  };
  
  
  const debouncedHandleSelectorChange = debounce(handleSelectorChange, 10000000);
  
  let renderedSelectors = [];
  
  const renderSelector = (group, categoryIndex, groupIndex, optionIndex) => {
    // Check if a selector has already been rendered for this category and group
    if (renderedSelectors.includes(`${groupIndex}_${categoryIndex}`)) {
      return null; // Skip rendering if a selector has already been rendered
    }
  
    const dropdownOptions = group.tagInfo
      .filter((tag) => tag.type === "82")
      .map((tag, index) => {
        // Assign the index of each option as the optionIndex
        return {
          ...tag,
          optionIndex: index,
        };
      });
  
    // Sort the dropdown options using the custom sorting function
    const sortedOptions = dropdownOptions.sort((a, b) => {
      // Extract the first two numbers from the options
      const timeRangeRegex = /(\d{2}):/;
      const aTimeRangeMatch = a.tagDescr.match(timeRangeRegex);
      const bTimeRangeMatch = b.tagDescr.match(timeRangeRegex);
  
      if (aTimeRangeMatch && bTimeRangeMatch) {
        const aStartTime = parseInt(aTimeRangeMatch[1]);
        const bStartTime = parseInt(bTimeRangeMatch[1]);
  
        // Sort based on the first two numbers
        return aStartTime - bStartTime;
      }
  
      // Default sorting
      return a.tagDescr.localeCompare(b.tagDescr);
    });
  
    // Find the selected option based on the received data
    const selectedOption = group.tagInfo.find((option) => option.type === "82" && option.value !== "");
  
    // Set the group value based on the selected option
    const groupValue = selectedOption ? selectedOption.value : "";
  
    // Add the combined category and group index to the rendered selectors list
    renderedSelectors.push(`${groupIndex}_${categoryIndex}`);
  
    return (
      <div>
        <div>{group.tagGroupTagGroup}</div>
        <select
          name={`selector_${categoryIndex}_${groupIndex}`}
          className="selectorVerityLite"
          value={groupValue} // Use the groupValue instead of group.value
          onChange={(e) =>
            handleSelectorChange(
              categoryIndex,
              groupIndex,
              e.target.value,
              parseInt(e.target.options[e.target.selectedIndex].getAttribute("data-option-index")) // Get the option index from the selected option
            )
          }
          style={{ alignSelf: "center" }}
        >
          <option value="">Select an option</option>
          {sortedOptions.map((dropdownOption) => (
            <option key={dropdownOption.optionIndex} value={dropdownOption.tagDescr} data-option-index={dropdownOption.optionIndex}>
              {dropdownOption.tagDescr}
            </option>
          ))}
        </select>
      </div>
    );
  };
  


const handleDateSelect = (date, tagCode) => {
  const updatedData = data.map((item) => {
    const updatedCategory = {
      ...item,
      preferencesElement: item.preferencesElement.map((category) => {
        const updatedGroup = {
          ...category,
          tagInfo: category.tagInfo.map((group) => {
            const updatedOptions = group.tagInfo.map((option) => {
              if (option.tagCode === tagCode) {
                return {
                  ...option,
                  value: date ? date.toLocaleDateString() : "", // Convert date to string format
                  clicked: true,
                };
              }
              return option;
            });
            return {
              ...group,
              tagInfo: updatedOptions,
            };
          }),
        };
        return updatedGroup;
      }),
    };
    return updatedCategory;
  });

  setData(updatedData);

  // Additional handling based on tagCode if needed
};


const renderAnniversary = (option) => {
  const handleModalClose = () => {
    setTrigger(false); // Close the modal
  };

  if (option.type === "86") {
    const selectedDate = option.value ? new Date(option.value) : null;

    return (
      <div style={{ position: 'relative', zIndex: 1000,width:'200px' }}> {/* Adjust zIndex as needed */}
        <div>{option.tagDescr}</div>
        <div style={{border:'1px solid rgb(88, 89, 85)', borderRadius:'10px', padding:'5px',width:'350px'}}>
        <DatePicker
          className="vlite-datepicker"
          dateFormat="dd/MM/yyyy"
          onChange={(date) => handleDateSelect(date, option.tagCode)}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          placeholderText={option.tagDescr}
          setTrigger={setTrigger}
          trigger={trigger}
          onClose={handleModalClose}
          selected={selectedDate}
        />
        </div>
      </div>
    );
  }

  return null;
};



const handleTextAreaClick = (textareaId) => {
  setTextAreaVisibility((prevState) => ({
    ...prevState,
    [textareaId]: !prevState[textareaId]
  }));
};


const renderTextArea = (option, group, categoryIndex, groupIndex, optionIndex) => {
  
  const tagType = "85";
  const shouldRenderTextArea = tagType;
  const textareaId = `textarea_${option.tagCode}`;
  const isVisible = textAreaVisibility[textareaId];

  // Check if the option type is 82 and has a value
  const isSelectorWithValue = group.tagInfo.some(
    (tag) => tag.type === '82' && tag.value!==''
  );

  // Check if the text area should be disabled
  const isDisabled = shouldRenderTextArea && isSelectorWithValue;

  if (shouldRenderTextArea && isVisible) {
    return (
      <div className="textarea-wrapper " >
        <button
         disabled={isDisabled} // Add disabled attribute
          onClick={() => handleTextAreaClick(textareaId)}
          style={{
            border: 'none',
            background: 'none',
            padding: '0',
            font: 'inherit',
            cursor: 'pointer',
            color: isDisabled ? 'gray' : 'inherit',     
            width:'fit-content'  
               }}
               
          className='flex justify-center '
        >
          {option.tagDescr}
          <CiEdit style={editstyle}></CiEdit>
        </button>
        <textarea
        
          defaultValue={option.value === 'FALSE' ? '' : option.value}
          className={`shadow-lg shadow-${buttonBackgroundColor}`}
          name={`textarea_${option.tagCode}`}
          style={{
            borderRadius: '10px',
            border: '1px solid black',
            color: 'black',
          }}
          rows={4}
          onChange={(e) => {
            handleTextAreaChange(categoryIndex, groupIndex, optionIndex, e.target.value);
          }}
        />
      </div>
    );
  } else if (shouldRenderTextArea) {
    return (
      <div className="textarea-wrapper" >
        <button
          disabled={isDisabled} // Add disabled attribute
          className='flex justify-center'
          style={{
            border: 'none',
            background: 'none',
            padding: '0',
            font: 'inherit',
            cursor: 'pointer',
            outline: 'none',
            width:'fit-content'  ,

            color: isDisabled ? 'gray' : 'inherit',
                      }}
          onClick={() => handleTextAreaClick(textareaId)}
        >
          {option.tagDescr}
          <CiEdit style={editstyle}></CiEdit>
        </button>
        <CFormTextarea
          disabled
          readOnly
          rows={1}
          className='flex justify-start'
          style={{
            width: '200px',
            backgroundColor: 'white',

          }}
        >
          {option.value === 'FALSE' ? '' : option.value}
        </CFormTextarea>
      </div>
    );
  }

  return null;
};


const handleTextAreaChange = (categoryIndex, groupIndex, optionIndex, value) => {
  const updatedData = data.map((item) => {
    const updatedCategory = {
      ...item,
      preferencesElement: item.preferencesElement.map((category, cIndex) => {
        if (cIndex === categoryIndex) {
          const updatedGroup = {
            ...category,
            tagInfo: category.tagInfo.map((group, gIndex) => {
              if (gIndex === groupIndex) {
                const updatedOptions = group.tagInfo.map((option, oIndex) => {
                  if (oIndex === optionIndex) {
                    return {
                      ...option,
                        value: value,
                        clicked: true,
                    };
                  }
                  return option;
                }); 
                return {
                  ...group,
                  tagInfo: updatedOptions,
                };
              }
              return group;
            }),
          };
          return updatedGroup;
        }
        return category;
      }),
    };
    return updatedCategory;
  });
  setData(updatedData);
};

const closeTextArea = () => {
  // Close the textarea by resetting the visibility state
  setTextAreaVisibility({});
};
    const updatedPreferences = [];

const handleClick = () => {
  setsShowToast(false)

        data.forEach((item) => {
            const updatedPreferencesElement = {
                programLabel: item.programLabel,
                acid: item.acid,
                cErrorCode: item.cErrorCode,
                cErrorMessage: item.cErrorMessage,
                preferencesElement: [],
            };

            item.preferencesElement.forEach((category) => {
                category.tagInfo.forEach((group) => {
                    group.tagInfo.forEach((option) => {
                        if (option.clicked === true) { // Check if the option was clicked
                            const updatedTagCategory = {
                              updatedtagDescr: option.tagDescr,
                                updatedtagCode: option.tagCode,
                                updatedvalTagId: option.valTagId,
                                updatedvalue: option.value,
                            };
                            updatedPreferencesElement.preferencesElement.push(updatedTagCategory);
                        }
                        if(option.type==='82'&&option.clicked === false){
                          const removeSelector= {
                            updatedtagDescr: option.tagDescr,
                            updatedtagCode: option.tagCode,
                            updatedvalTagId: option.valTagId,
                            updatedvalue: '',
                          }
                          updatedPreferencesElement.preferencesElement.push(removeSelector);
                        }
                        if(option.type==='83'&&option.clicked === false){
                          const removeSelector= {
                            updatedtagDescr: option.tagDescr,
                            updatedtagCode: option.tagCode,
                            updatedvalTagId: option.valTagId,
                            updatedvalue: 'FALSE',
                          }
                          updatedPreferencesElement.preferencesElement.push(removeSelector);
                        }
                    });
                });
            });

            if (updatedPreferencesElement.preferencesElement.length > 0) {
                // Only add the updatedPreferencesElement if there are clicked options
                updatedPreferences.push(updatedPreferencesElement);
            }
        });
        axios({
            method: 'put',
            baseURL: '/',
            url: 'api/member/updatePreferences',
            data: {
                prefNewList: updatedPreferences,
            },
        })
            .then((response) => {
                // Handle successful response
                notifySuccess();

              })
            .catch((error) => {
              notifyError(error.toString());

            });
    };


  const { toasts, handlers } = useToaster();

const [showToast,setsShowToast]=useState()
  const notifySuccess = () => {toast.success('Your preferences have been successfully updated.');
    setsShowToast(true)
  }
  const notifyError = (error) =>{ toast.error(error);
    setsShowToast(true)

  }

  const TOAST_LIMIT = 1;

  useEffect(() => {
    console.log(toasts, 'toasts');
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  const handleDismiss = () => {
    toasts.forEach((t) => toast.dismiss(t.id));
  };

  const toasterRef = useRef(null);

  useEffect(() => {
    if (toasterRef.current) {
      // Adding a timeout to ensure the content is rendered before scrolling
      setTimeout(() => {
        toasterRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, [showToast]);

  return (
    <>
          <div onClick={handleDismiss}>
        <div ref={toasterRef}>
          <Toaster
            duration='10000000'
            position='top-center'
            containerClassName='MyProfileToaster'
            containerStyle={{
              top: 10,
              left: 10,
              bottom: 10,
              right: 10,
            }}
            toastOptions={{
              error: {
                duration: 10000000,
                icon: (
                  <Exclamation
                    width='50'
                    height='50'
                    viewBox='-4 1 25 15'
                    color='red'
                  />
                ),
                style: {
                  color: 'black',
                  fontWeight: 'normal',
                  background: 'white',
                  filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                  fontFamily: 'Lato',
                  borderRadius: '7px',
                  height: 'auto',
                  maxHeight: '65px',
                  fontWeight: '600',
                  borderLeft: '6.5px solid red',
                },
              },
              success: {
                duration: 100000000,
                icon: (
                  <Tick
                    color='#26A65B'
                    width='50'
                    height='50'
                    viewBox='0 0 50 50'
                  />
                ),
                style: {
                  color: 'black',
                  fontWeight: 'normal',
                  background: '#fff',
                  filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                  fontFamily: 'Lato',
                  borderRadius: '7px',
                  height: 'auto',
                  fontWeight: '600',
                  borderLeft: '6.5px solid #26A65B',
                },
              },
            }}
          />
        </div>
      </div>
      <Header />

      <div className='flex'>
        <div
          className='flex-1 min-h-screen z-10 overflow-x-hide'
          style={{
            visibility: `${isSidebarVisible ? 'hidden' : 'visible'}`,
            background: '#FFFFFF',
          }}
        >

<div className='w-full h-[90px] Activity-heading text-center prefheader' style={{marginTop:'40px'}}>
<h1 >
              My Preferences
            </h1>
          </div>
          <div className='w-full text-center mx-auto'>
            <h1 className='min-w-[902px] font-roboto font-normal text-lg leading-[47px] text-skin-primary'>
            Please fill in your preferences in order for us to provide you the best experience.
            </h1>
          </div>
          {data &&
      data.map((item) => (
        <div key={item.acid}>
          {item.preferencesElement.map((category, categoryIndex) => {
        // Check if the category title is already rendered in a previous category
        const isCategoryTitleRendered =
          item.preferencesElement
            .slice(0, categoryIndex) // Exclude the current category
            .some((prevCategory) => prevCategory.tagCategory === category.tagCategory);

        // Render the category title only if it's not rendered in a previous category
        if (!isCategoryTitleRendered) {
              return (
                <div key={category.tagCategoryCode}>
              <div className='Preferences-TagCategory'>
                <h3>{category.tagCategory}</h3>
                <span>
                  <hr style={{ border: '1px solid #585955 ' }} />
                </span>
              </div>
              <div className='Preferences-TagGroupContainer'>
                    {category.tagInfo.map((group, groupIndex) => {
                  // Check if any of the options in the group are rendered
                  const isGroupRendered = group.tagInfo.some((option) => {
                    // Check if the option is already rendered in a previous category
                    const isOptionRendered =
                      item.preferencesElement
                        .slice(0, categoryIndex) // Exclude the current category
                        .some((prevCategory) =>
                          prevCategory.tagInfo.some((prevGroup) =>
                            prevGroup.tagInfo.some(
                              (prevOption) => prevOption.tagDescr === option.tagDescr
                            )
                          )
                        );

                    return !isOptionRendered; // Return true if the option is not rendered
                  });

                
                  if (!isGroupRendered ) {
                    return null; // Skip rendering the group
                  }
                  // Render the group if it should be displayed
                  // Render the group only if any of the options are rendered
                  if (isGroupRendered) {
                        return (
                          <div className='Preferences-TagGroup' key={group.tagGroupCode}>
                            <h4 className={`Preferences-TagHeading mt-5 ${ group.tagInfo.some(tag => tag.type === "72") ? '' : ''}`}>
                            {group.tagGroupTagGroup}
                          
                            </h4>  
                            <span class=" text-gray-500 italic ml-7"> {group.tagNote}</span>                  
                                                     
                            <div className='Preferences-checkBoxGroup'>
                              {group.tagInfo.map((option, optionIndex) => {
                            // Check if the option is already rendered in a previous category
                            const isOptionHidden = hideOptions(option.tagDescr);
                            // Render the option only if it should not be hidden
                            if (!isOptionHidden) {
                            const isOptionRendered =
                              item.preferencesElement
                                .slice(0, categoryIndex) // Exclude the current category
                                .some((prevCategory) =>
                                  prevCategory.tagInfo.some((prevGroup) =>
                                    prevGroup.tagInfo.some(
                                      (prevOption) => prevOption.tagDescr === option.tagDescr
                                    )
                                  )
                                );

                            // Render the option only if it's not rendered in a previous category
                            if (!isOptionRendered) {
                              if (option.type === "86") {
                                    // Render anniversary for elements with type '86'
                                    return (
                                      <div key={option.tagCode} className="Preferences-TagContainer">
                                        {renderAnniversary(option)}
                                      </div>
                                    );
                                  } else if (
                                option.type==="85"
                              ) {
                                // Render text area
                                return (
                                  <>
                                  <div key={option.tagDescr} className="Preferences-TagContainer">
                                    {renderTextArea(option,group, categoryIndex, groupIndex, optionIndex)}
                                  </div>
                                  </>
                                );
                              } else if ( option.type==="82") {
                                // Render selector
                                return (
                                    renderSelector(group, categoryIndex, groupIndex, optionIndex)
                                );
                              } else if (hasRadioOptions(group)) {
                                // Render radio button for accommodation and food options
                                return (
                                  <div key={option.tagGroupTagGroup}>
                                    <label className='Preferences-label'>
                                      <input
                                        className='checkboxPrefs mr-2 ' 
                                        style={radioStyle}
                                        type='radio'
                                        name={`radio_${categoryIndex}_${groupIndex}`}
                                        checked={option.value === "TRUE"}
                                        onChange={() =>
                                          handleRadioChange(categoryIndex, groupIndex, optionIndex)
                                        }
                                      />
                                      {option.tagDescr}
                                    </label>
                                  </div>
                                );
                              } else {
                                // Render checkbox for other options
                                return (
                                  <div key={option.tagDescr} className="Preferences-TagContainer">
                                    <label className="Preferences-label">
                                      <input
                                        className='checkboxPrefs mr-2 '
                                        style={chckboxstyle}
                                        type='checkbox'
                                        name={`checkbox_${categoryIndex}_${groupIndex}_${optionIndex}`}
                                        checked={option.value === "TRUE"}
                                        onChange={() =>
                                          handleCheckboxChange(categoryIndex, groupIndex, optionIndex)
                                        }
                                      />
                                      {option.tagDescr}
                                    </label>
                                  </div>
                                );
                              }
                            }
                            return null;

                            }
                                return null;
                              })}
                            </div>
                          </div>
                          
                        );
                      }

                      return null;
                    })}
                  </div>
                </div>
              );
            }

            return null;
          })}
        </div>
      ))}



      <div className={`w-full flex justify-center h-12 bcont`}>
            <button
              onClick={handleClick}
              style={buttonStyle}
            >
              Save
            </button>
            <br></br>
            <br></br>
            <br />
          </div>
          <br></br>
        </div>
        <br></br>
      </div>
    </>
  );
}

