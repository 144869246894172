import React from 'react';
import './ActivityAtlantica.css';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
function Listings({
  currentListings,
  showEvents,
  showRevenue,
  title = '',
  showNights,
  showRooms,
  showPoints,
  showCheckIn
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Tablet = useMediaQuery({
    query: '(min-width: 1024px) ',
  });

  const Mobile = useMediaQuery({
    query: '(min-width: 280px) and (max-width: 1023px)',
  });

  const programLabel = localStorage.getItem('programLabel');
  const GetEventType = (event) => {
    if (
      programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' &&
      event &&
      event === 'Booking'
    ) {
      return 'Upcoming Stay';
    } else if (
      programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' &&
      event &&
      event === 'Booking'
    ) {
      return 'Reservation';
    }
    return (event && event) || 'N/A';
  };

  const onClick = () => {
    dispatch({ type: "set", checkInInfo: currentListings });
    navigate("/home/OnlinePreCheckin");
  };
  console.log(currentListings, 'currentListings');
  if (Tablet)
    return (
      <div className='Activity-tableContainer'>
        {title && <h3 className='table-title'>{title}</h3>}
        <table id='Activity-table' className='Activity-table'>
          <thead>
            <tr id='Activity-thead-tr'>
              <th>HOTEL</th>
              <th>ARRIVAL</th>
              <th>DEPARTURE</th>
              {showNights && <th>NIGHTS</th>}
              {showEvents && <th>EVENT</th>}
              {showRooms && <th>ROOM</th>}
              {showRevenue && <th>REVENUE</th>}
              {showPoints && <th>POINTS</th>}
              {showCheckIn && <th>CheckIn</th>}
            </tr>
          </thead>
          <tbody>
            {currentListings?.map((item, index) => (
              <tr
                style={{
                  borderBottom: '2px solid var(--color-th)',
                  height: '100px',
                  fontSize: '1.15rem',
                  textAlign: 'center',
                }}
                key={index}>
                <td>{item?.partnerDescription || 'N/A'}</td>
                <td>
                  {moment(item?.arrivalDate).format('DD/MM/YYYY') || 'N/A'}
                </td>
                <td>
                  {moment(item?.departureDate).format('DD/MM/YYYY') || 'N/A'}
                </td>
                {showNights && <td>{item?.nights || 'N/A'} </td>}
                {showEvents && (
                  <td>{GetEventType(item?.eventTypeDescription)}</td>
                )}
                {showRooms && <td>{item?.roomType || 'N/A'}</td>}
                {showRevenue && (
                  <td>{Number(item?.eventRevenue).toFixed(2) || 'N/A'}</td>
                )}
                {showPoints && (
                  <td style={{ width: '60px' }}>
                    {item?.pointsEarned || 'N/A'}
                  </td>
                )}
                {showCheckIn&&(
                  <td>
                  <button onClick={onClick}>
                  CheckIn
                  </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  else if (Mobile) {
    return (
      <div className='Activity-tableContainer'>
        {title && <h3 className='table-title'>{title}</h3>}

        {currentListings?.map((item, index) => (
          <table key={index} id='Activity-table' className='Activity-table'>
            <thead></thead>
            <tbody
            // style={{
            //   border: '2px solid var(--color-th)',
            //   borderRadius: '50px',
            //   paddingRight: '1.5rem',
            // }}
            >
              <tr>
                <td>HOTEL</td>
                <td>{item?.partnerDescription || 'N/A'}</td>
              </tr>
              <tr>
                <td>ARRIVAL</td>
                <td>
                  {moment(item?.arrivalDate).format('DD/MM/YYYY') || 'N/A'}
                </td>
              </tr>
              <tr>
                <td>DEPARTURE</td>
                <td>
                  {moment(item?.departureDate).format('DD/MM/YYYY') || 'N/A'}
                </td>
              </tr>
              {showNights && (
                <tr>
                  <td>NIGHTS</td>
                  <td>{item?.nights || 'N/A'}</td>
                </tr>
              )}
              {showEvents && (
                <tr>
                  <td>EVENT</td>
                  <td>{GetEventType(item?.eventTypeDescription)}</td>
                </tr>
              )}
              {showRooms && (
                <tr>
                  <td style={{ fontColor: 'var(--color-th)' }}>ROOM</td>
                  <td>{item.nights || 'N/A'}</td>
                </tr>
              )}
              {showRevenue && (
                <tr>
                  <td style={{ fontColor: 'var(--color-th)' }}>REVENUE</td>
                  <td>{Number(item?.eventRevenue).toFixed(2) || 'N/A'}</td>
                </tr>
              )}
              {showPoints && (
                <tr>
                  <td style={{ fontColor: 'var(--color-th)' }}>POINTS</td>
                  <td>{item?.pointsEarned || 'N/A'}</td>
                </tr>
              )}
              {showCheckIn&&(
                  <td><button>CheckIn</button></td>
                )}
            </tbody>
          </table>
        ))}
      </div>
    );
  }
}

export default Listings;
