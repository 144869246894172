import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { useMediaQuery } from 'react-responsive';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Translation = ({ className, style }) => {
  const Lg = useMediaQuery({
    query: '(max-width: 1200px) ',
  });

  const { i18n } = useTranslation();

  const options = [
    { label: 'English', value: 'en', icon: 'uk.svg' },
    // { label: 'Greek', value: 'gr', icon: 'gr.svg' },
  ];
  const optionsLg = [
    { value: 'en', icon: 'uk.svg' },
    // { value: 'gr', icon: 'gr.svg' },
  ];
  const [selectedOption, setSelectedOption] = useState({
    label: 'English',
    value: 'en',
    icon: 'uk.svg',
  });

  const handleChange = (selectedOption) => {
    console.log('changing language...');
    console.log(selectedOption, 'selectedOption');
    setSelectedOption(selectedOption);
    i18n.changeLanguage(selectedOption.value);
  };

  const IconOption = (props) => {
    return (
      <div data-aos='fade-down'>
        <components.Option {...props}>
          <div style={{ display: 'flex', height: '35px' }}>
            <img
              src={require('../../../Assets/Icons/LanguageIcons/' +
                props.data.icon)}
              width='20'
              height='20'
              alt='languageIcon'
              style={{ marginRight: '0.5rem' }}
            />
            {props.data.label}
          </div>
        </components.Option>
      </div>
    );
  };
  const FlagAndValue = (props) => {
    return (
      <div>
        <components.ValueContainer {...props}>
          <div style={{ display: 'flex', height: '35px', padding: '7px' }}>
            <img
              src={require('../../../Assets/Icons/LanguageIcons/' +
                selectedOption.icon)}
              width='20'
              height='20'
              style={{ marginRight: '0.5rem' }}
              alt='languageIcon'
            />
            {!Lg && selectedOption.label}
          </div>
        </components.ValueContainer>
      </div>
    );
  };

  return (
    <Select
      value={selectedOption}
      styles={style}
      onChange={handleChange}
      className={className}
      components={{
        ValueContainer: FlagAndValue,
        Option: IconOption,
        IndicatorSeparator: () => null,
      }}
      options={Lg ? optionsLg : options}
    />
  );
};

export default Translation;
