import React from 'react';
import { NavLink } from 'react-router-dom';
import RightArrow from '../../Assets/Images/ArrowRight.svg';
import LeftArrow from '../../Assets/Images/ArrowLeft.svg';
import { GrNext } from 'react-icons/gr';
import './ActivityAtlantica.css';

const Navigate = ({ next, previous }) => {
  return (
    <div className='Activity-ArrowsContainer'>
      <div className='Activity-RightArrow'>
        <NavLink className='page-link' onClick={() => next()} to='#!'>
          <img src={RightArrow} alt='RightArrow' />
        </NavLink>
      </div>
      <div className='Activity-LeftArrow'>
        <NavLink className='page-link' onClick={() => previous()} to='#!'>
          <img src={LeftArrow} alt='LeftArrow' />
        </NavLink>
      </div>
    </div>
  );
};

export default Navigate;
