export default function Validate(values) {
  let errors = {};

  if (!values.email.trim()) {
    errors.email = 'Email is required';
  }

  if (!values.password.trim()) {
    errors.password = 'Password is required';
  }
  return errors;
}
