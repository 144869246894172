import React from 'react';

export default function Danger({
  size, // or any default size of your choice
  color, // or any color of your choice
  style,
  width,
  height,
  onClick,
  className,
}) {
  return (
    <svg
      onClick={onClick}
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 15.025C11.59 15.025 11.25 14.685 11.25 14.275V9.27502C11.25 8.86502 11.59 8.52502 12 8.52502C12.41 8.52502 12.75 8.86502 12.75 9.27502V14.275C12.75 14.685 12.41 15.025 12 15.025Z'
        fill={color}
      />
      <path
        d='M12 18.2751C11.94 18.2751 11.87 18.2651 11.8 18.2551C11.74 18.2451 11.68 18.2251 11.62 18.1951C11.56 18.1751 11.5 18.1451 11.44 18.1051C11.39 18.0651 11.34 18.0251 11.29 17.9851C11.11 17.7951 11 17.5351 11 17.2751C11 17.0151 11.11 16.7551 11.29 16.5651C11.34 16.5251 11.39 16.4851 11.44 16.4451C11.5 16.4051 11.56 16.3751 11.62 16.3551C11.68 16.3251 11.74 16.3051 11.8 16.2951C11.93 16.2651 12.07 16.2651 12.19 16.2951C12.26 16.3051 12.32 16.3251 12.38 16.3551C12.44 16.3751 12.5 16.4051 12.56 16.4451C12.61 16.4851 12.66 16.5251 12.71 16.5651C12.89 16.7551 13 17.0151 13 17.2751C13 17.5351 12.89 17.7951 12.71 17.9851C12.66 18.0251 12.61 18.0651 12.56 18.1051C12.5 18.1451 12.44 18.1751 12.38 18.1951C12.32 18.2251 12.26 18.2451 12.19 18.2551C12.13 18.2651 12.06 18.2751 12 18.2751Z'
        fill={color}
      />
      <path
        d='M18.0605 22.435H5.94046C3.99046 22.435 2.50046 21.725 1.74046 20.445C0.990464 19.165 1.09046 17.515 2.04046 15.805L8.10046 4.90499C9.10046 3.10499 10.4805 2.11499 12.0005 2.11499C13.5205 2.11499 14.9005 3.10499 15.9005 4.90499L21.9605 15.815C22.9105 17.525 23.0205 19.165 22.2605 20.455C21.5005 21.725 20.0105 22.435 18.0605 22.435ZM12.0005 3.61499C11.0605 3.61499 10.1405 4.33499 9.41046 5.63499L3.36046 16.545C2.68046 17.765 2.57046 18.885 3.04046 19.695C3.51046 20.505 4.55046 20.945 5.95046 20.945H18.0705C19.4705 20.945 20.5005 20.505 20.9805 19.695C21.4605 18.885 21.3405 17.775 20.6605 16.545L14.5905 5.63499C13.8605 4.33499 12.9405 3.61499 12.0005 3.61499Z'
        fill={color}
      />
    </svg>
  );
}
