import styled from 'styled-components';

export const Progress = styled.progress`
  position: relative;
  display: inline-block;
  height: 5px;
  background-color: #eee;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: gold;
    width: ${(props) => props.tierValue}%;
  }
`;
