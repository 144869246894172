import React, { useEffect, useState, useContext, useRef } from 'react';

import axios from 'axios';
import { ThemeDataContext } from '../../ContextProviders/theme';
import '../Components/MyProfile/MyProfileAtlantica.css';
import { RiEyeLine } from 'react-icons/ri';
import { Controller, useForm } from 'react-hook-form';
import Select, { components } from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import { InfoDataContext } from '../../ContextProviders/info';
import * as _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import toast, { Toaster, useToaster } from 'react-hot-toast';
import Exclamation from '../../Icons/Exclamation';
import Tick from '../../Icons/Tick';
import SideBar from '../Components/SideBar/SideBar2';
import TopBar from '../Components/TopBar/TopBar2';
import ChevronDown from '../../Icons/ChevronDown';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../Redux/reducers/vlite/vlite.actions';
import DontShow from '../../Icons/DontShow';
import Danger from '../../Icons/Danger';
import Toggler from '../Components/Toggler/Toggler';
import useStyles from '../Components/MyProfile/style';
import ErrorBoundary from '../Components/ErrorBoundary/ErrorBoundary';
import Birthday from '../Components/DayPicker/Birthday';
import Calendar from '../../Icons/Calendar';

export default function MyProfile() {
  const [countriesByCountry, setCountriesByCountry] = useState([]);
  const [countriesByNationality, setCountriesByNationality] = useState([]);
  const [firstNameDisabled, setFirstNameIsDisabled] = useState(true);
  const [lastNameDisabled, setLastNameIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [responseNationality, setResponseNationality] = useState('');
  const [responseCountry, setResponseCountry] = useState('');
  const [firstnamePlaceholder, setFirstnamePlaceholder] = useState('');
  const [lastnamePlaceholder, setLastnamePlaceholder] = useState('');
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmNewPasswordShown, setConfirmNewPasswordShown] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const { style, styleErrors, mobileStyle, mobileStyleErrors } = useStyles();

  const urlParams = new URLSearchParams(window.location.search);
  const programLabelFromURL = urlParams.get('programLabel');
  const programLabel =
    programLabelFromURL ?? localStorage.getItem('programLabel');
  const emailRef = useRef(false);
  const {
    email,
    country,
    nationality,
    firstname,
    lastname,
    idCard,
    birthday: birthdate,
    city,
    address,
    postalCode,
    phoneMobile,
    promotionalMailAccepted,
  } = useContext(InfoDataContext) || {};

  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const handlePopper = () => {
    setIsPopperOpen(!isPopperOpen);
    // buttonRef?.current?.focus();
  };

  useEffect(() => {
    if (!localStorage['programLabel']) {
      localStorage.setItem('programLabel', programLabel);
    }
  }, [programLabel]);

  useEffect(() => {
    if (
      localStorage.getItem('programLabel') !== programLabelFromURL &&
      programLabelFromURL
    ) {
      localStorage.setItem('programLabel', programLabelFromURL);
    }
  }, [programLabelFromURL]);

  console.log(email, 'email');

  const dispatch = useDispatch();

  const isChangePasswordOpen = useSelector(
    (state) => state.vlite.isChangePasswordOpen
  );
  const isChangeEmailOpen = useSelector(
    (state) => state.vlite.isChangeEmailOpen
  );

  const toggleChangePassword = () => {
    dispatch(actions.setChangePasswordOpen(!isChangePasswordOpen));
  };
  const toggleChangeEmail = () => {
    dispatch(actions.setChangeEmailOpen(!isChangeEmailOpen));
    resetField('email');
  };

  const toggleCurrentPassword = () => {
    setCurrentPasswordShown(!currentPasswordShown);
  };
  const toggleNewPassword = () => {
    setNewPasswordShown(!newPasswordShown);
  };
  const toggleConfirmNewPassword = () => {
    setConfirmNewPasswordShown(!confirmNewPasswordShown);
  };

  const theme = useContext(ThemeDataContext);

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };
  const linkStyle = {
    color: `${buttonBackgroundColor}`,
  };

  const minAge = 18;
  const maxAge = 110;

  const initialValues = {
    email: '',
    newEmail: '',
    firstname: '',
    lastname: '',
    birthday: '',
    postalCode: '',
    address: '',
    country: { value: '', label: '' },
    city: '',
    promotionalMailAccepted: false,
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
    resetField,
    clearErrors,
    setError,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: initialValues,
  });

  const firstnameWatch = watch('firstname');
  const lastnameWatch = watch('lastname');
  const newPassword = watch('newPassword');
  const userInfo = JSON.parse(localStorage['userInfo']) || {};
  const programId = userInfo.programId || {};

  const notifyPassComposition = () =>
    toast(
      <div
        style={{
          display: 'flex',
          width: '100%',
          borderRadius: '7px',
          fontFamily: 'Lato',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
          background: 'transparent',
          color: `black`,
        }}>
        <div
          style={{
            display: 'flex',
            order: '1',
            alignItems: 'center',
            width: '20%',
            transform: 'translateX(-10%)',
          }}>
          <Exclamation
            width='50'
            height='50'
            viewBox='-4 1 25 15'
            color='red'
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            order: '2',
            width: '80%',
            transform: 'translateX(0%)',
          }}>
          Password must contain at least: 8 latin characters, 1 uppercase
          letter, 1 lowercase letter, 1 number, 1 symbol.
        </div>
      </div>,
      {
        duration: 10000,
        style: {
          borderLeft: '6.5px solid red',
          maxWidth: '375px',
        },

        position: 'top-center',
      }
    );

  // const setFirstname = (firstname) => {
  //   if (firstname === null || firstname === '') {
  //     setError('firstname', {
  //       type: 'custom',
  //       message: 'Firstname is required',
  //     });
  //     return;
  //   }
  //   setValue('firstname', firstname);

  //   setFirstNameIsDisabled(true);
  // };
  // const setLastname = (lastname) => {
  //   if (lastname === null || lastname === '') {
  //     setError('lastname', {
  //       type: 'custom',
  //       message: 'Lastname is required',
  //     });
  //     return;
  //   }
  //   setValue('lastname', lastname);
  //   setLastNameIsDisabled(true);
  // };

  useEffect(() => {
    setValue('firstname', firstname?.toUpperCase());
    setValue('lastname', lastname?.toUpperCase());
    setResponseCountry(country);
    setResponseNationality(nationality);
    setValue('email', email);
    setValue('passport', idCard);
    if (birthdate) {
      const [year, month, day] = birthdate?.substring(0, 10).split('-');
      const date = `${day}/${month}/${year}`;
      setValue('birthday', date);
    }
    setValue('country', {
      value: country,
      label: country,
    });
    setValue('address', address);
    setValue('postalCode', postalCode);
    setValue('promotionalMailAccepted', promotionalMailAccepted);
    setValue('city', city);
    setValue('phoneMobile', phoneMobile);
  }, [InfoDataContext, firstname, lastname]);

  const onSubmit = (data) => {
    console.log(data, 'My Profile Data');
    setIsSubmitted(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const [day, month, year] = data.birthday.split('/');
    const birthday = new Date(`${year}-${month}-${day}`);
    console.log(birthday, 'birthday');
    const birthdayISOString = birthday.toISOString();
    console.log(birthdayISOString, 'birthdayISOString');

    if (isChangePasswordOpen && isChangeEmailOpen) {
      const changeEmailPromise = axios({
        method: 'put',
        baseURL: '/',
        url: 'api/member/ChangeEmail',
        data: {
          emailOld: portalInfo?.data?.email,
          emailNew: data.newEmail,
          programId: programId.toString(),
        },
      });
      const changePasswordPromise = axios({
        method: 'post',
        baseURL: '/',
        url: 'api/password/change',
        data: {
          programLabel: localStorage.getItem('programLabel'),
          userEmail: portalInfo?.data?.email,
          oldPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
      });

      const updateInfoPromise = axios({
        method: 'put',
        baseURL: '/',
        url: 'api/member/UpdateInfo',
        data: {
          acid: localStorage.getItem('acid'),
          email: profileInfo?.data?.email,
          firstname: data.firstname?.toUpperCase(),
          lastname: data.lastname?.toUpperCase(),
          idCard: data.passport,

          // birthday: data.birthday,
          birthday: birthdayISOString,
          memberAddress: data.address,
          // memberMobPhoneNo: data.phoneMobile,
          memberPostCode: data.postalCode,
          country: data.country.value,
          // nationality: data.nationality.value,
          city: data.city,
          promotionalMailAccepted: data.promotionalMailAccepted,
          programLabel: localStorage.getItem('programLabel'),
          memberCode: userInfo.memberCode.toString(),
        },
      });
      Promise.all([
        updateInfoPromise,
        changePasswordPromise,
        changeEmailPromise,
      ])
        .then((resp) => {
          resp.forEach((r) => {
            if (r.data.errorCode !== 200) throw new Error(r.data.errorMessage);
          });
          notifySuccess();
          setWasSubmitted(!wasSubmitted);
          setTimeout(() => dispatch(actions.setChangeEmailOpen(false)), 2000);
        })
        .catch((error) => {
          console.log(error);
          notifyError(error.toString());
          setIsSubmitted(false);
        });
    } else if (isChangePasswordOpen && !isChangeEmailOpen) {
      const changePasswordPromise = axios({
        method: 'post',
        baseURL: '/',
        url: 'api/password/change',
        data: {
          programLabel: localStorage.getItem('programLabel'),
          userEmail: data.email,
          oldPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
      });

      const updateInfoPromise = axios({
        method: 'put',
        baseURL: '/',
        url: 'api/member/UpdateInfo',
        data: {
          acid: localStorage.getItem('acid'),
          email: data.email,
          firstname: data.firstname?.toUpperCase(),
          lastname: data.lastname?.toUpperCase(),
          idCard: data.passport,
          birthday: birthdayISOString,
          memberAddress: data.address,
          memberPostCode: data.postalCode,
          country: data.country.value,
          city: data.city,
          promotionalMailAccepted: data.promotionalMailAccepted,
          programLabel: localStorage.getItem('programLabel'),
          memberCode: userInfo.memberCode.toString(),
        },
      });
      Promise.all([updateInfoPromise, changePasswordPromise])
        .then((resp) => {
          resp.forEach((r) => {
            if (r.data.errorCode !== 200) throw new Error(r.data.errorMessage);
          });
          notifySuccess();
          setWasSubmitted(!wasSubmitted);
        })
        .catch((error) => {
          console.log(error);
          notifyError(error.toString());
          setIsSubmitted(false);
        });
    } else if (!isChangePasswordOpen && isChangeEmailOpen) {
      const changeEmailPromise = axios({
        method: 'put',
        baseURL: '/',
        url: 'api/member/ChangeEmail',
        data: {
          emailOld: profileInfo?.data?.email,
          emailNew: data.newEmail,
          programId: programId.toString(),
        },
      });
      const updateInfoPromise = axios({
        method: 'put',
        baseURL: '/',
        url: 'api/member/UpdateInfo',
        data: {
          acid: localStorage.getItem('acid'),
          email: profileInfo?.data?.email,
          firstname: data.firstname?.toUpperCase(),
          lastname: data.lastname?.toUpperCase(),
          idCard: data.passport,
          birthday: birthdayISOString,
          memberAddress: data.address,
          memberPostCode: data.postalCode,
          country: data.country.value,
          city: data.city,
          promotionalMailAccepted: data.promotionalMailAccepted,
          programLabel: localStorage.getItem('programLabel'),
          memberCode: userInfo.memberCode.toString(),
        },
      });
      Promise.all([updateInfoPromise, changeEmailPromise])
        .then((resp) => {
          resp.forEach((r) => {
            if (r.data.errorCode !== 200) throw new Error(r.data.errorMessage);
          });
          notifySuccess();
          setWasSubmitted(!wasSubmitted);
          setTimeout(() => dispatch(actions.setChangeEmailOpen(false)), 2000);
        })
        .catch((error) => {
          console.log(error);
          notifyError(error.toString());
          setIsSubmitted(false);
        });
    } else if (!isChangePasswordOpen && !isChangeEmailOpen) {
      axios({
        method: 'put',
        baseURL: '/',
        url: 'api/member/UpdateInfo',
        data: {
          acid: localStorage.getItem('acid'),
          email: data.email,
          firstname: data.firstname?.toUpperCase(),
          lastname: data.lastname?.toUpperCase(),
          idCard: data.passport,
          birthday: birthdayISOString,
          memberAddress: data.address,
          memberPostCode: data.postalCode,
          country: data.country.value,
          city: data.city,
          promotionalMailAccepted: data.promotionalMailAccepted,
          programLabel: localStorage.getItem('programLabel'),
          memberCode: userInfo.memberCode.toString(),
        },
      })
        .then((resp) => {
          if (resp.data.errorCode !== 200) {
            throw new Error(resp.data.ErrorMessage);
          }
          notifySuccess();
          setWasSubmitted(!wasSubmitted);
          setTimeout(() => dispatch(actions.setChangeEmailOpen(false)), 2000);
          reset();
        })
        .catch((error) => {
          console.log(error);
          notifyError(error.toString());
          setIsSubmitted(false);
        });
    }
  };

  useEffect(() => {
    // Get countries
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/init/getCountries',
      params: {
        programLabel: localStorage.getItem('programLabel'),
      },
    })
      .then((resp) => {
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.ErrorMessage);
        }
        setCountriesByCountry(resp.data.countriesOrderByCountry);
        setCountriesByNationality(resp.data.countriesOrderByNationality);
      })
      .catch((error) => {
        console.log(error);
      });

    // const setFirstname = (firstname) => {
    //   if (firstname === null || firstname === '') {

    //     setError('firstname', {
    //       type: 'custom',
    //       message: 'Firstname is required',
    //     });
    //     return;
    //   }
    //   setValue('firstname', firstname);
    //   setFirstNameIsDisabled(true);
    // };
    // const setLastname = (lastname) => {
    //   if (lastname === null || lastname === '') {

    //     setError('lastname', {
    //       type: 'custom',
    //       message: 'Lastname is required',
    //     });
    //     return;
    //   }
    //   setValue('lastname', lastname);
    //   setLastNameIsDisabled(true);
    // };

    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/member/getInfo',
      params: {
        acid: localStorage.getItem('acid'),
        programLabel: localStorage.getItem('programLabel'),
      },
    })
      .then((resp) => {
        console.log(resp, 'getInforesponse');
        setProfileInfo(resp);
        setValue('firstname', resp.data.firstname?.toUpperCase());
        setValue('lastname', resp.data.lastname?.toUpperCase());
        setResponseCountry(resp.data.country);
        setResponseNationality(resp.data.nationality);
        setValue('email', resp.data.email);
        setValue('passport', resp.data.idCard);
        if (resp.data.birthday) {
          const [year, month, day] = resp.data.birthday
            .substring(0, 10)
            .split('-');
          const date = `${day}/${month}/${year}`;
          setValue('birthday', date);
        }

        setValue('country', {
          value: resp.data.country,
          label: resp.data.country,
        });
        setValue('nationality', {
          value: resp.data.nationality,
          label: resp.data.nationality,
        });
        setValue('address', resp.data.address);
        setValue('postalCode', resp.data.postalCode);
        setValue('promotionalMailAccepted', resp.data.promotionalMailAccepted);
        setValue('city', resp.data.city);
        setValue('phoneMobile', resp.data.phoneMobile);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reset, setValue, wasSubmitted]);

  const onError = (errors, e) => console.log(errors, e, 'error');

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {errors.country && <Danger color='#D80027' width='25' height='24' />}
      </components.DropdownIndicator>
    );
  };

  const countryWatch = watch('country');
  const birthdayWatch = watch('birthday');
  const newPasswordWatch = watch('newPassword');
  const confirmNewPasswordWatch = watch('confirmNewPassword');
  const emailWatch = watch('email');

  const Lg = useMediaQuery({
    query: '(max-width: 1200px) ',
  });

  // const Nationality = (props) => (
  //   <Select
  //     {...props}
  //     placeholder='Select Nationality...'
  //     components={{
  //       IndicatorSeparator: () => null,
  //     }}
  //     menuPlacement='auto'
  //     styles={Tablet ? Styles.style : Styles.mobileStyle}
  //     options={countriesByNationality.map((c) => ({
  //       value: c.nationality,
  //       label: c.nationality,
  //     }))}
  //     onChange={props.onChange}
  //     onBlur={props.onBlur}
  //     selected={props.value}
  //     defaultValue={{ value: nationality, label: nationality }}
  //   />
  // );

  const countryArray =
    countriesByCountry.filter((c) => c.code === country) || {};
  const countryDescription = _.get(countryArray, '[0].description');

  useEffect(() => {
    const timer = setTimeout(() => {
      setValue('countries', countryDescription);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const Country = (props) => (
    <Select
      {...props}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      menuPlacement='auto'
      styles={!errors.country ? style : styleErrors}
      options={countriesByCountry.map((c) => ({
        value: c.description,
        label: c.description,
      }))}
      onChange={props.onChange}
      onBlur={props.onBlur}
      selected={props.value}
      defaultValue={{
        value: countryDescription,
        label: countryDescription,
      }}
    />
  );
  const CountryMobile = (props) => (
    <Select
      {...props}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      menuPlacement='auto'
      styles={!errors.country ? mobileStyle : mobileStyleErrors}
      options={countriesByCountry.map((c) => ({
        value: c.description,
        label: c.description,
      }))}
      onChange={props.onChange}
      onBlur={props.onBlur}
      selected={props.value}
      defaultValue={{
        value: countryDescription,
        label: countryDescription,
      }}
    />
  );

  const isSidebarVisible = useSelector((state) => state.user.isSidebarVisible);

  const { toasts } = useToaster();

  const notifySuccess = () =>
    toast.success('Your profile has been successfully updated.');
  const notifyError = (error) => toast.error(error);

  const TOAST_LIMIT = 1;

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  const handleDismiss = () => {
    toasts.forEach((t) => toast.dismiss(t.id));
  };

  // useEffect(() => {
  //   if (isSubmitted && responseCountry === null) {
  //     setError('country', { type: 'custom', message: 'Country is required' });
  //   }

  //   if (isSubmitted && responseNationality === null) {
  //     setError('nationality', {
  //       type: 'custom',
  //       message: 'Nationality is required',
  //     });
  //   }
  // }, [isSubmitted, country, nationality, setError]);

  // useEffect(() => {
  //   if (firstnameWatch === '' || firstnameWatch === null) {
  //     setFirstNameIsDisabled(false);
  //   }
  //   if (lastnameWatch === '' || lastnameWatch === null) {
  //     setLastNameIsDisabled(false);
  //   }
  // }, [
  //   firstnameWatch,
  //   lastnameWatch,
  //   setFirstNameIsDisabled,
  //   setLastNameIsDisabled,
  // ]);

  const pathname = window.location.pathname;

  useEffect(() => {
    dispatch(actions.setChangePasswordOpen(false));
    dispatch(actions.setChangeEmailOpen(false));
  }, [pathname]);

  const clearCountryErrors = () => {
    if (errors.country) {
      clearErrors('country');
    }
  };
  const clearBirthdayErrors = () => {
    if (errors.birthday) {
      clearErrors('birthday');
    }
  };

  function handleClick() {
    if (isChangeEmailOpen) {
      resetField('newEmail');
    } else if (!isChangeEmailOpen && wasSubmitted) {
      setValue('email', profileInfo?.data?.email);
    }
  }

  useEffect(() => {
    handleClick();
  }, [isChangeEmailOpen, wasSubmitted, profileInfo]);

  useEffect(() => {
    emailRef.current = true;

    return () => {
      emailRef.current = false;
    };
  }, []);

  if (Lg) {
    return (
      <>
        <ErrorBoundary>
          <div
            className={`flex vliteOver ${
              isChangePasswordOpen ? 'min-h-[1400px]' : 'min-h-[1200px]'
            }`}>
            <Toggler />
            <SideBar />
            <div
              className='w-full h-auto z-10'
              style={{
                visibility: `${isSidebarVisible ? 'hidden' : 'visible'}`,
                background: '#FFFFFF',
              }}>
              <div>
                <TopBar />
              </div>
              <div className='w-full h-[115px] mt-[6%] text-center px-[21.2%]'>
                <h1 className='w-full font-roboto font-normal text-[24px] leading-[28px] font-darkBlue'>
                  My <span className='font-semibold'>Profile</span>
                </h1>
              </div>
              <br />
              <form
                className={`${
                  isChangePasswordOpen && 'h-[900px]'
                }  w-[85%] h-[700px] my-0 mx-auto`}
                onSubmit={handleSubmit(onSubmit, onError)}>
                <div className='w-full relative'>
                  {!isChangeEmailOpen ? (
                    <div className='block w-full h-[100px] relative'>
                      <label
                        className='indent-2 h-[19px] font-roboto font-normal text-base  text-skin-primary leading-[18.75px] mb-[11.5px]'
                        htmlFor='vlite-myprofile-email'>
                        Email
                      </label>
                      <input
                        className={`w-full h-[41px] bg-transparent rounded-[5px] border-skin-secondary ${
                          errors.email && 'border-[#D80027]'
                        }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                        id='vlite-myprofile-email'
                        type='email'
                        ref={emailRef}
                        disabled='true'
                        {...register('email', {
                          required: 'Email is required',
                        })}
                      />
                      {errors.email && (
                        <Danger
                          color='#D80027'
                          width='25'
                          height='24'
                          className={`absolute right-[2%] top-[43%]`}
                        />
                      )}
                      {errors.email && (
                        <p
                          role='alert'
                          className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm  w-[200px] h-[23px] bg-[rgba(216,0,39,0.7)] py-[1%] mt-1'>
                          {errors?.email?.message}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className='block w-full h-[100px] relative'>
                      <label
                        className='indent-2 h-[19px] font-roboto font-normal text-base  text-skin-primary leading-[18.75px] mb-[11.5px]'
                        htmlFor='vlite-myprofile-email'>
                        New Email
                      </label>
                      <input
                        // style={{ border: '1px solid #14D5FF' }}
                        className={`w-full h-[41px] bg-transparent rounded-[5px] placeholder-darkBlue placeholder-opacity-[0.5] ${
                          errors.newEmail
                            ? 'border-[#D80027]'
                            : 'border-skin-secondary'
                        }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                        id='vlite-myprofile-newEmail'
                        type='email'
                        placeholder='Please type your new email'
                        {...register('newEmail', {
                          required: 'New email is required',
                          pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                    message: 'Invalid email format',
                  },
                        })}
                      />
                      {errors.newEmail && (
                        <Danger
                          color='#D80027'
                          width='25'
                          height='24'
                          className={`absolute right-[2%] top-[43%]`}
                        />
                      )}
                      {errors.newEmail && (
                        <p
                          role='alert'
                          className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[200px] h-[23px] bg-[rgba(216,0,39,0.7)] py-[1%] mt-1'>
                          {errors?.newEmail?.message}
                        </p>
                      )}
                    </div>
                  )}
                  <div
                    className='absolute flex h-[105px] font-roboto font-medium top-1 right-0  leading-[21px] text-lg text-skin-secondary cursor-pointer'
                    onClick={toggleChangeEmail}>
                    <span
                      className='font-roboto font-medium no-underline  whitespace-nowrap text-[14px] text-skin-secondary leading-[16.4px]'
                      onClick={toggleChangeEmail}>
                      Change email
                    </span>
                  </div>
                  <div className='h-[105px] relative'>
                    <label
                      className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                      htmlFor='vlite-myprofile-firstname'>
                      First Name
                    </label>
                    <input
                        className={`w-full h-[41px] rounded-[5px] ${
                          errors.firstname
                            ? 'border-[#D80027]'
                            : 'border-skin-secondary'
                        }   indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px] ${
                          firstNameDisabled ? 'grayed-out-input' : ''
                        }`}
                        id='vlite-myprofile-firstname'
                        type='text'
                        {...register('firstname', {
                          required: 'First Name  is required',
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: 'First Name must contain only Latin characters ',
                          },
                        })}
                        disabled={firstNameDisabled}
                      />
                    {errors.firstname && (
                      <Danger
                        color='#D80027'
                        width='25'
                        height='24'
                        className={`absolute right-[2%] top-[43%]`}
                      />
                    )}
                    {errors.firstname && (
                      <p
                        role='alert'
                        className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm  w-[200px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                        {errors?.firstname?.message}
                      </p>
                    )}
                  </div>
                  <div className='h-[105px] relative'>
                    <label
                      className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                      htmlFor='vlite-lastname'>
                      Last Name
                    </label>
                    <input
                        className={`w-full h-[41px] rounded-[5px] ${
                          errors.firstname
                            ? 'border-[#D80027]'
                            : 'border-skin-secondary'
                        }   indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px] ${
                          lastNameDisabled ? 'grayed-out-input' : ''
                        }`}
                        id='vlite-myprofile-firstname'
                        type='text'
                        {...register('firstname', {
                          required: 'First Name is required',
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: 'First Name must contain only Latin characters ',
                          },
                        })}
                        disabled={lastNameDisabled}
                      />
                    {errors.lastname && (
                      <Danger
                        color='#D80027'
                        width='25'
                        height='24'
                        className={`absolute right-[2%] top-[43%]`}
                      />
                    )}
                    {errors.lastname && (
                      <p
                        role='alert'
                        className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm w-[200px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                        {errors?.lastname?.message}
                      </p>
                    )}
                  </div>
                  <div className='h-[100px] relative'>
                    <label
                      className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                      htmlFor='vlite-myprofile-passport'>
                      Passport number
                    </label>
                    <input
                      className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                        errors.passport
                          ? 'border-[#D80027]'
                          : 'border-skin-secondary'
                      }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                      id='vlite-myprofile-passport'
                      type='text'
                      {...register('passport', {
                        required: 'Passport number is required',
                        pattern: {
                    value: /^\d+$/,
                    message: 'Mobile phone must contain only numbers',
                  },
                        // validate: {
                        //   isValid: () =>
                        //     firstname.value !== 'Please fill in your firstname' ||
                        //     'Firstname is required',
                        // },
                      })}
                      // disabled={firstNameDisabled}
                    />
                    {errors.passport && (
                      <Danger
                        color='#D80027'
                        width='25'
                        height='24'
                        className={`absolute right-[2%] top-[43%]`}
                      />
                    )}
                    {errors.passport && (
                      <p
                        role='alert'
                        className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm 6 w-[200px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                        {errors?.passport?.message}
                      </p>
                    )}
                  </div>
                  <div
                    onClick={clearBirthdayErrors}
                    className='w-full h-[100px] relative'>
                    <label
                      className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                      htmlFor='vlite-myprofile-birthday'>
                      Birthdate
                    </label>
                    <input
                      // {...inputProps}
                      className={`
        rounded-[5px] h-[41px] indent-2 w-full border-[1px] border-solid border-skin-secondary 
        text-base text-skin-primary font-light font-roboto cursor-pointer`}
                      {...register('birthday', {
                        validate: {
                          isDefined: () =>
                            (birthdayWatch !== '' && birthdayWatch !== null) ||
                            'Birthday is required',
                        },
                        onChange: () => {
                          if (errors.birthday) {
                            clearErrors('birthday');
                          }
                        },
                      })}
                    />
                    <button
                      // ref={buttonRef}
                      type='button'
                      className={`w-[21px] h-[51px] bg-transparent absolute right-[2%] top-[22%]`}
                      aria-label='Pick a date'
                      onClick={handlePopper}>
                      <span
                        className={`w-[21px] h-[51px]`}
                        role='img'
                        aria-label='calendar icon'>
                        <Calendar />
                      </span>
                    </button>
                    {errors.birthday && (
                      <Danger
                        color='#D80027'
                        width='25'
                        height='24'
                        className={`absolute right-[2%] top-[43%]`}
                      />
                    )}
                    {errors.birthday && (
                      <p
                        role='alert'
                        className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm  w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                        {errors?.birthday?.message}
                      </p>
                    )}
                  </div>
                  <Birthday
                    isPopperOpen={isPopperOpen}
                    handlePopper={handlePopper}
                    setValue={setValue}
                    value={birthdayWatch}
                  />
                  <div
                    onClick={clearCountryErrors}
                    className='w-full h-[105px] mt-2 relative'>
                    <label
                      className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                      htmlFor='vlite-myprofilecountry'>
                      Country
                    </label>
                    <Controller
                      {...register('country', {
                        validate: {
                          isDefined: () =>
                            (countryWatch.value !== '' &&
                              countryWatch.value !== null) ||
                            'Country is required',
                        },
                      })}
                      name='country'
                      control={control}
                      options={countriesByCountry.map((c) => ({
                        value: c.description,
                        label: c.description,
                      }))}
                      style={mobileStyle}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CountryMobile
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                        />
                      )}
                    />
                    {/* {errors.country && (
                  <Danger
                    color='#D80027'
                    width='25'
                    height='24'
                    style={{ transform: 'translate(1090%, -145%)' }}
                  />
                )} */}
                    {errors.country && (
                      <p
                        role='alert'
                        className={
                          'text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-[0.4rem]'
                        }>
                        {errors?.country?.message}
                      </p>
                    )}
                  </div>
                  <div className='h-[105px] mt-2 relative'>
                    <label
                      className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                      htmlFor='vlite-myprofile-city'>
                      City
                    </label>
                    <input
                      className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                        errors.city
                          ? 'border-[#D80027]'
                          : 'border-skin-secondary'
                      }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                      id='vlite-myprofile-city'
                      type='city'
                      {...register('city', {
                        required: 'City is required',
                        pattern: {
                    value:/^[A-Za-z0-9\s]+$/,
                    message: 'City must contain only Latin characters',
                  },
                      })}
                    />
                    {errors.city && (
                      <Danger
                        color='#D80027'
                        width='25'
                        height='24'
                        className={`absolute right-[2%] top-[42%]`}
                      />
                    )}
                    {errors.city && (
                      <p
                        role='alert'
                        className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm  w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                        {errors?.city?.message}
                      </p>
                    )}
                  </div>
                  <div className='h-[105px] relative'>
                    <label
                      className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                      htmlFor='vlite-myprofile-address'>
                      Address
                    </label>
                    <input
                      className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                        errors.address
                          ? 'border-[#D80027]'
                          : 'border-skin-secondary'
                      }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                      id='vlite-myprofile-address'
                      type='text'
                      {...register('address', {
                        required: 'Address is required',
                        pattern: {
                    value: /^[A-Za-z0-9\s]+$/,
                    message: 'Address must contain only Latin characters and numbers',
                  },
                      })}
                    />
                    {errors.address && (
                      <Danger
                        color='#D80027'
                        width='25'
                        height='24'
                        className={`absolute right-[2%] top-[42%]`}
                      />
                    )}
                    {errors.address && (
                      <p
                        className={
                          'text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm  w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'
                        }
                        role='alert'>
                        {errors?.address?.message}
                      </p>
                    )}
                  </div>
                  <div className='h-[100px] relative'>
                    <label
                      className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                      htmlFor='postalCode'>
                      Postal Code
                    </label>
                    <input
                      className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                        errors.postalCode
                          ? 'border-[#D80027]'
                          : 'border-skin-secondary'
                      }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                      id='postalCode'
                      type='text'
                      {...register('postalCode', {
                        required: 'Postal code is required',
                        pattern: {
                    value: /^[A-Za-z0-9\s]+$/,
                    message: 'Postal code must contain only Latin characters and numbers',
                  },
                      })}
                    />
                    {errors.postalCode && (
                      <Danger
                        color='#D80027'
                        width='25'
                        height='24'
                        className={`absolute right-[2%] top-[42%]`}
                      />
                    )}
                    {errors.postalCode && (
                      <p
                        className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] py-[1%] mt-1'
                        role='alert'>
                        {errors?.postalCode?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className='w-full h-auto'></div>
                <div
                  className='indent-2 cursor-pointer mt-12'
                  onClick={toggleChangePassword}>
                  <span
                    // style={buttonStyle}
                    className='font-roboto font-medium no-underline text-[16px] text-skin-secondary leading-[19px]'
                    onClick={toggleChangePassword}>
                    Change Password
                  </span>
                  <span>
                    <ChevronDown
                      onClick={toggleChangePassword}
                      width='15'
                      height='7.5'
                      color='#14D5FF'
                      className={`inline-block ml-1 ${
                        isChangePasswordOpen && 'rotate-180'
                      }`}
                    />
                  </span>
                </div>
                {isChangePasswordOpen && (
                  // <div data-aos="fade-down"
                  <div>
                    <br />
                    <div className='h-[105px] mt-2 w-full relative'>
                      <label
                        className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                        htmlFor='vlite-myprofile-currentPassword'>
                        Current Password:
                      </label>
                      <input
                        className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                          errors.currentPassword
                            ? 'border-[#D80027]'
                            : 'border-skin-secondary'
                        }  w-full indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                        id='vlite-myprofile-currentPassword'
                        name='currentPassword'
                        type={currentPasswordShown ? 'text' : 'password'}
                        {...register('currentPassword', {
                          required: 'Password is required',
                          // pattern: {
                          //   value:
                          //     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
                          //   message:
                          //     'Password must contain minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and any 1 symbol.',
                          // },
                        })}
                      />
                      {currentPasswordShown && !errors.currentPassword && (
                        <DontShow
                          color='#14D5FF'
                          className={`absolute right-[3%] top-[42%]`}
                          onClick={toggleCurrentPassword}
                        />
                      )}
                      {!currentPasswordShown && !errors.currentPassword && (
                        <RiEyeLine
                          fill='#14D5FF'
                          size='21'
                          className={`absolute right-[3%] top-[42%]`}
                          style={{
                            color: '#14D5FF',
                          }}
                          onClick={toggleCurrentPassword}
                        />
                      )}
                      {errors.currentPassword && (
                        <Danger
                          color='#D80027'
                          width='25'
                          height='24'
                          className={`absolute right-[3%] top-[42%]`}
                        />
                      )}
                      {errors.currentPassword && (
                        <p
                          role='alert'
                          className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                          {errors?.currentPassword?.message}
                        </p>
                      )}
                    </div>
                    <div className='w-full relative'>
                      <div className='h-[105px]'>
                        <label
                          className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                          htmlFor='newPassword'>
                          New Password:
                        </label>
                        <input
                          className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                            errors.newPassword
                              ? 'border-[#D80027]'
                              : 'border-skin-secondary'
                          }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                          type={newPasswordShown ? 'text' : 'password'}
                          id='newPassword'
                          name='newPassword'
                          {...register(
                            'newPassword',
                            {
                              required: 'Password is required',
                              onChange: () => {
                                if (errors.newPassword) {
                                  clearErrors('newPassword');
                                }
                              },
                              validate: {
                                isValid: () =>
                                  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/.test(
                                    // /^(?=.*[0-9])(?=.*[a-zα-ω])(?=.*[A-ZΑ-Ω])(?=.*\W)(?!.* ).{8,}$/.test(
                                    newPassword
                                  ) || notifyPassComposition(),
                              },
                            }
                            // pattern: {
                            //   value:
                            //     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
                            //   message:
                            //     'Password must contain minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and any 1 symbol.',
                            // },
                          )}
                        />
                        {newPasswordShown && !errors.newPassword && (
                          <DontShow
                            color='#14D5FF'
                            className={`absolute right-[3%] top-[21%]`}
                            onClick={toggleNewPassword}
                          />
                        )}
                        {!newPasswordShown && !errors.newPassword && (
                          <RiEyeLine
                            fill='#14D5FF'
                            size='21'
                            className={`absolute right-[3%] top-[21%]`}
                            style={{
                              color: '#14D5FF',
                            }}
                            onClick={toggleNewPassword}
                          />
                        )}
                        {errors.newPassword && (
                          <Danger
                            color='#D80027'
                            width='25'
                            height='24'
                            className={`absolute right-[3%] top-[21%]`}
                          />
                        )}
                        {errors.newPassword &&
                          errors.newPassword.message ===
                            'Password is required' && (
                            <p
                              role='alert'
                              className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                              {errors?.newPassword?.message}
                            </p>
                          )}
                      </div>
                      <div className='h-[105px] w-full relative'>
                        <label
                          className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                          htmlFor='confirmPassword'>
                          Confirm New Password:
                        </label>
                        <input
                          className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                            errors.confirmNewPassword
                              ? 'border-[#D80027]'
                              : 'border-skin-secondary'
                          }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                          type={confirmNewPasswordShown ? 'text' : 'password'}
                          id='confirmNewPassword'
                          {...register('confirmNewPassword', {
                            onChange: () => {
                              if (errors.confirmPassword) {
                                clearErrors('confirmPassword');
                              }
                            },
                            required: 'Password is required',
                            validate: {
                              passwordsMatch: () =>
                                newPasswordWatch === confirmNewPasswordWatch ||
                                'Passwords must match',
                            },
                          })}
                        />
                        {confirmNewPasswordShown &&
                          !errors.confirmNewPassword && (
                            <DontShow
                              color='#14D5FF'
                              className={`absolute right-[3%] top-[42%]`}
                              onClick={toggleConfirmNewPassword}
                            />
                          )}
                        {!confirmNewPasswordShown &&
                          !errors.confirmNewPassword && (
                            <RiEyeLine
                              fill='#14D5FF'
                              size='21'
                              className={`absolute right-[3%] top-[42%]`}
                              onClick={toggleConfirmNewPassword}
                            />
                          )}

                        {errors.confirmNewPassword && (
                          <Danger
                            color='#D80027'
                            width='25'
                            height='24'
                            className={`absolute right-[3%] top-[42%]`}
                          />
                        )}
                        {errors.confirmNewPassword && (
                          <p
                            role='alert'
                            className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm  w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] py-[1%] mt-1'>
                            {errors.confirmNewPassword.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className='flex w-full mt-10'>
                  <label
                    className='font-roboto text-[14px] text-skin-primary leading-[19px] order-2 ml-4'
                    htmlFor='promotionalMailAccepted'>
                    I would like to receive exclusive member offers, account
                    updates, program and hotel news via email.
                  </label>

                  <input
                    className='checkboxVerityLite order-1 mt-0.5 flex-shrink-0'
                    type='checkbox'
                    id='promotionalMailAccepted'
                    {...register('promotionalMailAccepted')}
                  />
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className='fixed bottom-4 left-0 flex w-full justify-center h-12 mb-2'>
                  <button
                    // disabled={isDisabled}
                    // ref={buttonRef}

                    className='w-[275px] border-none outline-none font-roboto text-center text-[16px] font-bold text-skin-primary leading-[19px] bg-skin-secondary rounded-5 py-1'
                    type='submit'>
                    Save
                  </button>
                </div>
              </form>
              <br />
              <br />
              <div onClick={handleDismiss}>
                <Toaster
                  duration='1000000'
                  position='top-center'
                  containerClassName='transformY'
                  containerStyle={{
                    top: 10,
                    left: 10,
                    bottom: 10,
                    right: 10,
                  }}
                  toastOptions={{
                    error: {
                      duration: 1000000,
                      icon: (
                        <Exclamation
                          width='50'
                          height='50'
                          viewBox='-4 1 25 15'
                          color='red'
                        />
                      ),
                      style: {
                        // border: '1px solid #713200',

                        color: `black`,
                        fontWeight: 'normal',
                        background: `white` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                        filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                        fontFamily: 'Lato',
                        borderRadius: '7px',
                        height: 'auto',
                        maxHeight: '65px',
                        fontWeight: '600',
                        transform: 'translateY(100%)',
                        borderLeft: '6.5px solid red',
                      },
                    },
                    success: {
                      duration: 1000000,
                      icon: (
                        <Tick
                          color='#26A65B'
                          width='50'
                          height='50'
                          viewBox='0 0 50 50'
                        />
                        // <Exclamation width='60' height='60' viewBox='0 0 16 16' />
                      ),
                      style: {
                        // border: '1px solid #713200',
                        // padding: '16px',
                        color: `black`,
                        fontWeight: 'normal',
                        background: `#fff` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                        filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                        fontFamily: 'Lato',
                        borderRadius: '7px',
                        height: 'auto',
                        fontWeight: '600',
                        transform: 'translateY(100%)',
                        borderLeft: '6.5px solid #26A65B',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </>
    );
  }

  return (
    <>
      <ErrorBoundary>
        <div
          className={`flex vliteOver ${
            isChangePasswordOpen ? 'min-h-[1400px]' : 'min-h-[1200px]'
          } `}>
          <SideBar />
          <div
            className='flex-1 min-h-screen z-10 justify-center overflow-x-scroll'
            style={{
              visibility: `${isSidebarVisible ? 'hidden' : 'visible'}`,
              background: '#FFFFFF',
            }}>
            <div>
              <TopBar />
            </div>
            <div className='w-full h-[115px] mt-[6%] text-center mx-auto'>
              <h1 className='min-w-[902px] font-roboto font-normal text-[40px] leading-[47px] font-darkBlue'>
                My <span className='font-semibold'>Profile</span>
              </h1>
            </div>
            <br />
            <form
              className={`${
                isChangePasswordOpen && 'h-[900px]'
              } block w-[700px] h-[700px] my-0 mx-auto`}
              onSubmit={handleSubmit(onSubmit, onError)}>
              <div className='w-[660px] h-[500px] grid grid-cols-2 grid-rows-[5] auto-rows-max gap-x-10 justify-center'>
                {!isChangeEmailOpen ? (
                  <div className='block w-full h-[100px]'>
                    <label
                      className='indent-2 h-[19px] font-roboto font-normal text-base  text-skin-primary leading-[18.75px] mb-[11.5px]'
                      htmlFor='vlite-myprofile-email'>
                      Email
                    </label>
                    <input
                      className={`w-full h-[41px] bg-transparent rounded-[5px] border-skin-secondary ${
                        errors.email && 'border-[#D80027]'
                      }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                      id='vlite-myprofile-email'
                      type='email'
                      ref={emailRef}
                      disabled='true'
                      {...register('email', {
                        required: 'Email is required',
                      })}
                    />
                    {errors.email && (
                      <Danger
                        color='#D80027'
                        width='25'
                        height='24'
                        style={{ transform: 'translate(1090%, -145%)' }}
                      />
                    )}
                    {errors.email && (
                      <p
                        role='alert'
                        className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[200px] h-[23px] bg-[rgba(216,0,39,0.7)] py-[1%] mt-1'>
                        {errors?.email?.message}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className='block w-full h-[100px]'>
                    <label
                      className='indent-2 h-[19px] font-roboto font-normal text-base  text-skin-primary leading-[18.75px] mb-[11.5px]'
                      htmlFor='vlite-myprofile-email'>
                      New Email
                    </label>
                    <input
                      className={`w-full h-[41px] bg-transparent rounded-[5px] placeholder-darkBlue placeholder-opacity-[0.5] ${
                        errors.newEmail
                          ? 'border-[#D80027]'
                          : 'border-skin-secondary'
                      }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                      id='vlite-myprofile-newEmail'
                      type='email'
                      placeholder='Please type your new email'
                      {...register('newEmail', {
                        required: 'New email is required',
                      })}
                    />
                    {errors.newEmail && (
                      <Danger
                        color='#D80027'
                        width='25'
                        height='24'
                        style={{ transform: 'translate(1090%, -145%)' }}
                      />
                    )}
                    {errors.newEmail && (
                      <p
                        role='alert'
                        className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[200px] h-[23px] bg-[rgba(216,0,39,0.7)] py-[1%] mt-1'>
                        {errors?.newEmail?.message}
                      </p>
                    )}
                  </div>
                )}
                <div
                  className='h-[105px] font-roboto font-medium leading-[21px] text-lg text-skin-secondary translate-y-11 cursor-pointer'
                  onClick={toggleChangeEmail}>
                  <span
                    className='font-roboto font-medium no-underline text-lg text-skin-secondary leading-[21px]'
                    onClick={toggleChangeEmail}>
                    Change Email
                  </span>
                  <span>
                    <ChevronDown
                      onClick={toggleChangeEmail}
                      width='15'
                      height='7.5'
                      color='#14D5FF'
                      className={`inline-block ml-1 ${
                        isChangeEmailOpen && 'rotate-180'
                      }`}
                    />
                  </span>
                </div>
                <div className='h-[105px]'>
                  <label
                    className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                    htmlFor='vlite-myprofile-firstname'>
                    First Name
                  </label>
                  <input
                        className={`w-full h-[41px] rounded-[5px] ${
                          errors.firstname
                            ? 'border-[#D80027]'
                            : 'border-skin-secondary'
                        }   indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px] ${
                          firstNameDisabled ? 'grayed-out-input' : ''
                        }`}
                        id='vlite-myprofile-firstname'
                        type='text'
                        {...register('firstname', {
                          required: 'First Name  is required',
                          pattern: {
                            value: /^[\p{L}\s]+$/u,
                            message: 'You must only use letters',
                          },
                        })}
                        disabled={firstNameDisabled}
                      />
                  {errors.firstname && (
                    <Danger
                      color='#D80027'
                      width='25'
                      height='24'
                      style={{ transform: 'translate(1090%, -145%)' }}
                    />
                  )}
                  {errors.firstname && (
                    <p
                      role='alert'
                      className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[200px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                      {errors?.firstname?.message}
                    </p>
                  )}
                </div>
                <div className='h-[105px]'>
                  <label
                    className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                    htmlFor='vlite-lastname'>
                    Last Name
                  </label>
                  <input
                        className={`w-full h-[41px] rounded-[5px] ${
                          errors.firstname
                            ? 'border-[#D80027]'
                            : 'border-skin-secondary'
                        }   indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px] ${
                          lastNameDisabled ? 'grayed-out-input' : ''
                        }`}
                        id='vlite-myprofile-lastname'
                        type='text'
                        {...register('lastname', {
                          required: 'Last Name is required',
                          pattern: {
                            value: /^[\p{L}\s]+$/u,
                            message: 'You must only use letters',
                          },
                        })}
                        disabled={lastNameDisabled}
                      />
                  {errors.lastname && (
                    <Danger
                      color='#D80027'
                      width='25'
                      height='24'
                      style={{ transform: 'translate(1090%, -145%)' }}
                    />
                  )}
                  {errors.lastname && (
                    <p
                      role='alert'
                      className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[200px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                      {errors?.lastname?.message}
                    </p>
                  )}
                </div>
                <div className='h-[100px]'>
                  <label
                    className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                    htmlFor='vlite-myprofile-passport'>
                    Passport number
                  </label>
                  <input
                    className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                      errors.passport
                        ? 'border-[#D80027]'
                        : 'border-skin-secondary'
                    }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                    id='vlite-myprofile-passport'
                    type='text'
                    {...register('passport', {
                      required: 'Passport number is required',
                    })}
                  />
                  {errors.passport && (
                    <Danger
                      color='#D80027'
                      width='25'
                      height='24'
                      style={{ transform: 'translate(1090%, -145%)' }}
                    />
                  )}
                  {errors.passport && (
                    <p
                      role='alert'
                      className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[200px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                      {errors?.passport?.message}
                    </p>
                  )}
                </div>
                <div
                  onClick={clearBirthdayErrors}
                  className='h-[100px] relative'>
                  <label
                    className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                    htmlFor='vlite-myprofile-birthday'>
                    Birthdate
                  </label>
                  <input
                    className={`
        rounded-[5px] h-[41px] indent-2 w-full border-[1px] border-solid border-skin-secondary 
        text-base text-skin-primary font-light font-roboto cursor-pointer`}
                    {...register('birthday', {
                      validate: {
                        isDefined: () =>
                          (birthdayWatch !== '' && birthdayWatch !== null) ||
                          'Birthday is required',
                      },
                      onChange: () => {
                        if (errors.birthday) {
                          clearErrors('birthday');
                        }
                      },
                    })}
                  />

                  {/* <Controller
                    {...register('birthday', {
                      validate: {
                        isDefined: () =>
                          (birthdayWatch !== '' && birthdayWatch !== null) ||
                          'Birthday is required',
                      },
                      onChange: () => {
                        if (errors.birthday) {
                          clearErrors('birthday');
                        }
                      },
                    })}
                    control={control}
                    name='birthday'
                    render={({ field: { value, onChange } }) => (
                      <div>
                        <DatePicker
                          className={`${!errors.birthday && 'vlite-datepicker'}
                      rounded-[5px] h-[41px] indent-2 w-[310px] border-[1px] border-solid ${
                        !errors.birthday
                          ? 'border-skin-secondary'
                          : 'border-[#D80027]'
                      } text-base text-skin-primary font-light font-roboto cursor-pointer`}
                          dateFormat='dd/MM/yyyy'
                          onChange={onChange}
                          selected={value}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode='select'
                          placeholderText='DD/MM/YYYY'
                        />
                      </div>
                    )}
                  /> */}
                  <button
                    type='button'
                    className={`w-[51px] h-[50px] bg-transparent absolute right-[2%] top-[22%]`}
                    aria-label='Pick a date'
                    onClick={handlePopper}>
                    <span
                      className='w-[51px] h-[50px] '
                      role='img'
                      aria-label='calendar icon'>
                      <Calendar />
                    </span>
                  </button>
                  {errors.birthday && (
                    <Danger
                      color='#D80027'
                      width='25'
                      height='24'
                      style={{ transform: 'translate(1090%, -145%)' }}
                    />
                  )}
                  {errors.birthday && (
                    <p
                      role='alert'
                      className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm  w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] -translate-y-6 mt-1'>
                      {errors?.birthday?.message}
                    </p>
                  )}
                </div>
                <Birthday
                  value={birthdayWatch}
                  isPopperOpen={isPopperOpen}
                  handlePopper={handlePopper}
                  setValue={setValue}
                />
                <div onClick={clearCountryErrors} className='h-[105px] mt-2'>
                  <label
                    className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                    htmlFor='vlite-myprofilecountry'>
                    Country
                  </label>
                  <Controller
                    {...register('country', {
                      validate: {
                        isDefined: () =>
                          (countryWatch.value !== '' &&
                            countryWatch.value !== null) ||
                          'Country is required'},
                          pattern: {
                            value: /^[\p{L}\s]+$/u,
                            message: 'You must only use letters',
                      },
                    })}
                    name='country'
                    control={control}
                    options={countriesByCountry.map((c) => ({
                      value: c.description,
                      label: c.description,
                    }))}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Country
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      />
                    )}
                  />
                  {/* {errors.country && (
                  <Danger
                    color='#D80027'
                    width='25'
                    height='24'
                    style={{ transform: 'translate(1090%, -145%)' }}
                  />
                )} */}
                  {errors.country && (
                    <p
                      role='alert'
                      className={
                        'text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-[0.4rem]'
                      }>
                      {errors?.country?.message}
                    </p>
                  )}
                </div>
                <div className='h-[105px] mt-2'>
                  <label
                    className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                    htmlFor='vlite-myprofile-city'>
                    City
                  </label>
                  <input
                    className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                      errors.city ? 'border-[#D80027]' : 'border-skin-secondary'
                    }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                    id='vlite-myprofile-city'
                    type='city'
                    {...register('city', {
                      required: 'City is required',
                      pattern: {
                            value: /^[\p{L}\s]+$/u,
                            message: 'You must only use letters',
                      },
                    })}
                  />
                  {errors.city && (
                    <Danger
                      color='#D80027'
                      width='25'
                      height='24'
                      style={{ transform: 'translate(1090%, -145%)' }}
                    />
                  )}
                  {errors.city && (
                    <p
                      role='alert'
                      className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                      {errors?.city?.message}
                    </p>
                  )}
                </div>
                <div className='h-[105px]'>
                  <label
                    className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                    htmlFor='vlite-myprofile-address'>
                    Address
                  </label>
                  <input
                    className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                      errors.address
                        ? 'border-[#D80027]'
                        : 'border-skin-secondary'
                    }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                    id='vlite-myprofile-address'
                    type='text'
                    {...register('address', {
                      required: 'Address is required',
                    })}
                  />
                  {errors.address && (
                    <Danger
                      color='#D80027'
                      width='25'
                      height='24'
                      style={{ transform: 'translate(1090%, -145%)' }}
                    />
                  )}
                  {errors.address && (
                    <p
                      className={
                        'text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'
                      }
                      role='alert'>
                      {errors?.address?.message}
                    </p>
                  )}
                </div>
                <div className='h-[100px]'>
                  <label
                    className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                    htmlFor='postalCode'>
                    Postal Code
                  </label>
                  <input
                    className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                      errors.postalCode
                        ? 'border-[#D80027]'
                        : 'border-skin-secondary'
                    }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                    id='postalCode'
                    type='text'
                    {...register('postalCode', {
                      required: 'Postal code is required',
                    })}
                  />
                  {errors.postalCode && (
                    <Danger
                      color='#D80027'
                      width='25'
                      height='24'
                      style={{ transform: 'translate(1090%, -145%)' }}
                    />
                  )}
                  {errors.postalCode && (
                    <p
                      className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] py-[1%] mt-1'
                      role='alert'>
                      {errors?.postalCode?.message}
                    </p>
                  )}
                </div>
              </div>

              <div
                className='indent-2 cursor-pointer mt-12'
                onClick={toggleChangePassword}>
                <span
                  // style={buttonStyle}
                  className='font-roboto font-medium no-underline text-lg text-skin-secondary leading-[21px]'
                  onClick={toggleChangePassword}>
                  Change Password
                </span>
                <span>
                  <ChevronDown
                    onClick={toggleChangePassword}
                    width='15'
                    height='7.5'
                    color='#14D5FF'
                    className={`inline-block ml-1 ${
                      isChangePasswordOpen && 'rotate-180'
                    }`}
                  />
                </span>
              </div>
              {isChangePasswordOpen && (
                <div>
                  <br />
                  <div className='h-[105px] mt-2 w-[310px]'>
                    <label
                      className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                      htmlFor='vlite-myprofile-currentPassword'>
                      Current Password:
                    </label>
                    <input
                      className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                        errors.currentPassword
                          ? 'border-[#D80027]'
                          : 'border-skin-secondary'
                      }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                      id='vlite-myprofile-currentPassword'
                      name='currentPassword'
                      type={currentPasswordShown ? 'text' : 'password'}
                      {...register('currentPassword', {
                        required: 'Password is required',
                        // pattern: {
                        //   value:
                        //     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
                        //   message:
                        //     'Password must contain minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and any 1 symbol.',
                        // },
                      })}
                    />
                    {currentPasswordShown && !errors.currentPassword && (
                      <DontShow
                        color='#14D5FF'
                        style={{
                          transform: 'translate(1310%, -157%)',
                        }}
                        onClick={toggleCurrentPassword}
                      />
                    )}
                    {!currentPasswordShown && !errors.currentPassword && (
                      <RiEyeLine
                        fill='#14D5FF'
                        size='21'
                        style={{
                          color: '#14D5FF',
                          transform: 'translate(1310%, -157%)',
                        }}
                        onClick={toggleCurrentPassword}
                      />
                    )}
                    {errors.currentPassword && (
                      <Danger
                        color='#D80027'
                        width='25'
                        height='24'
                        style={{ transform: 'translate(1090%, -145%)' }}
                      />
                    )}
                    {errors.currentPassword && (
                      <p
                        role='alert'
                        className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                        {errors?.currentPassword?.message}
                      </p>
                    )}
                  </div>
                  <div className='grid grid-cols-2 h-[105px] grid-rows-1 w-[660px] gap-x-10'>
                    <div className='h-[105px] col-start-1'>
                      <label
                        className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                        htmlFor='newPassword'>
                        New Password:
                      </label>
                      <input
                        className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                          errors.newPassword
                            ? 'border-[#D80027]'
                            : 'border-skin-secondary'
                        }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                        type={newPasswordShown ? 'text' : 'password'}
                        id='newPassword'
                        name='newPassword'
                        {...register(
                          'newPassword',
                          {
                            required: 'Password is required',
                            onChange: () => {
                              if (errors.newPassword) {
                                clearErrors('newPassword');
                              }
                            },
                            validate: {
                              isValid: () =>
                                /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/.test(
                                  // /^(?=.*[0-9])(?=.*[a-zα-ω])(?=.*[A-ZΑ-Ω])(?=.*\W)(?!.* ).{8,}$/.test(
                                  newPassword
                                ) || notifyPassComposition(),
                            },
                          }
                          // pattern: {
                          //   value:
                          //     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
                          //   message:
                          //     'Password must contain minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and any 1 symbol.',
                          // },
                        )}
                      />
                      {newPasswordShown && !errors.newPassword && (
                        <DontShow
                          color='#14D5FF'
                          style={{
                            transform: 'translate(1310%, -157%)',
                          }}
                          onClick={toggleNewPassword}
                        />
                      )}
                      {!newPasswordShown && !errors.newPassword && (
                        <RiEyeLine
                          fill='#14D5FF'
                          size='21'
                          style={{
                            color: '#14D5FF',
                            transform: 'translate(1310%, -157%)',
                          }}
                          onClick={toggleNewPassword}
                        />
                      )}
                      {errors.newPassword && (
                        <Danger
                          color='#D80027'
                          width='25'
                          height='24'
                          style={{ transform: 'translate(1090%, -145%)' }}
                        />
                      )}
                      {errors.newPassword &&
                        errors.newPassword.message ===
                          'Password is required' && (
                          <p
                            role='alert'
                            className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                            {errors?.newPassword?.message}
                          </p>
                        )}
                    </div>
                    <div className='h-[105px] w-[310px] col-start-2'>
                      <label
                        className='indent-2 h-[19px] font-roboto font-normal text-base leading-[18.75px] mb-[11.5px] text-skin-primary'
                        htmlFor='confirmPassword'>
                        Confirm New Password:
                      </label>
                      <input
                        className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                          errors.confirmNewPassword
                            ? 'border-[#D80027]'
                            : 'border-skin-secondary'
                        }  indent-[0.5rem] border-solid  border-[1px] font-roboto  text-skin-primary font-light text-base leading-[19px]`}
                        type={confirmNewPasswordShown ? 'text' : 'password'}
                        id='confirmNewPassword'
                        {...register('confirmNewPassword', {
                          onChange: () => {
                            if (errors.confirmPassword) {
                              clearErrors('confirmPassword');
                            }
                          },
                          required: 'Password is required',
                          validate: {
                            passwordsMatch: () =>
                              newPasswordWatch === confirmNewPasswordWatch ||
                              'Passwords must match',
                          },
                        })}
                      />
                      {confirmNewPasswordShown &&
                        !errors.confirmNewPassword && (
                          <DontShow
                            color='#14D5FF'
                            style={{
                              transform: 'translate(1310%, -157%)',
                            }}
                            onClick={toggleConfirmNewPassword}
                          />
                        )}
                      {!confirmNewPasswordShown &&
                        !errors.confirmNewPassword && (
                          <RiEyeLine
                            fill='#14D5FF'
                            size='21'
                            style={{
                              transform: 'translate(1310%, -157%)',
                            }}
                            onClick={toggleConfirmNewPassword}
                          />
                        )}

                      {errors.confirmNewPassword && (
                        <Danger
                          color='#D80027'
                          width='25'
                          height='24'
                          style={{ transform: 'translate(1060%, -145%)' }}
                        />
                      )}
                      {errors.confirmNewPassword && (
                        <p
                          role='alert'
                          className='text-skin-a11y font-medium leading-[16px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] py-[1%] mt-1'>
                          {errors.confirmNewPassword.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className='flex w-[660px] mt-10'>
                <label
                  className='font-roboto text-base text-skin-primary leading-[21px] order-2 ml-4'
                  htmlFor='promotionalMailAccepted'>
                  I would like to receive exclusive member offers, account
                  updates, program and hotel news via email.
                </label>

                <input
                  className='checkboxVerityLite order-1 mt-0.5 flex-shrink-0'
                  type='checkbox'
                  id='promotionalMailAccepted'
                  {...register('promotionalMailAccepted')}
                />
              </div>
              <br />
              <br />
              <br />
              <div className='flex w-full justify-center h-12'>
                <button
                  // disabled={isDisabled}
                  // ref={buttonRef}

                  className='w-[330px] border-none outline-none font-roboto text-center text-xl font-bold text-skin-primary leading-[21px] bg-skin-secondary rounded-5 py-1'
                  type='submit'>
                  Save
                </button>
              </div>
            </form>
            <br />
            <br />
            <div onClick={handleDismiss}>
              <Toaster
                duration='1000000'
                position='top-center'
                containerClassName='transformY'
                containerStyle={{
                  top: 10,
                  left: 10,
                  bottom: 10,
                  right: 10,
                }}
                toastOptions={{
                  error: {
                    duration: 1000000,
                    icon: (
                      <Exclamation
                        width='50'
                        height='50'
                        viewBox='-4 1 25 15'
                        color='red'
                      />
                    ),
                    style: {
                      color: `black`,
                      fontWeight: 'normal',
                      background: `white`,
                      filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                      fontFamily: 'Lato',
                      borderRadius: '7px',
                      height: 'auto',
                      maxHeight: '65px',
                      fontWeight: '600',
                      transform: 'translateY(100%)',
                      borderLeft: '6.5px solid red',
                    },
                  },
                  success: {
                    duration: 1000000,
                    icon: (
                      <Tick
                        color='#26A65B'
                        width='50'
                        height='50'
                        viewBox='0 0 50 50'
                      />
                    ),
                    style: {
                      color: `black`,
                      fontWeight: 'normal',
                      background: `#fff`,
                      filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                      fontFamily: 'Lato',
                      borderRadius: '7px',
                      height: 'auto',
                      fontWeight: '600',
                      transform: 'translateY(100%)',
                      borderLeft: '6.5px solid #26A65B',
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </>
  );
}
