import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';

// export const attachPathNameToAction = (store) => (next) => (action) => {
//   action.pathname = store.getState().router.pathname; //<-----passing pathname
//   console.log('Middleware triggered:', action);
//   next(action);
// };

const configureStore = () => {
  const middlewares = [thunk];
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancers = composeEnhancers(applyMiddleware(...middlewares));
  const store = createStore(
    rootReducer(),
    enhancers
    // applyMiddleware(attachPathNameToAction)
  );

  return store;
};

export default configureStore;

// import { createStore } from 'redux';
// import easyReducer from '../reducers/easy/easy.reducer';
// import userReducer from '../reducers/user/user.reducer';
// import rootReducer from '../reducers/rootReducer';

// const configureStore = createStore(
//   rootReducer(),
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
//   applyMiddleware(applyMiddleware(attachPathNameToAction));
// );

// export default configureStore;

// import { configureStore } from '@reduxjs/toolkit';

// import rootReducer from '../reducers/rootReducer';

// export const store = configureStore({ reducer: rootReducer() });
