import React, { useCallback, useRef, useEffect } from 'react';
import { GrClose } from 'react-icons/gr';

function Modal({ setTrigger, trigger, children }) {
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setTrigger(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && trigger) {
        setTrigger(false);
      }
    },
    [setTrigger, trigger]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  return (
    <div>
      <div ref={modalRef} className='Redeem-popup' onClick={closeModal}>
        <div className='Redeem-popupInner'>
          <div className='Redeem-closeButton' onClick={() => setTrigger(false)}>
            <GrClose />
          </div>
          {children}
        </div>
      </div>
      ;
    </div>
  );
}

export default Modal;
