import React from 'react';
import './Message.css';

/**
 * A box that shows on invalid input and contains an error message.
 */
export default function Message(props) {
  const getContainerClassName = () =>
    'messageContainer ' + (props.message.length != 0 ? '' : 'hidden');

  return (
    <>
      <div className={getContainerClassName()}>
        {props.isError && <div className='warning-icon'>!</div>}
        <div className={'checkIn'}>{props.message}</div>
      </div>
    </>
  );
}

{
  /* export default function ErrorMessage(props) {
  console.log(props);
  const getContainerClassName = () =>
    'container ' + (props.message ? '' : 'hidden');

  return (
    <>
      <div className={getContainerClassName()}>
        <div className='warning-icon'>!</div>
        <div className={'message'}>{props.message}</div>
      </div>
    </>
  );
} */
}
