import React, { useState, useEffect, useContext, useRef } from 'react';
import logout from '../../../Assets/Images/logout.svg';
import { useNavigate } from 'react-router-dom';
import Redeem from '../Redeem/Redeem';
import BookNow from '../BookNow/BookNow';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import CookieService from '../../../API/Services/CookieService';
import actions from '../../../Redux/reducers/user/user.actions';
import { ThemeDataContext } from '../../../ContextProviders/theme';
import './TopBarAtlantica.css';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import * as _ from 'lodash';
import Logout from '../Logout/Logout';
import { toast as toastifyToast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';

export function TopBar() {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [buttonPopup2, setButtonPopup2] = useState(false);
  const [buttonPopup3, setButtonPopup3] = useState(false);
  const [buttonPopup4, setButtonPopup4] = useState(false);
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [hotels, setHotels] = useState([]);
  const [redeemDisabled, setRedeemDisabled] = useState(false);

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { locations } = portalInfo || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};
  const { termsFileUri } = portalInfo || {};

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const programLabel = localStorage.getItem('programLabel');
  const redeemRef = useRef(null);
  const redeemForm = useRef();
  const bookNowForm = useRef();

  const initialValues = { nights: null };

  const {
    reset,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: initialValues,
  });

  const getHotels = async () => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/init/getLoyaltyInfo',
      params: {
        programLabel: localStorage['programLabel'],
      },
    })
      .then((resp) => {
        setHotels(resp.data.locations);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getHotels();
  }, []);

  const hotel = _.map(hotels, 'description');

  const newArray = hotel.map((h, i) => ({ value: h, label: h }));

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };

  const buttonStyleUnhovered = {
    color: 'var(--color-accent)',
  };

  const [isHoveringFreeNights, setIsHoveringFreeNights] = useState(false);

  const [isHoveringHotelServices, setIsHoveringHotelServices] = useState(false);

  const handleMouseOverFreeNights = () => {
    setIsHoveringFreeNights(true);
  };

  const handleMouseOutFreeNights = () => {
    setIsHoveringFreeNights(false);
  };

  const handleMouseOverHotelServices = () => {
    setIsHoveringHotelServices(true);
  };

  const handleMouseOutHotelServices = () => {
    setIsHoveringHotelServices(false);
  };

  const style = {
    container: (base) => ({
      ...base,
      marginTop: '0.95rem',
      height: '28px',
    }),
    control: (base) => ({
      ...base,
      border: 0,
      '&:hover': { borderColor: '#535353' },
      borderBottomStyle: 'solid',
      borderBottomColor: '#535353',
      borderBottomWidth: '1px',
      borderRadius: 'none',
      '&:focus': { borderBottomWidth: '2px' },
      boxShadow: 'none',
    }),
    valueContainer: (base) => ({
      ...base,

      marginLeft: '-0.3rem',
    }),
  };

  const Hotel = (props) => (
    <Select
      {...props}
      components={{
        IndicatorSeparator: () => null,
      }}
      menuPlacement='auto'
      placeholder='Select Hotel...'
      styles={style}
      options={newArray}
      onChange={props.onChange}
      onBlur={props.onBlur}
      selected={props.value}
    />
  );

  const handleLogout = (e) => {
    e.preventDefault();
    toastifyToast.dismiss();
    dispatch(actions.userLogout());
    localStorage.clear();
    sessionStorage.clear();
    let options = { path: '/' };
    CookieService.remove('acidToken', options);
    navigate(`/signIn?programLabel=${programLabel}`);
  };

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const acidToken = userInfo.acidToken;
  const emailToken = userInfo.eMailToken;

  const redeem = (data) => {
    let hotel = hotels.filter((h) => h.description === data.hotel.value);

    let url = hotel[0].redeemNowUri;

    window.open(
      url +
        '?checkin=' +
        data.arrivalDate +
        '&nights=' +
        data.nights +
        '&bkcode=XLP&acidtkn=' +
        acidToken
    );
  };
  const bookNow = (data) => {
    console.log(data, 'data');
    console.log(data.arrivalDate, 'arrivalDate');

    let hotel = hotels.filter((h) => h.description === data.hotel.value);

    let url = hotel[0].bookNowUri;

    const arrival = `${format(data.arrivalDate, 'yyyy-MM-dd')}`;
    window.open(
      url +
        '?checkin=' +
        arrival +
        '&nights=' +
        data.nights +
        '&logintoken=' +
        emailToken
    );
  };
  const onError = (errors, e) => console.log(errors, e, 'error');
  const arrivalDate = watch('arrivalDate');

  function isInTheFuture(date) {
    const today = new Date();

    // 👇️ OPTIONAL!
    // This line sets the time of the current date to the
    // last millisecond, so the comparison returns `true` only if
    // date is at least tomorrow
    today.setHours(23, 59, 59, 998);

    return date > today;
  }

  const RedeemVisibility = () =>
    programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' ??
    'IXI-INTG-E98241F0-EE89-4B11-8F81-94C0CA0085FA'
      ? 'hidden'
      : 'visible';

  useEffect(() => {
    if (
      programLabel === 'IXI-E98241F0-EE89-4B11-8F81-94C0CA0085FA' ??
      'IXI-INTG-E98241F0-EE89-4B11-8F81-94C0CA0085FA'
    ) {
      setRedeemDisabled(true);
    }
    return;
  }, [programLabel, setRedeemDisabled]);

  // const { toasts, handlers } = useToaster();

  // const TOAST_LIMIT = 1;

  // useEffect(() => {
  //   console.log(toasts, 'toasts');
  //   toasts
  //     .filter((t) => t.visible) // Only consider visible toasts
  //     .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
  //     .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  // }, [toasts]);

  // const allowDrop = (e) => e.preventDefault();

  // const drag = (e) => e.dataTransfer.setData('text', e.target.id);

  // const drop = (e) => {
  //   e.preventDefault();
  //   var data = e.dataTransfer.getData('text');
  //   e.target.appendChild(document.getElementById(data));
  // };
  // draggable='true'
  // onDragStart={(e) => drag(e)}
  // id='drag1'

  // const handleDismiss = (t) => {
  //   setLogoutPopup(false);
  //   toast.dismiss(t.id);
  // };

  // const logoutToast = () => {
  //   setLogoutPopup(true);
  //   toast(
  //     (t) => (
  //       <div style={{ width: '300px' }}>
  //         <span
  //           style={{
  //             fontSize: '1.2rem',
  //             fontFamily: `'Lato', Arial, sans-serif`,
  //             userSelect: 'none',
  //             marginLeft: '1rem',
  //           }}>
  //           Are you sure you want to <b>logout?</b>
  //         </span>

  //         <br />
  //         <br />
  //         <div
  //           style={{
  //             display: 'flex',
  //           }}>
  //           <button
  //             className='top-logoutButton'
  //             onClick={(e, t) => handleLogout(e, t)}>
  //             Yes
  //           </button>
  //           <button
  //             className='top-logoutButton'
  //             onClick={() => handleDismiss(t)}>
  //             No
  //           </button>
  //         </div>
  //       </div>
  //     ),
  //     {
  //       style: {
  //         background: '#fff',
  //         color: 'var(--color-accent)',
  //         height: '150px',
  //       },
  //       duration: 10000,
  //       className: '',
  //       ariaProps: {
  //         role: 'logout',
  //       },
  //     }
  //   );
  // };
  // const TOAST_LIMIT = 1;

  // useEffect(() => {
  //   console.log(toasts, 'toasts');
  //   toasts
  //     .filter((t) => t.visible) // Only consider visible toasts
  //     .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
  //     .forEach((t) => toast.dismiss(t.id));
  //   // Dismiss – Use toast.remove(t.id) for no exit animation
  // }, [toasts]);

  return (
    <div className='top-container'>
      <ul className='top-list'>
        <li className='top-items'>
          <button
            id='redeemButton'
            style={{
              backgroundColor: `${buttonBackgroundColor}`,
              color: `${buttonColor}`,
              // visibility: RedeemVisibility(),
            }}
            onClick={() => setButtonPopup(true)}
            className='top-Redeem'
            disabled={redeemDisabled}>
            REDEEM
          </button>
          <Redeem trigger={buttonPopup} setTrigger={setButtonPopup}>
            <h1>Redeem your points</h1>
            <hr />
            <div className='Redeem-ButtonContainer'>
              <button
                style={
                  isHoveringFreeNights ? buttonStyle : buttonStyleUnhovered
                }
                onMouseOver={handleMouseOverFreeNights}
                onMouseOut={handleMouseOutFreeNights}
                onClick={() => {
                  setButtonPopup(false);
                  setButtonPopup2(true);
                }}
                className='Redeem-FreeNights'>
                With Free Nights
              </button>
              <button
                style={
                  isHoveringHotelServices ? buttonStyle : buttonStyleUnhovered
                }
                onMouseOver={handleMouseOverHotelServices}
                onMouseOut={handleMouseOutHotelServices}
                onClick={() => {
                  setButtonPopup(false);
                  setButtonPopup3(true);
                }}
                className='Redeem-HotelServices'>
                With Hotel Services
              </button>
            </div>
          </Redeem>
          <Redeem trigger={buttonPopup2} setTrigger={setButtonPopup2}>
            <h1>Redeem your points</h1>
            <hr />
            <div className='Redeem-ButtonContainer'>
              <button
                style={buttonStyle}
                onClick={() => setButtonPopup(true)}
                className='Redeem-FreeNights'>
                With Free Nights
              </button>
              <button
                onClick={() => {
                  setButtonPopup2(false);
                  setButtonPopup3(true);
                }}
                className='Redeem-HotelServices'>
                With Hotel Services
              </button>
            </div>
            <form
              onBlur={() => reset()}
              className='Redeem-FreeNights-form'
              onSubmit={handleSubmit(redeem, onError)}>
              <div className='Redeem-HotelWrap'>
                <label className='Redeem-label'>Hotel(*):</label>
                <Controller
                  {...register('hotel')}
                  control={control}
                  options={newArray}
                  defaultValue={null}
                  name='hotel'
                  rules={{ required: 'Hotel is required' }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Hotel onChange={onChange} onBlur={onBlur} value={value} />
                  )}
                />
                {errors.hotel && (
                  <p
                    role='alert'
                    style={{
                      color: 'rgb(255, 0, 0)',
                      marginTop: '0.4rem',
                    }}>
                    {errors?.hotel?.message}
                  </p>
                )}
              </div>
              <div className='Redeem-FreeNights-ArrivalDateWrap'>
                <label className='Redeem-label' htmlFor='Redeem-ArrivalDate'>
                  Arrival Date*:
                </label>
                <br />
                <br />
                <Controller
                  {...register('arrivalDate', {
                    required: 'Arrival date is required',

                    valueAsDate: true,
                    validate: {
                      isAFutureDate: () =>
                        isInTheFuture(arrivalDate) ||
                        'Arrival date must be a future date',
                    },
                  })}
                  defaultValue={null}
                  control={control}
                  name='arrivalDate'
                  render={({ field: { value, onChange } }) => (
                    <div className='Redeem-ReactDatePickerWrap'>
                      <DatePicker
                        className='Redeem-ReactDatePicker'
                        style={{ marginLeft: '2rem' }}
                        dateFormat='dd/MM/yyyy'
                        onChange={onChange}
                        selected={value}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        placeholderText='DD/MM/YYYY'
                      />
                    </div>
                  )}
                />
                {/* <input
                  {...register('arrivalDate', {
                    required: 'Arrival date is required',
                  })}
                  className='BookNow-input'
                  id='BookNow-ArrivalDate'
                  name='arrivalDate'
                  type='date'
                /> */}
                {errors.arrivalDate && (
                  <p
                    role='alert'
                    style={{
                      color: 'rgb(255, 0, 0)',
                      marginTop: '0rem',
                    }}>
                    {errors?.arrivalDate?.message}
                  </p>
                )}
              </div>

              <div className='Redeem-FreeNights-NightsWrap'>
                <label className='Redeem-label' htmlFor='Redeem-Nights'>
                  Nights*:
                </label>
                <br />
                <input
                  {...register('nights', {
                    required: 'Nights is required',
                    min: {
                      value: 1,
                      message: 'Value must be greater than or equal to 1',
                    },
                  })}
                  className='Redeem-input'
                  id='Redeem-Nights'
                  name='nights'
                  type='number'
                />
                {errors.nights && (
                  <p role='alert' style={{ color: 'rgb(255, 0, 0)' }}>
                    {errors?.nights?.message}
                  </p>
                )}
              </div>

              <div>
                <button style={buttonStyle} className='Redeem-Search'>
                  REDEEM
                </button>
              </div>
            </form>
          </Redeem>
          <Redeem
            trigger={buttonPopup3}
            setTrigger={setButtonPopup3}
            reset={reset}>
            <h1>Redeem your points</h1>
            <hr />
            <div className='Redeem-ButtonContainer'>
              <button
                onClick={() => {
                  setButtonPopup3(false);
                  setButtonPopup2(true);
                }}
                className='Redeem-FreeNights'>
                With Free Nights
              </button>
              <button
                style={buttonStyle}
                onClick={() => {
                  setButtonPopup(false);
                  setButtonPopup3(true);
                }}
                className='Redeem-HotelServices'>
                With Hotel Services
              </button>
            </div>
            <div className='Redeem-HotelServices-Info'>
              <p>Redemption of Points</p>
              {programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
              'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B' ? (
                <p>
                  Level 2 & 3 members are entitled to redeem up to 8000 points
                  per stay, towards discounts on their extras during their stay
                  in the hotel.
                </p>
              ) : (
                <p>
                  You may redeem your award points for Hotel Services during
                  your stay in any of our hotels.
                </p>
              )}

              {programLabel === 'ALT-14F4BCA1-339D-4E6F-92A7-04595936D72B' ??
              'ALT-INTG-14F4BCA1-339D-4E6F-92A7-04595936D72B' ? (
                <p>
                  Points for hotel services can be only redeemed during the
                  checkout process at reception. {''}
                  <a
                    href={termsFileUri}
                    style={{
                      color: `${buttonBackgroundColor}`,
                      textDecoration: 'underline',
                    }}
                    target='_blank'
                    rel='noreferrer noopener'>
                    Click here
                  </a>
                  , for terms and conditions.
                </p>
              ) : (
                <p>
                  Please visit the Guest Relations to complete and sign a
                  Redemption Voucher Application Form. You will receive your
                  Redemption Voucher within 24hours from your application.
                </p>
              )}
            </div>
          </Redeem>
        </li>
        <li className='top-items'>
          <button
            style={buttonStyle}
            onClick={() => setButtonPopup4(true)}
            className='top-BookNow'>
            BOOK NOW
          </button>
          <BookNow trigger={buttonPopup4} setTrigger={setButtonPopup4}>
            <h1>Book Your Stay</h1>
            <hr />
            <form
              onBlur={() => reset()}
              className='BookNow-form'
              onSubmit={handleSubmit(bookNow, onError)}>
              <div className='BookNow-HotelWrap'>
                <label className='BookNow-label'>Hotel(*):</label>

                <Controller
                  {...register('hotel')}
                  defaultValue={null}
                  control={control}
                  options={newArray}
                  name='hotel'
                  rules={{ required: 'Hotel is required' }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Hotel onChange={onChange} onBlur={onBlur} value={value} />
                  )}
                />
                {errors.hotel && (
                  <p
                    role='alert'
                    style={{
                      color: 'rgb(255, 0, 0)',
                      marginTop: '0.5rem',
                    }}>
                    {errors?.hotel?.message}
                  </p>
                )}
              </div>

              <div className='BookNow-ArrivalDateWrap'>
                <label className='BookNow-label' htmlFor='arrivalDate'>
                  Arrival Date*:
                </label>
                <br />
                <br />
                <Controller
                  {...register('arrivalDate', {
                    required: 'Arrival date is required',
                    valueAsDate: true,
                    validate: {
                      isAFutureDate: () =>
                        isInTheFuture(arrivalDate) ||
                        'Arrival date must be a future date',
                    },
                  })}
                  control={control}
                  defaultValue={null}
                  name='arrivalDate'
                  render={({ field: { value, onChange } }) => (
                    <div className='BookNow-ReactDatePickerWrap'>
                      <DatePicker
                        className='BookNow-ReactDatePicker'
                        style={{ marginLeft: '2rem' }}
                        dateFormat='dd/MM/yyyy'
                        onChange={onChange}
                        selected={value}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        placeholderText='DD/MM/YYYY'
                      />
                    </div>
                  )}
                />
                {/* <input
                  {...register('arrivalDate', {
                    required: 'Arrival date is required',
                  })}
                  className='BookNow-input'
                  id='BookNow-ArrivalDate'
                  name='arrivalDate'
                  type='date'
                /> */}
                {errors.arrivalDate && (
                  <p
                    role='alert'
                    style={{
                      color: 'rgb(255, 0, 0)',
                      marginTop: '0rem',
                    }}>
                    {errors?.arrivalDate?.message}
                  </p>
                )}
              </div>

              <div className='BookNow-NightsWrap'>
                <label className='BookNow-label' htmlFor='BookNow-Nights'>
                  Nights*:
                </label>
                <br />
                <input
                  {...register('nights', {
                    required: 'Nights is required',
                    min: {
                      value: 1,
                      message: 'Value must be greater than or equal to 1',
                    },
                  })}
                  className='BookNow-input'
                  id='BookNow-Nights'
                  name='nights'
                  type='number'
                />
                {errors.nights && (
                  <p role='alert' style={{ color: 'rgb(255, 0, 0)' }}>
                    {errors?.nights?.message}
                  </p>
                )}
              </div>

              <div>
                <button style={buttonStyle} className='BookNow-Search'>
                  BOOK NOW
                </button>
              </div>
            </form>
          </BookNow>
        </li>
      </ul>
      <div className='top-logoutWrap'>
        <span onClick={() => setLogoutPopup(true)}>
          <img src={logout} alt='logout' />
        </span>
        {/* <Toaster
          position='top-center'
          containerStyle={{
            top: 400,
          }}
        /> */}
        <Logout
          trigger={logoutPopup}
          setTrigger={setLogoutPopup}
          handleLogout={handleLogout}></Logout>
      </div>
    </div>
  );
}

export default TopBar;
