import React from 'react';
import './BookNowAtlantica.css';
import Modal from '../Modal/Modal';
import ReactPortal from '../ReactPortal/ReactPortal';

const BookNow = ({ trigger, setTrigger, children, reset }) => {
  return (
    <>
      {trigger ? (
        <ReactPortal wrapperId='react-portal-modal-container'>
          <Modal
            setTrigger={setTrigger}
            trigger={trigger}
            children={children}
            reset={reset}
          />
        </ReactPortal>
      ) : (
        ''
      )}
    </>
  );
};

export default BookNow;
