import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from '../Components/ProtectedRoutes/ProtectedRoute';
import SignIn from '../Pages/SignIn';
import SignUp from '../Pages/SignUp';
import Overview from '../Pages/Overview';
import MyProfile from '../Pages/MyProfile';
import PreferencesVL from '../Pages/PreferencesVL';
import Activity from '../Pages/Activity';
import Benefits from '../Pages/Benefits';
import ChangePassword from '../Pages/ChangePassword';
import ResetPassword from '../Pages/ResetPassword';
import ConfirmPage from '../Pages/ConfirmPage';
import MissingPoints from '../Pages/MissingPoints';
import ForgotPassword from '../Pages/ForgotPassword';
import ForgotPasswordSuccess from '../Pages/ForgotPasswordSuccess';
import RedirectToOverview from '../Components/Redirect/RedirectToOverview';
import ResetPasswordSuccess from '../Pages/ResetPasswordSuccess';
import NotFound from '../Pages/NotFound';
import '../../Container/globals'; // to load globals for the entire app;
import './index.css';
import SideBar2 from '../Components/SideBar/SideBar2';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeDataContext } from '../../ContextProviders/theme';
import { getRGBColor, getAccessibleColor, rgbToHex } from '../../utils';

function App() {
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};
  const { globalBackgroundColor } = portalInfo || {};
  const { globalColor } = portalInfo || {};

  const primaryColor = getRGBColor(buttonColor, 'primary-lite');
  const a11yColor = getRGBColor(getAccessibleColor(buttonColor), 'a11y-lite');
  const secondaryColor = getRGBColor(buttonBackgroundColor, 'secondary-lite');
  console.log(primaryColor, 'primaryColorLite');
  console.log(a11yColor, 'a11yColorLite');
  console.log(secondaryColor, 'secondaryColorLite');

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* <style type='text/css'>{`
            :root {
                --mainThemeF: #ffffff;
                --mainThemeB: #006633;                    
             {
         `}</style> */}
          <style type='text/css'>
            {`:root {${primaryColor}; ${a11yColor}; ${secondaryColor}; --color-primary-lite-hex: ${buttonColor};  --color-secondary-lite-hex: ${buttonBackgroundColor}}`}
          </style>
        </Helmet>
      </HelmetProvider>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path='/home/overview' element={<Overview />} />
          <Route path='/home/myprofile' element={<MyProfile />} />
          <Route path='/home/activity' element={<Activity />} />
          <Route path='/home/preferencesVL' element={<PreferencesVL />} />
          <Route path='/home/benefits' element={<Benefits />} />
          <Route path='/home/changepassword' element={<ChangePassword />} />
          <Route path='/home/missingpoints' element={<MissingPoints />} />
          <Route path='/home' element={<RedirectToOverview />} />
        </Route>
        {/* <Route
          path='/signIn/forgotpassword/success'
          element={<ForgotPasswordSuccess />}
        /> */}
        <Route path='/signIn/forgotpassword' element={<ForgotPassword />} />
        <Route path='/signIn' element={<SignIn />} />
        <Route path='/signUp/' element={<SignUp />} />
        <Route
          path='/confirm:email?:token?:programLabel?'
          element={<ConfirmPage />}
        />
        <Route path='/confirm' element={<ConfirmPage />} />
        <Route
          path='/resetPassword:email?:token?:programLabel?'
          element={<ResetPassword />}
        />
        <Route path='/resetPassword' element={<ResetPassword />} />

        {/* <Route
          path='/resetpassword/success'
          element={<ResetPasswordSuccess />}
        /> */}
        <Route path='*' element={<NotFound />} />
        {/* <Route index element={<SignIn />} /> */}
      </Routes>
    </>
  );
}

export default App;
