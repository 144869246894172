export const TRANSLATIONS_EN = {
  general: {
    'Powered by': 'Powered by',
    'Verity Logo': 'Verity Logo',
    Logo: 'Logo',
    'We have sent an email. Please verify it to continue':
      'We have sent an email. Please verify it to continue',
  },
  login: {
    Welcome: 'Welcome',
    'Email*': 'Email*',
    'Password*': 'Password*',
    'Sign In': 'Sign In',
    'Not a member? Please register.': 'Not a member? Please register.',
    'Already a member? Please sign in.': 'Already a member? Please sign in.',
    Register: 'Register',
    Login: 'Login',
    'Tell us about yourself': 'Tell us about yourself',
    'First Name*': 'First Name*',
    'First Name': 'First Name',
    'Last Name*': 'Last Name*',
    'Last Name': 'Last Name',
    'Set Password': 'Set Password',
    'Re-Enter Password*': 'Re-Enter Password*',
    'Forgot Password?': 'Forgot Password?',
    'First name is required': 'First name is required',
    'Last name is required': 'Last name is required',
    'Email is required': 'Email is required',
    'Password is required': 'Password is required',
    'Password must be between 8 and 16 characters, and must contain at least one uppercase, one lowercase, one number and one special character':
      'Password must be between 8 and 16 characters, and must contain at least one uppercase, one lowercase, one number and one special character',
    'Passwords must match': 'Passwords must match',
    'Email already exists': 'Email already exists',
    'Thank you for verifying your Email': 'Thank you for verifying your Email',
    'Please login': 'Please login',
    'Forgot Your Password': 'Forgot Your Password',
    'Reset Password': 'Reset Password',
    ', you should receive an email with instructions on how to reset your password shortly':
      ', you should receive an email with instructions on how to reset your password shortly',
    'If an account matches': 'If an account matches',
  },
  sidebar: {
    Dashboard: 'Dashboard',
    'My Profile': 'My Profile',
    'Personal Info': 'Personal Info',
    'Change Password': 'Change Password',
    'My Activity': 'My Activity',
    Logout: 'Logout',
  },
  dashboard: {
    'Welcome back,': 'Welcome back,',
    Member: 'Member',
    'current tier': 'current tier',
    'total points': 'total points',
    'nights stayed': 'nights stayed',
    'Card Number': 'Card Number',
    'Member Since': 'Member Since',
    Account: 'Account',
    'Book Now': 'Book Now',
    'You are': 'You are',
    'points away from': 'points away from',
    level: 'level',
    'You are a': 'You are a',
    member: 'member',
  },
  personalInfo: {
    'Personal information': 'Personal information',
    'My Profile': 'My Profile',
    'Change Email': 'Change Email',
    'Passport*': 'Passport*',
    Passport: 'Passport',
    'Country*': 'Country*',
    Country: 'Country',
    'City*': 'City*',
    'First Name*': 'First Name*',
    'Last Name*': 'Last Name*',
    Email: 'Email',
    City: 'City',
    'Address*': 'Address*',
    Address: 'Address',
    'Postal Code*': 'Postal Code*',
    'Postal Code': 'Postal Code',
    'Date of Birth*': 'Date of Birth*',
    'Date of Birth (dd-mm-yyyy)': 'Date of Birth (dd-mm-yyyy)',
    'Save Profile': 'Save Profile',
    'Passport is required': 'Passport is required',
    'Date of birth is required': 'Date of birth is required',
    'Country is required': 'Country is required',
    'City is required': 'City is required',
    'Postal Code is required': 'Postal Code is required',
    'Address is required': 'Address is required',
    'New Email*': 'New Email*',
    'Confirm Email*': 'Confirm Email*',
    'Apply Changes': 'Apply Changes',
    'Emails must match': 'Emails must match',
    'Email successfully updated': 'Email successfully updated',
    'Account successfully changed': 'Account successfully changed',
  },
  changePassword: {
    Close: 'Close',
    'Password successfully changed': 'Password successfully changed',
    'Change Password': 'Change Password',
    'Reset Password': 'Reset Password',
    'Your old password is required': 'Your old password is required',
    'Your new password is required': 'Your new password is required',
    'Passwords must match': 'Passwords must match',
    'Your old password*': 'Your old password*',
    'Your new password*': 'Your new password*',
    'Confirm your new password*': 'Confirm your new password*',
    'Something went wrong': 'Something went wrong',
  },
  myActivity: {
    'My Activity': 'My Activity',
    Hotel: 'Hotel',
    Date: 'Date',
    Activity: 'Activity',
    Nights: 'Nights',
    'Room#': 'Room#',
    Points: 'Points',
  },
};
