import React, { useContext, useEffect, useState } from 'react';
import '../Components/MissingPoints/MissingPointsAtlantica.css';
import Header from '../Components/Header';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import * as _ from 'lodash';
import Message from '../Components/Message/Message';
import { ThemeDataContext } from '../../ContextProviders/theme';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import toast, { Toaster, useToaster, useToasterStore } from 'react-hot-toast';
import Exclamation from '../../Icons/Exclamation';
import Tick from '../../Icons/Tick';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';

export default function MissingPoints({ toggle }) {
  const [hotels, setHotels] = useState([]);
  const [message, setMessage] = useState('');
  const [hasError, setHasError] = useState(false);

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };

  const getHotels = async () => {
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/init/getLoyaltyInfo',
      params: {
        programLabel: localStorage['programLabel'],
      },
    })
      .then((resp) => {
        setHotels(resp.data.locations);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getHotels();
  }, []);

  const hotel = _.map(hotels, 'description');

  const newArray = hotel.map((h, i) => ({ value: h, label: h }));

  const subject = [
    { value: 'Missing Points', label: 'Missing Points' },
    { value: 'Missing Reservations', label: 'Missing Reservations' },
  ];

  const style = {
    container: (base) => ({
      ...base,
      marginTop: '0.95rem',
      height: '28px',
    }),
    valueContainer: (base) => ({
      ...base,

      marginLeft: '-0.20rem',
    }),
    group: (base) => ({
      ...base,
    }),
    menu: (base) => ({
      ...base,
    }),
    control: (base) => ({
      ...base,
      border: 0,
      '&:hover': { borderColor: '#535353' },
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: '#535353',
      boxShadow: 'none',
      borderRadius: 'none',

      fontSize: '1.25rem',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: '#111',
      marginLeft: '-0.20rem',
    }),
    input: (base) => ({
      ...base,

      marginLeft: '-0.20rem',
    }),
  };

  const mobileStyle = {
    container: (base) => ({
      ...base,
      marginTop: '0.95rem',
      height: '28px',
    }),
    valueContainer: (base) => ({
      ...base,

      marginLeft: '-0.20rem',
    }),
    group: (base) => ({
      ...base,
    }),
    menu: (base) => ({
      ...base,
    }),
    control: (base) => ({
      ...base,
      border: 0,
      '&:hover': { borderColor: '#535353' },
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: '#535353',
      borderRadius: 'none',

      boxShadow: 'none',
      fontSize: '1.25rem',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: '#111',
      marginLeft: '-0.20rem',
    }),
    input: (base) => ({
      ...base,
      marginLeft: '-0.20rem',
    }),
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const onSubmit = (data) => {
    console.log(data, 'missingPoints');

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const userInfo = JSON.parse(localStorage['userInfo']);
    const memberInfo = userInfo.memberInfo;
    const arrival = `${format(data.arrivalDate, 'yyyy-MM-dd')}`;
    const departure = `${format(data.departureDate, 'yyyy-MM-dd')}`;

    axios({
      method: 'post',
      baseURL: '/',
      url: 'api/contact/supportRequest',
      data: {
        programLabel: localStorage.getItem('programLabel'),
        acid: localStorage.getItem('acid'),
        code: userInfo.memberCode.toString(),
        email: memberInfo.memberEmail,
        firstname: memberInfo.memberFirstName,
        lastname: memberInfo.memberLastName,
        tier: memberInfo.memberTier,
        subject: data.subject.value,
        location: data.hotel.value,
        arrivalDate: arrival,
        departureDate: departure,
        bookingSource: data.source,
        reservationNumber: data.reservation,
        remarks: data.remarks,
      },
    })
      .then((resp) => {
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.data.ErrorMessage);
        }
        notifySuccess();
        // showMessage('Your request has been successfully submitted', false);
      })
      .catch((error) => {
        console.log(error);
        notifyError(error.toString());
        // showMessage(error.toString(), true);
      });
  };

  const arrivalDate = watch('arrivalDate');
  const departureDate = watch('departureDate');

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  const showMessage = (msg, isError) => {
    const messageClearTimeout = 4000;

    setMessage(msg);
    setHasError(isError);
    window.scrollTo(0, 0);
    setInterval(() => {
      setMessage('');
      setHasError(false);
    }, messageClearTimeout);
  };

  const Tablet = useMediaQuery({
    query: '(min-width: 768px) ',
  });

  const Mobile = useMediaQuery({
    query: '(min-width: 280px) and (max-width: 767px)',
  });

  const isSidebarVisible = useSelector((state) => state.user.isSidebarVisible);

  const { toasts, handlers } = useToaster();

  const subjectWatch = watch('subject');

  const notifySuccess = () =>
    toast.success(`Your request has been successfully submitted.`);
  const notifyError = (error) => toast.error(error);

  const TOAST_LIMIT = 1;

  useEffect(() => {
    console.log(toasts, 'toasts');
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  const handleDismiss = () => {
    toasts.forEach((t) => toast.dismiss(t.id));
  };

  return (
    <>
      <Header />
      <div
        style={{
          visibility: `${isSidebarVisible ? 'hidden' : 'visible'}`,
        }}>
        <div className='MissingPoints-heading'>
          <h1>Missing Points</h1>
          <hr className='MissingPoints-line' />
        </div>
        {/* <Message message={message} isError={hasError} /> */}
        <br />
        <br />
        <br />
        <div onClick={handleDismiss}>
          <Toaster
            duration='1000000'
            position='top-center'
            containerClassName='MissingPointsToaster'
            containerStyle={{
              top: 10,
              left: 10,
              bottom: 10,
              right: 10,
            }}
            toastOptions={{
              error: {
                duration: 1000000,
                icon: (
                  <Exclamation
                    width='50'
                    height='50'
                    viewBox='-4 1 25 15'
                    color='red'
                  />
                ),
                style: {
                  // border: '1px solid #713200',
                  color: `black`,
                  fontWeight: 'normal',
                  background: `white` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                  filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                  fontFamily: 'Lato',
                  borderRadius: '7px',
                  height: 'auto',
                  maxHeight: '65px',
                  fontWeight: '600',
                  transform: 'translateY(100%)',
                  borderLeft: '6.5px solid red',
                },
              },
              success: {
                duration: 1000000,
                icon: (
                  <Tick
                    color='#26A65B'
                    width='50'
                    height='50'
                    viewBox='0 0 50 50'
                  />
                  // <Exclamation width='60' height='60' viewBox='0 0 16 16' />
                ),
                style: {
                  // border: '1px solid #713200',
                  // padding: '16px',
                  color: `black`,
                  fontWeight: 'normal',
                  background: `#fff` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                  filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                  fontFamily: 'Lato',
                  borderRadius: '7px',
                  height: 'auto',
                  fontWeight: '600',
                  transform: 'translateY(100%)',
                  borderLeft: '6.5px solid #26A65B',
                },
              },
            }}
          />
        </div>
        <div className='MissingPoints-info'>
          <p>
            Are you missing points from your recent stay or can't find your
            reservation?
          </p>
          <p>
            Just fill out the form below and we will respond in the wink of an
            eye.
          </p>
          <br />
          <p id='MissingPoints-asterisk'>
            All fields marked with an asterisk (*) are required.
          </p>
          <br />
          <form
            className='MissingPoints-form'
            onSubmit={handleSubmit(onSubmit)}>
            <div className='MissingPoints-subject'>
              <label
                style={{ transform: 'translateY(30%)' }}
                className='MissingPoints-label'
                htmlFor='subject'>
                SUBJECT*
              </label>
              <Controller
                {...register('subject')}
                placeholder='Select Subject...'
                control={control}
                options={subject}
                name='subject'
                rules={{ required: 'Subject is required' }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement='auto'
                    placeholder='Select Subject...'
                    styles={Tablet ? style : mobileStyle}
                    options={subject}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                  />
                )}
              />
              {errors.subject && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0.9rem',
                  }}>
                  {errors?.subject?.message}
                </p>
              )}
            </div>

            <div className='MissingPoints-hotel'>
              <br />
              <label className='MissingPoints-label' htmlFor='hotel'>
                HOTEL*
              </label>
              <Controller
                {...register('hotel')}
                control={control}
                options={newArray}
                name='hotel'
                rules={{ required: 'Hotel is required' }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder='Select Hotel...'
                    menuPlacement='auto'
                    styles={Tablet ? style : mobileStyle}
                    options={newArray}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                  />
                )}
              />
              {errors.hotel && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0.9rem',
                  }}>
                  {errors?.hotel?.message}
                </p>
              )}
            </div>

            <div className='MissingPoints-departureDate'>
              <label className='MissingPoints-label' htmlFor='departureDate'>
                DEPARTURE DATE*
              </label>
              {/* <input
                className='MissingPoints-input date'
                type='date'
                id='departureDate'
                name='departureDate'
                {...register('departureDate', {
                  valueAsDate: true,
                  required: 'Departure date is required',
                  validate: {
                    isDefined: () =>
                      (isValidDate(arrivalDate) &&
                        isValidDate(departureDate)) ||
                      'Departure date is required',
                    isDepartureDateValid: () =>
                      new Date(departureDate) > new Date(arrivalDate) ||
                      (isValidDate(departureDate) &&
                        'Departure date is before arrival date'),
                  },
                })}
              /> */}
              <Controller
                {...register('departureDate', {
                  valueAsDate: true,
                  required: 'Departure date is required',
                  validate: {
                    isDefined: () =>
                      (isValidDate(arrivalDate) &&
                        isValidDate(departureDate)) ||
                      'Departure date is required',
                    isDepartureDateValid: () =>
                      new Date(departureDate) > new Date(arrivalDate) ||
                      (isValidDate(departureDate) &&
                        'Departure date is before arrival date'),
                  },
                })}
                control={control}
                name='departureDate'
                render={({ field: { value, onChange } }) => (
                  <div className='MissingPoints-ReactDatePickerWrap'>
                    <DatePicker
                      className='MissingPoints-ReactDatePicker'
                      style={{ marginLeft: '2rem' }}
                      dateFormat='dd/MM/yyyy'
                      onChange={onChange}
                      selected={value}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                    />
                  </div>
                )}
              />
              {errors.departureDate && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                  }}>
                  {errors?.departureDate?.message}
                </p>
              )}
            </div>
            <div className='MissingPoints-arrivalDate'>
              <label className='MissingPoints-label' htmlFor='arrivalDate'>
                ARRIVAL DATE*
              </label>
              {/* <input
                className='MissingPoints-input date'
                name='arrivalDate'
                type='date'
                id='arrivalDate'
                {...register('arrivalDate', {
                  required: 'Arrival date is required',
                  valueAsDate: true,
                  validate: {
                    isDefined: () =>
                      (isValidDate(arrivalDate) &&
                        isValidDate(departureDate)) ||
                      'Arrival date is required',
                    isArrivalDateValid: () =>
                      new Date(arrivalDate) < new Date(departureDate) ||
                      (isValidDate(departureDate) &&
                        'Arrival date is after departure date'),
                  },
                })}
              /> */}
              <Controller
                {...register('arrivalDate', {
                  required: 'Arrival date is required',
                  valueAsDate: true,
                  validate: {
                    isDefined: () =>
                      (isValidDate(arrivalDate) &&
                        isValidDate(departureDate)) ||
                      'Arrival date is required',
                    isArrivalDateValid: () =>
                      new Date(arrivalDate) < new Date(departureDate) ||
                      (isValidDate(departureDate) &&
                        'Arrival date is after departure date'),
                  },
                })}
                control={control}
                name='arrivalDate'
                render={({ field: { value, onChange } }) => (
                  <div className='MissingPoints-ReactDatePickerWrap'>
                    <DatePicker
                      className='MissingPoints-ReactDatePicker'
                      style={{ marginLeft: '2rem' }}
                      dateFormat='dd/MM/yyyy'
                      onChange={onChange}
                      selected={value}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                    />
                  </div>
                )}
              />
              {errors.arrivalDate && (
                <p role='alert' style={{ color: 'rgb(255, 0, 0)' }}>
                  {errors?.arrivalDate?.message}
                </p>
              )}
            </div>
            <div className='MissingPoints-source'>
              <label className='MissingPoints-label' htmlFor='source'>
                HOW DID YOU MAKE YOUR RESERVATION?*
              </label>
              <input
                className='MissingPoints-input'
                type='text'
                id='source'
                name='source'
                {...register('source', {
                  required: 'Source of booking is required',
                  pattern: '/[a-z]*[A-Z]*[0-9]*.*/',
                })}
              />
              {errors.source && (
                <p role='alert' style={{ color: 'rgb(255, 0, 0)' }}>
                  {errors?.source?.message}
                </p>
              )}
            </div>

            <div className='MissingPoints-reservationNumber'>
              <label className='MissingPoints-label' htmlFor='reservation'>
                RESERVATION NUMBER*
              </label>

              <input
                className='MissingPoints-input'
                type='text'
                id='reservation'
                name='reservation'
                {...register('reservation', {
                  required: 'Reservation number is required',
                })}
              />
              {errors.reservation && (
                <p role='alert' style={{ color: 'rgb(255, 0, 0)' }}>
                  {errors?.reservation?.message}
                </p>
              )}
            </div>

            <div className='MissingPoints-remarks'>
              <label className='MissingPoints-label' htmlFor='remarks'>
                REMARKS
              </label>
              <textarea
                className='MissingPoints-textarea'
                id='remarks'
                name='remarks'
                {...register('remarks', {
                  pattern: '/[a-z]*[A-Z]*[0-9]*.*/',
                })}
                rows='5'
                cols='224'
              />
              {errors.remarks && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0.2rem',
                  }}>
                  {errors?.remarks?.message}
                </p>
              )}
            </div>
            <div className='MissingPoints-ButtonContainer'>
              <button
                style={buttonStyle}
                className='MissingPoints-Button'
                type='submit'>
                SUBMIT
              </button>
              <br />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
