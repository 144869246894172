
import React, { useEffect, useState, useContext } from 'react';
import Header from '../Components/Header';
import '../Components/OnlineCheckIn/onlinecheckIn.css'
import axios from 'axios';
import { InfoDataContext } from '../ContextProviders/info';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { AiOutlinePlusSquare,AiOutlineMinusSquare } from "react-icons/ai";
import { useSelector } from "react-redux";
import { ThemeDataContext } from '../ContextProviders/theme';
import Message from '../Components/Message/Message';
import Exclamation from '../Icons/Exclamation';
import Tick from '../Icons/Tick';
import toast, { Toaster, useToaster, useToasterStore } from 'react-hot-toast';


  function PreCheckinForm() {
    const checkInInfo = useSelector((state) => state.checkIn.checkInInfo);
    const { portalInfo } = useContext(ThemeDataContext) || {};
    const { termsFileUri } = portalInfo || {};
    const { buttonBackgroundColor } = portalInfo || {};
    const { buttonColor } = portalInfo || {};
    
    const [bookingRef, setBookingRef] = useState(''); // Initialize with an empty string
    const [agent, setAgent] = useState(''); // Initialize with an empty string
    const [arrivalDate, setArrivalDate] = useState(''); // Initialize with an empty string
    const [arrivalTime, setArrivalTime] = useState(''); // Initialize with an empty string
    const [departureDate, setDepartureDate] = useState(''); // Initialize with an empty string
    const [departureTime, setDepartureTime] = useState(''); // Initialize with an empty string
    const [hotl, setHotel] = useState([]);
    const [hasError, setHasError] = useState(false);



  const [countriesByCountry, setCountriesByCountry] = useState([]);
  const [countriesByNationality, setCountriesByNationality] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [firstNameDisabled, setFirstNameIsDisabled] = useState(true);
  const [lastNameDisabled, setLastNameIsDisabled] = useState(true);
  const { country, nationality } = useContext(InfoDataContext) || {};



    const [adults, setAdults] = useState(0); // Default to 1 adult
    const [children, setChildren] = useState(0); // Default to 0 children
    const [adultHiddenForms, setAdultHiddenForms] = useState([]);
    const [childHiddenForms, setChildHiddenForms] = useState([]);
    const [email, setEmail]=useState();
    const [city, setCity]=useState();
    const [countr, setCountry]=useState();
    const [national, setNationality]=useState();
    const [dob, setDOB]=useState();
    const [telephone, setTelephone]=useState();
    const [address, setAddress]=useState();
    const [postalCode, setPostalC]=useState();
    const [gender, setGender]=useState();
    const [passnum, setPassNum]=useState();
    const [title, setTitle]=useState();
    const [expDate, setExpDate]=useState();
    const [message, setMessage] = useState('');

    const { toasts, handlers } = useToaster();

function hexToRGBA(hex, opacity) {
  hex = hex.replace(/^#/, '');

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

const opacity = 0.25; // Change this to the desired opacity (0 to 1)
const fieldsetbackground = hexToRGBA(buttonBackgroundColor, opacity);

    console.log(checkInInfo)

    const closeForm = (formType, index) => {
      if (formType === 'adult') {
        if (adultHiddenForms.includes(index)) {
          setAdultHiddenForms(adultHiddenForms.filter(item => item !== index));
        } else {
          setAdultHiddenForms([...adultHiddenForms, index]);
        }
      } else if (formType === 'child') {
        if (childHiddenForms.includes(index)) {
          setChildHiddenForms(childHiddenForms.filter(item => item !== index));
        } else {
          setChildHiddenForms([...childHiddenForms, index]);
        }
      }
    };
    const onError = (errors, e) => console.log(errors, e, 'error');
   

    const handleAdultsChange = (e) => {
      setAdults(parseInt(e.target.value));
    };
  
    const handleChildrenChange = (e) => {
      setChildren(parseInt(e.target.value));
    };
  
    // Dynamically generate additional guest forms
    const generateAdditionalGuestForms = () => {
      const adultForms = [];
      for (let i = 2; i <= adults; i++) {
        const isHidden = adultHiddenForms.includes(i);
    
        adultForms.push(
            <>
            <h3 class="checkIntitle-3 m-3">ADDITIONAL GUEST INFORMATION (Adult {i})</h3>
                     <div class="checkInform-container">
                            <button  onClick={() => closeForm('adult',i)} formNoValidate  type="button" className='w-[20px] h-[20px] bg-transparent'>
                            {isHidden?(
                            <AiOutlinePlusSquare></AiOutlinePlusSquare>):
                            <AiOutlineMinusSquare></AiOutlineMinusSquare>}
                        </button>
                        {!isHidden?(
                <fieldset style={{backgroundColor:fieldsetbackground}}>
                        <div class="checkIngrid__item ten-twelfths lap--three-twelfths push--one-twelfth">
                            <div class="form__item">
                                <label htmlFor={`firstnameAdult${i}`}>First Name*</label>
                                <input type="text" name={`firstnameAdult${i}`} id={`firstnameAdult${i}`}                  
                                required
autoComplete='on' 
                                {...register(`firstnameAdult${i}`)}
                                 />               

                            </div>
                        </div>
                        <div class="checkIngrid__item ten-twelfths lap--three-twelfths push--one-twelfth">
                            <div class="form__item">
                                <label htmlFor={`lastnameAdult${i}`}>Last Name*</label>
                                <input type="text" name={`lastnameAdult${i}`} id={`lastnameAdult${i}`}                  
                                required
autoComplete='on'
                                {...register(`lastnameAdult${i}`)}

 />
                            </div>
                        </div>
                             <div class="checkIngrid__item ten-twelfths lap--four-twelfths push--one-twelfth">
                            <div class="form__item">
                <label htmlFor={`countryAdult${i}`}>Country*</label>
                <Controller
                {...register(`countryAdult${i}`)}
                  required
autoComplete='on'
                name={`countryAdult${i}`}
                control={control}
                options={countriesByCountry.map((c) => ({
                  value: c.description,
                  label: c.description,
                }))}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                    components={{
                        IndicatorSeparator: () => null,
                    }}                required
autoComplete='on'

                    placeholder='Select Country...'
                    menuPlacement='auto'
                    options={countriesByCountry.map((c) => ({
                        value: c.description,
                        label: c.description,
                    }))}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    defaultValue={{
                        value: countryDescription,
                        label: countryDescription,
                    }}
                    />               
                     )}
              />
              {errors.country && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '-0.1rem',
                    marginLeft: '2rem',
                  }}>
                  {errors?.country?.message}
                </p>
              )}
              </div>
            </div>
            <div class="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
                <div class="form__item">
                    <label htmlFor={`passportAdult${i}`}>Passport NUMBER*</label>
                    <input type="text" name={`passportAdult${i}`} id={`passportAdult${i}`}  placeholder="Passport Number"                
                     required
autoComplete='on'
                     {...register(`passportAdult${i}`)}

 />
                </div>
            </div>
            <div class="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
                <div class="form__item">
                    <label htmlFor={`dobAdult${i}`}>Date of Birth*</label>
                        <input type="date" name={`dobAdult${i}`} id={`dobAdult${i}`}  data-toggle="datepicker"                  
                        required
autoComplete='on'
                        {...register(`dobAdult${i}`)}

/>
                </div>
            </div>
          
    </fieldset>):null}
                </div>
          </>
        );
    
    }
      const childForms = [];
      for (let i = 1; i <= children; i++) {
        const isHidden = childHiddenForms.includes(i);

        childForms.push(
            <>
            <h3 class="checkIntitle-3 m-3">ADDITIONAL GUEST INFORMATION (Child {i})</h3>
                     <div class="checkInform-container">
                            <button onClick={() => closeForm('child',i)} formNoValidate  type="button" className='w-[20px] h-[20px] bg-transparent'>
                            {isHidden?(
                            <AiOutlinePlusSquare></AiOutlinePlusSquare>):
                            <AiOutlineMinusSquare></AiOutlineMinusSquare>}
                        </button>
                        {!isHidden?(
                <fieldset style={{backgroundColor:fieldsetbackground}}>
                        <div class="checkIngrid__item ten-twelfths lap--three-twelfths push--one-twelfth">
                            <div class="form__item">
                                <label htmlFor={`firstnameChild${i}`}>First Name*</label>
                                <input type="text" name={`firstnameChild${i}`} id={`firstnameChild${i}`}                   
                                required
autoComplete='on'
                                {...register(`firstnameChild${i}`)}

/>
                            </div>
                        </div>
                        <div class="checkIngrid__item ten-twelfths lap--three-twelfths push--one-twelfth">
                            <div class="form__item">
                                <label htmlFor={`lastnameChild${i}`}>Last Name*</label>
                                <input type="text" name={`lastnameChild${i}`} id={`lastnameChild${i}`}                  
                                required
autoComplete='on'
                                {...register(`lastnameChild${i}`)}

 />
                            </div>
                        </div>
                             <div class="checkIngrid__item ten-twelfths lap--four-twelfths push--one-twelfth">
                            <div class="form__item">
                <label htmlFor={`countryChild${i}`}>Country*</label>
                <Controller
                {...register(`countryChild${i}`,)}                
                required
autoComplete='on'
                name={`countryChild${i}`}
                control={control}
                options={countriesByCountry.map((c) => ({
                  value: c.description,
                  label: c.description,
                }))}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                    components={{
                        IndicatorSeparator: () => null,
                    }}                required
autoComplete='on'

                    placeholder='Select Country...'
                    menuPlacement='auto'
                    options={countriesByCountry.map((c) => ({
                        value: c.description,
                        label: c.description,
                    }))}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    defaultValue={{
                        value: countryDescription,
                        label: countryDescription,
                    }}
                    />               
                     )}
              />
              {errors.country && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '-0.1rem',
                    marginLeft: '2rem',
                  }}>
                  {errors?.country?.message}
                </p>
              )}
              </div>
            </div>
            <div class="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
                <div class="form__item">
                    <label htmlFor={`passportChild${i}`}>Passport NUMBER*</label>
                    <input type="text" name={`passportChild${i}`} id={`passportChild${i}`}  placeholder="Passport Number"                  
                    required
autoComplete='on'
                    {...register(`passportChild${i}`)}

/>
                </div>
            </div>
            <div class="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
                <div class="form__item">
                    <label htmlFor={`dobChild${i}`}>Date of Birth*</label>
                        <input type="date" name={`dobChild${i}`} id={`dobChild${i}`}  data-toggle="datepicker"                  
                        required
autoComplete='on'
                        {...register(`dobChild${i}`)}

/>
                </div>
            </div>
         
    </fieldset>):null}
                </div>
          </>
        );
      }
      console.log(adultForms,childForms)
      return [...adultForms, ...childForms];
    };
    const initialValues = {
        email: '',
        firstname: '',
        lastname: '',
        phoneMobile: '',
        birthday: '',
        postalCode: '',
        address: '',
        nationality: { value: '', label: '' },
        country: { value: '', label: '' },
        city: '',
        bookingRef:'',
        agent:'',
        arDate:'',
        arTime:'',
        depDate:'',
        depTime:'',
        passnumber:'',
        expDate:''
      };
    
     
      const {
        methods,
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        setError,
        setValue,
        getValues,
      } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: initialValues,
        shouldFocusError:true,
        shouldUseNativeValidation:true
      });

      const handleDismiss = () => {
        toasts.forEach((t) => toast.dismiss(t.id));
      };

      const notifySuccess = () =>
      toast.success('Your Check In has been successfully been completed.');
    const notifyError = (error) => toast.error(error);
  
    const TOAST_LIMIT = 1;
  
    useEffect(() => {
      console.log(toasts, 'toasts');
      toasts
        .filter((t) => t.visible) // Only consider visible toasts
        .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
        .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
    }, [toasts]);

    const captureAutoCompletedValues = () => {
      // Replace 'fieldName' with the actual name of each field
      const fieldsToCapture = [
        'bookingRef',
        'agent',
        'arrivalDate',
        'arrivalTime',
        'departureDate',
        'departureTime',
        'adults',
        'children',
        'gender',
        'title',
        'firstname',
        'lastname',
        'email',
        'phoneMobile',
        'address',
        'city',
        'postalcode',
        'country',
        'nationality',
        'dob',
        'passportid',
        'expireDate',
        'specialrequests',
      ];
  
      fieldsToCapture.forEach((fieldName) => {
        const inputElement = document.getElementById(fieldName);
        if (inputElement) {
          // Use the 'setValue' function from React Hook Form to capture the value
          setValue(fieldName, inputElement.value);
        }
      });
    };



        const onSubmit = (data) => {
          captureAutoCompletedValues();
          const bookingRef = document.getElementById('bookingRef').value;
          const agent = document.getElementById('agent').value;
          const arrivalDate = document.getElementById('arrivalDate').value;
          const arrivalTime = document.getElementById('arrivalTime').value;
          const departureDate = document.getElementById('departureDate').value;
       
          const firstname = document.getElementById('firstname').value;
          const lastname = document.getElementById('lastname').value;
          const email = document.getElementById('email').value;
          const phoneMobile = document.getElementById('phoneMobile').value;
          const address = document.getElementById('address').value;
           const city = document.getElementById('city').value;
           const postalcode = document.getElementById('postalcode').value;
           const passportid = document.getElementById('passportid').value;
           const expireDate = document.getElementById('expireDate').value;
           const dob = document.getElementById('dob').value;

           var adults = [];
          for (let i = 2; i <= data.adults; i++) {
            const adult = {
              "formName": `Adult ${i}`,
              "data": [
                ["First Name*", data[`firstnameAdult${i}`]===""? document.getElementById(`firstnameAdult${i}`).value:data[`firstnameAdult${i}`]],
                ["Last Name*", data[`lastnameAdult${i}`]===""? document.getElementById(`lastnameAdult${i}`).value:data[`lastnameAdult${i}`]],
                ["Passport NUMBER*", data[`passportAdult${i}`]===""? document.getElementById(`passportAdult${i}`).value:data[`passportAdult${i}`]],
                ["Date of Birth*", data[`dobAdult${i}`]===""? document.getElementById(`dobAdult${i}`).value:data[`dobAdult${i}`]],
                ["Country*", data[`countryAdult${i}`].value],
              ]
            };
            adults.push(adult);
          }
          var children = [];

          for (let i = 1; i <= data.children; i++) {
            const child = {
              "formName": `Child ${i}`,
              "data": [
                ["First Name*", data[`firstnameChild${i}`]===""? document.getElementById(`firstnameChild${i}`).value:data[`firstnameChild${i}`]],
                ["Last Name*", data[`lastnameChild${i}`]===""? document.getElementById(`lastnameChild${i}`).value:data[`lastnameChild${i}`]],
                ["Passport NUMBER*", data[`passportChild${i}`]===""? document.getElementById(`passportChild${i}`).value:data[`passportChild${i}`]],
                ["Date of Birth*", data[`dobChild${i}`]===""? document.getElementById(`dobChild${i}`).value:data[`dobChild${i}`]],
                ["Country*", data[`countryChild${i}`].value],
              ]
            };
            children.push(child);
          }
        
          var finaldata = {
            "Base": [
              ["Booking Reference No*", data.bookingRef===""?bookingRef:data.bookingRef],
              ["Travel Agent / Booking Channel*", data.agent===""?agent:data.agent],
              ["Hotel", data.hotel.value],
              ["Arrival Date*", data.arrivalDate===""?arrivalDate:data.arrivalDate],
              ["Arrival Time", data.arrivalTime===""?arrivalTime:data.arrivalTime],
              ["Departure Date*", data.departureDate===""?departureDate:data.departureDate],
              ["Departure Time", data.departureTime===""?departureTime:data.departureTime],
              ["Adults", data.adults],
              ["Children", data.children],
             
            ],
            "MainGuest": [
              ["Gender", data.gender],
              ["Title.", data.title],
              ["First Name*", data.firstname===""?firstname:data.firstname],
              ["Last Name*", data.lastname===""?lastname:data.lastname],
              ["Email*", data.email===""?email:data.email],
              ["Mobile Phone*", data.phoneMobile===""?phoneMobile:data.phoneMobile],
              ["Address*", data.address===""?address:data.address],
              ["City*", data.city===""?city:data.city],
              ["Postal Code*", data.postalcode===""?postalcode:data.postalcode],
              ["Country", data.country.value],
              ["Nationality", data.nationality.value],
              ["Date of Birth*", data.dob===""?dob:data.dob],
              ["Passport NUMBER*", data.passportid===""?passportid:data.passportid],
              ["Expiry Date*", data.expireDate===""?expireDate:data.expireDate]
            ],
            "AdditionalGuests": {
              adults,children
                        },
            "AddedInfo": data.specialrequests,
            "GuestEmail":data.email===""?email:data.email,
            "HotelCode": data.hotel.value,
            "AddtionalGuestNumber": data.adults+data.children - 1,
            "Acid": sessionStorage.getItem('acid'),
            "ProgramLabel": localStorage.getItem('programLabel')
          };
        
          console.log(finaldata);
           
    
        // Check for errors and focus on missing values
        if (Object.keys(errors).length > 0) {
          // Loop through the form fields and focus on the first error
          for (const fieldName in errors) {
            if (errors.hasOwnProperty(fieldName)) {
              const inputElement = document.getElementById(fieldName);
              if (inputElement) {
                inputElement.focus();
                break;
              }
            }
          }
        }
        const termsCheckbox = document.getElementById('i-agree');

  if (!termsCheckbox.checked) {
    notifyError("Please agree with our Terms and Conditions")
    return; // Prevent form submission
  }

        axios({
          method: 'post',
          baseURL: '/',
          url: 'api/member/Precheckin',
          data: finaldata,
          headers: {
            'Content-Type': 'application/json',
          }
          
        })
          .then((resp) => {
            console.log(resp, 'resp');
    
            if (resp.data.errorCode !== 200) {
              notifySuccess()            }
          })
          .catch((error) => {
            console.log(error);
            notifyError(error.toString());
            setIsSubmitted(false);

          });
      };

    const getHotels = async () => {
        axios({
          method: 'get',
          baseURL: '/',
          url: 'api/init/getLoyaltyInfo',
          params: {
            programLabel: localStorage['programLabel'],
          },
        })
          .then((resp) => {
            setHotels(resp.data.locations);
          })
          .catch((error) => {
            console.log(error);
          });
      };
    
      useEffect(() => {
        getHotels();
      }, []);
    
      useEffect(() => {
        if (checkInInfo) {
          // Assuming checkInInfo is the data you received
          // Parse and format the data as needed
          setBookingRef(checkInInfo.map(x=>x.bookingReference) || ''); // Use empty string as default
          setAgent(checkInInfo.map(x=>x.agentOrChannel) || '');

          const arrivalDateTimeParts = checkInInfo.map(x => x.arrivalDate)[0];
          const parts = arrivalDateTimeParts.split(' ');
          const dateComponents = parts[0].split('/');
          const arrivalDate = `${dateComponents[2]}-${dateComponents[0]}-${dateComponents[1]}`; // Date part
          const arrivalTime = parts[1]; // Time part
          const departureDateTimeParts = checkInInfo.map(x => x.departureDate)[0];
          const depParts = departureDateTimeParts.split(' ');
          const depDateComponents = depParts[0].split('/');
          const departureDate = `${depDateComponents[2]}-${depDateComponents[0]}-${depDateComponents[1]}`; // Date part
          const departureTime = depParts[1]; // Time part
          
          // Update the state variables
          setArrivalDate(arrivalDate);
          setArrivalTime(arrivalTime);
          setDepartureDate(departureDate);
          setDepartureTime(departureTime);
          
          // setAdults(checkInInfo.adults || '');
          // setChildren(checkInInfo.children || '');
          setHotel(checkInInfo.map(x=>x.partnerDescription)[0]); // You may need to set the correct hotel value from the data
        }
      }, [checkInInfo]);

      
      useEffect(() => {
        // Get countries
        axios({
          method: 'get',
          baseURL: '/',
          url: 'api/init/getCountries',
          params: {
            programLabel: localStorage.getItem('programLabel'),
          },
        })
          .then((resp) => {
            if (resp.data.errorCode !== 200) {
              throw new Error(resp.ErrorMessage);
            }
            setCountriesByCountry(resp.data.countriesOrderByCountry);
            setCountriesByNationality(resp.data.countriesOrderByNationality);
          })
          .catch((error) => {
            console.log(error);
          });
    
    
          const setFirstname = (firstname) => {
            if (firstname === null || firstname === '') {
              // setValue('firstname', 'Please fill in your firstname');
              setError('firstname', {
                type: 'custom',
                message: 'First Name  is required'              
              });
              return;
            }
            setValue('firstname', firstname);
            setFirstNameIsDisabled(true);
          };
          const setLastname = (lastname) => {
            if (lastname === null || lastname === '') {
              // setValue('lastname', 'Please fill in your lastname');
              setError('lastname', {
                type: 'custom',
                message: 'Last Name is required',
              });
              return;
            }
            setValue('lastname', lastname);
            setLastNameIsDisabled(true);
          };
      
          axios({
            method: 'get',
            baseURL: '/',
            url: 'api/member/getInfo',
            params: {
              acid: sessionStorage.getItem('acid'),
              programLabel: localStorage.getItem('programLabel'),
            },
          })
            .then((resp) => {
              console.log(resp.data, 'getInfo resp');
              setFirstname(resp.data.firstname);
              setLastname(resp.data.lastname);
              setCountry(resp.data.country);
              setNationality(resp.data.nationality);
              setEmail(resp.data.email);
              setDOB(formatDate(resp.data.birthday));
              setValue('country', {
          value: resp.data.country,
          label: resp.data.country,
        });
        setValue('nationality', {
          value: resp.data.nationality,
          label: resp.data.nationality,
        });
              setAddress(resp.data.address);
              setPostalC(resp.data.postalCode);
              setCity(resp.data.city);
              setTelephone(resp.data.phoneMobile);
              setGender(resp.data.gender);
              setPassNum(resp.data.idCard)
            })
            .catch((error) => {
              console.log(error);
            });
        }, [ setValue]);
      

      const countryArray =
        countriesByCountry.filter((c) => c.code === country) || {};
      const countryDescription = _.get(countryArray, '[0].description');
    
      useEffect(() => {
        const timer = setTimeout(() => {
          setValue('countries', countryDescription);
        }, 1000);
        return () => clearTimeout(timer);
      }, []);
    
      
  const hotel = _.map(hotels, 'description');

  const newArray = hotel.map((h, i) => ({ value: h, label: h }));

  const firstnameWatch = watch('firstname');
  const lastnameWatch = watch('lastname');

  const nationalityWatch = watch('nationality');
  useEffect(() => {
    if (firstnameWatch === '' || firstnameWatch === null) {
      setFirstNameIsDisabled(false);
    }
    if (lastnameWatch === '' || lastnameWatch === null) {
      setLastNameIsDisabled(false);
    }
  }, [
    firstnameWatch,
    lastnameWatch,
    setFirstNameIsDisabled,
    setLastNameIsDisabled,
  ]);

  // Function to format date as "dd/mm/yyyy"
// Function to format date as "dd/mm/yyyy" and remove the time portion
function formatDate(date) {
    if (!date) return ''; // Handle cases where date is undefined or null
    const dateWithoutTime = date.split('T')[0]; // Remove the time portion
    const parts = dateWithoutTime.split('-');
    if (parts.length !== 3) return ''; // Invalid date format
    return dateWithoutTime;
  }
  
  
    return (
        <>
        <Header />
      <div className="main">
<div class="container ">
<div onClick={handleDismiss}>
          <Toaster
            duration='1000000'
            position='top-center'
            containerClassName='MyProfileToaster'
            containerStyle={{
              top: 10,
              left: 10,
              bottom: 10,
              right: 10,
            }}
            toastOptions={{
              error: {
                duration: 1000000,
                icon: (
                  <Exclamation
                    width='50'
                    height='50'
                    viewBox='-4 1 25 15'
                    color='red'
                  />
                ),
                style: {
                  // border: '1px solid #713200',

                  color: `black`,
                  fontWeight: 'normal',
                  background: `white` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                  filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                  fontFamily: 'Lato',
                  borderRadius: '7px',
                  height: 'auto',
                  maxHeight: '65px',
                  fontWeight: '600',
                  transform: 'translateY(100%)',
                  borderLeft: '6.5px solid red',
                },
              },
              success: {
                duration: 1000000,
                icon: (
                  <Tick
                    color='#26A65B'
                    width='50'
                    height='50'
                    viewBox='0 0 50 50'
                  />
                  // <Exclamation width='60' height='60' viewBox='0 0 16 16' />
                ),
                style: {
                  // border: '1px solid #713200',
                  // padding: '16px',
                  color: `black`,
                  fontWeight: 'normal',
                  background: `#fff` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                  filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                  fontFamily: 'Lato',
                  borderRadius: '7px',
                  height: 'auto',
                  fontWeight: '600',
                  transform: 'translateY(100%)',
                  borderLeft: '6.5px solid #26A65B',
                },
              },
            }}
          />
        </div>
    <form className="checkInform checkInform--shadowed m-5" 
            autoSave='off'
            onSubmit={handleSubmit(onSubmit)}
            >
    <h3 className="checkIntitle-3 m-3">BOOKING INFO</h3>
    <fieldset style={{backgroundColor:fieldsetbackground}}>
        <div className="checkIngrid__item ten-twelfths lap--three-twelfths push--one-twelfth">
            <div className="form__item">
            <label htmlFor="bookingRef">Booking Reference No*</label>
            <input
                           {...register(`bookingRef`)}
                type="text"
                name="bookingRef"
                id="bookingRef"
                value={bookingRef || ''} // Initialize with an empty string
               onChange={(e) => setBookingRef(e.target.value)} // Handle input changes
               required
               autoComplete='on'
              /> 
              </div>
              </div>
        <div className="checkIngrid__item ten-twelfths lap--three-twelfths push--one-twelfth">
            <div className="form__item">
            <label htmlFor="agent">Travel Agent / Booking Channel*</label>
            <input
                 {...register("agent")}
                type='text'
                name="agent"
                id="agent"
                value={agent || ''} // Initialize with an empty string
               onChange={(e) => setAgent(e.target.value)} // Handle input changes
               required
               autoComplete='on'
              />      
            </div>
        </div>
        <div className="checkIngrid__item ten-twelfths lap--four-twelfths push--one-twelfth">
            <div className="form__control">
            <label htmlFor="hotel">Hotel*</label>
            <Controller
            {...register("hotel")}
            control={control}
            options={newArray}
            name="hotel"
            required
autoComplete='on'
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder='Select Hotel...'
                menuPlacement='bottom'
                options={newArray}
                value={newArray.find((option) => option.value === hotl)} // Find the matching option
                onChange={onChange} // Handle select changes
                onBlur={onBlur}
                id="hotel"
                name="hotel"
              />
            )}
          />
         
        </div>
      </div>
      <div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
  <div className="form__item">
    <label htmlFor="arrivalDate">Arrival Date*</label>
    <input
          {...register("arrivalDate")}
      type="date"
      name="arrivalDate"
      id="arrivalDate"
      required
      autoComplete='on'
      value={arrivalDate} // Set the value to the state variable
      onChange={(e) => setArrivalDate(e.target.value)} // Handle input changes
    />
  </div>
</div>

<div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
  <div className="form__item">
    <label htmlFor="arrivalTime">Arrival Time</label>
    <input
          {...register(`arrivalTime`)}
      type="time"
      name="arrivalTime"
      id="arrivalTime"
      required
      autoComplete='on'
      value={arrivalTime||""} // Set the value to the state variable
      onChange={(e) => setArrivalTime(e.target.value)} // Handle input changes
    />
  </div>
</div>

<div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
  <div className="form__item">
    <label htmlFor="departureDate">Departure Date*</label>
    <input
                {...register(`departureDate`)}
      type="date"
      name="departureDate"
      id="departureDate"
      required
      autoComplete='on'
      value={departureDate||""} // Set the value to the state variable
      onChange={(e) => setDepartureDate(e.target.value)} // Handle input changes
    />
  </div>
</div>

<div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
  <div className="form__item">
    <label htmlFor="departureTime">Departure Time</label>
    <input
          {...register(`departureTime`)}
      type="time"
      name="departureTime"
      id="departureTime"
      required
      autoComplete='on'
      value={departureTime||""} // Set the value to the state variable
      onChange={(e) => setDepartureTime(e.target.value)} // Handle input changes
    />
  </div>
</div>


   
    <div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
        <div className="form__item">
          <label htmlFor="adults">Adults*</label>
          <select
                    {...register(`adults`)}
          className="selectize-input"
          name="adults"
          onChange={handleAdultsChange}
          value={adults}                
          required
          autoComplete='on'
        >
            <option className="selectize-dropdown" value="1">1</option>
            <option className="selectize-dropdown" value="2">2</option>
            <option className="selectize-dropdown" value="3">3</option>
            <option className="selectize-dropdown" value="4">4</option>
            <option className="selectize-dropdown" value="5">5</option>
            <option className="selectize-dropdown" value="6">6</option>
          </select>
        </div>
      </div>
      <div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
        <div className="form__item">
          <label htmlFor="children">Children*</label>
          <select
                    {...register(`children`)}
          className="selectize-input"
          name="children"
          onChange={handleChildrenChange}
          value={children}                
          required
          autoComplete='on'
        >
            <option className="selectize-dropdown" value="0">0</option>
            <option className="selectize-dropdown" value="1">1</option>
            <option className="selectize-dropdown" value="2">2</option>
            <option className="selectize-dropdown" value="3">3</option>
            <option className="selectize-dropdown" value="4">4</option>
            <option className="selectize-dropdown" value="5">5</option>
            <option className="selectize-dropdown" value="6">6</option>
          </select>
        </div>
      </div>
    </fieldset>

    <h3 className="checkIntitle-3 m-3">PRIMARY GUEST</h3>
        <fieldset id="mainguest" style={{backgroundColor:fieldsetbackground}}>
            {/* Gender */}
            <div className="checkIngrid__item ten-twelfths lap--three-twelfths push--one-twelfth">
              <div className="form__item">
                <label htmlFor="gender">Gender*</label>
                <select
                  name="gender"
                  class="selectize-input"                 
                  required
                  autoComplete='on'
                  {...register(`gender`)}

                >
                  <option class="selectize-dropdown" disabled="">Gender</option>
                  <option class="selectize-dropdown" value="M">Male</option>
                  <option class="selectize-dropdown" value="F">Female</option>
                </select>
              </div>
            </div>

            {/* Title */}
            <div className="checkIngrid__item ten-twelfths lap--one-twelfth push--one-twelfth">
              <div className="form__item">
                <label htmlFor="title">Title*</label>
                <select
                {...register(`title`)}
                  name="title"
                  class="selectize-input"                  
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  autoComplete='on'
                  >
                  <option class="selectize-dropdown" disabled="">Title</option>
                  <option class="selectize-dropdown" value="MR">Mr.</option>
                  <option class="selectize-dropdown" value="MS">Ms.</option>
                  <option class="selectize-dropdown" >Unspecified</option>
                </select>
              </div>
            </div>

            <div className='checkIngrid__item ten-twelfths lap--three-twelfths push--one-twelfth '>
            <div className="form__item">
              <label  htmlFor='firstname'>
              First Name*
              </label>
              <input
              
                id='firstname'
                type='text'
                name='firstname'
                {...register('firstname', {
                  required: 'First Name  is required',
                  validate: {
                    isValid: () =>
                      firstname.value !== 'Please fill in your First Name' ||
                      'First Name  is required',
                  },  pattern: {
                    value: /^[A-Za-z\s]+$/, 
                    message: 'First Name must contain only Latin characters',
                  },
                })}
                //disabled={firstNameDisabled}
              />
              {errors.firstname && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0rem',
                    marginLeft: '2rem',
                  }}>
                  {errors?.firstname?.message}
                </p>
              )}
            </div>
            </div>

            {/* Last Name */}
            <div className='checkIngrid__item ten-twelfths lap--three-twelfths push--one-twelfth '>
            <div className="form__item">
              <label  htmlFor='lastname'>
              Last Name*
              </label>
              <input
               {...register('lastname', {
                  required: 'Last Name  is required',
                  validate: {
                    isValid: () =>
                      firstname.value !== 'Please fill in your Last Name' ||
                      'Last Name is required',
                  },  pattern: {
                    value: /^[A-Za-z\s]+$/, 
                    message: 'Last Name must contain only Latin characters',
                  },  })}
              
                id='lastname'
                name='lastname'
                type='text'
               
                //disabled={firstNameDisabled}
              />
              {errors.firstname && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0rem',
                    marginLeft: '2rem',
                  }}>
                  {errors?.firstname?.message}
                </p>
              )}
            </div>
            </div>
            {/* Email */}
            <div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
              <div className="form__item" htmlFor="email">
                <label >Email*</label>
                <input
                {...register("email")}
                id="email"
                name="email"
                type='text'
                value={email||''}                
                required
autoComplete='on'

                onChange={(e) => setEmail(e.target.value)}

                
                
              />
              </div>
            </div>

            {/* Mobile Phone */}
            <div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
              <div className="form__item">
                <label htmlFor="phoneMobile">Mobile Phone*</label>
                <input
                                {...register("phoneMobile")}

                id="phoneMobile"
                name="phoneMobile"

                type='number'
                value={telephone||''}               
                 required
                 autoComplete='on'
                onChange={(e) => setTelephone(e.target.value)}

                
              />
             
            </div>
            </div>

            {/* Address */}
            <div className="checkIngrid__item ten-twelfths lap--ten-twelfths push--one-twelfth">
              <div className="form__item">
                <label htmlFor="address">Address*</label>
                <input
                                {...register("address")}
                id="address"
                name="address"
                type='text'
                value={address||''}                
                required
                autoComplete='on'
                onChange={(e) => setAddress(e.target.value)}

              />
             
            </div>
            </div>

            {/* City */}
            <div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
              <div className="form__item">
                <label htmlFor="city">City*</label>
                <input
                                {...register("city")}
                id="city"
                name="city"
                type='text'
                value={city||''}                
                required
                autoComplete='on'
                onChange={(e) => setCity(e.target.value)}

              />
             
            </div>
            </div>

            {/* Postal Code */}
            <div className="checkIngrid__item ten-twelfths lap--two-twelfths push--one-twelfth">
              <div className="form__item">
                <label htmlFor="postalcode">Postal Code*</label>
                <input
                                {...register("postalcode")}
                id="postalcode"
                name="postalcode"
                type='text'
                value={postalCode||''}                
                required
                autoComplete='on'
                onChange={(e) => setPostalC(e.target.value)}

              />
            
            </div>
            </div>

            {/* Country */}
            <div className="checkIngrid__item ten-twelfths lap--three-twelfths push--one-twelfth">
              <div className="form__item">
                <label htmlFor="country">Country*</label>
                <Controller
                 {...register(`country`)}
                name='country'               
                 required
                 autoComplete='on'
                control={control}
                options={countriesByCountry.map((c) => ({
                    value: c.description,
                    label: c.description,
                }))}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    placeholder='Select Country...'
                    menuPlacement='auto'
                    options={countriesByCountry.map((c) => ({
                        value: c.description,
                        label: c.description,
                    }))}
                    onBlur={onBlur}
                    value={value} // Set the selected value here
                    defaultValue={null} // No need for defaultValue here
                    onChange={onChange}

                    />
                )}
                />

              </div>
            </div>

            {/* Nationality */}
            <div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
              <div className="form__item">
                <label htmlFor="nationality">Nationality*</label>
                <Controller
                  {...register(`nationality`)}
                    control={control}                
                    required
                    autoComplete='on'
                    options={countriesByNationality.map((c) => ({
                        value: c.nationality,
                        label: c.nationality,
                    }))}
                    name='nationality'
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        placeholder='Select Nationality...'
                        menuPlacement='auto'
                        options={countriesByNationality.map((c) => ({
                            value: c.nationality,
                            label: c.nationality,
                        }))}
                        onBlur={onBlur}
                        value={value} // Set the selected value here
                        defaultValue={null} // No need for defaultValue here
                        onChange={onChange}
                        />
                    )}
                    />


             
              </div>
            </div>

            {/* Date of Birth */}
            <div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
            <div className="form__item">
                <label htmlFor="dob">Date of Birth*</label>
                <input
                     {...register("dob")}
                     className='bg-white'
                    type="date"
                    name="dob"
                    id='dob'
                    value={dob}
                    onChange={(e) => setDOB(e.target.value)}
                        required
                        autoComplete='on'

                    />
              </div>
            </div>

            {/* Passport Number */}
            <div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
              <div className="form__item">
                <label htmlFor="passportid">Passport NUMBER*</label>
                <input
                   {...register(`passportid`)}
                  type="text"
                  name="passportid"
                  id="passportid"
                  value={passnum}
                  placeholder="Passport Number"                required
autoComplete='on'
                  onChange={(e) => setPassNum(e.target.value)}   
            
                />
              </div>
            </div>

            {/* Expiry Date */}
            <div className="checkIngrid__item ten-twelfths lap--five-twelfths push--one-twelfth">
              <div className="form__item">
                <label htmlFor="expireDate">Expiry Date*</label>
                <input
                {...register("expireDate")}
                  type="date"
                  id='expireDate'
                  name="expireDate"
                  required
                  autoComplete='on'      
                  onChange={(e) => setExpDate(e.target.value)} // Handle input changes

                />
              </div>
            </div>
        </fieldset>

<div>
        {generateAdditionalGuestForms()}
</div>
    {/* ADDITIONAL INFORMATION section */}
    <h3 className="checkIntitle-3 m-3">ADDITIONAL INFORMATION</h3>
    <fieldset style={{backgroundColor:fieldsetbackground}}>
    <div className="checkIngrid__item ten-twelfths lap--ten-twelfths push--one-twelfth">
    <label htmlFor="specialrequests">Special Requests</label>
    <input
            {...register(`specialrequests`)}
        type="text"
        name="specialrequests"
        id="specialrequests"
        placeholder="This does not include change of room or category."

    />
    </div>
    </fieldset>

    {/* Checkbox and Check-in Button */}
    <div className="checkIngrid">
    <div className="checkIngrid__item one-whole">
    <div className="center">
    <br />
    <label className="checkbox inline">
  <input
    type="checkbox"
    name="i-agree"
    id="i-agree"
    data-parsley-multiple="i-agree"
  />
  <span className="checkbox__inner"></span>
  <span>
    I agree to the{' '}
    <a href={`${termsFileUri}`} target="_blank">
      Terms and Conditions
    </a>
    .
  </span>
</label>
    </div>
    </div>
    </div>

    <div className="checkIngrid">
        <div className="checkIngrid__item six-eighths push--one-eighth">
          <div className="center m-3">
          <button
        type="submit"
        className="btn"
        id="checkinpostbtn"
        style={{
          borderColor: buttonBackgroundColor, // Set the border color
          borderRadius: '50px',
        }}
        onMouseOver={(e) => {
          e.target.style.backgroundColor = buttonBackgroundColor; // Set background color on hover
          e.target.style.color = buttonColor; // Set background color on hover

        }}
        onMouseOut={(e) => {
          e.target.style.backgroundColor = ''; // Reset background color on hover out
          e.target.style.color = 'black'; // Set background color on hover

        }}
      >              <span className='btn-hover:text-white btn-focus:text-white hover:text-white'>CHECK-IN</span>
            </button>
          </div>
        </div>
      </div>
    </form>

    </div>
</div>
    </>
    );
  }

export default PreCheckinForm;
