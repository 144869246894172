import React, { useEffect, useState, useContext } from 'react';
import Header from '../Components/Header';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Message from '../Components/Message/Message';
import { ThemeDataContext } from '../ContextProviders/theme';
import '../Components/MyProfile/MyProfileAtlantica.css';

import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { CgCalendarDates } from 'react-icons/cg';
import { InfoDataContext } from '../ContextProviders/info';
import * as _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import toast, { Toaster, useToaster, useToasterStore } from 'react-hot-toast';
import Exclamation from '../Icons/Exclamation';
import Tick from '../Icons/Tick';
import { BsTranslate } from 'react-icons/bs';
import { ErrorMessage } from '@hookform/error-message';
import { string } from 'yup';
import { FaCalendarAlt } from 'react-icons/fa';

export default function MyProfile({ toggle }) {
  const [countriesByCountry, setCountriesByCountry] = useState([]);
  const [countriesByNationality, setCountriesByNationality] = useState([]);
  const [message, setMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [firstNameDisabled, setFirstNameIsDisabled] = useState(true);
  const [lastNameDisabled, setLastNameIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [responseNationality, setResponseNationality] = useState('');
  const [responseCountry, setResponseCountry] = useState('');
  const [firstnamePlaceholder, setFirstnamePlaceholder] = useState('');
  const [lastnamePlaceholder, setLastnamePlaceholder] = useState('');

  const { country, nationality } = useContext(InfoDataContext) || {};

  const theme = useContext(ThemeDataContext);

  const { portalInfo, programLabel } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };
  const linkStyle = {
    color: `${buttonBackgroundColor}`,
  };

  const minAge = 18;
  const maxAge = 110;

  const initialValues = {
    email: '',
    firstname: '',
    lastname: '',
    phoneMobile: '',
    birthday: '',
    postalCode: '',
    address: '',
    nationality: { value: '', label: '' },
    country: { value: '', label: '' },
    city: '',
    promotionalMailAccepted: false,
  };

  const {
    methods,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
    getValues,
    setError,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: initialValues,
  });

  const birthday = watch('birthday');
  const firstnameWatch = watch('firstname');
  const lastnameWatch = watch('lastname');

  const nationalityWatch = watch('nationality');

  useEffect(() => {
    // setDateRange();
    let countriesList;
    // Get countries
    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/init/getCountries',
      params: {
        programLabel: localStorage.getItem('programLabel'),
      },
    })
      .then((resp) => {
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.ErrorMessage);
        }
        setCountriesByCountry(resp.data.countriesOrderByCountry);
        setCountriesByNationality(resp.data.countriesOrderByNationality);
      })
      .catch((error) => {
        console.log(error);
      });

    const setFirstname = (firstname) => {
      if (firstname === null || firstname === '') {
        // setValue('firstname', 'Please fill in your firstname');
        setError('firstname', {
          type: 'custom',
          message: 'First Name  is required',
        });
        return;
      }
      setValue('firstname', firstname?.toUpperCase());
      setFirstNameIsDisabled(true);
    };
    const setLastname = (lastname) => {
      if (lastname === null || lastname === '') {
        // setValue('lastname', 'Please fill in your lastname');
        setError('lastname', {
          type: 'custom',
          message: 'Lastname is required',
        });
        return;
      }
      setValue('lastname', lastname?.toUpperCase());
      setLastNameIsDisabled(true);
    };

    axios({
      method: 'get',
      baseURL: '/',
      url: 'api/member/getInfo',
      params: {
        acid: sessionStorage.getItem('acid'),
        programLabel: localStorage.getItem('programLabel'),
      },
    })
      .then((resp) => {
        console.log(resp.data, 'getInfo resp');

        setFirstname(resp.data.firstname?.toUpperCase());
        setLastname(resp.data.lastname?.toUpperCase());

        // reset({
        //   ...resp.data,
        //   // birthday: new Date(parseISO(resp.data.birthday.substring(0, 10))),
        //   birthday: resp.data.birthday.substring(0, 10),
        //   nationality: {
        //     value: resp.data.nationality,
        //     label: resp.data.nationality,
        //   },
        //   country: { value: resp.data.country, label: resp.data.country },
        // });
        setResponseCountry(resp.data.country);
        setResponseNationality(resp.data.nationality);
        setValue('email', resp.data.email);
        // setValue('firstname', resp.data.firstname);
        // setValue('lastname', resp.data.lastname);
        setValue(
          'birthday',
          new Date(parseISO(resp.data.birthday.substring(0, 10)))
        );
        // setValue('birthday', resp.data.birthday?.substring(0, 10));
        setValue('country', {
          value: resp.data.country,
          label: resp.data.country,
        });
        setValue('nationality', {
          value: resp.data.nationality,
          label: resp.data.nationality,
        });
        setValue('address', resp.data.address);
        setValue('postalCode', resp.data.postalCode);
        setValue('promotionalMailAccepted', resp.data.promotionalMailAccepted);
        setValue('city', resp.data.city);
        setValue('phoneMobile', resp.data.phoneMobile);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reset, setValue]);

  const onSubmit = (data) => {
    console.log(data, 'data');
    setIsSubmitted(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // Parse the date without modifying the time
  // Parse the date without modifying the time zone
  const bday = new Date(data.birthday);
    
  // Set the time to 00:00:00
  bday.setHours(0, 0, 0, 0);

  // Assuming the local time is at UTC+0, you can adjust the date to match the local time zone
  // by subtracting the time zone offset
  const timeZoneOffsetMinutes = bday.getTimezoneOffset();
  bday.setMinutes(bday.getMinutes() - timeZoneOffsetMinutes);

  axios({
      method: 'put',
      baseURL: '/',
      url: 'api/member/UpdateInfo',
      data: {
          acid: sessionStorage.getItem('acid'),
          email: data.email,
          firstname: data.firstname?.toUpperCase(),
          lastname: data.lastname?.toUpperCase(),
          birthday: bday.toISOString(), // Convert to ISO format for consistency
          memberAddress: data.address,
          memberMobPhoneNo: data.phoneMobile,
          memberPostCode: data.postalCode,
          country: data.country.value,
          nationality: data.nationality.value,
          city: data.city,
          promotionalMailAccepted: data.promotionalMailAccepted,
          programLabel: localStorage.getItem('programLabel'),
      },
        })
      .then((resp) => {
        console.log(resp, 'resp');

        if (resp.data.errorCode !== 200) {
          throw new Error(resp.data.ErrorMessage);
        }
        notifySuccess();
        // showMessage('Your profile has been successfully updated.', false);
      })
      .catch((error) => {
        console.log(error);
        notifyError(error.toString());
        // showMessage(error.toString(), true);
        setIsSubmitted(false);
      });
    
  };

  useEffect(() => {
    const form = document.getElementById('my-profile-form'); // Add an id to your form element
    if (form) {
      form.reset(); // Reset the form to clear the input values
    }
  }, []);
  
  const onError = (errors, e) => console.log(errors, e, 'error');

  const showMessage = (msg, isError) => {
    const messageClearTimeout = 6000;

    setMessage(msg);
    setHasError(isError);
    window.scrollTo(0, 0);
    setInterval(() => {
      setMessage('');
      setHasError(false);
    }, messageClearTimeout);
  };

  // const setDateRange = () => {
  //   const currentYear = new Date().getFullYear();
  //   const min = new Date();
  //   const max = new Date();

  //   min.setFullYear(currentYear - maxAge);
  //   max.setFullYear(currentYear - minAge);

  //   setMinDate(min.toLocaleDateString('en-GB').split('/').reverse().join('-'));
  //   setMaxDate(max.toLocaleDateString('en-GB').split('/').reverse().join('-'));
  // };

  const style = {
    container: (base) => ({
      ...base,
      marginTop: '0.25rem',
      width: '25vw',
    }),
    valueContainer: (base) => ({
      ...base,
      width: '25vw',
      paddingBottom: '0',
      marginLeft: '-0.28rem',
    }),
    group: (base) => ({
      ...base,
      width: '25vw',
    }),
    menu: (base) => ({
      ...base,
      width: '25vw',
      marginLeft: '2rem',
    }),
    control: (base) => ({
      ...base,
      border: 0,
      '&:hover': { borderColor: '#535353' },
      borderBottom: '2px solid #535353',
      boxShadow: 'none',
      borderRadius: 'none',
      width: '25vw',
      marginLeft: '2rem',
      fontSize: '1.25rem',
      borderBottom: '1px solid var(--color-accent)',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: '#111',
      marginLeft: '-0.28rem',
    }),
    input: (base) => ({
      ...base,
      width: '25vw',
      marginLeft: '-0.28rem',
    }),
  };

  const mobileStyle = {
    container: (base) => ({
      ...base,
      marginTop: '0.25rem',
      paddingRight: '1vw',
    }),
    valueContainer: (base) => ({
      ...base,

      paddingBottom: '0',
      marginLeft: '-0.28rem',
      paddingRight: '1vw',
    }),
    group: (base) => ({
      ...base,
      paddingRight: '1vw',
    }),
    menu: (base) => ({
      ...base,
      marginLeft: '2rem',
      paddingRight: '1vw',
    }),
    control: (base) => ({
      ...base,
      border: 0,
      '&:hover': { borderColor: '#535353' },
      borderBottom: '2px solid #535353',
      boxShadow: 'none',
      borderRadius: 'none',

      marginLeft: '2rem',
      fontSize: '1.25rem',
      borderBottom: '1px solid var(--color-accent)',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: '#111',
      marginLeft: '-0.28rem',
    }),
    input: (base) => ({
      ...base,
      marginLeft: '-0.28rem',
      paddingRight: '1vw',
    }),
  };

  const countryWatch = watch('country');
  const birthdayWatch = watch('birthday');
  const Tablet = useMediaQuery({
    query: '(min-width: 1028px) ',
  });

  const Mobile = useMediaQuery({
    query: '(min-width: 280px) and (max-width: 1027px)',
  });

  const Nationality = (props) => (
    <Select
      {...props}
      placeholder='Select Nationality...'
      components={{
        IndicatorSeparator: () => null,
      }}
      menuPlacement='auto'
      styles={Tablet ? style : mobileStyle}
      options={countriesByNationality.map((c) => ({
        value: c.nationality,
        label: c.nationality,
      }))}
      onChange={props.onChange}
      onBlur={props.onBlur}
      selected={props.value}
      defaultValue={{ value: nationality, label: nationality }}
    />
  );

  const countryArray =
    countriesByCountry.filter((c) => c.code === country) || {};
  const countryDescription = _.get(countryArray, '[0].description');

  useEffect(() => {
    const timer = setTimeout(() => {
      setValue('countries', countryDescription);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const Country = (props) => (
    <Select
      {...props}
      components={{
        IndicatorSeparator: () => null,
      }}
      menuPlacement='auto'
      styles={Tablet ? style : mobileStyle}
      options={countriesByCountry.map((c) => ({
        value: c.description,
        label: c.description,
      }))}
      onChange={props.onChange}
      onBlur={props.onBlur}
      selected={props.value}
      defaultValue={{
        value: countryDescription,
        label: countryDescription,
      }}
    />
  );

  const isSidebarVisible = useSelector((state) => state.user.isSidebarVisible);

  const { toasts, handlers } = useToaster();

  const notifySuccess = () =>
    toast.success('Your profile has been successfully updated.');
  const notifyError = (error) => toast.error(error);

  const TOAST_LIMIT = 1;

  useEffect(() => {
    console.log(toasts, 'toasts');
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  const handleDismiss = () => {
    toasts.forEach((t) => toast.dismiss(t.id));
  };

  // useEffect(() => {
  //   if (isSubmitted && responseCountry === null) {
  //     setError('country', { type: 'custom', message: 'Country is required' });
  //   }

  //   if (isSubmitted && responseNationality === null) {
  //     setError('nationality', {
  //       type: 'custom',
  //       message: 'Nationality is required',
  //     });
  //   }
  // }, [isSubmitted, country, nationality, setError]);

  useEffect(() => {
    if (firstnameWatch === '' || firstnameWatch === null||firstnameWatch === undefined) {
      setFirstNameIsDisabled(false);
    }
    if (lastnameWatch === '' || lastnameWatch === null||firstnameWatch === undefined) {
      setLastNameIsDisabled(false);
    }
  }, [
    firstnameWatch,
    lastnameWatch,
    setFirstNameIsDisabled,
    setLastNameIsDisabled,
  ]);
  return (
    <>
      <Header />
      <div
        className='MyProfile-Container'
        style={{
          visibility: `${isSidebarVisible ? 'hidden' : 'visible'}`,
        }}>
        <div className='MyProfile-personal-heading'>
          <h1>Personal Information</h1>
          <hr id='MyProfile-line' />
        </div>
        {/* <Message
          className='MyProfile-messageContainer'
          message={message}
          isError={hasError}
        /> */}
        <br />
        <br />
        <br />

        <div onClick={handleDismiss}>
          <Toaster
            duration='1000000'
            position='top-center'
            containerClassName='MyProfileToaster'
            containerStyle={{
              top: 10,
              left: 10,
              bottom: 10,
              right: 10,
            }}
            toastOptions={{
              error: {
                duration: 1000000,
                icon: (
                  <Exclamation
                    width='50'
                    height='50'
                    viewBox='-4 1 25 15'
                    color='red'
                  />
                ),
                style: {
                  // border: '1px solid #713200',

                  color: `black`,
                  fontWeight: 'normal',
                  background: `white` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                  filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                  fontFamily: 'Lato',
                  borderRadius: '7px',
                  height: 'auto',
                  maxHeight: '65px',
                  fontWeight: '600',
                  transform: 'translateY(100%)',
                  borderLeft: '6.5px solid red',
                },
              },
              success: {
                duration: 1000000,
                icon: (
                  <Tick
                    color='#26A65B'
                    width='50'
                    height='50'
                    viewBox='0 0 50 50'
                  />
                  // <Exclamation width='60' height='60' viewBox='0 0 16 16' />
                ),
                style: {
                  // border: '1px solid #713200',
                  // padding: '16px',
                  color: `black`,
                  fontWeight: 'normal',
                  background: `#fff` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                  filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                  fontFamily: 'Lato',
                  borderRadius: '7px',
                  height: 'auto',
                  fontWeight: '600',
                  transform: 'translateY(100%)',
                  borderLeft: '6.5px solid #26A65B',
                },
              },
            }}
          />
        </div>
        <div className='MyProfile-address-heading'>
          <h1>Address Information</h1>
          <hr id='MyProfile-line' />
        </div>
        <div className='MyProfile-formContainer'>
          <form
            autoComplete='true'
            autoSave='on'
            onSubmit={handleSubmit(onSubmit, onError)}
            className='MyProfile-form'>
            <div id='MyProfile-lastName'>
              <label className='MyProfile-label' htmlFor='lastname'>
                LAST NAME
              </label>
              <input
                autoComplete='true'
                className='MyProfile-form-input'
                id='lastname2'
                type='text'
                {...register('lastname', {
                  required: 'Lastname is required',
                  validate: {
                    isValid: () =>
                      lastnameWatch !== 'Please fill in your last name' ||
                      'Last Name  is required',
                  },
                  pattern: {
                    value: /^[\p{L}\s]+$/u,
                    message: 'You must only use letters',
                  },
                                        
                  
                })}
                disabled={lastNameDisabled}
              />
              {(errors.lastname ||!lastnameWatch)&& (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0rem',
                    marginLeft: '2rem',
                  }}>
                  Last Name  is required
                </p>
              )}
            </div>
            <div id='MyProfile-firstName'>
              <label className='MyProfile-label' htmlFor='firstname'>
                FIRST NAME
              </label>
              <input
              autoComplete='on'
                className='MyProfile-form-input'
                id='firstname'
                type='text'
                {...register('firstname', {
                  required: 'First Name is required',
                  validate: {
                    isValid: () =>
                      firstname.value !== 'Please fill in your firstname' ||
                      'First Name  is required',
                  },  pattern: {
  value: /^[\p{L}\s]+$/u,
  message: 'You must only use letters',
},
                      
                })}
                disabled={firstNameDisabled}
              />
              {(errors.firstname ||!firstnameWatch)&& (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0rem',
                    marginLeft: '2rem',
                  }}>
First Name  is required                </p>
              )}
            </div>
            <div id='MyProfile-email'>
              <label className='MyProfile-label' htmlFor='email'>
                EMAIL*
              </label>
              <input
               autoComplete='off'
                className='MyProfile-form-input'
                id='email'
                type='email'
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                    message: 'Invalid email format',
                  },
                })}
              />
            </div>
            <div id='MyProfile-mobile'>
              <label className='MyProfile-label' htmlFor='phoneMobile'>
                MOBILE PHONE
              </label>
              <input
               autoComplete='off'
                className='MyProfile-form-input'
                id='phoneMobile'
                type='tel'
                {...register('phoneMobile', {
                  pattern: {
                    value: /^\d+$/,
                    message: 'Mobile phone must contain only numbers',
                  },
                })}
              />
              {errors.phoneMobile && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0.4rem',
                    marginLeft: '2rem',
                  }}>
                  {errors?.phoneMobile?.message}
                </p>
              )}
            </div>
            <div id='MyProfile-dob'>
              <label className='MyProfile-dob-label' htmlFor='birthday'>
                BIRTHDATE*
              </label>
              <Controller
                {...register('birthday', {
                  validate: {
                    isDefined: () => {
                      if (birthdayWatch !== '' || null) {
                        return true;
                      }
                      return 'Birthday is required';
                    },
                  },
                })}
                control={control}
                name='birthday'
                render={({ field: { value, onChange } }) => (
                  <div className='MyProfile-ReactDatePickerWrap'>
                    <DatePicker
                      className='MyProfile-ReactDatePicker'
                      style={{ marginLeft: '2rem' }}
                      dateFormat='dd/MM/yyyy'
                      onChange={onChange}
                      selected={value}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      placeholderText='DD/MM/YYYY'
                    />
                  </div>
                )}
              />

              {/* <input
                className='MyProfile-dob-input'
                id='birthday'
                type='date'
                {...register('birthday', {
                  required: 'Date of Birth is required',
                })}
              /> */}
              {errors.birthday && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0.4rem',
                    marginLeft: '2rem',
                  }}>
                  {errors?.birthday?.message}
                </p>
              )}
            </div>

            <div id='MyProfile-nationality'>
              <label className='MyProfile-label' htmlFor='nationality'>
                NATIONALITY
              </label>
              <Controller
                {...register('nationality', {
                  validate: {
                    isDefined: () => {
                      if (nationalityWatch.value !== '' || null) {
                        return true;
                      }
                      return 'Nationality is required';
                    },
                  },
                })}
                placeholder='Select nationality...'
                control={control}
                options={countriesByNationality.map((c) => ({
                  value: c.nationality,
                  label: c.nationality,
                }))}
                name='nationality'
                // rules={{
                //   validate: () => {
                //     return (
                //       getValues('nationality') == null ||
                //       'Nationality is required'
                //     );
                //   },
                // }}
                render={({ field: { onChange, onBlur, value } }) => (
                  // <Select
                  //   components={{
                  //     IndicatorSeparator: () => null,
                  //   }}
                  //   menuPlacement='auto'
                  //   styles={style}
                  //   options={countriesByNationality.map((c) => ({
                  //     value: c.code,
                  //     label: c.nationality,
                  //   }))}
                  //   onChange={onChange}
                  //   onBlur={onBlur}
                  //   selected={value}
                  //   defaultValue={value}
                  // />
                  <Nationality
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />

              {errors.nationality && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '-0.1rem',
                    marginLeft: '2rem',
                  }}>
                  {errors?.nationality?.message}
                </p>
              )}
            </div>

            <p id='MyProfile-changePass'>
              Click{' '}
              <NavLink
                style={linkStyle}
                className='MyProfile-changePassLink'
                to='/home/changepassword'>
                here
              </NavLink>{' '}
              to change your password.
            </p>

            <div id='MyProfile-address'>
              <label className='MyProfile-label' htmlFor='address'>
                ADDRESS*
              </label>
              <input
               autoComplete='false'
                className='MyProfile-form-input'
                id='address'
                type='text'
                {...register('address', {
                  required: 'Address is required',  
                  pattern: {
                    value: /^[A-Za-z0-9\s]+$/,
                    message: 'Address must contain only Latin characters and numbers',
                  },
                })}
              />
              {errors.address && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '0rem',
                    marginLeft: '2rem',
                  }}>
                  {errors?.address?.message}
                </p>
              )}
            </div>
            <div id='MyProfile-city'>
              <label className='MyProfile-label' htmlFor='city'>
                CITY*
              </label>
              <input
                className='MyProfile-form-input'
                id='city'
                autoComplete='off'
                type='city'
                {...register('city', {
                  required: 'City is required',  
                  pattern: {
                            value: /^[\p{L}\s]+$/u,
                            message: 'You must only use letters',
                      },
                })}
              />
              {errors.city && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginLeft: '2rem',
                  }}>
                  {errors?.city?.message}
                </p>
              )}
            </div>
            <div id='MyProfile-postalCode'>
              <label className='MyProfile-label' htmlFor='postalCode'>
                POSTAL CODE*
              </label>
              <input
                autoComplete={false}
                className='MyProfile-form-input'
                id='postalCode'
                type='text'
                {...register('postalCode', {
                  required: 'Postal code is required',  
                  pattern: {
                    value: /^[A-Za-z0-9\s]+$/,
                    message: 'Postal code must contain only Latin characters and numbers',
                  },
              
                })}
              />
              {errors.postalCode && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',

                    marginLeft: '2rem',
                  }}>
                  {errors?.postalCode?.message}
                </p>
              )}
            </div>
            <div id='MyProfile-country'>
              <label className='MyProfile-label' htmlFor='country'>
                COUNTRY
              </label>
              <Controller
                {...register('country', {
                  validate: {
                    isDefined: () => {
                      if (countryWatch.value !== '' || null) {
                        return true;
                      }
                      return 'Country is required';
                    },
                  },
                })}
                name='country'
                control={control}
                options={countriesByCountry.map((c) => ({
                  value: c.description,
                  label: c.description,
                }))}
                render={({ field: { onChange, onBlur, value } }) => (
                  // <Select
                  //   components={{
                  //     IndicatorSeparator: () => null,
                  //   }}
                  //   //placeholder={value}
                  //   menuPlacement='auto'
                  //   styles={style}
                  //   options={countriesByCountry.map((c) => ({
                  //     value: c.code,
                  //     label: c.description,
                  //   }))}
                  //   onChange={onChange}
                  //   onBlur={onBlur}
                  //   selected={value}
                  //   defaultValue={{ value: country, label: country }}
                  // />
                  <Country onChange={onChange} onBlur={onBlur} value={value} />
                )}
              />
              {errors.country && (
                <p
                  role='alert'
                  style={{
                    color: 'rgb(255, 0, 0)',
                    marginTop: '-0.1rem',
                    marginLeft: '2rem',
                  }}>
                  {errors?.country?.message}
                </p>
              )}
            </div>
            <br />
            <br />
            <div id='MyProfile-memberOffers'>
              <label
                className='MyProfile-Label'
                htmlFor='promotionalMailAccepted'>
                I would like to receive exclusive member offers, account
                updates, program and hotel news via email.
              </label>

              <input
                autoComplete='off'
                className='MyProfile-input checkboxVerity'
                type='checkbox'
                id='promotionalMailAccepted'
                {...register('promotionalMailAccepted')}
              />
            </div>
            <div className='MyProfile-footerButtonContainer'>
              <button className='MyProfile-changePassword'>
                <NavLink
                  style={buttonStyle}
                  className='MyProfile-changePasswordLink'
                  to='/home/changepassword'>
                  Change Password
                </NavLink>
              </button>
              <div className='MyProfile-saveContainer'>
                <button style={buttonStyle} className='MyProfile-save'>
                  SAVE
                </button>
              </div>
            </div>
            <br />
          </form>
        </div>
      </div>
    </>
  );
}
