import React, { useContext } from 'react';
import { ThemeDataContext } from '../../../ContextProviders/theme';

export const Styles = () => {
  const { portalInfo } = useContext(ThemeDataContext) || {};

  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};
  const { globalBackgroundColor } = portalInfo || {};
  const { globalColor } = portalInfo || {};

  console.log(buttonBackgroundColor, 'in Styles');

  console.log(buttonColor, 'in Styles');

  const style = {
    container: (base) => ({
      ...base,
      width: '310px',
      height: '40px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: buttonColor,
      fontWeight: '300',
    }),
    valueContainer: (base) => ({
      ...base,
      width: '310px',
      paddingBottom: '0',
      height: '41px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: buttonColor,
      fontWeight: '300',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: buttonBackgroundColor,
      '&:hover': { color: buttonBackgroundColor },
    }),
    group: (base) => ({
      ...base,
      width: '310px',
    }),
    menu: (base) => ({
      ...base,
      width: '310px',
    }),
    control: (base) => ({
      ...base,
      '&:hover': { borderColor: buttonBackgroundColor },
      border: `1px solid ${buttonBackgroundColor}`,
      boxShadow: 'none',
      borderRadius: '5px',
      width: '310px',
      fontSize: '1rem',
      height: '41px',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: buttonColor,
      marginLeft: '0.15rem',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: buttonColor,
      fontWeight: '300',
    }),
    input: (base) => ({
      ...base,
      width: '310px',
      marginLeft: '-0.28rem',
      fontFamily: 'Roboto, sans-serif',
      color: buttonColor,
      fontSize: '1rem',
      fontWeight: '300',
    }),
  };

  const styleErrors = {
    container: (base) => ({
      ...base,
      width: '310px',
      height: '40px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
    }),
    valueContainer: (base) => ({
      ...base,
      width: '310px',
      paddingBottom: '0',
      height: '41px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
      // marginLeft: '-0.28rem',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'transparent',
      '&:hover': { color: '#D80027' },
    }),
    group: (base) => ({
      ...base,
      width: '310px',
    }),
    menu: (base) => ({
      ...base,
      width: '310px',
      // marginLeft: '2rem',
    }),
    control: (base) => ({
      ...base,
      '&:hover': { borderColor: '#D80027' },
      border: '1px solid #D80027',
      boxShadow: 'none',
      borderRadius: '5px',
      width: '310px',
      // marginLeft: '2rem',
      fontSize: '1rem',
      height: '41px',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      marginLeft: '0.15rem',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
    }),
    input: (base) => ({
      ...base,
      width: '310px',
      marginLeft: '-0.28rem',
      fontFamily: 'Roboto, sans-serif',
      color: '#D80027',
      fontSize: '1rem',
      fontWeight: '300',
    }),
  };
  const mobileStyle = {
    container: (base) => ({
      ...base,
      // marginTop: '0.25rem',
      width: '100%',
      height: '40px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: buttonColor,
      fontWeight: '300',
    }),
    valueContainer: (base) => ({
      ...base,
      width: '100%',
      paddingBottom: '0',
      height: '41px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: buttonColor,
      fontWeight: '300',
      // marginLeft: '-0.28rem',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: buttonBackgroundColor,
      '&:hover': { color: buttonBackgroundColor },
    }),
    group: (base) => ({
      ...base,
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
      // marginLeft: '2rem',
    }),
    control: (base) => ({
      ...base,
      '&:hover': { borderColor: buttonBackgroundColor },
      border: `1px solid ${buttonBackgroundColor}`,
      boxShadow: 'none',
      borderRadius: '5px',
      width: '100%',
      // marginLeft: '2rem',
      fontSize: '1rem',
      height: '41px',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: buttonColor,
      marginLeft: '0.15rem',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: buttonColor,
      fontWeight: '300',
    }),
    input: (base) => ({
      ...base,
      width: '100%',
      marginLeft: '-0.28rem',
      fontFamily: 'Roboto, sans-serif',
      color: buttonColor,
      fontSize: '1rem',
      fontWeight: '300',
    }),
  };

  const mobileStyleErrors = {
    container: (base) => ({
      ...base,
      // marginTop: '0.25rem',
      width: '100%',
      height: '40px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
    }),
    valueContainer: (base) => ({
      ...base,
      width: '100%',
      paddingBottom: '0',
      height: '41px',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
      // marginLeft: '-0.28rem',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'transparent',
      '&:hover': { color: '#D80027' },
    }),
    group: (base) => ({
      ...base,
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '100%',
      // marginLeft: '2rem',
    }),
    control: (base) => ({
      ...base,
      '&:hover': { borderColor: '#D80027' },
      border: '1px solid #D80027',
      boxShadow: 'none',
      borderRadius: '5px',
      width: '100%',
      // marginLeft: '2rem',
      fontSize: '1rem',
      height: '41px',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      marginLeft: '0.15rem',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '1rem',
      color: '#D80027',
      fontWeight: '300',
    }),
    input: (base) => ({
      ...base,
      width: '100%',
      marginLeft: '-0.28rem',
      fontFamily: 'Roboto, sans-serif',
      color: '#D80027',
      fontSize: '1rem',
      fontWeight: '300',
    }),
  };

  return { mobileStyle, mobileStyleErrors, style, styleErrors };
};

export default Styles;
