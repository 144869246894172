import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import tick from '../Assets/Images/Tick.svg';
import axios from 'axios';
import { ThemeDataContext } from '../ContextProviders/theme';
import '../Components/ForgotPassword/ForgotPasswordAtlantica.css';
import { ProgramLabelDataContext } from '../ContextProviders/programLabel';

function ForgotPassword() {
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const [isHoveringSignIn, setIsHoveringSignIn] = useState(false);

  const [isHoveringSignUp, setIsHoveringSignUp] = useState(false);

  const handleMouseOverSignIn = () => {
    setIsHoveringSignIn(true);
  };

  const handleMouseOutSignIn = () => {
    setIsHoveringSignIn(false);
  };

  const handleMouseOverSignUp = () => {
    setIsHoveringSignUp(true);
  };

  const handleMouseOutSignUp = () => {
    setIsHoveringSignUp(false);
  };

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
    borderRadius:'0px'

  };

  const buttonStyleTransparent = {
    backgroundColor: 'white',
    color: `${buttonBackgroundColor}`,
    border: `3px solid ${buttonBackgroundColor}`,
    borderRadius:'0px'
  };

  const buttonStyleFill = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: 'white',
    border: 'none',
    borderRadius:'0px'

  };

  const [email, setEmail] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const navigate = useNavigate();

  useEffect(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), []);
  const programLabel = localStorage.getItem('programLabel');

  const handleSubmit = (event) => {
    event.preventDefault();

    const programLabel = localStorage.getItem('programLabel');

    axios({
      method: 'post',
      baseURL: '/',
      url: 'api/password/forgotrequest',
      data: {
        email: email,
        programLabel,
        template: 1,
        customization: {
          logoUrl: portalInfo.logoUrl,
          globalColor: portalInfo.globalColor,
          backgroundColor: portalInfo.globalBackgroundColor,
          buttonColor: portalInfo.buttonColor,
          buttonBackgroundColor: portalInfo.buttonBackgroundColor,
        },
      },
    })
      .then((resp) => {
        console.log(resp, 'forgotrequest resp');
        setShowMessage(true);
      })
      .catch((error) => {
        console.log(error);
      });
    navigate('/signIn/forgotpassword/success');
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(https://verity.blob.core.windows.net/portal/${programLabel}/backroundimage_front.jpg)`;
    document.body.style.backgroundColor = 'transparent';
    document.body.style.background = `url(https://verity.blob.core.windows.net/portal/${programLabel}/backroundimage_front.jpg)`;
    document.body.style.backgroundSize = 'cover';
    document.body.style.WebkitBackgroundSize = 'cover';
    document.body.style.MozBackgroundSize = 'cover';
    document.body.style.backgroundAttachment = 'fixed';
    document.body.style.height = '100vh';
    document.body.style.width = '100vw';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.backgroundPosition = 'center';
  }, []);

  return (
    <div>
      <header className='forgotPass-background'>
        <div
          style={{
            backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
          }}
          className={`${localStorage['programLabel']==="9DF45F46-9911-4A64-A340-126466DAF541"?"forgotPassLogo MKLogo":"forgotPassLogo"}`}></div>
        <br />
        <div className={`${localStorage['programLabel']==="9DF45F46-9911-4A64-A340-126466DAF541"?"forgotPass-buttonsContainer MKContainerButtons":"forgotPass-buttonsContainer"}`} >

          <button
            // style={buttonStyleTransparent}
            style={isHoveringSignIn ? buttonStyleFill : buttonStyleTransparent}
            name='signIn'
            className='forgotPass-button'
            onMouseOver={handleMouseOverSignIn}
            onMouseOut={handleMouseOutSignIn}
            onClick={() => navigate('/signIn')}>
            Sign In
          </button>
          <button
            // style={buttonStyleTransparent}
            style={isHoveringSignUp ? buttonStyleFill : buttonStyleTransparent}
            name='signUp'
            className='forgotPass-button'
            onMouseOver={handleMouseOverSignUp}
            onMouseOut={handleMouseOutSignUp}
            onClick={() => navigate('/signUp')}>
            Join The Club
          </button>
        </div>
        <div className={`${localStorage['programLabel']==="9DF45F46-9911-4A64-A340-126466DAF541"?"forgotPass-formContainer MKContainer":"forgotPass-formContainer"}`} >
          <div className='forgotPass-Heading'>
            <br />
            <br />
            <h1>Forgot your Password?</h1>
            <hr className='line' />
            <p>
              No problem! Please enter the email you used to sign in and we will
              send you a reset link:
            </p>
          </div>
          {showMessage && (
            <div className='forgotPass-InfoContainer'>
              <div className='forgotPass-tickWrap'>
                <img src={tick} alt='tick' />
              </div>
              <p>
                If an account matches this email address, you should receive an
                email with instructions on how to reset your password shortly
              </p>
            </div>
          )}
          <form onSubmit={handleSubmit} className='forgotPass-form'>
            <div className='forgotPass-EmailWrap'>
              <label className='forgotPass-label' htmlFor='forgotPass-email'>
                Email
              </label>
              <input
                className='forgotPass-input'
                id='forgotPass-email'
                name='forgotPass-email'
                type='email'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className='forgotPass-buttonContainer'>
              <button
                style={buttonStyle}
                className='forgotPass-formButton'
                type='submit'>
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
}

export default ForgotPassword;
