import React from 'react';

export default function DontShow({
  size = 18, // or any default size of your choice
  color, // or any color of your choice
  style,
  onClick,
  className,
}) {
  return (
    <svg
      onClick={onClick}
      className={className}
      style={style}
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.0885 13.4837C7.91172 13.4837 7.73493 13.4185 7.59537 13.279C6.8324 12.5161 6.4137 11.502 6.4137 10.4321C6.4137 8.20861 8.21877 6.40375 10.4425 6.40375C11.5126 6.40375 12.5267 6.8224 13.2897 7.58528C13.42 7.71553 13.4944 7.8923 13.4944 8.07836C13.4944 8.26443 13.42 8.4412 13.2897 8.57144L8.58164 13.279C8.44208 13.4185 8.26529 13.4837 8.0885 13.4837ZM10.4425 7.79926C8.99104 7.79926 7.80937 8.9808 7.80937 10.4321C7.80937 10.8973 7.93033 11.3439 8.15364 11.7346L11.7452 8.14349C11.3544 7.92021 10.9078 7.79926 10.4425 7.79926Z'
        fill={color}
      />
      <path
        d='M4.48779 16.4886C4.32961 16.4886 4.16213 16.4328 4.03187 16.3212C3.03629 15.4745 2.14306 14.4326 1.38009 13.2231C0.393812 11.6881 0.393812 9.18543 1.38009 7.64107C3.65038 4.08716 6.95348 2.04041 10.4427 2.04041C12.4896 2.04041 14.5087 2.74747 16.2766 4.07785C16.5836 4.31044 16.6488 4.7477 16.4161 5.05471C16.1835 5.36173 15.7462 5.42685 15.4392 5.19426C13.9132 4.04064 12.1826 3.43592 10.4427 3.43592C7.43731 3.43592 4.56222 5.24078 2.55245 8.39464C1.85462 9.48314 1.85462 11.381 2.55245 12.4695C3.25029 13.558 4.05048 14.4977 4.9344 15.2606C5.22284 15.5118 5.26006 15.949 5.00884 16.2467C4.87858 16.4049 4.68318 16.4886 4.48779 16.4886Z'
        fill={color}
      />
      <path
        d='M10.4426 18.8239C9.20509 18.8239 7.9955 18.5727 6.83244 18.0796C6.47887 17.9308 6.31139 17.5214 6.46026 17.1679C6.60913 16.8144 7.01853 16.6469 7.3721 16.7957C8.35838 17.2144 9.39117 17.4284 10.4333 17.4284C13.4386 17.4284 16.3137 15.6235 18.3235 12.4697C19.0213 11.3812 19.0213 9.48326 18.3235 8.39476C18.035 7.93889 17.7187 7.50163 17.3837 7.09228C17.1418 6.79457 17.1883 6.35731 17.4861 6.10611C17.7838 5.86422 18.2211 5.90144 18.4724 6.20845C18.8352 6.65502 19.1888 7.13879 19.5052 7.64118C20.4914 9.17624 20.4914 11.6789 19.5052 13.2232C17.2349 16.7771 13.9318 18.8239 10.4426 18.8239Z'
        fill={color}
      />
      <path
        d='M11.0847 14.4048C10.759 14.4048 10.4613 14.1722 10.3961 13.8373C10.3217 13.4558 10.5729 13.093 10.9544 13.0279C11.9779 12.8418 12.8339 11.9859 13.02 10.9625C13.0944 10.5811 13.4573 10.3392 13.8388 10.4043C14.2203 10.4787 14.4715 10.8416 14.3971 11.223C14.0993 12.8325 12.8153 14.1071 11.2149 14.4048C11.1684 14.3955 11.1312 14.4048 11.0847 14.4048Z'
        fill={color}
      />
      <path
        d='M1.13814 20.4334C0.961352 20.4334 0.784567 20.3682 0.645 20.2287C0.375169 19.9589 0.375169 19.5123 0.645 19.2425L7.59545 12.2929C7.86528 12.0231 8.3119 12.0231 8.58173 12.2929C8.85156 12.5627 8.85156 13.0092 8.58173 13.279L1.63128 20.2287C1.49171 20.3682 1.31492 20.4334 1.13814 20.4334Z'
        fill={color}
      />
      <path
        d='M12.7967 8.77619C12.6199 8.77619 12.4431 8.71107 12.3036 8.57152C12.0337 8.30172 12.0337 7.85515 12.3036 7.58535L19.254 0.635699C19.5239 0.3659 19.9705 0.3659 20.2403 0.635699C20.5101 0.905498 20.5101 1.35206 20.2403 1.62186L13.2898 8.57152C13.1503 8.71107 12.9735 8.77619 12.7967 8.77619Z'
        fill={color}
      />
    </svg>
  );
}
