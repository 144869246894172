import React, { useContext, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ThemeDataContext } from '../ContextProviders/theme';
import '../Components/ResetPassword/ResetPasswordSuccessAtlantica.css';

export default function ResetPasswordSuccess() {
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};
  const programLabel = localStorage.getItem('programLabel');
  const navigate = useNavigate();

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };

  useEffect(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), []);

  // setTimeout(() => navigate('/signIn'), 5000);

  useEffect(() => {
    document.body.style.backgroundImage = `url(https://verity.blob.core.windows.net/portal/${programLabel}/backroundimage_front.jpg)`;
    document.body.style.backgroundColor = 'transparent';
    document.body.style.background = `url(https://verity.blob.core.windows.net/portal/${programLabel}/backroundimage_front.jpg)`;
    document.body.style.backgroundSize = 'cover';
    document.body.style.WebkitBackgroundSize = 'cover';
    document.body.style.MozBackgroundSize = 'cover';
    document.body.style.backgroundAttachment = 'fixed';
    document.body.style.height = '100vh';
    document.body.style.width = '100vw';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.backgroundPosition = 'center';
  }, []);

  return (
    <div className='h-full w-full'>
      <div
        style={{
          display: 'block',
          margin: '0 auto',
          // backgroundImage: `url(${img})`,
          backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
                 className={`${localStorage['programLabel']==="9DF45F46-9911-4A64-A340-126466DAF541"?"resetPasswordSuccess-logo MKLogo":"resetPasswordSuccess-logo"}`}></div>
        <br />

        <div className={`${localStorage['programLabel']==="9DF45F46-9911-4A64-A340-126466DAF541"?"resetPasswordSuccess-formContainer MKContainer":"resetPasswordSuccess-formContainer"}`} >
        <div className='resetPasswordSuccess-Heading'>
          <br />
          <br />
          <h1>Your password has been successfully updated!</h1>
          <hr className='resetPasswordSuccess-line' />
          <p>Please click the SIGN IN option to login to your account</p>
        </div>
        <div className='resetPasswordSuccess-buttonContainer'>
          <NavLink
            style={buttonStyle}
            className='resetPasswordSuccess-button'
            to='/signIn'>
            Sign In
          </NavLink>
        </div>
      </div>
    </div>
  );
}
