import React, { useContext, useEffect, useState, useRef } from 'react';
import './ButtonsAtlantica.css';
import { ThemeDataContext } from '../../ContextProviders/theme';
import hexRgb from 'hex-rgb';
import { useButton } from '@react-aria/button';

export function Buttons(props) {
  const [buttonActive, setButtonActive] = useState({});
  const [button, setButton] = useState({});

  const { toggle, activeButton } = props;

  const { portalInfo } = useContext(ThemeDataContext) || {};

  const { buttonBackgroundColor } = portalInfo || {};

  const { buttonColor } = portalInfo || {};

  // let ref = useRef();
  // let { buttonProps, isPressed } = useButton({ onPress: onClick }, ref);

  const [isHoveringSignIn, setIsHoveringSignIn] = useState(false);

  const [isHoveringSignUp, setIsHoveringSignUp] = useState(false);

  const handleMouseEnterSignIn = () => {
    setIsHoveringSignIn(true);
  };

  const handleMouseLeaveSignIn = () => {
    setIsHoveringSignIn(false);
  };

  const handleMouseEnterSignUp = () => {
    setIsHoveringSignUp(true);
  };

  const handleMouseLeaveSignUp = () => {
    setIsHoveringSignUp(false);
  };

  // const handleMouseEnterSignIn = () => {
  //   setIsHoveringSignIn(true);
  //   activeButton === 'signUp' &&
  //     setButton({
  //       ...button,
  //       color: `${buttonColor}`,
  //       backgroundColor: `${buttonBackgroundColor}`,
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     }) &&
  //     setButtonActive({
  //       ...buttonActive,
  //       color: `${buttonColor}`,
  //       backgroundColor: `transparent`,
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     });
  //   activeButton === 'signIn' &&
  //     setButton({
  //       ...button,
  //       color: `${buttonBackgroundColor}`,
  //       backgroundColor: `transparent`,
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     });
  //   setButtonActive({
  //     ...buttonActive,
  //     color: `${buttonColor}`,
  //     backgroundColor: `${buttonBackgroundColor}`,
  //     border: `3px solid ${buttonBackgroundColor}`,
  //   });
  // };

  // const handleMouseLeaveSignIn = () => {
  //   setIsHoveringSignIn(false);
  //   activeButton === 'signUp' &&
  //     setButton({
  //       ...button,
  //       color: `${buttonColor}`,
  //       backgroundColor: 'transparent',
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     }) &&
  //     setButtonActive({
  //       ...buttonActive,
  //       color: `${buttonColor}`,
  //       backgroundColor: `${buttonBackgroundColor}`,
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     });
  //   activeButton === 'signIn' &&
  //     setButton({
  //       ...button,
  //       color: `${buttonColor}`,
  //       backgroundColor: 'transparent',
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     }) &&
  //     setButtonActive({
  //       ...buttonActive,
  //       color: `${buttonColor}`,
  //       backgroundColor: `${buttonBackgroundColor}`,
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     });
  // };

  // const handleMouseEnterSignUp = () => {
  //   setIsHoveringSignUp(true);
  //   activeButton === 'signIn' &&
  //     setButton({
  //       ...button,
  //       color: `${buttonColor}`,
  //       backgroundColor: `${buttonBackgroundColor}`,
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     }) &&
  //     setButtonActive({
  //       ...buttonActive,
  //       color: `${buttonBackgroundColor}`,
  //       backgroundColor: 'transparent',
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     });
  //   activeButton === 'signUp' &&
  //     setButton({
  //       ...button,
  //       color: `${buttonBackgroundColor}`,
  //       backgroundColor: 'transparent',
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     }) &&
  //     setButtonActive({
  //       ...buttonActive,
  //       color: `${buttonColor}`,
  //       backgroundColor: `${buttonBackgroundColor}`,
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     });
  // };

  // const handleMouseLeaveSignUp = () => {
  //   setIsHoveringSignUp(false);
  //   activeButton === 'signIn' &&
  //     setButton({
  //       ...button,
  //       color: `${buttonBackgroundColor}`,
  //       backgroundColor: 'transparent',
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     }) &&
  //     setButtonActive({
  //       ...buttonActive,
  //       color: `${buttonColor}`,
  //       backgroundColor: `${buttonBackgroundColor}`,
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     });
  // };

  // () =>
  //   setButtonActive({
  //     ...activeButton,
  //     ':hover': {
  //       color: `${buttonColor}`,
  //       backgroundColor: `${buttonBackgroundColor}`,
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     },
  //   }) &&
  //   setButton({
  //     ...button,
  //     ':hover': {
  //       color: `${buttonColor}`,
  //       backgroundColor: `${buttonBackgroundColor}`,
  //       border: `3px solid ${buttonBackgroundColor}`,
  //     },
  //   });

  console.log(buttonBackgroundColor, 'buttonBackgroundColor');

  useEffect(() => {
    setButtonActive({
      color: `${buttonColor}`,
      backgroundColor: `${buttonBackgroundColor}`,
      border: `3px solid ${buttonBackgroundColor}`,
    });

    setButton({
      backgroundColor: 'white',
      color: `${buttonBackgroundColor}`,
      //border: `3px solid ${buttonBackgroundColor}`,
    });
  }, [buttonBackgroundColor, buttonColor]);

  // if (!buttonBackgroundColor) return <div>loading...</div>;

  return (
    <div className='buttons-Container'>
      <button
        style={activeButton === 'signIn' ? buttonActive : button}
        name='signIn'
        onClick={toggle}
        onMouseEnter={handleMouseEnterSignIn}
        onMouseLeave={handleMouseLeaveSignIn}
        className={
          activeButton === 'signIn' ? 'buttons-btn active' : 'buttons-btn'
        }>
        Sign In
      </button>
      <button
        style={activeButton === 'signUp' ? buttonActive : button}
        name='signUp'
        onClick={toggle}
        className={
          activeButton === 'signUp' ? 'buttons-btn active' : 'buttons-btn'
        }
        onMouseEnter={handleMouseEnterSignUp}
        onMouseLeave={handleMouseLeaveSignUp}>
        JOIN THE CLUB
        {/* {localStorage['programLabel'] === TYPE_OF_THEME.ATLANTICA_MODE
          ? 'JOIN THE CLUB'
          : 'Sign Up'} */}
      </button>
    </div>
  );
}

export default Buttons;
