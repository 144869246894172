import React, { useContext, useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ThemeDataContext } from '../../ContextProviders/theme';
import './ThankYouAtlantica.css';

export default function ThankYou() {
  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};
  const navigate = useNavigate();

  const programLabel = localStorage.getItem('programLabel');

  const buttonStyle = {
    color: `${buttonColor}`,
    backgroundColor: `${buttonBackgroundColor}`,
  };

  setTimeout(() => navigate('/signIn'), 5000);

  return (
    <div>
      <header
        style={{
          height: '1700px',
          backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/backroundimage_front.jpg)`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          width: '100%',
        }}
        id='thankYou-background'>
        <div
          style={{
            backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
          }}
          className='thankYou-logo'></div>
        <br />
        <div className='thankYou-formContainer'>
          <div className='thankYou-Heading'>
            <br />
            <br />
            <h1>Thank you for verifying your email !</h1>
            <hr className='thankYou-line' />
            <p>Please click the SIGN IN option to login to your account.</p>
          </div>
          <div className='thankYou-buttonContainer'>
            <NavLink
              style={buttonStyle}
              className='thankYou-button'
              to='/signIn'>
              Sign In
            </NavLink>
          </div>
        </div>
      </header>
    </div>
  );
}
