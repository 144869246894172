import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from '../Components/ProtectedRoutes/protectedRoute';
import SignIn from '../Pages/SignIn';
import SignUp from '../Pages/SignUp';
import Overview from '../Pages/Overview';
import MyProfile from '../Pages/MyProfile';
import Preferences from '../Pages/Preferences';
import Activity from '../Pages/Activity';
import Benefits from '../Pages/Benefits';
import ChangePassword from '../Pages/ChangePassword';
import ResetPassword from '../Pages/ResetPassword';
import ConfirmPage from '../Pages/ConfirmPage';
import MissingPoints from '../Pages/MissingPoints';
import ForgotPassword from '../Pages/ForgotPassword';
import ForgotPasswordSuccess from '../Pages/ForgotPasswordSuccess';
import RedirectToOverview from '../Components/Redirect/RedirectToOverview';
import ResetPasswordSuccess from '../Pages/ResetPasswordSuccess';
import NotFound from '../Pages/NotFound';
import OnlinePreCheckin from '../Pages/OnlineCheckIn'
import '../Container/globals'; // to load globals for the entire app;

function App() {
  const programLabel = localStorage.getItem('programLabel');
  console.log(document.title, 'title');

  return (
    <>
    <div className={programLabel === 'ALD-D6723397-1407-4C60-93BD-386DCE063346' ? 'aldr-font' : 'normal-font'}>  
    <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path='/home/overview' element={<Overview />} />
          <Route path='/home/myprofile' element={<MyProfile />} />
          <Route path='/home/activity' element={<Activity />} />
          <Route path='/home/preferences' element={<Preferences />} /> 
          <Route path='/home/onlinePreCheckin' element={<OnlinePreCheckin />} /> 
          <Route path='/home/benefits' element={<Benefits />} />
          <Route path='/home/changepassword' element={<ChangePassword />} />
          <Route path='/home/missingpoints' element={<MissingPoints />} />
          <Route path='/home' element={<RedirectToOverview />} />
        </Route>
        <Route
          path='/signIn/forgotpassword/success'
          element={<ForgotPasswordSuccess />}
        />
        <Route path='/signIn/forgotpassword' element={<ForgotPassword />} />
        <Route path='/signIn' element={<SignIn />} />
        <Route path='/signUp/' element={<SignUp />} />
        <Route
          path='/confirm:email?:token?:programLabel?'
          element={<ConfirmPage />}
        />
        <Route path='/confirm' element={<ConfirmPage />} />
        <Route
          path='/resetPassword:email?:token?:programLabel?'
          element={<ResetPassword />}
        />
        <Route path='/resetPassword' element={<ResetPassword />} />

        <Route
          path='/resetpassword/success'
          element={<ResetPasswordSuccess />}
        />
        <Route path='*' element={<NotFound />} />
        {/* <Route index element={<SignIn />} /> */}
      </Routes>
      </div>
    </>
  );
}

export default App;
