import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../Components/ResetPassword/ResetPasswordAtlantica.css';
import axios from 'axios';
import Message from '../Components/Message/Message';
import { RiEyeLine } from 'react-icons/ri';
import { ThemeDataContext } from '../../ContextProviders/theme';
import { useForm } from 'react-hook-form';
import toast, { Toaster, useToaster, useToasterStore } from 'react-hot-toast';
import Exclamation from '../../Icons/Exclamation';
import Tick from '../../Icons/Tick';
import DontShow from '../../Icons/DontShow';
import Danger from '../../Icons/Danger';
import { ToastContainer, toast as toastify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import LargeX from '../../Icons/LargeX';

export default function ResetPassword() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [authError, SetAuthError] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isHoveringSignIn, setIsHoveringSignIn] = useState(false);

  const [isHoveringSignUp, setIsHoveringSignUp] = useState(false);

  const handleMouseOverSignIn = () => {
    setIsHoveringSignIn(true);
  };

  const handleMouseOutSignIn = () => {
    setIsHoveringSignIn(false);
  };

  const handleMouseOverSignUp = () => {
    setIsHoveringSignUp(true);
  };

  const handleMouseOutSignUp = () => {
    setIsHoveringSignUp(false);
  };

  const { portalInfo } = useContext(ThemeDataContext) || {};
  const { buttonBackgroundColor } = portalInfo || {};
  const { buttonColor } = portalInfo || {};

  const buttonStyle = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: `${buttonColor}`,
  };

  const buttonStyleTransparent = {
    backgroundColor: 'transparent',
    color: `${buttonBackgroundColor}`,
    border: `3px solid ${buttonBackgroundColor}`,
  };

  const buttonStyleFill = {
    backgroundColor: `${buttonBackgroundColor}`,
    color: 'white',
    border: 'none',
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const PasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!-/:-@{-~"^_`\[\]]).{8,}$/;
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get('email');
  const token = queryParams.get('token');
  const programLabel = queryParams.get('programLabel');

  const onSubmit = (data) => {
    setIsSubmitting(true);

    axios({
      method: 'post',
      baseURL: '/',
      url: 'api/password/reset',
      data: {
        email: email,
        newPassword: data.newPassword,
        token: token,
        programLabel: programLabel,
      },
    })
      .then((resp) => {
        console.log(resp);
        if (resp.data.errorCode !== 200) {
          throw new Error(resp.data.errorMessage);
        }
        notifyResetPasswordSuccess();
        setTimeout(() => navigate('/signIn'), 5000);
      })
      .catch((err) => {
        console.log(err);
        notifyError(err.toString());
        // SetAuthError(err.toString());
      });
  };

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const newPassword = watch('newPassword');
  const confirmPassword = watch('confirmPassword');
  const onError = (errors, e) => console.log(errors, e);

  // useEffect(() => {
  //   if (isSubmitting && Object.keys(authError) === 0) {
  //     navigate('/resetpassword/success');
  //   }
  // }, [authError, isSubmitting]);

  const { toasts, handlers } = useToaster();

  const notifySuccess = () =>
    toast.success('Your password has been successfully resetted.');
  const notifyError = (error) => toast.error(error);

  const TOAST_LIMIT = 1;

  useEffect(() => {
    console.log(toasts, 'toasts');
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  const handleDismiss = () => {
    toasts.forEach((t) => toast.dismiss(t.id));
  };

  const notifyPassComposition = () =>
    toast(
      <div
        style={{
          display: 'flex',
          width: '100%',
          borderRadius: '7px',
          fontFamily: 'Lato',
          filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
          background: 'transparent',
          color: `black`,
        }}>
        <div
          style={{
            display: 'flex',
            order: '1',
            alignItems: 'center',
            width: '20%',
            transform: 'translateX(-10%)',
          }}>
          <Exclamation
            width='50'
            height='50'
            viewBox='-4 1 25 15'
            color='red'
          />
          {/* <BsExclamationCircle
            color='red'
            size='40'
          /> */}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            order: '2',
            width: '80%',
            transform: 'translateX(0%)',
          }}>
          Password must contain at least: 8 characters, 1 uppercase letter, 1
          lowercase letter, 1 number, 1 symbol.
        </div>
      </div>,
      {
        style: {
          borderLeft: '6.5px solid red',
          maxWidth: '375px',
        },

        position: 'top-center',
      }
    );

  const notifyResetPasswordSuccess = () =>
    toastify(
      <div
        style={
          {
            // backgroundImage: `url(${logo})`,
            // backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
          }
        }
        className='w-full p-[7%] bg-no-repeat bg-center bg-opacity-50 bg-[length:50px_50px] rounded-[5px] '>
        <h1 className='font-roboto text-skin-primary font-bold text-xl'>
          Your password has been successfully updated!
        </h1>
        <br />
        <p className='font-roboto'>
          Please click the SIGN IN option to login to your account
        </p>
        <br />
        <div className='flex w-full justify-center h-12'>
          <button
            // disabled={isDisabled}
            // ref={buttonRef}
            // style={{background: 'background: #14D5FF', color: '#ffffff'}}
            className='w-[330px] text-roboto text-center text-xl transition ease-in hover:-translate-y-[2px] duration-300 font-bold text-skin-primary leading-11 bg-turquoise rounded-5 py-1'
            type='submit'
            onClick={() => navigate('/signIn')}>
            Login
          </button>
        </div>
        <LargeX
          className={'absolute top-4 right-4'}
          color='#909090'
          width='33'
          height='33'
        />
      </div>,
      { containerId: 'resetPasswordSuccess' },
      {
        // position: toast.POSITION.TOP_CENTER,
        className: '',
      }
    );
  try {
    return (
      <header className='w-screen h-screen bg-skin-primary pt-[8.5rem] overflow-hidden'>
        {/* <div
        style={{
          display: 'block',
          margin: '0 auto',
          // backgroundImage: `url(${img})`,
          backgroundImage: `url(https://verity.blob.core.windows.net/portal/${programLabel}/logo_white.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        className='resetPassword-logo'></div> */}
        <br />

        {/* <div className='resetPassword-buttonContainer'>
        <button
          style={isHoveringSignIn ? buttonStyleFill : buttonStyleTransparent}
          // style={buttonStyleTransparent}
          name='signIn'
          className='resetPassword-button'
          onMouseOver={handleMouseOverSignIn}
          onMouseOut={handleMouseOutSignIn}
          onClick={() => navigate('/signIn')}>
          Sign In
        </button>

        <button
          style={isHoveringSignUp ? buttonStyleFill : buttonStyleTransparent}
          // style={buttonStyleTransparent}
          name='signUp'
          className='resetPassword-button'
          onMouseOver={handleMouseOverSignUp}
          onMouseOut={handleMouseOutSignUp}
          onClick={() => navigate('/signUp')}>
          Sign Up
        </button>
      </div> */}
        <div className='h-[600px] bg-transparent w-full'>
          <div className='resetPassword-Heading w-100'>
            <br />
            <br />
            <h1 className='w-full h-50px text-center font-roboto font-normal text-skin-a11y text-[40px] leading-{50} tracking-wide my-0 drop-shadow-3xl'>
              Reset <span className='text-skin-secondary'> Password</span>
            </h1>
            <p className='text-skin-a11y font-roboto font-normal leading-[21px] text-lg w-full text-center m-0 mt-10'>
              Please enter your new password:
            </p>
          </div>
          <br />
          <br />
          <div onClick={handleDismiss}>
            <Toaster
              duration='1000000'
              position='top-center'
              containerClassName='resetPasswordToaster'
              containerStyle={{
                top: 10,
                left: 10,
                bottom: 10,
                right: 10,
              }}
              toastOptions={{
                error: {
                  duration: 1000000,
                  icon: (
                    <Exclamation
                      width='50'
                      height='50'
                      viewBox='-4 1 25 15'
                      color='red'
                    />
                  ),
                  style: {
                    // border: '1px solid #713200',
                    color: `black`,
                    fontWeight: 'normal',
                    background: `white` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                    filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                    fontFamily: 'Lato',
                    borderRadius: '7px',
                    height: 'auto',
                    maxHeight: '65px',
                    fontWeight: '600',
                    transform: 'translateY(100%)',
                    borderLeft: '6.5px solid red',
                  },
                },
                success: {
                  duration: 1000000,
                  icon: (
                    <Tick
                      color='#26A65B'
                      width='50'
                      height='50'
                      viewBox='0 0 50 50'
                    />
                    // <Exclamation width='60' height='60' viewBox='0 0 16 16' />
                  ),
                  style: {
                    // border: '1px solid #713200',
                    // padding: '16px',
                    color: `black`,
                    fontWeight: 'normal',
                    background: `#fff` /*#f8e6ae beige  #dcd5cd tier2 rgba(175, 65, 84)*/,
                    filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))',
                    fontFamily: 'Lato',
                    borderRadius: '7px',
                    height: 'auto',
                    fontWeight: '600',
                    transform: 'translateY(100%)',
                    borderLeft: '6.5px solid #26A65B',
                  },
                },
              }}
            />
          </div>
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className='w-100 h-auto flex flex-col items-center'>
            <div className='block w-[310px] h-[105px] mt-2'>
              <label
                className='font-roboto text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem]'
                htmlFor='newPassword'>
                New Password:
              </label>
              <input
                className={`w-full h-[41px] bg-transparent rounded-[5px] ${
                  errors.newPassword
                    ? 'border-[#D80027]'
                    : 'border-skin-secondary'
                } indent-[0.5rem] border-solid  border-[1px]  text-skin-a11y font-light text-base leading-[19px] mt-[0.7rem]`}
                type={passwordShown ? 'text' : 'password'}
                id='newPassword'
                {
                  ...register('newPassword', {
                    required: 'Password is required',
                    onChange: () => {
                      if (errors.newPassword) {
                        clearErrors('newPassword');
                      }
                    },
                    validate: {
                      isValid: () =>
                        /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/.test(
                          newPassword
                        ) || notifyPassComposition(),
                    },
                  })
                  // pattern: {
                  //   value:
                  //     /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/,
                  //   message:
                  //     'Password must contain minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and any 1 symbol.',
                  // },
                }
              />
              {passwordShown && !errors.newPassword && (
                <DontShow
                  color='#14D5FF'
                  style={{
                    transform: 'translate(1310%, -157%)',
                  }}
                  onClick={togglePassword}
                />
              )}
              {!passwordShown && !errors.newPassword && (
                <RiEyeLine
                  fill='#14D5FF'
                  size='21'
                  style={{
                    color: '#14D5FF',
                    transform: 'translate(1310%, -157%)',
                  }}
                  onClick={togglePassword}
                />
              )}
              {errors.newPassword && (
                <Danger
                  color='#D80027'
                  width='25'
                  height='24'
                  style={{ transform: 'translate(1090%, -145%)' }}
                />
              )}
              {errors.newPassword && (
                <p
                  role='alert'
                  className='font-roboto text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                  {errors?.newPassword?.message}
                </p>
              )}
              {/* {errors.newPassword &&
              errors.newPassword.message === 'Password is required' && (
                <p role='alert' className='resetPasswordErrors'>
                  {errors?.newPassword?.message}
                </p>
              )} */}
            </div>
            <div className='block w-[310px] h-[105px] mt-2'>
              <label
                className='font-roboto text-skin-a11y font-normal text-base leading-[18.75px] indent-[0.5rem]'
                htmlFor='confirmPassword'>
                Confirm New Password:
              </label>
              <input
                className={`w-full h-[41px] bg-transparent rounded-[5px]  ${
                  errors.confirmPassword
                    ? 'border-[#D80027]'
                    : 'border-skin-secondary'
                } indent-[0.5rem] border-solid  border-[1px]  text-skin-a11y font-light text-base leading-[19px] mt-[0.7rem]`}
                type={confirmPasswordShown ? 'text' : 'password'}
                id='confirmPassword'
                name='confirmPassword'
                {...register('confirmPassword', {
                  onChange: () => {
                    if (errors.confirmPassword) {
                      clearErrors('confirmPassword');
                    }
                  },
                  required: 'Password is required',
                  validate: {
                    passwordsMatch: () =>
                      newPassword === confirmPassword || 'Passwords must match',
                  },
                })}
              />
              {/* <BsEyeFill
              fill='grey'
              style={{ transform: 'translateX(-90%)' }}
              onClick={toggleConfirmPassword}
            /> */}
              {confirmPasswordShown && !errors.confirmPassword && (
                <DontShow
                  color='#14D5FF'
                  style={{
                    transform: 'translate(1310%, -157%)',
                  }}
                  onClick={toggleConfirmPassword}
                />
              )}
              {!confirmPasswordShown && !errors.confirmPassword && (
                <RiEyeLine
                  fill='#14D5FF'
                  size='21'
                  style={{
                    color: '#14D5FF',
                    transform: 'translate(1310%, -157%)',
                  }}
                  onClick={toggleConfirmPassword}
                />
              )}
              {errors.confirmPassword && (
                <Danger
                  color='#D80027'
                  width='25'
                  height='24'
                  style={{ transform: 'translate(1090%, -145%)' }}
                />
              )}
              {errors.confirmPassword && (
                <p
                  role='alert'
                  className='font-roboto text-skin-a11y font-medium leading-[16px] text-[14px] rounded-[5px] text-center text-sm -translate-y-6 w-[170px] h-[23px] bg-[rgba(216,0,39,0.7)] items py-[1%] mt-1'>
                  {errors.confirmPassword.message}
                </p>
              )}
              {/* {errors.confirmPassword && (
              <p role='alert' className='resetPasswordErrors'>
                {errors?.confirmPassword?.message}
              </p>
            )} */}
            </div>
            {/* <ul className='resetPassword-Requirements'>
            <li>Must contain minimum 8 characters</li>
            <li>Contain one uppercase letter </li>
            <li>Contain one lowercase letter</li>
            <li>Contain a number</li>
            <li>Contain any one symbol</li>
          </ul> */}

            <div className='flex w-full justify-center h-12 mt-6'>
              <button
                // style={buttonStyle}
                type='submit'
                className='block w-[330px] h-12 text-roboto text-center  text-xl transition ease-in hover:-translate-y-[2px] font-bold text-skin-primary leading-11 bg-turquoise rounded-5 py-1px'>
                Save
              </button>
            </div>
          </form>
          <br />
          <div className='flex w-full h-7 justify-center'>
            <p className='font-roboto font-bold text-lg leading-[21px] text-skin-a11y'>
              Return to {''}
              <NavLink
                // style={linkStyle}
                className='font-roboto font-bold text-lg leading-[21px] text-skin-secondary no-underline'
                to='/signIn'>
                Sign in
              </NavLink>
            </p>
          </div>
        </div>
        <ToastContainer
          enableMultiContainer
          containerId={'resetPasswordSuccess'}
          limit={1}
          hideProgressBar={true}
          transition={Zoom}
          closeButton={false}
          className=''
          autoClose={false}
          style={{
            position: 'absolute',
            top: '30%',
            left: '0%',
            width: '500px',
            height: '500px',
            borderRadius: '0',
            margin: '0 auto',
            textAlign: 'center',
          }}
        />
      </header>
    );
  } catch (err) {
    console.log(err, 'err');
  }
}
