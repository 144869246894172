export const TRANSLATIONS_GR = {
  general: {
    'Powered by': 'Powered by',
    'Verity Logo': 'Verity Logo',
    Logo: 'Logo',
  },
  login: {
    Welcome: 'Καλωσήρθατε',
    'Email*': 'Email*',
    'Password*': 'Κωδικός*',
    'Sign In': 'Είσοδος',
    'Not a member? Please register.': 'Δεν είστε μέλος? Παρακαλώ εγγραφείτε.',
    'Already a member? Please sign in.': 'Είστε ήδη μέλος? Παρακαλώ εισέλθετε.',
    Register: 'Εγγραφή',
    Login: 'Είσοδος',
    'Tell us about yourself': 'Πείτε μας για εσάς',
    'First Name*': 'Όνομα*',
    'Last Name*': 'Επίθετο*',
    'Set Password': 'Ορίστε κωδικό πρόσβασης',
    'Re-Enter Password*': 'Επαναλάβετε τον κωδικό*',
    'Forgot Password?': 'Ξεχάσατε τον κωδικό πρόσβασης;',
    'First name is required': 'Το όνομα είναι υποχρεωτικό',
    'Last name is required': 'Το επίθετο είναι υποχρεωτικό',
    'Email is required': 'Το Email είναι υποχρεωτικό',
    'Password is required': 'Ο κωδικός πρόσβασης είναι υποχρεωτικός',
    'Password must be between 8 and 16 characters, and must contain at least one uppercase, one lowercase, one number and one special character':
      'Ο κωδικός πρόσβασης πρέπει να περιέχει από 8 ως 16 χαρακτήρες και να περιέχει τουλάχιστον έναν αριθμό ένα κεφαλαίο γράμμα και έναν ειδκικό χαρακτήρα',
    'Passwords must match': 'Οι κωδικοί πρόσβασης πρέπει να είναι ίδιοι',
  },
};
