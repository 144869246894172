import React from 'react';

export default function ChevronDown({
  size = 18, // or any default size of your choice
  color = 'black', // or any color of your choice
  className,
  width,
  height,
  style,
}) {
  return (
    <svg
      width={width}
      style={style}
      height={height}
      className={className}
      viewBox='0 0 13 8'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.146894 0.950971C0.19334 0.904407 0.248515 0.867464 0.30926 0.842258C0.370005 0.817052 0.435127 0.804077 0.500894 0.804077C0.566661 0.804077 0.631782 0.817052 0.692528 0.842258C0.753273 0.867464 0.808448 0.904407 0.854894 0.950971L6.50089 6.59797L12.1469 0.950971C12.1934 0.904483 12.2486 0.867606 12.3093 0.842447C12.37 0.817288 12.4352 0.804339 12.5009 0.804339C12.5666 0.804339 12.6317 0.817288 12.6925 0.842447C12.7532 0.867606 12.8084 0.904483 12.8549 0.950971C12.9014 0.997459 12.9383 1.05265 12.9634 1.11339C12.9886 1.17413 13.0015 1.23923 13.0015 1.30497C13.0015 1.37071 12.9886 1.43581 12.9634 1.49655C12.9383 1.55729 12.9014 1.61248 12.8549 1.65897L6.85489 7.65897C6.80845 7.70553 6.75327 7.74248 6.69253 7.76768C6.63178 7.79289 6.56666 7.80586 6.50089 7.80586C6.43513 7.80586 6.37001 7.79289 6.30926 7.76768C6.24852 7.74248 6.19334 7.70553 6.14689 7.65897L0.146894 1.65897C0.100331 1.61253 0.0633878 1.55735 0.0381813 1.4966C0.0129749 1.43586 0 1.37074 0 1.30497C0 1.2392 0.0129749 1.17408 0.0381813 1.11334C0.0633878 1.05259 0.100331 0.997416 0.146894 0.950971Z'
        fill={color}
      />
    </svg>
  );
}
