import React from 'react';

export default function Vector({
  size = 18, // or any default size of your choice
  color, // or any color of your choice
  onClick,
  checked,
  style,
  className,
}) {
  return (
    <svg
      className={className}
      style={style}
      width='7'
      height='9'
      viewBox='0 0 7 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.73999 8.03L5.25999 4.5L1.73999 0.969999'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
