import React, { useEffect, useRef } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import '../Logout/Logout.css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

function SmartGuestNotification({ memberInfo, prevPath }) {
  // const dismissRef = useRef(null);
  const navigate = useNavigate();
  const Tablet = useMediaQuery({
    query: '(min-width: 768px) ',
  });

  const handleUpdate = () => {
    navigate('/home/myprofile');
    localStorage.setItem('smart', 'clicked');
  };

  const handleDismiss = () => {
    localStorage.setItem('smart', 'clicked');
  };
  const notifySmartGuest = () =>
    toast(
      <div style={{ width: '100%' }}>
        <div
          style={{
            fontSize: '1.2rem',
            fontFamily: `'Lato', Arial, sans-serif`,
            userSelect: 'none',
            color: 'var(--color-accent)',
            paddingRight: '0.7rem',
          }}>
          Please update your Profile information
        </div>
        <br />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '0.5rem',
          }}>
          <button className='LogoutButton' onClick={handleUpdate}>
            UPDATE
          </button>
          <button className='LogoutButton' onClick={toast.dismiss()}>
            NOT NOW
          </button>
        </div>
      </div>,
      { containerId: 'smartGuest' },
      {
        // position: toast.POSITION.TOP_CENTER,
        className: 'Logout-Toaster',
      }
    );

  useEffect(() => {
    const smart = localStorage.getItem('smart');
    console.log(smart, 'smart');
    console.log(typeof smart);
    if (
      memberInfo &&
      (memberInfo.memberFirstName === null ||
        memberInfo.memberFirstName === '' ||
        memberInfo.memberLastName === null ||
        memberInfo.memberLastName === '') &&
      // prevPath === '/signIn' &&
      smart !== 'clicked'
    ) {
      notifySmartGuest();
    }
  }, []);

  console.log(prevPath, 'prevPath');

  // useEffect(() => {
  //   const handleDismiss = () => {
  //     localStorage.setItem('smart', 'clicked');
  //   };

  //   const element = dismissRef.current;

  //   if (element) {
  //     element.addEventListener('click', handleDismiss);
  //   }

  //   if (element) {
  //     return () => {
  //       element.removeEventListener('click', handleDismiss);
  //     };
  //   }
  // }, [dismissRef]);

  return (
    <div onClick={handleDismiss}>
      {Tablet ? (
        <ToastContainer
          enableMultiContainer
          containerId={'smartGuest'}
          limit={1}
          hideProgressBar={true}
          transition={Flip}
          closeButton={false}
          className='Login-ToastContainer'
          autoClose={false}
          style={{
            position: 'absolute',
            top: '35.5%',
            left: '50%',
            width: '367px',
            borderRadius: '0',
            margin: '0 auto',
            textAlign: 'center',
          }}
        />
      ) : (
        <ToastContainer
          limit={1}
          hideProgressBar={true}
          transition={Zoom}
          closeButton={false}
          className='Login-ToastContainer'
          autoClose={false}
          style={{
            position: 'absolute',
            top: '35%',
            left: '2%',
            right: '2%',
            width: '96vw',
            maxWidth: '767px',
            borderRadius: '0',
            margin: '0 auto',

            textAlign: 'center',
            // transform: 'translateY(170%)',
          }}
        />
      )}
    </div>
  );
}

export default SmartGuestNotification;
