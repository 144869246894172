import React from 'react';

export default function Toggle({
  size, // or any default size of your choice
  color, // or any color of your choice
  style,
  width,
  height,
  onClick,
  className,
}) {
  return (
    <svg
      className={className}
      onClick={onClick}
      style={style}
      width={width}
      height={height}
      viewBox='0 0 25 24'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.9854 22.75H8.98535C3.55535 22.75 1.23535 20.43 1.23535 15V9C1.23535 3.57 3.55535 1.25 8.98535 1.25H14.9854C20.4154 1.25 22.7354 3.57 22.7354 9V15C22.7354 20.43 20.4254 22.75 14.9854 22.75ZM8.98535 2.75C4.37535 2.75 2.73535 4.39 2.73535 9V15C2.73535 19.61 4.37535 21.25 8.98535 21.25H14.9854C19.5954 21.25 21.2354 19.61 21.2354 15V9C21.2354 4.39 19.5954 2.75 14.9854 2.75H8.98535Z'
        fill={color}
      />
      <path
        d='M7.98535 22.75C7.57535 22.75 7.23535 22.41 7.23535 22V2C7.23535 1.59 7.57535 1.25 7.98535 1.25C8.39535 1.25 8.73535 1.59 8.73535 2V22C8.73535 22.41 8.40535 22.75 7.98535 22.75Z'
        fill={color}
      />
      <path
        d='M14.9852 15.31C14.7952 15.31 14.6052 15.24 14.4552 15.09L11.8952 12.53C11.6052 12.24 11.6052 11.76 11.8952 11.47L14.4552 8.91C14.7452 8.62 15.2252 8.62 15.5152 8.91C15.8052 9.2 15.8052 9.68001 15.5152 9.97001L13.4952 12L15.5252 14.03C15.8152 14.32 15.8152 14.8 15.5252 15.09C15.3752 15.24 15.1852 15.31 14.9852 15.31Z'
        fill={color}
      />
    </svg>
  );
}
