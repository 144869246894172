import actionTypes from './user.actionTypes';

const userLoginStart = () => ({
  type: actionTypes.USER_LOGIN_START,
});

const userLoginSuccess = (member) => ({
  type: actionTypes.USER_LOGIN_SUCCESS,
  payload: member,
});

const userLoginError = (errorMessage) => ({
  type: actionTypes.USER_LOGIN_ERROR,
  payload: errorMessage,
});

const userLogout = () => ({
  type: actionTypes.USER_LOGOUT,
});

const setSidebarVisibility = (isVisible) => ({
  type: actionTypes.SET_SIDEBAR_VISIBILITY,
  payload: isVisible,
});
const setPrevPath = (currentPath) => ({
  type: actionTypes.SET_PREV_PATH,
  payload: currentPath,
});

export default {
  userLoginStart,
  userLoginSuccess,
  userLoginError,
  userLogout,
  setSidebarVisibility,
  setPrevPath,
};
