import React from 'react';

export default function Tick({
  size = 18, // or any default size of your choice
  color = 'black', // or any color of your choice
  width,
  height,
  viewBox,
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox={viewBox}
      width={width}
      height={height}>
      <g transform='translate(8,7) scale(0.9)'>
        <path
          fill='white'
          d='M20,38.5C9.799,38.5,1.5,30.201,1.5,20S9.799,1.5,20,1.5S38.5,9.799,38.5,20S30.201,38.5,20,38.5z'
        />
        <path
          fill='#26A65B'
          stroke='#26A65B'
          strokeWidth='3'
          d='M20,2c9.925,0,18,8.075,18,18s-8.075,18-18,18S2,29.925,2,20S10.075,2,20,2 M20,1 C9.507,1,1,9.507,1,20s8.507,19,19,19s19-8.507,19-19S30.493,1,20,1L20,1z'
        />
        <path
          fill='none'
          stroke='#26A65B'
          strokeMiterlimit='10'
          strokeWidth='3'
          d='M11 20L17 26 30 13'
        />
      </g>
    </svg>
  );
}
