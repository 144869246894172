/**
 * Contains methods for dates.
 */
export class DateLogic {
    /**
     * Calculates the allowed birthdate range, based on the the current day,
     * the minimum and the maximum age.
     * @returns An object of two properties; the min and max.
     */
     static getBirthdateRange = () => {
        const currentYear = new Date().getFullYear();
        const min = new Date();
        const max = new Date();

        min.setFullYear(currentYear - maxAge);
        max.setFullYear(currentYear - minAge);

        return {
            min: min.toLocaleDateString('en-GB').split('/').reverse().join('-'),
            max: max.toLocaleDateString('en-GB').split('/').reverse().join('-'),
        };
    };
}